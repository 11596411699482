<template>
  <section class="container lms-watch-our-lives">
    <div class="d-flex justify-content-between align-items-center mb-5">
      <span class="section-title">Assista nossas lives</span>
      <span class="see-all">Ver Mais</span>
    </div>
    <div class="container mb-3 px-0">
      <div class="section-cards d-flex flex-wrap g-1">

        <div class="swiper-container watch-lives">
          <div class="swiper-wrapper">

            <div class="swiper-slide" v-for="(live, idxLive) in lives.slice(0, 4)" :key="idxLive" @click="viewLive(live.slug)">

              <div class="cards" >
                <div class="cards-img" :style="{ 'background-image': 'url(' + live.thumbnail + ')' }">
                </div>
                <div class="d-flex live-info ms-4 mb-5">
                  <div class="border-gradient">
                    <div class="host rounded-circle" :style="{ 'background-image': 'url(' + live.thumbnail + ')' }"></div>
                  </div>
                  <div class="contact w-75 ms-3">
                    <div class="social-media-link">
                      <span>@alexandre</span>
                    </div>
                    <div class="live-title">
                      <span>Estratégia de negócios e transformação digital</span>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>


<!--        <div class="swiper-button-prev swiper-button-prev-watch-lives mt-0">-->
<!--          <i class="bi bi-chevron-left"></i>-->
<!--        </div>-->
<!--        <div class="swiper-button-next swiper-button-next-watch-lives mt-0">-->
<!--          <i class="bi bi-chevron-right"></i>-->
<!--        </div>-->

      </div>

    </div>
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import Swiper from "swiper";

// Import Swiper styles
import "swiper/swiper.min.css";

export default {
  name: "WatchLives",
  computed: {
    ...mapGetters('LMS', [
      'getLms'
    ]),
    lives: function(){
      // TODO change to lives
      if(!this.getLms.courses?.length) { return []; }
      return this.getLms.courses;
    }
  },
  mounted(){
    this.buildSwiper();
  },
  methods: {
    viewLive(liveSlug) {
      console.log("Live: ", liveSlug)
      // this.$router.push({
      //   name: "LMSCourses",
      //   params: {
      //     lmsSlug: this.getLms.slug,
      //     courseSlug: courseSlug
      //   }
      // });
    },
    buildSwiper() {
      let root_ = this;

      let options = {
        slidesPerView: 4,
        spaceBetween: 20,
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1.5
          },
          640: {
            slidesPerView: 2.5
          },
          1024: {
            slidesPerView: 2.5
          },
          1280: {
            slidesPerView: 3.5
          }
        },
        autoplay: false,
        loop: false,
        allowTouchMove: true,
        observer: true,
        navigation: {
          nextEl: ".swiper-button-next-watch-lives.swiper-button-next",
          prevEl: ".swiper-button-prev-watch-lives.swiper-button-prev",
        },
      };
      root_.swiper = new Swiper(".swiper-container.watch-lives", options);

    },


  }

}

</script>


<style scoped lang="scss">

.swiper-button-next-watch-lives, .swiper-button-prev-watch-lives {
  position: absolute;
  height: 100%;
  top: 0;
  background: #000;
  opacity: .3;

  .bi {
    color: #FFFFFF;
    font-size: 35px;
  }

}
.swiper-button-prev-watch-lives {
  left: 0;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.swiper-button-next-watch-lives {
  right: 0;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

  .section-cards {
    position: relative;
    gap: 30px;

    .swiper-container {
      width: 100%;
    }

    .cards {
      position: relative;
      height: 438px;
      cursor: pointer;
      flex: 1 0 auto;

      .cards-img {
        position: relative;
        background-size: cover;
        background-position: top center;
        background-repeat: no-repeat;
        background-blend-mode: saturation;
        height: 100%;
        width: 100%;
        border-radius: 30px;
      }

      a {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2;
      }

    }

    .live-info {
      position: absolute;
      bottom: 0;
      color: #FFFFFF;

      .host {
        width: 52px;
        height: 52px;
        position: relative;
        background-size: cover;
        background-position: top center;
        background-repeat: no-repeat;
        background-blend-mode: saturation;

      }

      .border-gradient {

        background: linear-gradient(to right, #6954D9, #A18AE5) border-box;
        color: #313149;
        border: 5px solid transparent;
        border-radius: 50%;
        display: inline-block;
        width: 65px;
        height: 62px;

      }

    }
  }

</style>