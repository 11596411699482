<template>
    <!-- <div class="box-bg" id="login" ref="login">
        <form class="ani box" id="login-form" ref="login-form" v-on:submit="login">
            <h3 class="tab">
                Login
                <router-link class="btn-0" to="/" ref="btn-close">
                    <img :src="$url + 'assets/img/icon_close_box.png'" alt="Fechar" style="float: right;margin: 20px 25px 0 0" width="16">
                </router-link>
            </h3>
            <ul>
                <li class="li-inline">
                    <LoginFacebook origin="login"></LoginFacebook>
                    <LoginGoogle origin="login"></LoginGoogle>
                </li>
                <li>
                    <input id="email" name="email" placeholder="Seu e-mail" ref="email" type="text">
                </li>
                <li>
                    <input id="password" name="password" placeholder="Sua senha" ref="password" type="password">
                    <router-link class="btn-simple" to="/requisitar-senha">Esqueci minha senha</router-link>
                </li>
                <li>
                    <input class="btn-red" ref="btn-submit" type="submit" :value="btn_submit_value">
                </li>
                <li>
                    <label for="btn-register">Ainda não é cadastrado?</label>
                    <router-link class="btn-blue" id="btn-register" to="/cadastro">CADASTRE-SE AQUI</router-link>
                </li>
            </ul>
        </form>
    </div> -->

    <div class="modals bgAzul" id="login" ref="login">
        <div v-on:click="$parent.modal_login_ = false;" style="z-index: -1;width: 100%; height: 100%; position: fixed; top: 0px; left: 0px;"></div>
        <div class="login">
            <div class="modals__content">
                <div class="modals__content--header alignVertical">
                    <p>{{ $t('login.login') }}</p>
                    <a class="btn-0" v-on:click="$parent.modal_login_ = false" ref="btn-close">
                        <img src="assets/icons/modals/x.svg" alt="">
                    </a>
                </div>
                <form class="modals__content--body flex flex-wrap" id="login-form" ref="login-form" v-on:submit="login_">

                    <div v-if="!webview && $parent.modal_login_" class="col-12 alignVertical" style="margin-bottom: 20px; gap: 5px;">
                        <div v-if="this.switch.Login_Gmail"><LoginGoogle origin="login"></LoginGoogle></div>
                        <div v-if="this.switch.Login_Facebook"><LoginFacebook origin="login"></LoginFacebook></div>
                        <div v-if="this.switch.Login_Azure"><LoginAzure origin="login" :active="true"></LoginAzure></div>
                    </div>
                    <div class="col-12" v-if="!this.switch.waiting_mode && (this.switch.Login_Gmail || this.switch.Login_Facebook || this.switch.Login_Azure)">
                        <hr>
                        <p v-if="$url==='https://ac.bancodebogota.com.co/'" class="text-gray" style="text-align: center;font-size: 1.2rem;padding: 0px 34px;">
                            Si no eres colaborador, conéctate aquí:
                        </p>
                        
                        <p v-else class="text-gray" style="text-align: center;font-size: 1.2rem;padding: 0px 34px;">
                            {{ $t('login.ouCadastreSeComEmail') }}
                        </p>
                    
                    </div>
                    <div class="col-12 flex">
                        <input class="col-12 text" v-model="logIn.emailL_" id="email" name="email" ref="email" type="text" :placeholder="$t('login.email')">
                    </div>
                    <div class="col-12 flex">
                        <input class="col-12 text" v-model="logIn.passwordL_" id="password" name="password" ref="password" type="password" :placeholder="$t('login.senha')">
                    </div>
                    <div v-if="this.show_captcha && $parent.modal_login_" class="col-12 flex" style="margin-top: 10px; justify-content: center; margin-left: 10px;">
                        <div class="col-12">
                            <!-- <img class="float-right" src="assets/images/home/capchat.png"> -->
                            <!-- <div v-if="$parent.modal_login_" id="captcha" class="g-recaptcha flex" style="justify-content:center" :data-sitekey="this.switch.captcha_client"></div> -->
                            <vue-recaptcha ref="recaptcha" @render="checkCaptcha = false" @verify="checkCaptcha = true;" @expired="checkCaptcha = false;" :sitekey="this.switch.captcha_client" />
                        </div>
                    </div>
                    <div class="col-12 flex alinItem">
                        <div class="col-12 flex" style="margin-left: 10px;">
                            <img style="cursor: pointer;height: 17px;padding: 4px;border: 1px solid #CECECE;border-radius: 4px;display: block;margin: auto 0;" :src="$url + 'assets/icons/header/question-rectangle.svg'">
                            <a v-on:click="$parent.modal_login_=false;$parent.modal_RememberPassword=true;">{{ $t('login.esqueceuSuaSenha') }}</a>
                        </div>
                    </div>

                    <div class="login__button flex col-12">
                        <input v-if="!checkCaptcha" class="button__buttonForm button__red col-12 button__desactive" ref="btn-submit" type="submit" :value="btn_submit_value" disabled="true">
                        <input v-else class="button__buttonForm button__red col-12" ref="btn-submit" type="submit" :value="btn_submit_value">
                    </div>

                    <div class="col-12 flex alinItem">
                        <hr class="col-12">
                    </div>

                    <p class="ainda">{{ $t('login.aindaNaoE') }}</p>

                    <div class="login__button flex col-12">
                        <a class="col-12" style="margin: 0px 10px 0px 10px;" v-on:click="$parent.modal_login_ = false;$parent.modal_cadastro_ = true;">
                            <button style="margin: 0px;" class="button__buttonForm button__secondary col-12" type="button">{{ $t('login.cadastreSeAqui') }}</button>
                        </a>
                    </div>

                </form>
                <div class="modals__content--footer flex">
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import { mapGetters, mapActions } from "vuex";
    import {VueRecaptcha} from 'vue-recaptcha';
    import LoginFacebook from '@/components/login/LoginFacebook';
    import LoginGoogle from '@/components/login/LoginGoogle';
    import LoginAzure from '@/components/login/LoginAzure';
    export default {
        name: 'Login',
        computed: {
            ...mapGetters([
                'webview', 'switch', 'reward_hash'
            ]),
            logIn(){
                var response = this.$store.getters['signup/cadastro']
                return response
            },
            show_captcha(){
                return (this.switch && this.switch.captcha && this.switch.captcha == 1)
            }
        },
        data() {
            return {
                btn_submit      : false,
                btn_submit_value: this.$i18n.t('login.entrar'),
                error           : null,
                captcha:null,
                checkCaptcha: false
            }
        },
        components: {
            LoginFacebook, LoginGoogle, VueRecaptcha, LoginAzure
        },
        methods: {
            ...mapActions([
                'update_email', 'update_email_verified',  'update_type_validation', 'update_logged', 'update_token', 'update_id','update_login_social'
            ]),
            block(v) {
                    this.btn_submit_value = (v ? (this.$i18n.t('login.aguarde')) : this.$i18n.t('login.entrar'));
                    this.$refs["btn-submit"].style.pointerEvents = (v ? 'none' : 'auto');
                    this.$refs["btn-submit"].style.opacity = (v ? '.4' : '1');
            },
            loginSocial(data) {
                this.$parent.loginSocial(data,this.block,this.loginResponse,this.loginError)
            },
            login_(e) {
                this.error = null;
                if (this.$refs["email"].value.length <= 0) {
                    this.error = this.$i18n.t('login.erroEmailNulo');
                } else if (this.$refs["password"].value.length <= 0) {
                    this.error = this.$i18n.t('login.erroSenhaNula');
                }
                if (this.error) {
                    this.$parent.modal('error', this.$i18n.t('login.erro'), this.error, null, null, null, "OK");
                    e.preventDefault();
                } else {
                    this.sendLogin(e);
                }
            },
            sendLogin(e) {
                if (!this.submit) {
                    this.block(true);
                    let form_data = new FormData(this.$refs["login-form"]);
                    axios.post(this.$url_api + 'api/login', form_data,{headers:{'language': this.$i18n.locale,}}).then(response => this.loginResponse(response)).catch(error => this.loginError(error.response));
                }
                e.preventDefault();
            },
            async loginResponse(obj,social=false,type='email',from="login",email=null) {
                this.block(false);
                email = obj.data.email;
                this.$parent.loginResponse(obj,social,type,from,email)
            },
            loginError(obj) {
                this.$parent.wait(false);
                this.block(false);
                let str = '';
                for (let o in obj.data.errors) {
                    str += obj.data.errors[o] + '\n';
                }
                //this.error = str;
                this.$parent.modal('error', this.$i18n.t('login.erro'), str, null, null, null, "OK");
                if (this.$refs.recaptcha) {
                    this.$refs.recaptcha.reset();
                    this.checkCaptcha = false
                }
                if (obj.data.errors["register_first"]){
                    this.$parent.modal_cadastro_ = true;
                    this.$parent.modal_login_ = false;
                }
            },
        },
        mounted() {
            this.$refs['email'].focus();
            let eventName = new Event('closeMenu');
            document.dispatchEvent(eventName);
            if (this.$parent.modal_login_  && !this.show_captcha)
                this.checkCaptcha = true
            this.$parent.bodyOverflow(true);
        },
        updated() {
            this.btn_submit_value = this.$i18n.t('login.entrar');
            if (this.$parent.modal_login_  && !this.show_captcha)
                this.checkCaptcha = true
        },
        destroyed() {
        },
        beforeDestroy() {
            this.$parent.bodyOverflow(false);
        },

    }
</script>
<style lang="scss" scoped>
#login {
  input[type=text] {
    margin: 6px 8px;
  }
  button, input[type=submit] {
    transition: all 0.25s ease;
    &:hover {
      border: none;
    }
  }
  .modals__content {
    border-radius: 6px 6px 4px 4px;
  }
}

</style>
