import { render, staticRenderFns } from "./LmsMyJourney.vue?vue&type=template&id=bf0ed2a8&scoped=true&"
import script from "./LmsMyJourney.vue?vue&type=script&lang=js&"
export * from "./LmsMyJourney.vue?vue&type=script&lang=js&"
import style0 from "./LmsMyJourney.vue?vue&type=style&index=0&id=bf0ed2a8&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf0ed2a8",
  null
  
)

export default component.exports