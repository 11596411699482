<template>
    <section class="list-badges">
        <div class="badges-container">

            <div class="title">
                <h1>{{ $t('badges.conquistarBadge') }}</h1>
            </div>
            <div class="select-session">
                <!-- <select name="session" v-on:change="filterBadge">
                    <option value="">{{ $t('badges.nomeSessao') }}</option>
                    <option v-for="(room, index) in all_rooms" :key="index">{{ room }}</option>
                </select>-->
                <!--<input autocomplete="off" id="search-label" maxlength="30" name="search-label" v-model="search" ref="search-label"
                    v-on:keydown="autocomplete_on_key_down" v-on:keyup="autocomplete_on_key_up" placeholder="Buscar..."
                    type="text">-->
                    <input id="search-input" name="search-label" type="text" :placeholder="$t('badges.nomeBadge')" v-model="search"
                    v-on:keydown="autocomplete_on_key_down" v-on:keyup="autocomplete_on_key_up" ref="search-label">
            </div>
            <div class="header_radius"></div>
            <div class="mosaico">
                <div class="badge" v-for="(badge, index) in badges" :key="index">
                    <div class="badge-container list-badges-container">
                        <div class="badge_img">
                            <video v-if="!/\.(jpe?g|png|svg|gif)$/i.test(badge.file_url)" :src="badge.file_url">
                                <source :src="badge.file_url">
                            </video>
                            <img v-else :src="badge.file_url">
                        </div>
                        <div class="text-elements">
                            <span class="badge_name">{{ badge.name }}</span>
                            <span class="badge_description">{{ badge.description }}</span>
                            <!--<span class="badge_unblocked" >DESBLOQUEADA</span>-->
                        </div>
                        <div class="mosaico-conquistar">
                            <p v-if="badge.points" class="flex">
                                <svg style="margin-right: 40px;" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.75437 1.38428C4.72845 1.38405 4.70287 1.39026 4.67995 1.40237C4.65703 1.41447 4.63748 1.43209 4.62307 1.45364C4.60865 1.47519 4.59982 1.49998 4.59738 1.52579C4.59493 1.55159 4.59894 1.5776 4.60906 1.60147L4.6989 1.81358L6.28874 5.52062C6.29955 5.54583 6.3168 5.56775 6.33876 5.58419C6.36073 5.60062 6.38663 5.61098 6.41387 5.61422C6.44111 5.61747 6.46872 5.61349 6.49393 5.60268C6.51914 5.59187 6.54106 5.57462 6.55749 5.55265L9.49187 1.63428C9.50928 1.61107 9.51988 1.58347 9.52249 1.55457C9.52509 1.52567 9.5196 1.49661 9.50662 1.47066C9.49365 1.4447 9.4737 1.42288 9.44901 1.40762C9.42433 1.39236 9.39589 1.38428 9.36687 1.38428H4.75437Z" fill="var(--buttons_color)"/>
                                    <path d="M16.4034 2.40222L14.7909 6.16667C14.7807 6.19044 14.7766 6.21635 14.779 6.24208C14.7813 6.26782 14.79 6.29257 14.8042 6.31413C14.8184 6.33569 14.8378 6.35338 14.8606 6.36562C14.8833 6.37785 14.9088 6.38426 14.9346 6.38425H18.8799C18.9078 6.38426 18.9351 6.37683 18.9592 6.36271C18.9832 6.3486 19.003 6.32832 19.0165 6.30398C19.0301 6.27963 19.0369 6.25211 19.0362 6.22426C19.0355 6.19641 19.0274 6.16924 19.0127 6.14558L16.6795 2.38152C16.6645 2.35729 16.643 2.33764 16.6176 2.32472C16.5921 2.31181 16.5636 2.3061 16.5352 2.30824C16.5067 2.31037 16.4794 2.32026 16.4561 2.33683C16.4329 2.3534 16.4147 2.37601 16.4034 2.40222Z" fill="var(--buttons_color)"/>
                                    <path d="M3.32077 2.38313L0.988351 6.14563C0.973656 6.1693 0.965555 6.19647 0.964889 6.22432C0.964222 6.25217 0.971013 6.27969 0.984559 6.30403C0.998106 6.32838 1.01791 6.34866 1.04193 6.36277C1.06595 6.37688 1.09331 6.38432 1.12116 6.38431H5.06648C5.09233 6.38428 5.11778 6.37783 5.14053 6.36554C5.16328 6.35326 5.18263 6.33552 5.19684 6.31392C5.21105 6.29231 5.21967 6.26752 5.22194 6.24177C5.22421 6.21601 5.22005 6.19009 5.20984 6.16634L3.59421 2.40383C3.58266 2.37828 3.56443 2.3563 3.54146 2.34021C3.51848 2.32412 3.4916 2.31451 3.46363 2.3124C3.43566 2.31028 3.40764 2.31573 3.3825 2.32817C3.35737 2.34062 3.33604 2.3596 3.32077 2.38313V2.38313Z" fill="var(--buttons_color)"/>
                                    <path d="M15.3624 1.38428H10.462C10.4479 1.38419 10.434 1.38805 10.4219 1.39544C10.4099 1.40283 10.4001 1.41344 10.3938 1.42608C10.3874 1.43872 10.3848 1.45288 10.3861 1.46696C10.3873 1.48104 10.3925 1.49448 10.4011 1.50576L13.5229 5.66436C13.5309 5.67504 13.5416 5.68344 13.5538 5.68869C13.5661 5.69395 13.5795 5.69588 13.5927 5.6943C13.6059 5.69271 13.6185 5.68766 13.6292 5.67967C13.6398 5.67167 13.6482 5.661 13.6534 5.64874L15.2972 1.81397L15.43 1.48819C15.4347 1.477 15.4366 1.46485 15.4355 1.45278C15.4345 1.4407 15.4305 1.42905 15.424 1.41884C15.4175 1.40862 15.4085 1.40014 15.398 1.39413C15.3875 1.38811 15.3757 1.38473 15.3636 1.38428H15.3624Z" fill="var(--buttons_color)"/>
                                    <path d="M9.33616 17.6343L5.8428 7.73232C5.83254 7.70357 5.81362 7.6787 5.78865 7.66114C5.76368 7.64359 5.73387 7.6342 5.70334 7.63428H1.00413C0.976904 7.6342 0.950208 7.64177 0.927079 7.65613C0.90395 7.67048 0.885319 7.69105 0.873308 7.71547C0.861297 7.7399 0.856389 7.76722 0.859145 7.7943C0.8619 7.82138 0.872207 7.84714 0.888892 7.86865L9.32639 18.7987C9.35495 18.8357 9.3957 18.8614 9.4414 18.8712C9.48709 18.881 9.53478 18.8743 9.576 18.8522C9.61746 18.8304 9.64969 18.7944 9.66683 18.7509C9.68397 18.7073 9.68487 18.659 9.66936 18.6147L9.33616 17.6343Z" fill="var(--buttons_color)"/>
                                    <path d="M18.9956 7.63428H14.2964C14.266 7.63427 14.2364 7.6437 14.2116 7.66127C14.1868 7.67883 14.1681 7.70366 14.1581 7.73232L10.3222 18.5979C10.3061 18.6436 10.3067 18.6934 10.324 18.7386C10.3414 18.7838 10.3742 18.8213 10.4167 18.8444C10.4592 18.8674 10.5085 18.8746 10.5558 18.8647C10.6031 18.8547 10.6453 18.8283 10.6749 18.7901L19.1108 7.86865C19.1275 7.84714 19.1378 7.82138 19.1406 7.7943C19.1433 7.76722 19.1384 7.7399 19.1264 7.71547C19.1144 7.69105 19.0958 7.67048 19.0727 7.65613C19.0495 7.64177 19.0228 7.6342 18.9956 7.63428V7.63428Z" fill="var(--buttons_color)"/>
                                    <path d="M10.1251 3.21747L12.3126 6.13427C12.33 6.15748 12.3406 6.18508 12.3432 6.21398C12.3458 6.24288 12.3403 6.27194 12.3274 6.29789C12.3144 6.32385 12.2944 6.34567 12.2697 6.36093C12.2451 6.37618 12.2166 6.38426 12.1876 6.38426H7.8126C7.78358 6.38426 7.75514 6.37618 7.73046 6.36093C7.70577 6.34567 7.68582 6.32385 7.67285 6.29789C7.65987 6.27194 7.65438 6.24288 7.65698 6.21398C7.65959 6.18508 7.67019 6.15748 7.6876 6.13427L9.8751 3.21747C9.88966 3.19806 9.90853 3.18231 9.93022 3.17146C9.95192 3.16062 9.97584 3.15497 10.0001 3.15497C10.0244 3.15497 10.0483 3.16062 10.07 3.17146C10.0917 3.18231 10.1105 3.19806 10.1251 3.21747V3.21747ZM9.85166 15.339L7.20713 7.84247C7.19883 7.81891 7.19629 7.79371 7.19973 7.76897C7.20318 7.74423 7.2125 7.72068 7.22693 7.70029C7.24135 7.67989 7.26045 7.66326 7.28263 7.65177C7.30481 7.64028 7.32942 7.63428 7.3544 7.63426H12.6458C12.6708 7.63428 12.6954 7.64028 12.7176 7.65177C12.7397 7.66326 12.7589 7.67989 12.7733 7.70029C12.7877 7.72068 12.797 7.74423 12.8005 7.76897C12.8039 7.79371 12.8014 7.81891 12.7931 7.84247L10.1474 15.339C10.1366 15.3694 10.1166 15.3957 10.0903 15.4144C10.0639 15.433 10.0324 15.443 10.0001 15.443C9.96781 15.443 9.93632 15.433 9.90995 15.4144C9.88358 15.3957 9.86363 15.3694 9.85283 15.339H9.85166Z" fill="var(--buttons_color)"/>
                                </svg>
                                {{badge.points}}
                            </p>
                        </div>
                        
                        <div class="mosaico-sessao">
                            <div class="badge-session" v-for="(session, i) in badge.sessions" :key="i">
                                <a v-if="session.session_name" href="#" v-on:click="goToAmbient(session.session_name)" class="flex">
                                    <svg style="margin-right: 5px;" width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_1798_12462)">
                                            <path d="M8 15.1343C6.14348 15.1343 4.36301 14.3968 3.05025 13.084C1.7375 11.7713 1 9.99079 1 8.13428C1 6.27776 1.7375 4.49728 3.05025 3.18453C4.36301 1.87178 6.14348 1.13428 8 1.13428C9.85652 1.13428 11.637 1.87178 12.9497 3.18453C14.2625 4.49728 15 6.27776 15 8.13428C15 9.99079 14.2625 11.7713 12.9497 13.084C11.637 14.3968 9.85652 15.1343 8 15.1343ZM8 16.1343C10.1217 16.1343 12.1566 15.2914 13.6569 13.7911C15.1571 12.2908 16 10.256 16 8.13428C16 6.01255 15.1571 3.97771 13.6569 2.47742C12.1566 0.977132 10.1217 0.134277 8 0.134277C5.87827 0.134277 3.84344 0.977132 2.34315 2.47742C0.842855 3.97771 0 6.01255 0 8.13428C0 10.256 0.842855 12.2908 2.34315 13.7911C3.84344 15.2914 5.87827 16.1343 8 16.1343V16.1343Z" fill="var(--buttons_color)"/>
                                            <path d="M5.2551 5.92028C5.25373 5.95257 5.25898 5.9848 5.27053 6.01499C5.28208 6.04518 5.29968 6.07269 5.32225 6.09583C5.34482 6.11896 5.37189 6.13723 5.40179 6.14952C5.43168 6.16181 5.46378 6.16785 5.4961 6.16728H6.3211C6.4591 6.16728 6.5691 6.05428 6.5871 5.91728C6.6771 5.26128 7.1271 4.78328 7.9291 4.78328C8.6151 4.78328 9.2431 5.12628 9.2431 5.95128C9.2431 6.58628 8.8691 6.87828 8.2781 7.32228C7.6051 7.81128 7.0721 8.38228 7.1101 9.30928L7.1131 9.52628C7.11415 9.59189 7.14095 9.65445 7.18772 9.70047C7.23449 9.7465 7.29748 9.77229 7.3631 9.77228H8.1741C8.2404 9.77228 8.30399 9.74594 8.35087 9.69905C8.39776 9.65217 8.4241 9.58858 8.4241 9.52228V9.41728C8.4241 8.69928 8.6971 8.49028 9.4341 7.93128C10.0431 7.46828 10.6781 6.95428 10.6781 5.87528C10.6781 4.36428 9.4021 3.63428 8.0051 3.63428C6.7381 3.63428 5.3501 4.22428 5.2551 5.92028V5.92028ZM6.8121 11.6833C6.8121 12.2163 7.2371 12.6103 7.8221 12.6103C8.4311 12.6103 8.8501 12.2163 8.8501 11.6833C8.8501 11.1313 8.4301 10.7433 7.8211 10.7433C7.2371 10.7433 6.8121 11.1313 6.8121 11.6833Z" fill="var(--buttons_color)"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1798_12462">
                                                <rect width="16" height="16" fill="white" transform="translate(0 0.134277)"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    {{session.session_title ? session.session_title : session.session_name}}
                                </a>
                            </div>
                        </div>
                        <div class="badge_cta" v-if="badge.cta && (!checkIOS && !checkMobile)">
                            <span>{{$t('badges.conquer')}}</span>
                            <button class="redirect_cta" @click="redirectCta(badge.external_url, badge.cta_type)">
                                {{ badge.cta_btn_label }}
                            </button>
                        </div>
                        <div class="badge_cta" v-else-if="badge.cta_mobile && (checkIOS || checkMobile)">
                            <span>{{$t('badges.conquer')}}</span>
                            <button class="redirect_cta" @click="redirectCta(badge.external_url_mobile, badge.cta_type_mobile)">
                                {{ badge.cta_btn_label_mobile }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="badge" v-if="all_badges.length == 0">
                    <div class="badge-container badge-empty">
                        <span class="badge_name" style="margin-top:0px">{{ $t('badges.nenhumBadge') }}</span>
                    </div>
                </div>
            </div>
        </div>

    </section>

</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";

export default {
    name: 'All-Badges',
    computed: {
        ...mapGetters([
            'logged', 'token', 'avatar', 'switch', 'sessions', 'checkIOS', 'checkMobile'
        ]),
    },
    data() {
        return {
            all_badges: [],
            all_rooms: [],
            badges: {},
            total_search: 0,
            search: ''
        }
    },
    components: {},
    methods: {
        ...mapActions([
            "update_switch",
        ]),
        loadBadges(search_, completeClick = false) {
            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                language: this.$i18n.locale, Authorization: `Bearer ${this.token}`
            };
            /*let form_data = new FormData();
            form_data.append('search', type_);*/
            axios.get(this.$url_api + "api/all-badges", { headers: headers, params: { search: search_ } })
                .then((response) => this.loadBadgesResponse(response, completeClick))
                .catch((error) => this.loadBadgesError(error.response));
        },
        loadBadgesResponse(obj, completeClick = false) {

            this.all_badges = obj.data.all_badges;
            /*if (this.all_rooms.length === 0) {
                this.all_rooms = [...new Set(obj.data.all_rooms)];
            }*/
            //this.all_rooms = obj.data.all_rooms;
            let comparison = [];
            this.badges = {};

            this.all_badges.forEach(element => {
                if (comparison.includes(element.id)) {
                    this.badges[element.id].sessions[element.session_id] = {
                        'session_id': element.session_id,
                        'session_name': element.session_name,
                        'session_title': element.session_title,
                        'session_url': element.url
                    }
                } else {
                    comparison.push(element.id);
                    this.badges[element.id] = {
                        'name': element.name,
                        'description': element.description,
                        'badge_id': element.id,
                        'file_path': element.file_path,
                        'file_url': element.file_url,
                        'points': element.points,
                        "cta": element.cta,
                        "external_url": element.external_url,
                        "cta_type": element.cta_type,
                        "cta_btn_label": element.cta_btn_label,
                        "cta_mobile": element.cta_mobile,
                        "external_url_mobile": element.external_url_mobile,
                        "cta_type_mobile": element.cta_type_mobile,
                        "cta_btn_label_mobile": element.cta_btn_label_mobile,
                        'sessions': {},
                    };
                    this.badges[element.id].sessions[element.session_id] = {
                        'session_id': element.session_id,
                        'session_name': element.session_name,
                        'session_title': element.session_title,
                        'session_url': element.url
                    }
                }
                console.log('badges =>', this.badges)
            });

            if(completeClick) {
                setTimeout(function() {
                    document.getElementById('search-input').value = completeClick;
                }, 100)
            }
        },
        loadBadgesError(obj) {
            console.log('ERRO =>', obj);
        },
        goToAmbient(ambient) {
            const sessions = JSON.parse(JSON.stringify(this.sessions));

            const session = sessions.find(obj => {
                return obj.name === ambient;
            });

            if (session === undefined) this.$router.push({ name: 'Map' });
            else this.$router.push({ path: "/e/" + session.name });
        },
        searchBadge() {
            if (this.$refs['search-label'].value != '') {
                this.$parent.wait(true);
                let headers = {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Access': 'application/json',
                    language: this.$i18n.locale, Authorization: `Bearer ${this.token}`
                };
                axios.get(this.$url_api + 'api/all-badges', {
                    headers: headers,
                    params: { search: this.$refs['search-label'].value }
                }).then(response => this.searchBadgeResponse(response)).catch(error => this.searchBadgeError(error.response));
            } else {
                /*this.searchCompleated = "";
                this.autocomplete_clear_all();
                this.total_search = 0;
                this.all_badges = [];*/
                this.loadBadges();
            }
        },
        searchBadgeResponse(obj) {
            if (obj.data.status == 'success') {
                //this.$parent.analytics('search_friend', {});
                this.autocomplete_clear_all();
                this.total_search = obj.data.all_badges.length;
                this.all_badges = obj.data.all_badges;
                if (obj.data.all_badges.length > 0) {

                    let root_ = this;

                    let autocomplete_div, autocomplete_ul, autocomplete_li;
                    autocomplete_div = document.createElement('div');
                    autocomplete_div.setAttribute("class", "auto-complete");
                    autocomplete_div.setAttribute("id", "search-badge-auto-complete");

                    autocomplete_ul = document.createElement("ul");
                    autocomplete_ul.setAttribute("id", "search-label-auto-complete-list");
                    autocomplete_ul.setAttribute("class", "auto-complete-items");
                    autocomplete_div.appendChild(autocomplete_ul);
                    this.$refs['search-label'].parentNode.appendChild(autocomplete_div);
                    
                    for (let autocomplete_t in obj.data.all_badges) {
                        autocomplete_li = document.createElement("li");
                        autocomplete_li.innerHTML = obj.data.all_badges[autocomplete_t].name + ', ' + (obj.data.all_badges[autocomplete_t].session_title ? obj.data.all_badges[autocomplete_t].session_title : '');
                        autocomplete_li.addEventListener("click", function () { root_.autocomplete_click_option(obj.data.all_badges[autocomplete_t]); });
                        autocomplete_ul.appendChild(autocomplete_li);
                    }

                }

                let comparison = [];
                this.badges = {};

                this.all_badges.forEach(element => {
                    if (comparison.includes(element.id)) {
                        this.badges[element.id].sessions[element.session_id] = {
                            'session_id': element.session_id,
                            'session_name': element.session_name,
                            'session_title': element.session_title,
                            'session_url': element.url
                        }
                    } else {
                        comparison.push(element.id);
                        this.badges[element.id] = {
                            'name': element.name,
                            'description': element.description,
                            'badge_id': element.id,
                            'file_path': element.file_path,
                            'file_url': element.file_url,
                            'points': element.points,
                            'sessions': {}
                        };
                        this.badges[element.id].sessions[element.session_id] = {
                            'session_id': element.session_id,
                            'session_name': element.session_name,
                            'session_title': element.session_title,
                            'session_url': element.url
                        }
                    }
                });
            }
            this.$parent.wait(false);
        },
        searchBadgeError(obj) {
            obj;
            this.$parent.wait(false);
        },
        //----- auto-complete -----//
        autocomplete_clear_all() {
            let autocomplete_count_all = document.getElementsByClassName('auto-complete').length;
            if (autocomplete_count_all > 0) {
                for (let autocomplete_t = 0; autocomplete_t < autocomplete_count_all; autocomplete_t++) {
                    document.getElementsByClassName('auto-complete')[autocomplete_t].remove();
                }
            }
        },
        autocomplete_remove_active(x) {
            for (let i = 0; i < x.length; i++)
                x[i].classList.remove("auto-complete-active");
        },
        autocomplete_add_active(x, e) {
            if (!x) return false;
            this.autocomplete_remove_active(x);
            if (e.target.dataset.currentFocus >= x.length) e.target.dataset.currentFocus = 0;
            if (e.target.dataset.currentFocus < 0) e.target.dataset.currentFocus = (x.length - 1);
            x[e.target.dataset.currentFocus].classList.add("auto-complete-active");
        },
        autocomplete_on_key_down(e) {
            if (e.keyCode === 189)
                e.preventDefault();

            if (e.target.hasAttribute("data-current-focus") == false) {
                e.target.setAttribute("data-current-focus", -1);
            }

            let x = document.getElementById(e.target.name + "-auto-complete-list");
            if (x) x = x.getElementsByTagName("li");
            if (e.keyCode === 40) {
                e.target.dataset.currentFocus++;
                this.autocomplete_add_active(x, e);
            } else if (e.keyCode === 38) {
                e.target.dataset.currentFocus--;
                this.autocomplete_add_active(x, e);
            } else if ((e.keyCode === 9) || (e.keyCode === 13)) {
                if (e.target.dataset.currentFocus > -1) {
                    if (x) {
                        x[e.target.dataset.currentFocus].click();
                    }
                }
                e.preventDefault();
            }
        },
        autocomplete_on_key_up(e) {
            switch (e.keyCode) {
                case 13: /* enter */
                    this.searchBadge();
                    //if (e.target.dataset.currentFocus === -1)
                    //    search(null);
                    break;
                case 9: /* tab */
                case 38: /* up */
                case 40: /* down */
                    break;
                default:
                    this.searchBadge();
                    //oi
                    //autocomplete_load(e);
                    // this.searchBadge();
                    break;
            }
        },
        autocomplete_click_option(obj) {
            this.loadBadges(obj.name, obj.name);
            document.getElementById('search-badge-auto-complete').remove();
            //autocomplete_clear(params);
            //utocomplete_clear_all();

            //window["auto_complete_callback_" + params.e.target.name.replaceAll("-", "_")](obj);
        },
        //----- \auto-complete\ -----//
        redirectCta(url, type) {
            switch(type) {
                case 'self':
                    window.open(url, '_top');
                    break;
                case 'iframe':
                    this.$parent.modalI("", url, null, "OK");
                    break
                case 'blank':
                    window.open(url, '_blank')
                    break
            }
        }
    },
    mounted() {
        this.loadBadges(0);
    },
    beforeDestroy() {

    },
}
</script>
<!--scss-->
<style lang="scss" scoped>
.badge_cta {
    display:flex;
    flex-direction: column;
    width:100%;
    justify-content: center;
    align-items: center;

    .redirect_cta {
        border: 3px solid #DFDFDF;
        background-color: var(--buttons_color);
        width: 70%;
        border-radius: 18px;
        line-height: 1;
        padding: 20px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
}
#search-input {
    width: 100%;
    border-radius: 20px;
    border: 2px solid #DFDFDF;
    padding-left: 20px;
    height: 100%;
}

#search-input:focus-visible {
    border: 2px solid #DFDFDF;
    outline:transparent;
}

#search-input::placeholder {
    color: rgba(64, 64, 64, 0.4);
    font-weight: 600;
    /*font-size: 24px;*/
    line-height: 37px;
}
/*----- auto-complete -----*/
.auto-complete {
    /*display: none;*/
    font-family: inherit;
    font-size: inherit;
    position: relative;
    width: 100%;
}

.auto-complete-items {
    color: #000 !important;
    border: 1px solid #d4d4d4;
    border-bottom: 1px solid #d4d4d4;
    border-top: none;
    left: 0;
    position: absolute;
    right: 0;
    /*top: 100%;*/
    z-index: 99;
    box-sizing: border-box !important;
    display: block !important;
    height: auto !important;
    margin-top: 0px !important;
    padding: 0 !important;
    width: 100% !important;
}

.auto-complete-items>li {
    background-color: #fff;
    border: none !important;
    border-bottom: 1px solid #d4d4d4;
    cursor: pointer;
    list-style: none;
    padding: 10px !important;
    font-family: "Roboto", sans-serif;
}

.auto-complete-items>li:hover {
    background-color: #fff !important;
    /*color: DodgerBlue !important;*/
    color: #283a6c !important;
}

.auto-complete-active {
    /*background-color: DodgerBlue !important;*/
    background-color: var(--buttons_color) !important;
    color: #ffffff;
}

/*----- \auto-complete\ -----*/
</style>
