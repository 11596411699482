<template>
    <div>
        <LmsPrivateMessages v-if="openMessages" ref="private-messages" :wait="wait" :friend_id="privateMessagesUser"></LmsPrivateMessages>
        <div v-else-if="!openMessages">
            <section class="container-list-inbox">
                <div v-for="(l, index) in privateMessagesUsers" :key="index" class="card-inbox" v-on:click="openPrivateMessage(l)">
                    <div class="inbox-img-wrap">
                        <img class="profile-pic" :src="l.avatar" alt="imagem de um amigo"/>
                        <div v-if="l.online" style="padding: 6px;background-color: #0FFF35; border: 2px solid #FFF;" class="info__online"></div>
                        <div v-else style="padding: 6px;background-color: #D0D0D0; border: 2px solid #FFF;" class="info__online"></div>
                    </div>
                    <div class="inbox-info-wrap">
                        <h4 class="inbox-name">{{ l.name }} <span class="inbox-date">{{ l.inbox.last_message_formated }}</span></h4>
                        <p class="inbox-last-message">{{ l.inbox.last_message }}</p>
                    </div>
                    <!-- <div v-if="l.inbox.unread > 0" class="inbox-unread"><span>{{ l.inbox.unread }}</span></div> -->
                    <div v-if="l.inbox.unread > 0" class="inbox-unread">
                        <span v-text="l.inbox.unread > 10 ? '10+' : l.inbox.unread"></span>
                    </div>
                </div>
            </section>
            <div class="load-more-inbox" v-if="!is_end">
                <button type="button" class="button_load" v-on:click="loadInbox(offset);"> Carregar mais... </button>
            </div>
        </div>
    </div>
</template>
  
<script>
import LmsPrivateMessages from "@/components/lms/components/LmsFriendsComponents/LmsPrivateMessages.vue";

    export default {
        name: "Inbox",
        props: ['wait', 'changeSubtitle'],
        components: {
            LmsPrivateMessages
        },
        watch:{

        },
        data() {
            return {
                openMessages: false,
                privateMessagesUser: null,
                privateMessagesUsers: [],
                is_end: false,
                limit: null,
                offset: 0,   
            }
        },
        computed: {

        },
        mounted(){

        },
        beforeMount(){
            this.loadInbox();
        },
        methods: {
            openPrivateMessage(user){
                this.loading(true);
                var text = this.createSubtitle(user);
                this.changeSubtitle('inbox', text)
                this.privateMessagesUser = user.id;
                this.openMessages = true;
                this.loading(false);
            },
            createSubtitle(payload){
                let img = `<img style="width: 53px; height: 53px; object-fit: contain; object-position: center; border-radius: 50%" src=${payload.avatar}>`
                let status;
                if(payload.online) {
                    status = `<div style="padding: 6px;background-color: #0FFF35; border: 2px solid #FFF; width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    position: absolute;
                    top: 0;
                    right: 0;"></div>`
                } else {
                    status = `<div style="padding: 6px;background-color: #D0D0D0; border: 2px solid #FFF;    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    position: absolute;
                    top: 0;
                    right: 0;"></div>`
                }

                let imageDiv = `
                    <div style="position: relative; margin-right: 5px; margin-left: 10%">
                        ${img}
                        ${status}
                    </div>
                `
                
                let name = `<span style="font-size: 22px">${payload.name}</span>`

                let div = `<div style="display: flex; align-items: center;">
                    ${imageDiv}
                        &nbsp;
                    ${name}
                    </div>`
                return div;
            },
            loading(state) {
                this.wait(state);
            },
            updateInbox(payload){
                const userId = payload.user.id;
                if(this.privateMessagesUsers.length > 0) {
                    const userIndex = this.privateMessagesUsers.findIndex(user => user.id === userId);
                    if (userIndex !== -1) {
                        this.privateMessagesUsers[userIndex].inbox.last_message = payload.message.message;
                        this.privateMessagesUsers[userIndex].inbox.from_user = payload.message.from_user;
                        this.privateMessagesUsers[userIndex].inbox.to_user = payload.message.to_user;
                        this.privateMessagesUsers[userIndex].inbox.last_message_formated = payload.message.last_message_formated;
                        this.privateMessagesUsers[userIndex].inbox.unread += 1
                    } else {
                        return;
                    }
                } else {
                    this.loadInbox(0, false)
                }
            },
            loadInbox(offset = 0, load = true, reset = false) {
                let root_ = this;
                this.loading(load);
                let form_data   = new FormData();
                form_data.append('offset', offset);
                if(!reset) {
                    form_data.append('search', this.$parent.searchText)
                } 

                root_.$store.dispatch('Friends/LoadInbox', form_data).then( response => {
                    
                    let more_inbox      = null;
                    root_.offset         = response.data.pagination.offset;
                    root_.limit          = (root_.limit) ? root_.limit : response.data.pagination.limit;
                    root_.is_end         = response.data.pagination.is_end;

                    if( root_.offset > root_.limit ) {
                        more_inbox      = response.data.users;
                        for(let i=0; i<more_inbox.length; i++) {
                            root_.privateMessagesUsers.push(more_inbox[i]);
                        }
                    }
                    else {
                        root_.privateMessagesUsers   = response.data.users;
                    }
                    this.loading(false);

                }).catch( error => {
                    console.log('loadInboxError => ', error);
                    this.loading(false);
                });
            },
        }
    }
</script>

<style lang="scss">
    .button_load {
        background-color: #6954D9 !important;
        padding: 8px 20px;
        border-radius: 10px;
    }

    .button_load:hover {
        background-color: #3e23db !important;
    }
    .card-inbox {
        border-top: none !important;
        border-bottom: 1px solid #E1E1E1;
        padding: 20px 10px 20px 10px;
        gap: 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;
        transition: all 0.3s ease;

        .profile-pic {
            border-radius: 50% !important;
        }

        .inbox-info-wrap {
            .inbox-name {
                font-family: Hanken Grotesk;
                font-size: 16px;
                font-weight: 700;
                line-height: 20.85px;
                letter-spacing: 0em;
                text-align: left;
                color: #6954D9;

                .inbox-date {
                    font-family: Hanken Grotesk;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #706F6F;
                }
            }

            .inbox-last-message {
                font-family: Hanken Grotesk;
                font-size: 16px;
                font-weight: 400;
                line-height: 21px;
                letter-spacing: 0em;
                text-align: left;
                color: #706F6F;
                margin-top: 2px;
            }
        }
        .inbox-unread {
            background-color: #6954D9 !important;
            margin-right: 6px;
        }
    }

    .card-inbox:hover {
        background-color: #F9F9F9 !important;
        color: #6954D9 !important;
        .inbox-name {
            color: #6954D9 !important;
        }
    }

</style>