<template>
    <div id="modal_badge" ref="modal_badge" class="modals bgTransparent" style="z-index: 99999;">
        <div class="badge-container" v-on:click="openModalWith(badge_file,badge_name,badge_external_url)">
            <span class="close" v-on:click="close"><svg width="12" height="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.09654 8.00002L15.5443 1.55224C15.6717 1.40345 15.7383 1.21206 15.7308 1.01631C15.7232 0.820559 15.642 0.634873 15.5035 0.496355C15.365 0.357837 15.1793 0.27669 14.9836 0.269129C14.7878 0.261568 14.5964 0.328151 14.4476 0.455571L7.99987 6.90335L1.55209 0.447793C1.40563 0.301335 1.20699 0.219055 0.999871 0.219055C0.792747 0.219055 0.594107 0.301335 0.447648 0.447793C0.30119 0.594252 0.21891 0.792892 0.21891 1.00002C0.21891 1.20714 0.30119 1.40578 0.447648 1.55224L6.9032 8.00002L0.447648 14.4478C0.366229 14.5175 0.300102 14.6033 0.253418 14.6998C0.206733 14.7963 0.180498 14.9014 0.176361 15.0085C0.172223 15.1156 0.190272 15.2225 0.229375 15.3223C0.268477 15.4221 0.327789 15.5127 0.403587 15.5885C0.479385 15.6643 0.570033 15.7236 0.669842 15.7627C0.76965 15.8018 0.876464 15.8199 0.983579 15.8157C1.09069 15.8116 1.1958 15.7854 1.29229 15.7387C1.38879 15.692 1.47459 15.6259 1.54431 15.5445L7.99987 9.09668L14.4476 15.5445C14.5964 15.6719 14.7878 15.7385 14.9836 15.7309C15.1793 15.7233 15.365 15.6422 15.5035 15.5037C15.642 15.3652 15.7232 15.1795 15.7308 14.9837C15.7383 14.788 15.6717 14.5966 15.5443 14.4478L9.09654 8.00002Z" fill="black"/>
                </svg></span>
            <div class="badge-data">
                <div class="badge_img">
                    <video v-if="!/\.(jpe?g|png|svg|gif)$/i.test(badge_file)" :src="badge_file">
                        <source :src="badge_file">
                    </video>
                    <img v-else :src="badge_file">
                </div>
                <div class="text-elements">
                    <span class="badge_name" >{{ badge_name }}</span>
                    <span class="badge_description" >{{ badge_text }}</span>
                </div>
            </div>
            
            <button class="button_badge" v-on:click="close">Ok</button>
        </div>

    </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
    name: 'ModalBadge',
    props: ['badge_file','badge_name','badge_text','badge_external_url',],
    computed: {
        ...mapGetters([
            'logged', 'token','switch'
        ]),
        // isMobileIframe(){
        //     return window.innerWidth < 450 && this.$parent.isMobile && (this.t == 'image' || this.t == 'iframe');
        // },
        // t(){
        //     if (this.modal_type)
        //         return this.modal_type.split("_")[0];
        //     return this.modal_type;
        // },
        // sub_t(){
        //     console.log(this.modal_type);
        //     if (this.modal_type)
        //         return this.modal_type.split("_")[1]??"";
        //     return "";
        // },
        // txt(){
        //     if (this.modal_text)
        //         return this.modal_text.split(/[\n\r]/);
        //     return [];
        // }
    },
    data() {
        return {
            openModal: false,
            badge_title_: "",
            badge_url_: "",
            badge_external_url_: "",
        }

    },
    methods: {
        close(e) {
            this.$parent.modal_iframe = false
            this.$parent.modal_ = false
            this.$parent.modal_badge_ = false;
            document.getElementById('iframe').contentWindow.postMessage({message_type:"external",data: "krpano_modal", params: {}}, '*')
            e.preventDefault();
        },
        // openModalWith(badge_file,badge_name,badge_external_url){
        //     this.openModal = true;
        //     this.badge_title_ = badge_name;
        //     if (badge_external_url != null)
        //         this.badge_external_url_ = badge_external_url;
        //     this.badge_url_ = badge_file;
        // },
    },
    created() {

    },
    mounted() {
        //console.log("mounted");
        //console.log(this.t);
    },
    beforeDestroy() {
    },
}
</script>

<style scoped>
.badge-container {
    padding: 25px;
    border-radius: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 345px;
    height: 430px;
    background-color: white;
    border-radius: 30px;
    flex-direction: column;
}
img, video {
    object-fit: cover;
    min-width: 200px;
    max-width: 200px;
    min-height: 200px;
    max-height: 200px;
}

.text-elements{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.badge_name {
    max-width: 200px;
    width: 200px;
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--buttons_color);
    display:block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.badge_description {
    max-width: 200px;
    height: 105px;
    overflow: hidden;
    font-size: 13px;
    flex-wrap: wrap;
    text-align: center;
    word-break: break-word;
    color:#555;
    display:block;
    margin-top:10px;
}
.button_badge {
    background-color: var(--buttons_color);
    color: var(--fonts-color);
    width: 139px;
    height: 50px;
    border:none;
    border-radius: 10px;
}

.badge-data {
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:space-between;
    height:290px;
    margin:-30px 0px 10px;
}
.close{
cursor: pointer;
position: relative;
right: -165px;
top: 0;
}
</style>