<template>

    <div id="modal" ref="modal" class="modals bgBlack">
        <div style="width: 100vw;height: 100vh;position: fixed;top: 0;left: 0;" data-response="0" v-on:click="closeCancel"></div>
        <div ref="buy-handler-content" class="modalGerais" id="modalKrpano">
            <div class="modals__content alignVertical">
                <div class="modals__content--header alignVertical">
                    <p>{{ title }}</p>
                    <a href="#" class="closeCancel" data-response="0" v-on:click="closeCancel">
                        <img src="assets/icons/modals/x.svg" alt="">
                    </a>
                </div>
                <div class="modals__content--body alignVertical">
                    <div v-if="mode=='block'">
                        <p>Este ambiente não está acessível a todos os usuários</p>
                        <div class="col-12" style="text-align:center;margin-top:30px">
                            <div style="width:auto;display:inline-block;">
                                <button v-on:click="closeCancel" class="button__buttonForm button__red">OK</button>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="mode=='ask'">
                        <p>Para acessar este ambiente é necessário informar uma senha:</p>
                        <div class="col-12" style="text-align:center;margin-top:30px">
                            <input type="password" @keyup.enter="enter" v-model="password" class="text" 
                                style="width:70%;background-image:url('assets/icons/modals/lock.png');background-repeat:no-repeat;background-position:95% center;background-size:30px" 
                                    placeholder="Senha de acesso">
                            <p style="color:red" v-if="error_pass">A senha informada é inválida</p>
                        </div>
                        <div class="col-12" style="text-align:center;margin-top:30px">
                            <div style="width:auto;display:inline-block;">
                                <button v-on:click="closeCancel" class="button__secondary">CANCELAR</button>
                                <button v-on:click="enter" class="button__buttonForm button__red" :disabled="password.length==0">ENTRAR</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    
    import { mapActions, mapGetters } from "vuex";
    import axios from "axios";
    
    export default {
        name: 'SessionAccess',
        props: ['mode'],
        computed: {
            ...mapGetters([
                'logged', 'token', "current_session","switch", "use_map"
            ]),
        },
        data() {
            return {
               iframe_actual:null,
               error_pass: false,
               title:"Acesso Restrito",
               password:""
            }
        },
        components: {
            
        },
        methods: {
            ...mapActions([
                'update_id_form_actual'
            ]),
            closeCancel(e) {
                console.log('route =>', this.$route.name)
                this.$parent.session_access = false;
                if (e)
                    e.preventDefault();

                if (this.use_map == 3)
                    this.$parent.logoutEnd()
                else
                    this.$parent.link('Map')
                // this.$parent.link('Map');
            },
            enter(e){
                if (e)
                    e.preventDefault();

                this.$parent.wait(true);
                var _root = this;
                _root.error_pass = false;
                let headers = { 'Content-Type': 'application/json;charset=UTF-8', 'Access': 'application/json', language: this.$i18n.locale, Authorization : `Bearer ${this.token}`};
                axios.post(`${this.$url_api}api/enter_ambient_private`,{
                    password:_root.password,
                    session:_root.current_session.name,
                    event:_root.switch.Nome_Aba
                }, {headers}).then(response=>{
                    if(response.data.can === true) {
                        if (_root.use_map == 3) {
                            _root.$parent.setCookie('map',_root.password)
                            _root.$parent.session_access = false;
                            _root.$parent.wait(false);
                            _root.$router.go(0);
                        } else {
                            _root.$parent.setCookie(`${_root.switch.Nome_Aba}-${_root.current_session.name}`,_root.password)
                            _root.$parent.session_access = false;
                            _root.$parent.wait(false);
                            _root.$router.go(0); // refreshing
                        }

                    }else{
                        _root.$parent.wait(false);
                        _root.error_pass = true;
                    }
                }).catch(()=>{
                    _root.$parent.wait(false);
                    _root.error_pass = true;
                });
            }
        },
        mounted() {
        },
    }
</script>

<style scoped>
    #modal
    {
        z-index: 999;
    }
    .box
    {
        width: 550px;
    }
    .tab
    {
        text-align: left;
    }

    iframe
    {
        display: block;
        height: 100%;
        min-height: 350px;
        width: 99%;
    }


    /*----- body -----*/
    .modal-body {
        box-sizing: border-box;
        display: block;
        min-height: 400px;
        padding: 25px 15px;
        text-align: center;
        width: 100%;
    }
    /*----- \body\ -----*/


    @media screen and (max-width: 550px)
    {
        .box
        {
            width: calc(100% - 40px);
        }
    }
</style>