<template>
    <div id="blocked-users" class="blocked-users" ref="blocked-users" style="padding-top: 0px;">
        <div class="container">
            <router-link v-if="this.switch.Habilitar_Amigos" class="amigos--button" :to="`/amigos`">
                <div class="amigos--button-icon">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M7.34992 0.81888C7.35007 0.648254 7.29697 0.48185 7.19806 0.342955C7.09915 0.204059 6.95936 0.0996234 6.79828 0.0442622C6.63719 -0.011099 6.46285 -0.0146151 6.29967 0.034206C6.13649 0.083027 5.99263 0.181742 5.88821 0.316537L0.172052 7.67988C0.0605418 7.8235 0 8.00026 0 8.18222C0 8.36418 0.0605418 8.54094 0.172052 8.68456L5.88821 16.0479C5.99263 16.1827 6.13649 16.2814 6.29967 16.3302C6.46285 16.3791 6.63719 16.3755 6.79828 16.3202C6.95936 16.2648 7.09915 16.1604 7.19806 16.0215C7.29697 15.8826 7.35007 15.7162 7.34992 15.5456V12.2811C11.7424 12.3728 13.8525 13.2081 14.9124 14.1163C15.9201 14.9794 16.1046 15.9947 16.2965 17.0583L16.3463 17.3324C16.383 17.5285 16.4899 17.7045 16.647 17.8271C16.804 17.9498 17.0004 18.0107 17.1992 17.9985C17.398 17.9862 17.5854 17.9016 17.7263 17.7605C17.8671 17.6195 17.9517 17.4317 17.964 17.2326C18.1037 14.9843 17.8938 11.7264 16.3088 9.00119C14.7703 6.35611 11.9996 4.32219 7.34992 4.10947V0.81888Z"
                              fill="white"/>
                    </svg>
                </div>
                <div class="amigos--button-text">{{ $t('blockedusers.amigos') }}</div>
            </router-link>
            <h1>{{ $t('blockedusers.usuriosBloqueados') }}</h1>
            <div class="container-content">
                <main>
                    <ul>
                        <li class="blocked--users-list" v-for="(blocked, key) in list_blocked" :key="key">
                            <div class="blocked--users-list-item">
                                <div class="blocked--users-list-item-avatar"
                                     :style="userProfile(blocked.avatar_url)"></div>
                                <div class="blocked--users-list-item-name">{{ blocked.name }}</div>
                            </div>
                            <div class="blocked--users-list-item-button">
                                <button class="blocked--users-list-item-button-unblock" @click="unlockRequest(blocked)">{{ $t('blockedusers.desbloquear') }}</button>
                            </div>
                        </li>
                    </ul>
                </main>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import {mapGetters} from "vuex";

export default {
    name: 'BlockedUsers',
    computed: {
        ...mapGetters([
            'logged', 'token', 'switch'
        ]),
    },
    data() {
        return {
            e_actual: null,
            list_blocked: [],
            total_blocked: 0,

            userToUnblock: {},
        }
    },
    methods: {
        //----- blocked -----//
        blockedRequest() {
            this.$parent.wait(true);
            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                language: this.$i18n.locale, Authorization: `Bearer ${this.token}`
            };
            axios.get(this.$url_api + 'api/blockedUsers', {headers: headers}).then(response => this.blockedResponse(response)).catch(error => this.blockedError(error.response));
        },
        blockedResponse(obj) {
            if (obj.data.data.length > 0) {
                this.total_blocked = obj.data.data.length;
                this.list_blocked = obj.data.data;
            }
            this.$parent.wait(false);
        },
        blockedError(obj) {
            obj;
            this.$parent.wait(false);
        },
        //----- \blocked\ -----//

        //----- unlock -----//
        unlockRequest(blocked) {
            this.userToBlock = blocked;
            this.$parent.modal('confirmation', 'Atenção!', 'Deseja realmente desbloquear esse usuário?', this.unlockRequestResponse);
        },
        unlockRequestResponse(r) {
            let friend = this.userToBlock;

            if (r === 1) {
                this.$parent.wait(true);
                let headers = {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Access': 'application/json',
                    language: this.$i18n.locale, Authorization: `Bearer ${this.token}`
                };
                axios.post(this.$url_api + 'api/unBlockUser/' + friend.id, null, {headers: headers}).then(response => this.unlockResponse(response, friend.id)).catch(error => this.unlockError(error.response));
            }
        },
        unlockResponse(obj, id) {
            if (obj.data.status == 'success') {
                this.total_blocked--;
                for (let o in this.list_blocked) {
                    if (this.list_blocked[o].id == id) {
                        this.list_blocked.splice(o, 1);
                    }
                }
                this.$parent.modal('success', 'Sucesso!', obj.data.data, null, null, null, "OK");
            }
            this.$parent.wait(false);
        },
        unlockError(obj) {
            if (obj.data.status == 'error') {
                this.$parent.modal('error', 'Erro!', obj.data.message, null, null, null, "OK");
            }
            this.$parent.wait(false);
        },
        //----- \unlock\ -----//
        userProfile(avatarURL) {
            return avatarURL ? `background: url('${avatarURL}') center center / cover;` : 'background-color: #000;';
        },
    },
    mounted() {
        this.blockedRequest();
    },
}
</script>