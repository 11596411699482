<template>
    <div class="modals bgAzul">
        <div v-on:click="$parent.modal_cadastro_ = false" style="z-index: -1000;width: 100%; height: 100%; position: fixed; top: 0px; left: 0px;"></div>
        <div class="cadastro">
            <div class="modals__content">
                <div class="modals__content--header alignVertical">
                    <p>{{ $t('signup.cadastreSe') }}</p>
                    <a class="btn-0" v-on:click="$parent.modal_cadastro_ = false" ref="btn-close">
                        <img src="assets/icons/modals/x.svg" alt="">
                    </a>
                </div>
                <div v-show="!webview" style="position: relative; margin-top: 20px;gap:5px;justify-content: center" class="modals__content-social-medias flex">
                    <LoginGoogle v-if="this.switch.Login_Gmail" origin="register"></LoginGoogle>
                    <LoginFacebook v-if="this.switch.Login_Facebook" origin="register" ></LoginFacebook>
                    <LoginAzure v-if="this.switch.Login_Azure" origin="register"
                        :active="!((this.switch.waiting_mode && signUp.emailS_.length==0) || !checkCaptcha || (!this.switch.waiting_mode && !isActive))"></LoginAzure>
                </div>
                <div v-if="this.switch.header_signup_terms" class="modals__content--body flex flex-wrap" style="padding: 10px 0 0">
                    <div class="col-sm-12 col-12 alignVertical" v-if="!this.switch.waiting_mode">
                        <div v-for="op in this.switch.optins" :key="op.id" class="cadastro__termos flex flex-wrap col-12 alignVertical" style="margin: 0 10px;">
                            <div class="col-12 flex" style="align-items: center;justify-content: center;">
                                <input class="checkbox" v-model="signUp.checks[op.id]" :id="'header_' + op.id" :name="op.name" :ref="'header_'+op.name" type="checkbox" value="1" :data-id="op.id" :data-required="Boolean(op.required)" v-on:change="optinHandler">
                                <label class="home__terms alignVertical" v-on:click="handleLabelClick" :for="'header_' + op.id"  v-html="String(op.title.substring(3, op.title.length))"></label>
                            </div>
                        </div>
                    </div>
                </div>
                <form v-on:submit="signup" ref="signup-form" id="signup-form">
                    <input type="hidden" name="friend_code" id="ref" :value="ref">
                    <div class="modals__content--body flex flex-wrap">
                        <div v-if="this.switch.waiting_mode" class="col-12">
                            <p style="text-align: center;font-size: 20px;">Pré-Cadastro Metaverso Fazenda</p>
                            <p class="text-gray" style="text-align: center;font-size: 15px;padding: 0px 34px;">
                                No momento nossa capacidade máxima foi atingida.<br><br>
                                Por favor informe seu email para entrar na fila e enviaremos uma mensagem assim que sua vez chegar. Agradecemos pela compreensão!
                            </p>
                        </div>
                        <div class="col-12" v-if="!this.switch.waiting_mode && (this.switch.Login_Gmail || this.switch.Login_Facebook || this.switch.Login_Azure)">
                            <hr>
                            <p v-if="$url==='https://ac.bancodebogota.com.co/'" class="text-gray" style="text-align: center;font-size: 1.2rem;padding: 0px 34px;">
                                Si no eres colaborador, regístrate aquí:
                            </p>
                            <p v-else class="text-gray" style="text-align: center;font-size: 1.2rem;padding: 0px 34px;">
                                {{ $t('signup.ouCadastreSeComEmail') }}
                            </p>
                        </div>
                        <div v-if="!this.switch.waiting_mode" class="col-sm-12 col-6 alignVertical">
                            <input class="col-12 text" id="name" v-model="signUp.nameS_" name="name" :placeholder="$t('signup.nomeCompleto')" ref="name" type="text">
                            <p class="col-12 flex text-gray extra-label-signup" >{{ $t('signup.ate255Caracteres') }}</p>
                        </div>
                        
                        <div v-if="!this.switch.waiting_mode" class="col-sm-12 col-6 alignVertical">
                            <input class="col-12 text" v-model="signUp.nicknameModelS_" v-mask="'NNNNNNNNNNNNN'"
                                maxlength="13" id="nickname" name="nickname" :placeholder="$t('signup.apelido')" ref="nickname" type="text">
                            <p class="col-12 flex text-gray extra-label-signup" >{{ $t('signup.de5a13Caracteres') }}</p>
                        </div>

                        <div v-if="!this.switch.waiting_mode && Boolean(this.switch.signup_extra_fields['birth'])" class="col-sm-12 col-6 alignVertical">
                            <input class="col-12 text"
                                id="birth_date" name="birth_date"  v-model="signUp.birthS_" :placeholder="$t('signup.dataDeNascimento')" ref="birth_date" v-mask="'##/##/####'" type="text">
                            <p class="col-12 flex text-gray extra-label-signup"  v-if="this.only_major">*Este evento é para maiores de idade</p>
                        </div>

                        <div v-if="!this.switch.waiting_mode && Boolean(this.switch.signup_extra_fields['cpf'])" class="col-sm-12 col-6 alignVertical">
                            <input class="col-12 text" v-model="signUp.cpfS_" @keyup="mask_cpf" id="cpf" name="cpf" :placeholder="$t('signup.cpf')" ref="cpf" type="text">
                            <p class="col-12 flex text-gray extra-label-signup" v-if="Boolean(this.switch.signup_extra_fields['birth']) && this.only_major">&nbsp;</p>
                        </div>
                        <div v-if="!this.switch.waiting_mode && Boolean(this.switch.signup_extra_fields['enrollment'])" class="col-sm-12 col-12 alignVertical">
                            <input class="col-12 text" id="enrollment" name="enrollment" :placeholder="$t('signup.enrollmentOrCPF')" ref="enrollment" type="text">
                        </div>
                        <div v-if="!this.switch.waiting_mode && Boolean(this.switch.signup_extra_fields['phone'])" class="col-sm-12 col-6 alignVertical">
                            <input class="col-12 text" v-model="signUp.phoneS_" @keyup="mask_phone" id="phone" name="phone" :placeholder="$t('signup.phone')" ref="phone" type="text">
                            <p class="col-12 flex text-gray extra-label-signup" v-if="Boolean(this.switch.signup_extra_fields['birth']) && !Boolean(this.switch.signup_extra_fields['cpf']) && this.only_major">&nbsp;</p>
                        </div>
                        <div v-if="!this.switch.waiting_mode && Boolean(this.switch.signup_extra_fields['company'])" class="col-sm-12 col-6 alignVertical">
                            <input class="col-12 text" v-model="signUp.companyS_" id="company" name="company" :placeholder="$t('signup.company')" ref="company" type="text">
                        </div>
                        <div v-if="!this.switch.waiting_mode && Boolean(this.switch.signup_extra_fields['state'])" class="col-sm-12 col-6 alignVertical">
                            <select class="col-12 text" v-model="signUp.stateS_" name="state" id="state" ref="state" @change="cityHandler">
                                <option value="">{{ $t('signup.state') }}</option>
                                <option :key="state.id" v-for="state in states" :value="state.value" :data-value="state.id">{{state.value}}</option>
                            </select>
                        </div>
                        <div v-if="!this.switch.waiting_mode && Boolean(this.switch.signup_extra_fields['state'])" class="col-sm-12 col-6 flex">
                            <select class="col-12 text" v-model="signUp.cityS_" name="city" id="city" ref="city">
                                <option value="">{{ $t('signup.city') }}</option>
                                <option v-for="(item, index) in cities" :key="index" :value="item.name">{{ item.name }}</option>
                            </select>
                        </div>

                        <div v-if="!this.switch.waiting_mode && Boolean(this.switch.signup_extra_fields['region'])" class="col-sm-12 col-6 alignVertical">
                            <select class="col-12 text" v-model="signUp.regionS_" name="region" id="region" ref="region">
                                <option value="" selected>{{ this.$i18n.t('signup.region') }}</option>
                                <option :key="region.id" v-for="region in regions" :value="region.id">{{region.region_name}}</option>
                            </select>
                        </div>

                        <div class="col-12 flex">
                            <input class="col-12 text" v-model="signUp.emailS_" id="email" name="email" :placeholder="$t('signup.meuEmail')" ref="email" type="email">
                        </div>

                        <div v-if="!this.switch.waiting_mode && Boolean(this.switch.signup_extra_fields['nationality'])" class="col-sm-12 col-12 alignVertical">
                            <select class="col-12 text" v-model="signUp.nationalityS_" name="nationality" id="nationality" ref="signup_nationality">
                                <option value="">{{ this.$i18n.t('signup.nacionalidade') }}</option>
                                <option v-for="(country, index) in nacionality" :key="index" :value="country.sigla" :data-value="country.sigla">{{country.nome_pais_int}}</option>
                            </select>
                        </div>

                        <div v-if="!this.switch.waiting_mode && this.switch.group_name" class="col-12 flex">
                            <select class="col-12 text" name="group_name" id="group_name">
                                <option value="">{{ $t('signup.grupo') }}</option>
                                <option v-for="(gn, index) in this.switch.group_name" :key="index" :value="gn.id">{{ gn.group_name }}</option>
                            </select>
                        </div>

                        <div v-if="!this.switch.waiting_mode" class="col-sm-12 col-6 flex">
                            <input class="col-12 text" v-model="signUp.passwordS_" id="password" name="password" :placeholder="$t('signup.senha')" ref="password" type="password">
                        </div>

                        <div v-if="!this.switch.waiting_mode" class="col-sm-12 col-6 flex">
                            <input class="col-12 text" v-model="signUp.passwordConfS_" id="password_confirmation" name="password_confirmation" :placeholder="$t('signup.confirmeASenha')" ref="password_confirm" type="password">
                        </div>

                        <p v-if="!this.switch.waiting_mode" class="col-12 flex text-gray extra-label-signup" >{{ $t('signup.de8a20Caracteres') }}</p>

                        <div :class="{'col-sm-12':true,'col-6':this.show_captcha,'col-12':!this.show_captcha, 'alignVertical':true}" v-if="!this.switch.waiting_mode">
                            <div v-if="this.switch.iframe_signup_terms">
                                <iframe :src="this.switch.iframe_signup_terms_url" frameborder="0" width="100%" height="100%" style="min-height: 200px;"></iframe>
                            </div>

                            <div v-for="op in this.switch.optins" :key="op.id" class="cadastro__termos flex flex-wrap col-12">
                                <div class="col-12 flex">
                                    <div>
                                        <input class="checkbox" v-model="signUp.checks[op.id]" :id="'_' + op.id" :name="op.name" :ref="op.name" type="checkbox" value="1" :data-id="op.id" :data-required="Boolean(op.required)" v-on:change="optinHandler">
                                    </div>
                                    <div>
                                        <label v-on:click="handleLabelClick" :for="'_' + op.id" class="home__terms" v-html="String(op.title.substring(3, op.title.length))"></label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="this.show_captcha" class="col-sm-12 col-6 alignVertical" style="margin: 10px 0px; justify-content: center;">
                            <div v-if="this.show_captcha" class="col-12">
                                <vue-recaptcha ref="recaptcha" @render="checkCaptcha = false" @verify="checkCaptcha = true;" @expired="checkCaptcha = false;" :sitekey="this.switch.captcha_client" />
                            </div>
                        </div>

                        <!-- <div class="col-12" v-if="!this.switch.waiting_mode">
                            <p class="text-gray" style="text-align: center;">{{ $t('signup.essesDadosSerao') }}</p>
                        </div> -->

                    </div>
                    <div class="modals__content--footer flex">
                        <div class="cadastro__button flex col-12">
                            <div class="flex col-6" style="max-height: 60px; align-self: end;">
                                <input v-if="(this.switch.waiting_mode && signUp.emailS_.length==0) || !checkCaptcha || (!this.switch.waiting_mode && !isActive)" id="btn-register-confirm" class="button__buttonForm button__red col-12 button__desactive" disabled="true" ref="btn-submit" type="submit" :value="btn_submit_value">
                                <input v-else class="button__buttonForm button__red col-12" ref="btn-submit" id="btn-register-confirm" type="button" v-on:click="signup" :value="btn_submit_value">
                            </div>

                            <div class="flex flex-wrap col-6">
                                <div class="col-12 alignVerticalEnd">
                                    <p class="register" style="margin-bottom: 5px; margin-right: 0 !important;" for="btn-register">{{ $t('signup.jaECadastrado') }}</p>
                                </div>
                                <div class="col-12 alignVerticalEnd">
                                    <a class="button__secondary" id="btn-register" @click="$parent.modal_login_=true;$parent.modal_cadastro_=false;">{{ $t('signup.entreAqui') }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </form>
            </div>
        </div>
    </div>

</template>

<script>
import axios from "axios";
import countries from '../../../public/js/countries.js';
import { mapGetters, mapActions, mapMutations } from "vuex";
import {VueRecaptcha} from 'vue-recaptcha';
import LoginFacebook from '@/components/login/LoginFacebook';
import LoginGoogle from '@/components/login/LoginGoogle';
import LoginAzure from '@/components/login/LoginAzure';

export default {
    name: 'Signup',

    computed: {
        ...mapGetters([
                'webview', 'switch', 'checkers', 'isGuest'
        ]),

        isActive() {
            if(this.requireds === 0){
                return true
            } 
            return (this.checkers == this.requireds)
        },
        signUp() {
            var response = this.$store.getters['signup/cadastro']
            response.cpfS_ = this.mask_cpf(false,response.cpfS_)
            response.phoneS_ = this.mask_phone(false,response.phoneS_)
            return response
        },
        show_captcha(){
            return (this.switch && this.switch.captcha && this.switch.captcha == 1)
        },
        requireds(){
            let count = 0;
            for (let o in this.switch.optins)
                if (this.switch.optins[o].required == 1)
                    count++;

            return count;
        },
        only_major(){
            return this.switch.only_major??false;
        }
    },

    data() {
        return {
            btn_submit      : false,
            btn_submit_value: this.$i18n.t('signup.cadastrar'),
            error           : null,
            ref             : null,
            captcha:null,
            checkCaptcha: false,
            cities: null,
            user_token: null,
            user_guest: false,
            regions: null,
            states:[
                {id:1,value:"Acre",capital:"Rio Branco"},{id:2,value:"Alagoas",capital:"Maceió"},{id:3,value:"Amapá",capital:"Macapá"},
                {id:4,value:"Amazonas",capital:"Manaus"}, {id:5,value:"Bahia",capital:"Salvador"}, {id:6,value:"Ceará",capital:"Fortaleza"},
                {id:7,value:"Distrito Federal",capital:"Brasília"}, {id:8,value:"Espírito Santo",capital:"Vitória"},
                {id:9,value:"Goiás",capital:"Goiânia"}, {id:10,value:"Maranhão",capital:"São Luís"},
                {id:11,value:"Mato Grosso",capital:"Cuiabá"}, {id:12,value:"Mato Grosso do Sul",capital:"Campo Grande"},
                {id:13,value:"Minas Gerais",capital:"Belo Horizonte"}, {id:14,value:"Pará",capital:"Belém"},
                {id:15,value:"Paraíba",capital:"João Pessoa"}, {id:16,value:"Paraná",capital:"Curitiba"},
                {id:17,value:"Pernambuco",capital:"Recife"}, {id:18,value:"Piauí",capital:"Teresina"},
                {id:19,value:"Rio de Janeiro",capital:"Rio de Janeiro"},
                {id:20,value:"Rio Grande do Norte",capital:"Natal"}, {id:21,value:"Rio Grande do Sul",capital:"Porto Alegre"},
                {id:22,value:"Rondônia",capital:"Porto Velho"}, {id:23,value:"Roraima",capital:"Boa Vista"},
                {id:24,value:"Santa Catarina",capital:"Florianópolis"}, {id:25,value:"São Paulo",capital:"São Paulo"},
                {id:26,value:"Sergipe",capital:"Aracaju"}, {id:27,value:"Tocantins",capital:"Palmas"},
            ],
            nacionality: countries
        }
    },

    components: {
        LoginFacebook, LoginGoogle, VueRecaptcha, LoginAzure
    },

    methods: {
        ...mapMutations('signup', ['updateKeepOpen']),
        ...mapActions([
            'update_logged', 'update_token', 'update_email_verified','update_type_validation', 'update_id','update_login_social',"update_age_verified", "update_checkers", "update_guest"
        ]),
        handleLabelClick() {
            this.updateKeepOpen(true);
        },
        block(v) {
            this.btn_submit_value = (v ? (this.$i18n.t('signup.aguarde')) : this.$i18n.t('signup.cadastrar'));
            this.$refs["btn-submit"].style.pointerEvents = (v ? 'none' : 'auto');
            this.$refs["btn-submit"].style.opacity = (v ? '.4' : '1');
        },
        just_number(v)
        {
            return v.replace(/\D/g, '');
        },
        check(e)
        {
            if ((e.key !== "Backspace") || (e.key !== "ArrowLeft") || (e.key !== "ArrowRight") || (e.key !== "Tab") || (e.key !== "Delete"))
            {
                if (!/^[0-9]*$/.test(e.key))
                {
                    e.preventDefault();
                    return false;
                }
            }
        },
        mask_phone(e, value)
        {
            if (e)
            {
                this.check(e);
                let x = this.just_number(e.currentTarget.value).match(/(\d{0,2})(\d{0,5})(\d{0,5})/);
                e.currentTarget.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '' + x[3] : '');
            }
            else if (value)
            {
                let x = this.just_number(value).match(/(\d{0,2})(\d{0,5})(\d{0,5})/);
                return !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '' + x[3] : '');
            }
            else
            {
                return null;
            }
        },
        mask_cpf(e, value)
        {
            if (e)
            {
                this.check(e);
                let v = this.just_number(e.currentTarget.value).match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/);
                e.currentTarget.value = !v[2] ? v[1] : v[1] + '.' + v[2] + (v[3] ? '.' + v[3] : '') + (v[4] ? '-' + v[4] : '');
            }
            else if (value)
            {
                let v = this.just_number(value).match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/);
                return !v[2] ? v[1] : v[1] + '.' + v[2] + (v[3] ? '.' + v[3] : '') + (v[4] ? '-' + v[4] : '');
            }
            else
            {
                return null;
            }
        },
        check_captcha() {

        },
        cityHandler(e)
        {
            let form_data = new FormData();
            let root = this;
            if (e) {
                form_data.append('uf', this.$refs['state'].options[e.currentTarget.selectedIndex].dataset.value);
                this.signUp.cityS_ = "";
            }else if (root.signUp.stateS_){
                let state = this.states.find(_ => _.value == root.signUp.stateS_)
                if(state) form_data.append('uf', this.states.find(_ => _.value == root.signUp.stateS_).id);
                else return;
            }else return;
            let headers = { "Content-Type": "application/json;charset=UTF-8", Access: "application/json", language: this.$i18n.locale, Authorization: `Bearer ${this.token}` };
            axios.post(this.$url_api + 'api/store/get_cities', form_data, { headers: headers }).then(response => this.cityHandlerResponse(response));
            if (e)
                e.preventDefault();
        },
        cityHandlerResponse(r)
        {
            let root = this;
            let data = r.data;
            let capital = this.states.find(_=>_.value===root.signUp.stateS_).capital;
            const capitalArray = data.filter(_=>_.name===capital)
            data = data.filter(_=>_.name!==capital)
            this.cities = [...capitalArray,...data];
        },
        optinHandler(e)
        {
            if (this.checkers < 0) {
                this.update_checkers(0)
            }
            if (e.currentTarget.dataset.required)
            {
                if (e.currentTarget.checked)
                    this.update_checkers(this.checkers+1);
                    
                else
                    this.update_checkers(this.checkers-1);
            }
        },
        loginSocial(data) {
            if (data.type === 'azure'){
                for (const optin of this.switch.optins) {
                    data[optin.name] = this.signUp.checks[optin.id]
                }
            }
            if (window.localStorage.getItem('query_tracking')){
                data['query_tracking'] = window.localStorage.getItem('query_tracking');
            }
            this.$parent.loginSocial(data,this.block,this.signupResponse,this.signupError)
        },
        signup(e) {
            this.error = null;
            if (this.switch.waiting_mode)
            {
                if(this.show_captcha && this.checkCaptcha)
                    this.sendWaitingSignup();                
            }
            else
            {
                if (this.switch.signup_extra_fields['birth']) {
                    let date_birth = this.$refs['birth_date'].value.split('/')
                    let birthday = new Date(date_birth[2] + "-" + date_birth[1] + "-" + date_birth[0])

                    let result = Math.floor((new Date() - birthday.getTime()) / 3.15576e+10)
                    console.log(result)
                    if (this.only_major && (result < 18)) {
                        this.error = 'Idade não permitida'
                    }
                }
                if (this.error) {
                    this.$parent.modal('error', this.$i18n.t('signup.erro'), this.error, null, null, null, "OK");
                } else {
                    this.sendSignup(e);
                }
            }
            e.preventDefault();
            return false;
        },
        sendSignup() {
            if (!this.submit) {
                this.block(true);
                
                let form_data = new FormData(this.$refs["signup-form"]);
                if (this.switch.signup_extra_fields['birth']) {
                    let birth_date = this.$refs["birth_date"].value.split('/').reverse().join('-')
                    form_data.append('birth_date', birth_date)
                }
                if (window.localStorage.getItem('query_tracking')) {
                    form_data.append('query_tracking', window.localStorage.getItem('query_tracking'));
                }

                if(this.user_guest) {
                    if (localStorage.getItem("user")) {
                        let user = JSON.parse(localStorage.getItem("user"));
                        this.user_token = user.token;
                    } else {
                        this.user_token = null;
                    }
                    this.block(true);
                    let headers = {
                        "Content-Type": "application/json;charset=UTF-8",
                        'Access': "application/json",
                        'language': this.$i18n.locale,
                        'Authorization': `Bearer ${this.user_token}`
                    };
                    axios
                        .post(this.$url_api + "api/updateGuest", form_data,{headers: headers})
                        .then((response) => this.signupResponse(response))
                        .catch((error) => this.signupError(error.response));
                } else {
                    axios.post(this.$url_api + 'api/registerWithAge', form_data,{headers:{'language': this.$i18n.locale,}}).then(response => this.signupResponse(response)).catch(error => this.signupError(error.response));
                }
            }
        },
        sendWaitingSignup() {
            if (!this.submit) {
                this.block(true);
                let form_data = new FormData(this.$refs["signup-form"]);
                axios.post(this.$url_api + 'api/waiting_signup', form_data,{headers:{'language': this.$i18n.locale,}}).then(response => this.waitingSignupResponse(response)).catch(error => this.waitingSignupError(error));
            }
        },
        signupResponse(obj,social = false, type='email', ref='',email=null) {
            ref;
            if (this.ref != '')
            {
                this.$parent.analytics('signup_by_friend_invite');
            }
            email = obj.data.email;
            this.$parent.loginResponse(obj,social,type,'signup',email)
            this.block(false);
        },
        signupError(obj)
        {
            this.block(false);
            let str = '';
            
            for (let o in obj.data.errors) {
                if (Array.isArray(obj.data.errors[o])) {
                    obj.data.errors[o].forEach((r) => {
                        str += r.charAt(0).toUpperCase() + r.slice(1) + '\n'
                    })
                } else {
                    str += obj.data.errors[o].charAt(0).toUpperCase() + obj.data.errors[o].slice(1) + '\n';
                }
            }
            this.$parent.modal('error', this.$i18n.t('signup.erro'), str, null, null, null, "OK");
            if (this.$refs.recaptcha) {
                this.$refs.recaptcha.reset();
                this.checkCaptcha = false
            }
        },
        waitingSignupResponse(){
            var root = this;
            this.$store.dispatch("signup/reset")
            this.$parent.modal('success', 'Confirmação', 'Pré-cadastro realizado com sucesso!', function(){           
                root.$parent.modal_cadastro_ = false;
            }, null, null, "OK");    
        },
        waitingSignupError()
        {
            this.block(false);
            this.$parent.modal('error', this.$i18n.t('signup.erro'), 'Erro ao tentar registrar seu pré-cadastro', null, null, null, "OK");
        },
        // getSetupResponse(data){
        //     this.optins = ((data && data.data && data.data.optins) ? data.data.optins : null);
        //     this.only_major = ((data && data.data && data.data.only_major) ? data.data.only_major : null);
        // },
    },
    mounted() {
        this.regions = this.switch.regions;
        if(localStorage.getItem("user")) {
            let user = JSON.parse(localStorage.getItem("user"));
            this.user_guest = user.is_guest;
            /*if (!this.user_guest) 
            {
                this.$parent.getSetup();
            }*/
        } else {
            this.user_guest = false;
        }
        if (this.$route.query._==="84502530"){
            this.signUp.emailS_ = this.$route.query.email??"";
        }
        if (this.switch.waiting_mode)
            this.$refs['email'].focus();
        else
            this.$refs['name'].focus();
        this.ref = this.$route.query.ref ? this.$route.query.ref : '';

        let eventName = new Event('closeMenu');
        document.dispatchEvent(eventName);
        this.cityHandler()
        
        if(this.$parent.modal_cadastro_ && !this.show_captcha)
            this.checkCaptcha = true;

        let totalCheckers = 0
        for (const optinKey in this.switch.optins) {
            const optin = this.switch.optins[optinKey];
            if (optin.required && this.signUp.checks[optin.id]) {
                totalCheckers++
            }
        }
        this.update_checkers(totalCheckers);
        this.$parent.bodyOverflow(true);
    },
    updated() {
        if(this.$parent.modal_cadastro_ && !this.show_captcha)
            this.checkCaptcha = true;
        this.btn_submit_value = this.$i18n.t('signup.cadastrar');
    },
    beforeDestroy() {
        this.$parent.bodyOverflow(false);
    }
}

</script>
<style lang="scss" scoped>
#btn-register {
  border: 0;
  padding: 18px;
  background-color: var(--buttons_color_2nd);
  box-shadow: 0px 1px 4px rgb(0 0 0 / 25%);
  border-radius: 8px;
  color: var(--fonts_color_2nd);
  cursor: pointer;
  font-weight: bold;
  line-height: 148.5%;
  font-size: 16px;
  transition: all 0.25s ease;
  &:hover {
    border: none;
    color: var(--buttons_color_2nd);
    background-color: var(--buttons_color);
  }
}

#btn-register-confirm {
  border: 0;
  transition: all 0.25s ease;
  &:hover {
    border: none;
    color: var(--buttons_color);
    background-color: var(--buttons_color_2nd);
  }
}

.modals__content {
  border-radius: 6px 6px 4px 4px;
}
</style>