<template>
    <div id="amigos" class="amigos" ref="amigos" style="padding-top: 0px;">
        <div class="container">
            <div class="container-content">
                <main>
                    <section class="spacing-master">
                        <div class="master-container-sub-header">
                            <div class="container-invite-friends">
                                <h1 class="title-friends">{{ $t('friends.amigos') }}</h1>
                                <div v-if="this.switch.Habilitar_convidar_amigos" class="invite--friends">
                                    <router-link to="/usuarios-convidados">
                                        <div class="plus--signal">
                                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus"
                                                class="svg-inline--fa fa-plus fa-w-14" role="img"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0    0 448 512">
                                                <path fill="currentColor"
                                                    d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path>
                                            </svg>
                                        </div>
                                        <div class="anchor--text">{{ $t('friends.convidarAmigos') }}</div>
                                    </router-link>
                                </div>
                            </div>
                            <div class="search--area">
                                <label for="search-input" class="search-input">
                                <span class="get-friend">{{ $t('friends.buscar') }}</span>
                                    <input id="search-input" type="text" :placeholder="$t('friends.buscarAmigos')" v-model="search"
                                        @keyup.enter="searchFriend" ref="search-label">
                                    <button class="btn-transparent" v-on:click="searchFriend">
                                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search"
                                            class="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512">
                                            <path fill="currentColor"
                                                d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
                                        </svg>
                                    </button>
                                </label>
                            </div>
                        </div>
                        <!-- <h1 :style="{ 'visibility': data.friends.length > 0 ? 'visible' : 'hidden' }" >{{ $t('friends.meusAmigos', { '1': data.friendsCount }) }}</h1> -->
                    

                        <section v-if="friendsResult.length > 0" class="container-list-friends">
                            <!-- card friend -->
                            <div v-for="(friend, keyFriendsResult) in friendsResult" class="card-friend" :key="keyFriendsResult">
                                <div class="p-relative">
                                    <img class="profile-pic" :src="friend.avatar" alt="imagem de um amigo"/>
                                    <div v-if="!friend.friend"></div>
                                    <div v-else-if="friend.online" style="background-color: #0FFF35"
                                        class="info__online">
                                    </div>
                                    <div v-else style="background-color: #D0D0D0;"
                                        class="info__online">
                                    </div>
                                </div>
                                <div class="container-name-friend">
                                    <h4 class="name-friend">{{friend.name}}</h4>
                                    <div v-if="friend.friend && friend.ambient && friend.online" class="friend--area--button">
                                        <a :title="$i18n.t('friends.irAteAmigo')" class="alignVertical" @click="goToFriend(friend.ambient)">
                                            <img src="../../public/assets/images/friends/svg/mapa.svg" alt="">
                                            <div class="friend--area--name" v-text="friend.ambient"></div>
                                        </a>
                                    </div>
                                </div>
                                <div v-if="!friend.friend" class="container-adding-friend">
                                    <button v-if="!friend.requested" v-on:click="requestAddFriend(friend.id)" class="btn-transparent" > <img width="40px" :title="$i18n.t('friends.convidarAmigo')" src="../../public/assets/images/friends/svg/adicionar.svg" alt="imagem para excluir um amigo"/> </button>
                                    <button v-else class="btn-transparent" >  <img width="40px" :title="$i18n.t('friends.conviteFeito')" src="../../public/assets/images/friends/svg/adicionado.svg" alt="imagem para excluir um amigo"/> </button>
                                    <button class="btn-transparent" v-on:click="openPrivateMessage(friend.id)">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.5977 10C14.5977 10.2578 14.4974 10.4727 14.2969 10.6445C14.125 10.8164 13.9102 10.9023 13.6523 10.9023H4.5L0.847656 14.5977V1.75C0.847656 1.49219 0.933594 1.27734 1.10547 1.10547C1.27734 0.933594 1.49219 0.847656 1.75 0.847656H13.6523C13.9102 0.847656 14.125 0.933594 14.2969 1.10547C14.4974 1.27734 14.5977 1.49219 14.5977 1.75V10ZM18.25 4.5C18.5078 4.5 18.7227 4.58594 18.8945 4.75781C19.0664 4.92969 19.1523 5.14453 19.1523 5.40234V19.1523L15.5 15.5H5.40234C5.14453 15.5 4.92969 15.4141 4.75781 15.2422C4.58594 15.0703 4.5 14.8555 4.5 14.5977V12.75H16.4023V4.5H18.25Z" fill="var(--buttons_color)"/>
                                        </svg>
                                    </button>
                                </div>
                                <div v-else class="container-adding-friend">
                                    <button v-on:click="requestRemoveFriend(friend.id)" class="btn-transparent"> <img :title="$i18n.t('friends.desfazerAmizade')" src="../../public/assets/images/friends/svg/excluir.svg" alt="imagem para excluir um amigo"/> </button>
                                    <button v-on:click="showModalOptions(keyFriendsResult, 'search')" class="btn-transparent options">
                                        <svg width="20" height="7" viewBox="0 0 22 7" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.40028 3.59882C5.40028 2.1327 4.17294 0.910934 2.70014 0.910934C1.19665 0.910934 0 2.1327 0 3.59882C0 5.06494 1.19665 6.2867 2.70014 6.2867C4.17294 6.2867 5.40028 5.06494 5.40028 3.59882ZM13.6848 3.59882C13.6848 2.1327 12.4575 0.910934 10.9847 0.910934C9.51186 0.910934 8.28452 2.1327 8.28452 3.59882C8.28452 5.06494 9.51186 6.2867 10.9847 6.2867C12.4575 6.2867 13.6848 5.06494 13.6848 3.59882ZM22 3.59882C22 2.1327 20.8033 0.910934 19.2999 0.910934C17.8271 0.910934 16.5997 2.1327 16.5997 3.59882C16.5997 5.06494 17.8271 6.2867 19.2999 6.2867C20.8033 6.2867 22 5.06494 22 3.59882Z" fill="black"/>
                                        </svg>
                                    </button>
                                    <button class="btn-transparent" v-on:click="openPrivateMessage(friend.id)">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.5977 10C14.5977 10.2578 14.4974 10.4727 14.2969 10.6445C14.125 10.8164 13.9102 10.9023 13.6523 10.9023H4.5L0.847656 14.5977V1.75C0.847656 1.49219 0.933594 1.27734 1.10547 1.10547C1.27734 0.933594 1.49219 0.847656 1.75 0.847656H13.6523C13.9102 0.847656 14.125 0.933594 14.2969 1.10547C14.4974 1.27734 14.5977 1.49219 14.5977 1.75V10ZM18.25 4.5C18.5078 4.5 18.7227 4.58594 18.8945 4.75781C19.0664 4.92969 19.1523 5.14453 19.1523 5.40234V19.1523L15.5 15.5H5.40234C5.14453 15.5 4.92969 15.4141 4.75781 15.2422C4.58594 15.0703 4.5 14.8555 4.5 14.5977V12.75H16.4023V4.5H18.25Z" fill="var(--buttons_color)"/>
                                        </svg>
                                    </button>
                                </div>
                                <div v-if="friend.friend" class="modal--options" :ref="'data-search-key-'+keyFriendsResult">
                                    <div class="report">
                                        <div class="modal--options__title" v-on:click="$parent.complaintOpenHandler(friend.id)">{{ $t('friends.denunciar') }}</div>
                                    </div>
                                    <div class="block">
                                        <div class="modal--options__title" v-on:click="blockUser(friend)">{{ $t('friends.bloquear') }}</div>
                                    </div>
                                </div>
                            </div>
                            <!-- card un-friends (falta condicional para exibir o botao) -->
                        </section>
                        <section v-else-if="searchCompleated" class="container-list-friends">
                            <strong class="color-buttons">{{ searchCompleated }}</strong>
                        </section>
                    
                        <section v-if="friendsRequests.length > 0" class="container-list-friends container-friend-request">
                            <p class="text-friend-request">{{ $t('friends.solicitaesDeAmizade') }} ({{friendsRequests.length}}) </p>
                            <section class="list-friends-request">
                                <div v-for="(friend,keyFriendsRequests) in friendsRequests" class="card-friend request-friend" :key="keyFriendsRequests">
                                    <img class="request-friend-image" :src="friend.avatar" alt="imagem de um amigo"/>
                                    <div class="container-name-friend name-request">
                                        <h4 class="name-friend">{{friend.name}}</h4>
                                        <div class="container-button">
                                            <div>
                                                <button v-on:click="declineFriendRequest(friend.id)" class="button-card cancelled"> <img src="../../public/assets/images/friends/cancelled.svg" alt="icone do botao play zone"/>{{ $t('friends.negar') }}</button>
                                                <button v-on:click="declineFriendRequest(friend.id)" class="button-card cancelled-mobile"> <img src="../../public/assets/images/friends/cancelled.svg" alt="icone do botao play zone" /></button>
                                            </div>   
                                            <div>
                                                <button v-on:click="acceptFriendRequest(friend.id)" class="button-card acepted"> <img src="../../public/assets/images/friends/acepted.svg" alt="icone do botao play zone"/>{{ $t('friends.aceitar') }}</button>
                                                <button v-on:click="acceptFriendRequest(friend.id)" class="button-card acepted-mobile"> <img src="../../public/assets/images/friends/acepted.svg" alt="icone do botao play zone" /></button>
                                            </div>
                                        </div>
                                    </div> 
                                </div>

                                <!-- <div class="card-friend request-friend">
                                <img class="request-friend-image" src="../../public/assets/images/friends/bgamigos.png" alt="imagem de um amigo"/>
                                <div class="container-name-friend name-request">
                                    <h4 class="name-friend">{{ $t('friends.carlosMagno') }}</h4>
                                    <div class="container-button">
                                        <div>
                                            <button class="button-card cancelled"> <img src="../../public/assets/images/friends/cancelled.svg" alt="icone do botao play zone"/>{{ $t('friends.negar') }}</button>
                                            <button class="button-card cancelled-mobile"> <img src="../../public/assets/images/friends/cancelled.svg" alt="icone do botao play zone" /></button>
                                        </div>   
                                        <div>
                                            <button class="button-card acepted"> <img src="../../public/assets/images/friends/acepted.svg" alt="icone do botao play zone"/>{{ $t('friends.aceitar') }}</button>
                                            <button class="button-card acepted-mobile"> <img src="../../public/assets/images/friends/acepted.svg" alt="icone do botao play zone" /></button>
                                        </div>
                                    </div>
                                </div> 
                                </div>

                                <div class="card-friend request-friend">
                                <img class="request-friend-image" src="../../public/assets/images/friends/bgamigos.png" alt="imagem de um amigo"/>
                                <div class="container-name-friend name-request">
                                    <h4 class="name-friend">{{ $t('friends.carlosMagno') }}</h4>
                                    <div class="container-button">
                                        <div>
                                            <button class="button-card cancelled"> <img src="../../public/assets/images/friends/cancelled.svg" alt="icone do botao play zone"/>{{ $t('friends.negar') }}</button>
                                            <button class="button-card cancelled-mobile"> <img src="../../public/assets/images/friends/cancelled.svg" alt="icone do botao play zone" /></button>
                                        </div>   
                                        <div>
                                            <button class="button-card acepted"> <img src="../../public/assets/images/friends/acepted.svg" alt="icone do botao play zone"/>{{ $t('friends.aceitar') }}</button>
                                            <button class="button-card acepted-mobile"> <img src="../../public/assets/images/friends/acepted.svg" alt="icone do botao play zone" /></button>
                                        </div>
                                    </div>
                                </div> 
                                </div>-->
                            </section>
                        </section> 

                        <section class="container-my-friends">
                            <div class="container-title-my-friend">
                                <h3 v-text="data.friendsCount > 0?$t('friends.meusAmigos'):''" class="text-my-friends"></h3>
                                <router-link v-if="showBlocked" :to="`/usuarios-bloqueados`">
                                    <button class="blocked"><img src='../../public/assets/images/friends/svg/excluir.svg' alt="botao para bloquear" />{{ $t('friends.bloqueados') }}</button>
                                </router-link>
                            </div>
                            <section v-if="data.friendsCount > 0" class="container-list-friends">
                                <div v-for="(friend,keyFriends) in data.friends" class="card-friend" :key="keyFriends">
                                    <div class="p-relative">
                                        <img class="profile-pic" :src="friend.avatar" alt="imagem de um amigo"/>
                                        <div v-if="friend.online" style="padding: 2px;background-color: #0FFF35"
                                            class="info__online">
                                        </div>
                                        <div v-else style="padding: 2px;background-color: #D0D0D0;"
                                            class="info__online">
                                        </div>
                                    </div>
                                    <div class="container-name-friend">
                                        <div class="container-mobile">
                                            <div class="container-bandeira">
                                                <button v-on:click="$parent.complaintOpenHandler(friend.id)" class="btn-transparent"> <img  src="../../public/assets/icons/friends/bandeira.svg" alt="imagem de uma bandeira"/> </button>
                                            </div>
                                            <div class="container-bandeira close">
                                                <button v-on:click="blockUser(friend)" class="btn-transparent"> <img src="../../public/assets/icons/friends/close.svg" alt="imagem de fechar"/> </button>
                                            </div>
                                        </div>
                                        <h4 class="name-friend">{{friend.name}}</h4>
                                        <div v-if="friend.ambient && friend.online" class="friend--area--button">
                                            <a :title="$i18n.t('friends.irAteAmigo')" class="alignVertical" @click="goToFriend(friend.ambient)">
                                                <img src="../../public/assets/images/friends/svg/mapa.svg" alt="">
                                                <div class="friend--area--name" v-text="friend.ambient"></div>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="container-adding-friend">
                                        <button v-on:click="requestRemoveFriend(friend.id)" class="btn-transparent"> <img :title="$i18n.t('friends.desfazerAmizade')" width="40px" src="../../public/assets/images/friends/svg/excluir.svg" alt="imagem para excluir um amigo"/> </button>
                                        <button v-on:click="showModalOptions(keyFriends, 'friends')" class="btn-transparent options">
                                            <svg width="20" height="7" viewBox="0 0 22 7" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.40028 3.59882C5.40028 2.1327 4.17294 0.910934 2.70014 0.910934C1.19665 0.910934 0 2.1327 0 3.59882C0 5.06494 1.19665 6.2867 2.70014 6.2867C4.17294 6.2867 5.40028 5.06494 5.40028 3.59882ZM13.6848 3.59882C13.6848 2.1327 12.4575 0.910934 10.9847 0.910934C9.51186 0.910934 8.28452 2.1327 8.28452 3.59882C8.28452 5.06494 9.51186 6.2867 10.9847 6.2867C12.4575 6.2867 13.6848 5.06494 13.6848 3.59882ZM22 3.59882C22 2.1327 20.8033 0.910934 19.2999 0.910934C17.8271 0.910934 16.5997 2.1327 16.5997 3.59882C16.5997 5.06494 17.8271 6.2867 19.2999 6.2867C20.8033 6.2867 22 5.06494 22 3.59882Z" fill="black"/>
                                            </svg>
                                        </button>
                                        <button class="btn-transparent" v-on:click="openPrivateMessage(friend.id)">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14.5977 10C14.5977 10.2578 14.4974 10.4727 14.2969 10.6445C14.125 10.8164 13.9102 10.9023 13.6523 10.9023H4.5L0.847656 14.5977V1.75C0.847656 1.49219 0.933594 1.27734 1.10547 1.10547C1.27734 0.933594 1.49219 0.847656 1.75 0.847656H13.6523C13.9102 0.847656 14.125 0.933594 14.2969 1.10547C14.4974 1.27734 14.5977 1.49219 14.5977 1.75V10ZM18.25 4.5C18.5078 4.5 18.7227 4.58594 18.8945 4.75781C19.0664 4.92969 19.1523 5.14453 19.1523 5.40234V19.1523L15.5 15.5H5.40234C5.14453 15.5 4.92969 15.4141 4.75781 15.2422C4.58594 15.0703 4.5 14.8555 4.5 14.5977V12.75H16.4023V4.5H18.25Z" fill="var(--buttons_color)"/>
                                            </svg>
                                        </button>
                                    </div>
                                    <div v-if="friend.friend" class="modal--options" :ref="'data-friend-key-'+keyFriends">
                                        <div class="report">
                                            <div class="modal--options__title" v-on:click="$parent.complaintOpenHandler(friend.id)">{{ $t('friends.denunciar') }}</div>
                                        </div>
                                        <div class="block">
                                            <div class="modal--options__title" v-on:click="blockUser(friend)">{{ $t('friends.bloquear') }}</div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </section>

                        <section class="container-my-inbox" v-if="privateMessagesUsers.length > 0">
                            <div class="container-title-inbox">
                                <h3 class="text-inbox">{{ this.$i18n.t('pm.titleInbox') }}</h3>
                            </div>
                            <section class="container-list-inbox">
                                <div v-for="(l, index) in privateMessagesUsers" :key="index" class="card-inbox" v-on:click="openPrivateMessage(l.id)">
                                    <div class="inbox-img-wrap">
                                        <img class="profile-pic" :src="l.avatar" alt="imagem de um amigo"/>
                                        <div v-if="l.online" style="padding: 2px;background-color: #0FFF35" class="info__online"></div>
                                        <div v-else style="padding: 2px;background-color: #D0D0D0;" class="info__online"></div>
                                    </div>
                                    <div class="inbox-info-wrap">
                                        <h4 class="inbox-name">{{ l.name }}</h4>
                                        <p class="inbox-last-message">{{ l.inbox.last_message }}</p>
                                    </div>
                                    <!-- <div v-if="l.inbox.unread > 0" class="inbox-unread"><span>{{ l.inbox.unread }}</span></div> -->
                                    <div v-if="l.inbox.unread > 0" class="inbox-unread">
                                        <span v-text="l.inbox.unread > 10 ? '10+' : l.inbox.unread"></span>
                                    </div>
                                </div>
                            </section>
                            <div class="load-more-inbox" v-if="!is_end">
                                <button type="button" class="button__blue" v-on:click="loadInbox(offset);">{{ this.$i18n.t('pm.loadMore') }}</button>
                            </div>
                        </section>

                    </section>
                </main>
            </div>
        </div>
    </div> 
</template>

<script>
import axios from 'axios';
import {mapActions, mapGetters} from "vuex";


export default {
    name: 'Friends',
    computed: {
        ...mapGetters([
            'logged', 'token', 'switch', 'sessions'
        ])
    },
    data() {
        return {
            search: '',
            searchCompleated:"",
            friendsResult: [],
            friendsRequests: [],
            data: {
                friendsCount: 0,
                friends: [],
            },
            showBlocked: false,
            userToBlock: {},
            friendToReport: {},
            privateMessagesUsers: [],   // usuarios no inbox
            is_end: false,              // pagination: para checar se chegou ao fim do de conversas
            limit: null,                // pagination: para saber qual é o limit vindo do back
            offset: 0                   // pagination: para o total de conversas (inbox) carregadas ate o momento
        };
    },
    
    methods: {
        ...mapActions([
            'update_user_friends'
        ]),

        denunciar() {
            return null;
        },
        userPhoto(userPhotoUrl) {
            return userPhotoUrl ? `background: url('${userPhotoUrl}') center center / cover;` : 'background-color: #000;';
        },
        showModalOptions(key, type) {
            console.log({key, type})
            let modal;

            switch (type) {
                case 'friends':
                    modal = this.$refs[`data-friend-key-${key}`];
                    break;
                case 'search':
                    modal = this.$refs[`data-search-key-${key}`];
                    break;
                default:
                    break;
            }

            if (modal) {
                if (modal[0].classList.contains('show'))
                    modal[0].classList.remove('show');
                else
                    modal[0].classList.add('show');

                // console.log(modal);
            }
        },
        async declineFriendRequest(userId) {
            this.$parent.wait(true);
            const headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                language: this.$i18n.locale, Authorization: `Bearer ${this.token}`,
            };

            const form_data = new FormData();
            form_data.append('friend_id', userId);

            try {
                const res = await axios.post(this.$url_api + 'api/RejectRequestFriend', form_data, {headers});
                this.requestHandlerResponse(res, userId);
            } catch (error) {
                this.requestHandlerError(error.response);
            }
        },
        async acceptFriendRequest(userId) {
            this.$parent.wait(true);
            const headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                language: this.$i18n.locale, Authorization: `Bearer ${this.token}`,
            };

            const form_data = new FormData();
            form_data.append('friend_id', userId);

            try {
                const res = await axios.post(this.$url_api + 'api/AcceptRequestFriend', form_data, {headers});
                this.requestHandlerResponse(res, userId);
            } catch (error) {
                this.requestHandlerError(error.response);
            }
        },
        goToFriend(ambient) {
            const sessions = JSON.parse(JSON.stringify(this.sessions));

            const session = sessions.find(obj => {
                return obj.name === ambient;
            });

            if (session === undefined) this.$router.push({name: 'Map'});
            else this.$router.push({path: "/e/"+session.name});
        },
        //----- request-handler -----//
        requestHandlerResponse(obj, id) {
            try {
                if (obj.data.status == 'success') {
                    console.log(this.friendsRequests);
                    let pos = this.friendsRequests.map(function (e_) {
                        return e_.id;
                    }).indexOf(Number(id));

                    if (obj.data.message == 'Solicitação recusada') {
                        this.$parent.analytics('deny_friendship');
                        this.$parent.modal('success', this.$i18n.t('friends.sucesso'), this.$i18n.t('friends.solicitacaoRecusada'), null, null, null, "OK");
                    } else if (obj.data.message == 'Solicitação aceita') {
                        this.$parent.analytics('accept_friendship');
                        this.data.friendsCount++;
                        let friend = this.friendsRequests[pos];
                        friend.friend = true;
                        this.data.friends.push(friend);
                        this.$parent.modal('success', this.$i18n.t('friends.sucesso'), this.$i18n.t('friends.solicitacaoAceita'), null, null, null, "OK");
                    }
                    this.update_user_friends(this.data.friends);
                    this.friendsRequests.splice(pos, 1);
                    this.$forceUpdate()
                }
                this.$parent.wait(false);
            }catch (e){
                console.log(e)
            }
        },
        requestHandlerError(obj) {
            if (obj.data.message == 'erro_desconhecido') {
                let str = '';
                for (let o in obj.data.errors) {
                    str += obj.data.errors[o] + '\n';
                }
                this.$parent.modal('error', this.$i18n.t('friends.erro'), str, null, null, null, "OK");
            }
            this.$parent.wait(false);
        },
        //----- \request-handler\ -----//
        //----- auto-complete -----//
        autocomplete_clear_all() {
            let autocomplete_count_all = document.getElementsByClassName('auto-complete').length;
            if (autocomplete_count_all > 0) {
                for (let autocomplete_t = 0; autocomplete_t < autocomplete_count_all; autocomplete_t++) {
                    document.getElementsByClassName('auto-complete')[autocomplete_t].remove();
                }
            }
        },
        autocomplete_remove_active(x) {
            for (let i = 0; i < x.length; i++)
                x[i].classList.remove("auto-complete-active");
        },
        autocomplete_add_active(x, e) {
            if (!x) return false;
            this.autocomplete_remove_active(x);
            if (e.target.dataset.currentFocus >= x.length) e.target.dataset.currentFocus = 0;
            if (e.target.dataset.currentFocus < 0) e.target.dataset.currentFocus = (x.length - 1);
            x[e.target.dataset.currentFocus].classList.add("auto-complete-active");
        },
        autocomplete_on_key_down(e) {

            if (e.keyCode === 189)
                e.preventDefault();

            if (e.target.hasAttribute("data-current-focus") == false) {
                e.target.setAttribute("data-current-focus", -1);
            }

            let x = document.getElementById(e.target.name + "-auto-complete-list");
            if (x) x = x.getElementsByTagName("li");
            if (e.keyCode === 40) {
                e.target.dataset.currentFocus++;
                this.autocomplete_add_active(x, e);
            } else if (e.keyCode === 38) {
                e.target.dataset.currentFocus--;
                this.autocomplete_add_active(x, e);
            } else if ((e.keyCode === 9) || (e.keyCode === 13)) {
                if (e.target.dataset.currentFocus > -1) {
                    if (x) {
                        x[e.target.dataset.currentFocus].click();
                    }
                }
                e.preventDefault();
            }
        },
        autocomplete_on_key_up(e) {
            switch (e.keyCode) {
                case 13: /* enter */
                    this.searchFriend();
                    //if (e.target.dataset.currentFocus === -1)
                    //    search(null);
                    break;
                case 9: /* tab */
                case 38: /* up */
                case 40: /* down */
                    break;
                default:
                    //oi
                    //autocomplete_load(e);
                    // this.searchFriend();
                    break;
            }
        },
        autocomplete_click_option(obj) {
            obj;
            //autocomplete_clear(params);
            //utocomplete_clear_all();

            //window["auto_complete_callback_" + params.e.target.name.replaceAll("-", "_")](obj);
        },
        //----- \auto-complete\ -----//
        //----- search-friends -----//
        searchFriend() {
            if (this.$refs['search-label'].value != '') {
                this.$parent.wait(true);
                let headers = {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Access': 'application/json',
                    language: this.$i18n.locale, Authorization: `Bearer ${this.token}`
                };
                axios.get(this.$url_api + 'api/SearchParticipants', {
                    headers: headers,
                    params: {search: this.$refs['search-label'].value}
                }).then(response => this.searchFriendResponse(response)).catch(error => this.searchFriendError(error.response));
            } else {
                this.searchCompleated="";
                this.autocomplete_clear_all();
                this.total_search = 0;
                this.friendsResult = [];
            }
        },
        searchFriendResponse(obj) {
            if (obj.data.status == 'success') {

                this.$parent.analytics('search_friend');

                this.autocomplete_clear_all();
                this.total_search = obj.data.data.length;
                this.friendsResult = obj.data.data;
                this.searchCompleated = this.$i18n.t('friends.noResults')+'"'+this.search+'"';
                /*if (obj.data.data.length > 0) {

                    let root_ = this;

                    let autocomplete_div, autocomplete_ul, autocomplete_li;
                    autocomplete_div = document.createElement('div');
                    autocomplete_div.setAttribute("class", "auto-complete");

                    autocomplete_ul = document.createElement("ul");
                    autocomplete_ul.setAttribute("id", "search-label-auto-complete-list");
                    autocomplete_ul.setAttribute("class", "auto-complete-items");
                    autocomplete_div.appendChild(autocomplete_ul);
                    this.$refs['search-label'].parentNode.appendChild(autocomplete_div);

                    for (let autocomplete_t in obj.data.data) {
                        autocomplete_li = document.createElement("li");
                        autocomplete_li.innerHTML = obj.data.data[autocomplete_t].name + ', ' + obj.data.data[autocomplete_t].email;
                        autocomplete_li.addEventListener("click", function() { root_.autocomplete_click_option(obj.data.data[autocomplete_t]); } );
                        autocomplete_ul.appendChild(autocomplete_li);
                    }

               }*/
            }
            this.$parent.wait(false);
        },
        searchFriendError(obj) {
            obj;
            this.$parent.wait(false);
        },
        //----- \search-friends\ -----//
        //----- load-friends -----//
        loadFriend() {
            // this.loading = true;
            this.$parent.wait(true);
            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                language: this.$i18n.locale, Authorization: `Bearer ${this.token}`
            };
            axios.get(this.$url_api + 'api/GetFriends', {headers: headers})
                    .then(response => {
                        this.loadFriendResponse(response)
                        })
                    .catch(error => this.loadFriendError(error.response));
        },
        loadFriendResponse(obj) {
            if (obj.data.status == 'success') {
                this.data.friendsCount = obj.data.friends.length;
                this.data.friends = obj.data.friends;
                console.log(obj.data.friends.length)
            }

            this.$parent.wait(false);
        },
        loadFriendError(obj) {
            obj;
            this.$parent.wait(false);
        },
        //----- \load-friends\ -----//
        //----- load-request -----//
        loadRequest() {
            this.$parent.wait(true);
            console.log('requests friends')
            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                language: this.$i18n.locale, Authorization: `Bearer ${this.token}`
            };
            axios.get(this.$url_api + 'api/RequestsFriend', {headers: headers})
                    .then(response => this.loadRequestResponse(response))
                    .catch(error => this.loadRequestError(error.response));
        },
        loadRequestResponse(obj) {
            if (obj.data.status == 'success') {
                this.friendsRequests = obj.data.requests;
            }
            this.$parent.wait(false);
        },
        loadRequestError(obj) {
            obj;
            this.$parent.wait(false);
        },
        //----- \load-request\ -----//
        //----- requestSend-friends -----//
        requestSendHandler(e) {
            e.preventDefault();

            //aqui juligo
            this.e_temp = {
                id: e.currentTarget.dataset.id,
                v: e.currentTarget.dataset.v,
                index: e.currentTarget.dataset.index
            };
            //this.dataset_actual = e.currentTarget.dataset;
            //this.e_target_actual = e;

            if (this.e_temp.v == '1')
                this.$parent.modal('confirmation', 'Atenção!', 'Deseja realmente remover este amigo?', this.requestSendHandlerResponse);
            else
                this.requestSendHandlerResponse(1);


        },
        requestSendHandlerResponse(r) {
            if (r === 1) {
                this.$parent.wait(true);

                let headers = {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Access': 'application/json',
                    language: this.$i18n.locale, Authorization: `Bearer ${this.token}`
                };
                let form_data = new FormData();
                form_data.append('friend_id', this.e_temp.id);

                axios.post(this.$url_api + 'api/' + ((this.e_temp.v == '0') ? 'AddFriend' : 'RemoveFriend'), form_data, {headers: headers}).then(response => this.requestSendResponse(response)).catch(error => this.requestSendError(error.response));
            }
        },
        toggleFriendStatus(friend) {
            if (friend.friend) {
                this.requestRemoveFriend(friend.id);
                return;
            }

            this.requestAddFriend(friend.id);
        },
        requestAddFriend(id) {
            this.$parent.requestAddFriend(id);
        },
        requestRemoveFriend(id) {
            this.$parent.wait(true);

            /*let alreadyFriends = this.data.friends.find((friend) => friend.id === id);

            if (!alreadyFriends) {
                console.log("Dá não!")
                return;
            }*/

            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                language: this.$i18n.locale, Authorization: `Bearer ${this.token}`
            };
            let form_data = new FormData();
            form_data.append('friend_id', id);

            axios.post(`${this.$url_api}api/RemoveFriend`, form_data, {headers: headers})
                    .then(response => this.requestRemoveFriendResponse(response, id))
                    .catch(error => this.$parent.requestToggleFriendStatusError(error.response));
        },
        requestRemoveFriendResponse(response, friendId) {
            this.$parent.analytics('remove_friend');
            let friendIndex = this.data.friends.findIndex(({id}) => id === friendId);

            this.data.friendsCount--;
            this.data.friends.splice(friendIndex, 1);

            this.$parent.modal('success', this.$i18n.t('friends.sucesso'), this.$i18n.t('friends.aAmizadeFoiDesfeita'), null, null, null, "OK");

            this.$parent.wait(false);
        },
        //----- \requestSend-friends\ -----//
        //----- blocked -----//
        blockedRequest() {
            this.$parent.wait(true);
            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                language: this.$i18n.locale, Authorization: `Bearer ${this.token}`
            };
            axios.get(this.$url_api + 'api/blockedUsers', {headers: headers})
                    .then(response => this.blockedResponse(response))
                    .catch(error => this.blockedError(error.response));
        },
        blockedResponse(obj) {
            const blockedUsers = obj.data.data;
            if (blockedUsers.length > 0) this.showBlocked = true;

            this.$parent.wait(false);
        },
        blockedError(obj) {
            obj;
            this.$parent.wait(false);
        },
        //----- \blocked\ -----//
        //----- blockUser -----//
        blockUser(friend) {
            this.userToBlock = friend;
            this.$parent.modal('confirmation', this.$i18n.t('friends.atencao'), this.$i18n.t('friends.desejaRealmenteBloquear'), this.blockUserConfirmation);
        },
        blockUserConfirmation(r) {
            let friend = this.userToBlock;

            if (r === 1) {
                this.$parent.wait(true);
                let headers = {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Access': 'application/json',
                    language: this.$i18n.locale, Authorization: `Bearer ${this.token}`
                };
                axios.post(this.$url_api + 'api/blockUser/' + friend.id, null, {headers: headers}).then(response => this.blockUserResponse(response, friend)).catch(error => this.blockUserError(error.response));
            }
        },
        blockUserResponse(obj, friend) {
            if (obj.data.status == 'success') {
                this.$parent.analytics('block_user');
                this.blockUserProcess(friend)
                this.$parent.modal('success', this.$i18n.t('friends.sucesso'), obj.data.data, null, null, null, "OK");

            }
            this.$parent.wait(false);
        },
        blockUserProcess(friend){
            let friendIndex = this.data.friends.findIndex(({id}) => id === friend.id);
            this.data.friendsCount--;
            this.data.friends.splice(friendIndex, 1);
            this.showBlocked = true;
        },
        blockUserError(obj) {
            if (obj.data.status == 'error') {
                this.$parent.modal('error', this.$i18n.t('friends.erro'), obj.data.message, null, null, null, "OK");
            }
            this.$parent.wait(false);
        },
        displayFriends() {
            if (!this.switch.Habilitar_Amigos) {
                this.$router.push({name:'Map'});
            }
        },
        openPrivateMessage(friend_id) {
            this.$parent.modal_private_messages = true;
            this.$parent.private_messages_friend_id = Number(friend_id);
            this.$parent.refreshNotificationPrivateMessages(friend_id);     // retira a notificacao
            // find index on private messages to set unread to 0
            if( this.privateMessagesUsers.length > 0 ) {
                let index = Object.keys(this.privateMessagesUsers).find(x => this.privateMessagesUsers[x].id === friend_id);
                if( this.privateMessagesUsers[index] ) {
                    this.privateMessagesUsers[index].inbox.unread = 0;
                }
            }
        },
        loadInbox(offset = 0) {
            this.$parent.wait(true);
            let form_data   = new FormData();
            form_data.append('offset', offset);
            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                language: this.$i18n.locale, Authorization: `Bearer ${this.token}`
            };
            axios.post(this.$url_api + 'api/pm/loadInbox', form_data, {headers: headers})
                    .then(response => {this.loadInboxResponse(response)})
                    .catch(error => this.loadInboxError(error.response));
        },
        loadInboxResponse(obj) {
            // console.log('INBOX => ', obj.data);
            let more_inbox      = null;
            this.offset         = obj.data.pagination.offset;
            this.limit          = (this.limit) ? this.limit : obj.data.pagination.limit;
            this.is_end         = obj.data.pagination.is_end;

            // caso nao seja o primeiro load
            if( this.offset > this.limit ) {
                more_inbox      = obj.data.users;
                for(let i=0; i<more_inbox.length; i++) {
                    this.privateMessagesUsers.push(more_inbox[i]);
                }
            }
            // ... é o primeiro load
            else {
                this.privateMessagesUsers   = obj.data.users;
            }
            
            this.$parent.wait(false);
        },
        loadInboxError(obj) {
            console.log('loadInboxError => ', obj);
            this.$parent.wait(false);
        },
    },
    mounted() {
        // this.$parent.analytics('visit_friends', {});
        this.displayFriends();
        this.loadFriend();
        this.blockedRequest();
        this.loadRequest();
        this.loadInbox();
        // document.addEventListener('click', this.autocomplete_clear_all);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.autocomplete_clear_all);
    },
}
</script>