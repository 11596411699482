<template>
  <LmsBasicTemplate :current-page="navigationConfig.pageName" :pageSubTitle="selectedCategory" :searchField="true" searchPlaceholder="Pesquisar curso...">
    <template v-slot:navigation-side>
      <div @click="selectCategory(category)" class="category-item" :data-categoryid="category.id" :class="{'selected': selectedCategoryId === category.id}" v-for="(category, idxCategory) in categories" :key="idxCategory" >
        <span :data-categoryid="category.id">{{category.title}}</span>
        <div>
          <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="19" cy="19" r="16" stroke="#5F5F5F" stroke-width="6"/>
            <path d="M19 3.36255C19 1.50546 20.517 -0.030191 22.3451 0.296769C24.7679 0.730097 27.0942 1.63203 29.1896 2.96341C32.2357 4.89892 34.6676 7.66198 36.2007 10.9292C37.7337 14.1965 38.3045 17.8328 37.8462 21.4126C37.3879 24.9925 35.9195 28.3677 33.6129 31.1435C31.3062 33.9192 28.2567 35.9805 24.8213 37.0863C21.3858 38.192 17.7064 38.2964 14.2138 37.3873C10.7211 36.4781 7.55962 34.593 5.09928 31.9526C3.40688 30.1363 2.09439 28.0143 1.22495 25.7118C0.568921 23.9744 1.80098 22.202 3.6267 21.8621C5.45241 21.5222 7.16906 22.7618 7.99019 24.4275C8.51697 25.4961 9.19914 26.4876 10.0195 27.368C11.609 29.0738 13.6514 30.2917 15.9079 30.8791C18.1643 31.4664 20.5413 31.399 22.7608 30.6846C24.9803 29.9702 26.9504 28.6385 28.4406 26.8453C29.9308 25.052 30.8795 22.8714 31.1755 20.5587C31.4716 18.2459 31.1029 15.8967 30.1125 13.7859C29.122 11.6751 27.5509 9.89003 25.583 8.6396C24.5673 7.99426 23.4676 7.50509 22.3207 7.18279C20.5329 6.6804 19 5.21963 19 3.36255Z" fill="#A18AE5"/>
            <path d="M15.1562 24.5L20.625 19L15.1562 13.5L16.3438 12.3438L23 19L16.3438 25.6562L15.1562 24.5Z" fill="#5F5F5F"/>
          </svg>
        </div>
      </div>
    </template>
    <template v-slot:content-side>

    </template>
  </LmsBasicTemplate>
</template>
<script>

import { mapGetters } from "vuex";
import LmsBasicTemplate from "@/components/lms/LmsBasicTemplate.vue";

export default {
  name: "LmsMyJourney",
  components: {
    LmsBasicTemplate,
  },
  data() {
    return {
      selectedCategory: null,
      selectedCategoryId: 0,
      navigationConfig: [],
    }
  },
  beforeMount() {
    let root_ = this;

    root_.navigationConfig = root_.getPageByName("LmsMyJourney");

    if(root_.categories.length > 0) {
      var index = (root_.categories.length - 1);
      root_.selectCategory(root_.categories[index])
    }
  },
  mounted() {

  },
  methods: {
    selectCategory(category) {
      this.selectedCategory = category.title;
      this.selectedCategoryId = category.id;

      return true;
    },
  },
  computed:{
    ...mapGetters({
      getLms: "LMS/getLms",
      getPageByName: "LMS/getPageByName"
    }),
    categories: function () {
      return this.getLms.categories;
    },
  },
  beforeDestroy() {

  }

}

</script>

<style scoped lang="scss">

.category-item {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background-color: #F6F6F6;
  padding: 20px;
  margin: 10px 0;
  gap: 24px;
  cursor: pointer;
  color: #706F6F;
  font-family: Hanken Grotesk;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.selected {
  background-color: #F2EEFF !important;
  cursor: default;
  color: #6954D9;
  font-family: Hanken Grotesk;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

</style>