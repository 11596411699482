import { render, staticRenderFns } from "./LmsNotifications.vue?vue&type=template&id=79b4caea&scoped=true&"
import script from "./LmsNotifications.vue?vue&type=script&lang=js&"
export * from "./LmsNotifications.vue?vue&type=script&lang=js&"
import style0 from "./LmsNotifications.vue?vue&type=style&index=0&id=79b4caea&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79b4caea",
  null
  
)

export default component.exports