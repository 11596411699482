<template>
    <section class="badges">
        <div class="badges-container">
            
            <div class="title">
                <h1>{{ $t('badges.Badges') }}</h1>
            </div>
            <div class="header_radius"></div>
            <div class="mosaico">
                <div class="badge" v-for="(badge, index) in badges" :key="index">
                    <div class="badge-container">
                        <div class="badge-data">
                            <div class="badge_img">
                                <video v-if="!/\.(jpe?g|png|svg|gif)$/i.test(badge.file_url)" :src="badge.file_url">
                                    <source :src="badge.file_url">
                                </video>
                                <img v-else :src="badge.file_url">
                            </div>
                            <div class="text-elements">
                                <span class="badge_name" >{{ badge.name }}</span>
                                <span class="badge_description" >{{ badge.description }}</span>
                            </div>
                        </div>
                        <div class="badge_sessions" v-if="badge.rooms.length > 0">
                            <select name="chooseSession" :id="'chooseSession_'+badge.id" class="dropdown" v-on:change="selectSession($event, badge.id)">
                                <option value="" disabled selected>{{ $t('badges.dropdownSelectSession') }}</option>
                                <option v-for="(room, index) in badge.rooms" :key="index" :value="room.url">{{room.name}}</option>
                            </select>
                        </div>
                        <div class="badge_sessions" v-else>
                            <input id="noSession" class="dropdown" :value="$t('badges.badgeObtida')" readonly/>
                        </div>
                        <div class="redirect" v-if="badge.rooms.length > 0">
                            <button class="redirect_button not_ready" :id="'button_redirect_'+badge.id" v-on:click="goToSession(badge.id)">{{ $t('badges.buttonSession')}}</button>
                        </div>
                    </div>
                </div>
                <div class="badge" v-if="badges.length==0">
                    <div class="badge-container badge-empty">
                        <span class="badge_name" style="margin-top:0px">{{$t('badges.youDontHaveBadges')}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="openModal" class="modals bgBlack">
            <div style="width: 100vw;height: 100vh;position: fixed;top: 0;left: 0;" v-on:click="closeModal"></div>
            <div class="modalGerais tutorial">
                <div class="modals__content alignVertical">
                    <div class="modals__content--header alignVertical">
                        <p>{{ badge_title }} </p>
                        <span class="close" data-response="0" v-on:click="closeModal">
                            <img src="assets/icons/modals/x.svg" alt="">
                        </span>
                    </div>
                    <div class="modals__content--body alignVertical" :style="`background-image: url('${$url}assets/icons/modals/conteudo.svg');`">
                        <!-- senha alterada -->
                        <a v-if="badge_external_url.length>0" class="modalGerais__tutorial" v-on:click="openLink(badge_external_url)">
                            <div v-if="!/\.(jpe?g|png|svg|gif)$/i.test(badge_url)">
                                <iframe :id="badge_url" :src="badge_url" frameborder="0"></iframe>
                            </div>
                            <div v-else class="alignVertical col-12" ref="text-info">
                                <img :src="badge_url" style="max-width:100%">
                            </div>
                        </a>
                        <div v-else class="modalGerais__tutorial">
                            <div v-if="!/\.(jpe?g|png|svg|gif)$/i.test(badge_url)">
                                <iframe :id="badge_url" :src="badge_url" frameborder="0"></iframe>
                            </div>
                            <div v-else class="alignVertical col-12" ref="text-info">
                                <img :src="badge_url" style="max-width:100%">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="badges_left.length>0" class="badges-container second-container">
            <div class="title">
                <h1>{{ $t('badges.BadgesLeft') }}</h1>
            </div>
            <div class="header_radius"></div>
            <div class="mosaico">
                <div class="badge" v-for="(badge, index) in badges_left" :key="index">
                    <div class="notObtained-badge-container">
                        <div class="notObtained-badge-data">
                            <div class="badge_img">
                                <video v-if="!/\.(jpe?g|png|svg|gif)$/i.test(badge.file_url)" :src="badge.file_url">
                                    <source :src="badge.file_url">
                                </video>
                                <img v-else :src="badge.file_url">
                            </div>
                            <div class="text-elements">
                                <span class="notObtained-badge_name" >{{ badge.name }}</span>
                                <span class="notObtained-badge_description" >{{ badge.description }}
                                
                                    <div v-if="badge.conquest" style="margin-top: 15px;">
                                        <span class="notObtained-badge_conquest" >{{ $t('badges.conquest') }}</span>
                                        <span class="notObtained-badge_description" >{{ badge.conquest }}</span>
                                    </div>
                                </span>
                                
                            </div>
                        </div>
                        <div class="badge_cta" v-if="badge.cta && (!checkIOS && !checkMobile)">
                            <span>{{$t('badges.conquer')}}</span>
                            <button class="redirect_cta" @click="redirectCta(badge.external_url, badge.cta_type)">
                                {{ badge.cta_btn_label }}
                            </button>
                        </div>
                        <div class="badge_cta" v-else-if="badge.cta_mobile && (checkIOS || checkMobile)">
                            <span>{{$t('badges.conquer')}}</span>
                            <button class="redirect_cta" @click="redirectCta(badge.external_url_mobile, badge.cta_type_mobile)">
                                {{ badge.cta_btn_label_mobile }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import axios from "axios";

export default {
    name: 'Badges',
    computed: {
        ...mapGetters([
            'logged', 'token', 'avatar', 'switch', 'checkIOS', 'checkMobile'
        ]),
    },
    data() {
        return {
            badges:[],
            badges_left: [],
            openModal:false,
            badge_title:"",
            badge_external_url:"",
            badge_url:"",
        }
    },
    components: {},
    methods: {
        ...mapActions([
            "update_switch",
        ]),
        openModalWith(badge){
            this.openModal = true;
            this.badge_title = badge.name;
            this.badge_external_url = badge.external_url??"";
            this.badge_url = badge.file_url;
            this.$parent.bodyOverflow(true)
        },
        selectSession(e, id) {
            let button = document.getElementById('button_redirect_'+id)
            button.classList.remove('not_ready')
        },
        goToSession(id) {
            window.open(document.getElementById('chooseSession_'+id).value, '_top')
        },
        closeModal(){
            this.openModal = false;
            this.badge_title = "";
            this.badge_external_url = "";
            this.badge_url = "";
            this.$parent.bodyOverflow(false)
        },
        openLink(badge_external_url){
            window.open(badge_external_url,'_blank')
        },
        redirectCta(url, type) {
            switch(type) {
                case 'self':
                    window.open(url, '_top');
                    break;
                case 'iframe':
                    this.$parent.modalI("", url, null, "OK");
                    break
                case 'blank':
                    window.open(url, '_blank')
                    break
            }
        }
    },
    created() {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access': 'application/json',
            language: this.$i18n.locale, Authorization: `Bearer ${this.token}`
        };
        const _root = this;
        axios.get(this.$url_api + "api/badges", {headers,}).then(response=>{
            _root.badges = response.data.badges
            _root.badges_left = response.data.badges_left
        }).catch(_=>console.log(_));
    },
    beforeDestroy() {

    },
}
</script>
