<template>
  <LmsBasicTemplate :current-page="navigationConfig.pageName" :showEtalent="true" :searchField="false" searchPlaceholder="Pesquisar curso...">
    <template v-slot:navigation-side>

      <div class="dropdown mt-3">
        <span class="btn btn-secondary w-100 p-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" @click="toggleMenu">
          {{ selectedCategory || 'Escolha uma categoria' }}
          <i v-if="dropdownMenuToggle" class="bi bi-chevron-down float-right"></i>
          <i v-if="!dropdownMenuToggle" class="bi bi-chevron-up float-right"></i>
        </span>

        <ul class="dropdown-menu w-100" aria-labelledby="dropdownMenuLink" data-bs-target="#dropdownMenuLink"
            aria-hidden="true">
          <li><a class="dropdown-item" href="#" @click.prevent="selectCategory('')">Escolha uma categoria</a></li>
          <li v-for="category in categories" :key="category.id">
            <a class="dropdown-item" href="#" @click.prevent="selectCategory(category)">
              {{ category.title }}
            </a>
          </li>
        </ul>
      </div>

      <ProfileNavigation></ProfileNavigation>

      <section class="mt-3">
        <span class="mycommunities">{{navigationConfig.navigationTitle}}</span>
        <div class="menu-buttons mt-3 d-flex flex-column gap-3">
          <button v-for="(button, idx) in navigationConfig.navigation" :key="idx" type="button" class="btn py-3" @click.prevent="goTo(button.slug)">
            <i v-if="button.icon" :class="button.icon"></i>
            {{ button.name }}
          </button>
        </div>
      </section>

    </template>
    <template v-slot:content-side>

      <div id="nav-tabContent" class="tab-content ps-5" >


        <div class="tab-pane fade active show" id="nav-courses" role="tabpanel" aria-labelledby="nav-courses-tab">
          <div class="container gap-2">
            <div class="row">
              <CourseCard v-for="(course, idx) in coursesToShow" :key="idx" :coursedata="course" :coursename="course.title" ></CourseCard>
            </div>
            <pagination ref="pagination" v-if="paginationOptions.show" v-model="paginationOptions.page" :records="paginationOptions.total" :per-page="paginationOptions.per_page" :options="paginationOptions" @paginate="filterCourse"/>
          </div>
        </div>


        <div class="tab-pane fade" id="nav-etalent" role="tabpanel" aria-labelledby="nav-etalent-tab">
          <div class="container gap-2 my-3" v-if="showEtalent === 'register'">
              <div class="row">
                <div class="col-md-4 col-sm-12">
                  <img src="https://cdn.discordapp.com/attachments/931328487011270696/1141103908517838948/image.png" style="width: 270px;">
                </div>
                <div class="col-md-8 col-sm-12">
                  <h4 class="etalent-title">
                    Entenda o seu perfil
                  </h4>
                  <p class="etalent-desc my-4">
                    Entender o seu perfil é um passo crucial para o crescimento pessoal e profissional.
                    Ao mergulhar nas suas características únicas, você ganha insights valiosos sobre suas habilidades, paixões e áreas de melhoria.
                    Essa compreensão profunda permite tomar decisões mais alinhadas com seus objetivos, explorar oportunidades de aprendizado e maximizar seu potencial.
                    Conheça a si mesmo para direcionar seu caminho com confiança e sucesso.
                  </p>
                  <div class="buy-buttons d-flex justify-content-start align-items-center gap-3 flex-wrap">
                    <div class="btn-checkout">
                      <span class="btn-buy-buttons" @click="registerEtalent">
                        Cadastre-se
                      </span>
                    </div>
                  </div>

                </div>
              </div>
          </div>

          <div class="container gap-2 my-3" v-else-if="showEtalent === 'iframe'">
            <iframe :src="iframeEtalent" frameborder="0" style="width: 100%; height: 65vh;"></iframe>
            <!-- <div class="buy-buttons d-flex justify-content-start align-items-center gap-3 flex-wrap">
              <div class="btn-checkout">
                <span class="btn-buy-buttons" @click="checkResult()">
                  Finalizar
                </span>
              </div>
            </div> -->
          </div>

          <div class="container gap-2 my-3" v-else-if="showEtalent === 'done'">
            <div class="row" v-if="currentResult != null">
              <div class="col-md-4 col-sm-12 align-items-center">
                <div class="box-talent my-2 d-flex justify-content-center align-items-center">
                  <div class="d-flex align-items-center flex-column">
                    <p class="etalent-desc text-center" style="font-weight: 700; font-size: 24px; color: #969A9D">{{ currentResult.Talent.Talentname }}</p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="196" height="110" viewBox="0 0 196 110" fill="none">
                      <line x1="5.47656" y1="55.0447" x2="195.111" y2="55.0447" stroke="#C6CFD2" stroke-width="2"/>
                      <line y1="-2" x2="62.671" y2="-2" transform="matrix(0.699118 -0.715007 0.699118 0.715007 5.47656 88.252)" stroke="#969A9D" stroke-width="4"/>
                      <line y1="-2" x2="83.5736" y2="-2" transform="matrix(0.647135 0.762376 -0.74779 0.663935 45.8682 43.4419)" stroke="#969A9D" stroke-width="4"/>
                      <line y1="-2" x2="130.055" y2="-2" transform="matrix(0.631673 -0.775235 0.761085 0.648652 100.636 107.156)" stroke="#969A9D" stroke-width="4"/>
                      <ellipse cx="100.294" cy="104.706" rx="5.1345" ry="5.25119" fill="#969A9D"/>
                      <ellipse cx="181.761" cy="5.98337" rx="5.1345" ry="5.25119" fill="#969A9D"/>
                      <ellipse cx="46.8952" cy="42.3916" rx="5.1345" ry="5.25119" fill="#969A9D"/>
                      <ellipse cx="5.1345" cy="87.9018" rx="5.1345" ry="5.25119" fill="#969A9D"/>
                    </svg>
                  </div>
                </div>
                <p class="etalent-sum" v-html="currentResult.Talent.Summary"></p>
              </div>
              <div class="col-md-8 col-sm-12">
                <h4 class="etalent-title">
                  Talento {{ currentResult.Talent.Talentname }}
                </h4>
                <p class="etalent-desc" style="font-weight: 600 !important;">{{ currentResult.Talent.Motivation }}</p>
                <p class="etalent-desc my-4">
                    {{ currentResult.Talent.Description }}
                </p>
              </div>

              <div v-if="!purchased" class="position-relative d-flex justify-content-center align-items-center" style="height: 300px;">
                <div class="blur-box position-absolute w-100 h-100 d-flex justify-content-center align-items-center" style="filter: blur(8px); color: #706F6F;">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  <br>
                  Sed feugiat neque nec turpis auctor, ac ultricies massa iaculis. Nullam id posuere lorem. Vivamus tincidunt nisl a quam scelerisque blandit.
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed feugiat neque nec turpis auctor, ac ultricies massa iaculis. Nullam id posuere lorem. Vivamus tincidunt nisl a quam scelerisque blandit.
                  <br>
                  Sed feugiat neque nec turpis auctor, ac ultricies massa iaculis. Nullam id posuere lorem. Vivamus tincidunt nisl a quam scelerisque blandit. Sed feugiat neque nec turpis auctor, ac ultricies massa iaculis. Nullam id posuere lorem. Vivamus tincidunt nisl a quam scelerisque blandit.
                  <br>
                  Sed feugiat neque nec turpis auctor, ac ultricies massa iaculis. Nullam id posuere lorem. Vivamus tincidunt nisl a quam scelerisque blandit. Sed feugiat neque nec turpis auctor, ac ultricies massa iaculis. Nullam id posuere lorem. Vivamus tincidunt nisl a quam scelerisque blandit.
                  Sed feugiat neque nec turpis auctor, ac ultricies massa iaculis. Nullam id posuere lorem. Vivamus tincidunt nisl a quam scelerisque blandit. Sed feugiat neque nec turpis auctor, ac ultricies massa iaculis. Nullam id posuere lorem. Vivamus tincidunt nisl a quam scelerisque blandit.
                </div>
                
                <div class="blur-out container text-center d-flex justify-content-center align-items-center">
                  <div class="gap-2" style="max-width: 70%;">
                    <span class="material-symbols-outlined" style="font-size: 58px; font-weight: 400; color: #6954D9;">
                      lock_open
                    </span>
                    <p class="etalent-title my-1">Veja o seu resultado completo</p>
                    <p class="etalent-desc">Desbloqueie insights profundos sobre seus talentos exclusivos e habilidades. Aprofundar-se em seu perfil completo pode revelar oportunidades ocultas e direcionar suas escolhas de carreira de maneira mais confiante.</p>
                    
                    <div class="buy-buttons d-flex justify-content-center align-items-center gap-3 flex-wrap my-4">
                      <a class="btn-checkout" style="text-decoration:none;" :href="free_report_url" target="_blank">
                        <span class="btn-buy-buttons" style="color: #6954D9 !important; background-color: #FFF !important; font-weight: 700;" title="Acesse o resumo do resultado">
                          Acesse o resumo do resultado
                        </span>
                      </a>
                      <div class="btn-checkout">
                        <span class="btn-buy-buttons" title="Desbloqueie o resultado completo" @click="buyResult()">
                          Desbloqueie o resultado completo
                        </span>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="buy-buttons d-flex justify-content-center align-items-center gap-3 flex-wrap">
                  <a class="btn-checkout" style="margin-top:40px;text-decoration:none;" :href="purchase_report_url" target="_blank">
                    <span class="btn-buy-buttons">
                      Acesse seu resultado completo
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>



    </template>
  </LmsBasicTemplate>
</template>
<script>

import { mapGetters } from "vuex";
import LmsBasicTemplate from "@/components/lms/LmsBasicTemplate.vue";
import ProfileNavigation from "@/components/lms/components/ProfileNavigation.vue";
import CourseCard from "@/components/lms/components/LmsStudentProgressComponents/CourseCard.vue";
import CustomPagination from "@/components/lms/components/LmsHomeComponents/CustomPagination.vue";
let emitter = require('tiny-emitter/instance');
import $ from 'jquery';

export default {
  name: "LmsStudentProgress",
  components: {
    LmsBasicTemplate,
    ProfileNavigation,
    CourseCard
  },
  data() {
    return {
      showEtalent: 'register',
      iframeEtalent: null,
      currentResult: null,
      purchased: false,
      purchase_report_url: null,
      free_report_url: null,
      discProfileId: null,
      dropdownMenuToggle: true,
      selectedCategory: null,
      selectedCategoryId: 0,
      allCourses: [],
      navigationConfig: [],
      searchText: "",
      coursesToShow: [],
      getAllCourses: false,
      categories: [],
      paginationOptions: {
        current_page: 1,
        paginationKey: 0,
        show: true,
        total: 10,
        page: 1,
        per_page: 6,
        template: CustomPagination
      }
    }
  },
  beforeMount() {
    let root_ = this;

    root_.navigationConfig = root_.getPageByName("LmsStudentProgress");

    root_.getUserProgress(1);

    root_.checkResult();
  },
  mounted() {
    let root_ = this;

    emitter.on('filter-courses', function (searchText) {
      root_.searchText = searchText;
      if(root_.searchText.length) {
        root_.coursesToShow = root_.allCourses.filter(course => {
          return course.title.toLowerCase().includes(root_.searchText.toLowerCase());
        });

        root_.paginationOptions.show = false;

      } else if(root_.searchText.length === 0) {

        root_.getUserProgress(1);
      }
    });

    emitter.on('etalentReport::response', function (payload) {
      root_.etalentResponse(payload);
    });

    if(this.$route.query.disc) {
      document.getElementById('nav-etalent-tab').click();
      $('#nav-etalent-tab').tab('show')
    }

  },
  methods: {
    etalentResponse(payload) {
      // console.log('websoket payload => ', payload);
      this.free_report_url  = payload.object.externalReportUrl;
      this.currentResult    = payload.object.response_data;
      this.discProfileId    = payload.object.discProfileId;
      this.showEtalent      = 'done';
    },
    checkResult() {
      let root_ = this;

      let payload = {
        "lms_id": root_.$route.params.lmsSlug,
      };

      this.$store.dispatch('LMS/checkEtalentResult', payload).then( response => {
        // console.log('JOHN 1', response.data.data.current)
        this.free_report_url      = response.data.data.current.externalReportUrl;
        this.currentResult        = response.data.data.current.response_data;
        this.discProfileId        = response.data.data.current.discProfileId;
        this.purchased            = response.data.data.current.purchased;
        this.purchase_report_url  = response.data.data.current.purchase_report_url;
        this.showEtalent = 'done';

      }).catch( error => {
        error;
        this.showEtalent = 'register';
      });
    },
    buyResult() {
      this.$router.push({ name: 'LmsCheckoutAddToCart', query: { object: 'etalentReport', object_id: this.discProfileId } });
    },
    registerEtalent() {
      let root_ = this;
      let payload = {
        "lms_id": root_.$route.params.lmsSlug,
      };

      this.$store.dispatch('LMS/registerEtalentUser', payload).then( response => {
        this.showEtalent = 'iframe';
        this.iframeEtalent = response.data.data.etalent.access_key_url;
      }).catch( error => {
        console.log(error);
        this.showEtalent = 'register';
      });
    },
    getUserProgress(page) {
      let root_ = this;

      let payload = {
        "slug": root_.$route.params.lmsSlug,
        "language": root_.$i18n.locale,
        "page": page,
        "category_id" : root_.selectedCategoryId ?? 0
      };

      root_.$store.dispatch("LMS/getUserProgress", payload)
          .then(response => {
            root_.allCourses = Object.values(response.courses.data);
            root_.coursesToShow = Object.values(response.courses.data);
            root_.categories = response.categories;

            root_.paginationOptions.current_page = response.courses.current_page;
            root_.paginationOptions.total = response.courses.total;
            root_.paginationOptions.per_page = response.courses.per_page;
            root_.paginationOptions.page = response.courses.current_page;

            if(root_.searchText.length) {
              root_.coursesToShow = root_.allCourses.filter(course => {
                return course.title.toLowerCase().includes(root_.searchText.toLowerCase());
              });

              root_.paginationOptions.total = root_.coursesToShow.length;
            }

            root_.paginationOptions.show = root_.paginationOptions.total > root_.paginationOptions.per_page;

          });

    },
    selectCategory(category) {
      let root_ = this;

      root_.selectedCategory = category.title;
      root_.selectedCategoryId = category.id;

      root_.getUserProgress(1);

    },
    goTo(slug){
      console.log("goTo: ", slug);
      if(slug !== "") {
        this.$router.push({ name: slug})
      }
    },
    toggleMenu(){
      this.dropdownMenuToggle = !this.dropdownMenuToggle;
    },
    filterCourse(page){
      let root_ = this;

      root_.getUserProgress(page);

    },
  },
  computed:{
    ...mapGetters({
      getLms: "LMS/getLms",
      getPageByName: "LMS/getPageByName"
    }),
    ...mapGetters([
        "avatar"
    ]),
  },
  beforeDestroy() {
    emitter.off('filter-courses');
    emitter.off('etalentReport::response');
  }

}

</script>

<style scoped lang="scss">

.active {
   background-color: transparent !important;
}

.blur-box {
  width: 100%;
  height: 300px;
  filter: blur(10px);
}

.buy-buttons {
  color: #706F6F;
    span {
      color: #706F6F;
      font-family: "Hanken Grotesk";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      cursor: pointer;

      &.btn-buy-buttons {
        height: 50px;
        padding: 14px 28px;
        color: #FFFFFF;
        border-radius: 30px;
        background: #6954D9;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      }
  }
}

.etalent-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  font-family: "Hanken Grotesk", sans-serif;
  color: #6954D9;
}

.etalent-desc {
  font-size: 16px;
  font-weight: 400;
  font-family: "Hanken Grotesk", sans-serif;
  line-height: 20px;
  color: #706F6F;
}

.etalent-sum {
  font-size: 14px;
  font-weight: 600;
  font-family: "Hanken Grotesk", sans-serif;
  line-height: 20px;
  color: #706F6F;
}

.box-talent {
  width: 100%;
  height: 200px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #DFEEF1;
}

.box-talent svg {
  width: 100%;
}

#dropdownMenuLink {
  color: #706F6F;
  font-size: 16px;
  font-family: "Hanken Grotesk", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
  border-radius: 10px;
  border: 1px solid #E1E1E1;
  background: #FFF;

}

.mycommunities {
  color: #706F6F;
  font-size: 16px;
  font-family: "Hanken Grotesk", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.menu-buttons {

  .btn {
    color: #706F6F;
    background-color: #F6F6F6;
    font-size: 18px;
    font-family: "Hanken Grotesk", sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;

    .bi {
      color: #6954D9;
    }
  }

}

.VuePagination {
  display: flex;
  justify-content: center;
}

</style>