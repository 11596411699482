<template>
    <section class="myaccountSection">
      <div class="myaccount">
            <p class="title-myaccount">{{ $t('account.minhaConta') }}</p>
        <div class="myaccount__content">
            <div v-for="(content, index) in content_box" :key="index" class="card-container" v-on:click="content.click($parent)"  v-show="true">
                        <img :src="content.icon_url">
                    <div class="text">
                      <div class="container-description">
                          <h2 class="content-title">{{ content.title }}</h2>
                          <p>{{ content.description }}</p>
                      </div>
                    </div>
                </div>
        </div>

        <div class="myaccount__buttons">
      <a v-on:click="$parent.logout">
          <button class="signup">
                  <img src="../../public/assets/icons/myAccount/logout.svg" alt="icone para sair da conta">
                  <span>{{ $t('account.sair') }}</span>
          </button>
      </a>

      <a v-on:click="$parent.deleteAccount">
          <button class="signup delete">
                  <img src="../../public/assets/icons/myAccount/lixeira.svg" alt="icone para deletar conta">
                  <span>{{ $t('account.deletarConta') }}</span>
          </button>
      </a>
        </div>

 
    </div>
    <div class="footer">
      <img 
        src="assets/images/footer.svg" 
        alt="background do footer (menu mapa)"
      >
      </div>   
    </section>
</template>

<script>
import {mapGetters, mapActions} from "vuex";

export default {
    name: 'Account',
    computed: {
        ...mapGetters([
            'logged', 'token', 'avatar', 'switch'
        ]),
        
    },
    data() {
        return {

          content_box: [
            {
              title: this.$i18n.t('account.meuPerfil'),
              click: (parent) => parent.link('Profile'),
              icon_url: '/assets/icons/myAccount/my-profile.png',
              description: this.$i18n.t('account.mantenhaSeuCadastro'),
            },
            {
              title: this.$i18n.t('account.minhaAgenda'),
              click: (parent) => parent.link('Agenda'),
              icon_url: '/assets/icons/myAccount/calendar.png',
              description: '',
              tag: true,
            },
            {
              title: this.$i18n.t('account.suporte'),
              click: (parent) => parent.link('Support'),
              icon_url: '/assets/icons/myAccount/whats.png',
              description: this.$i18n.t('account.duvidas'),
            },
            {
              title: this.$i18n.t('account.galeriaDeFotos'),
              click: (parent) => { parent.camera=true; parent.open_gallery=true },
              icon_url: '/assets/icons/myAccount/galery.png',
              description: this.$i18n.t('account.confiraTodas'),
            },
            {
              title: this.$i18n.t('account.meuAvatar'),
              click: () => this.$router.push({path:'/e/redcarpet'}),
              icon_url: '/assets/icons/myAccount/person.png',
              description: this.$i18n.t('account.seuAvatarEsta'),
            },
            // {
            //   title: this.$i18n.t('account.amigos'),
            //   click: (parent) => parent.link('Friends'),
            //   icon_url: '/assets/icons/myAccount/friends.png',
            //   description: this.$i18n.t('account.esteEOLugar'),
            // },
          ]
        }
    },
    components: {},
    methods: {
      ...mapActions([
        "update_switch",
      ]),

    created() {
      
    },
    beforeDestroy() {

    },

  }
}
</script>
