<template>
  <section class="container">
    <div class="category mt-5" v-for="(category, idx) in categories" :key="idx">
      <div class="d-flex justify-content-between align-items-center mb-5">
        <h3 class="section-title">{{ category.title }}</h3>
        <span class="see-all" @click="goLibrary(category.id)">Ver Mais</span>
      </div>

      <div class="container mb-3 px-0">
        <div class="swiper-container course-sections" :class="category.slug">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(course, idxCourse) in category.courses.slice(0, 4)" v-for-callback="{key: idxCourse, array: category, slidesToShow: slidesToShow, categoryslug: category.slug, callback: endVforCourses}" :key="idxCourse">

              <div class="cards" @click="viewCourse(course.slug, course.id, category.id)">
                <div class="cards-img" :style="{ 'background-image': 'url(' + course.thumbnail + ')' }">
                </div>
                <div class="favorite" @click.stop="favoriteItem" :class="{ 'selected': course.is_fav }" :data-id="course.id" data-object="course">
                  <i class="bi bi-suit-heart-fill"></i>
                </div>
                <span class="section-title text-center">{{ course.title }}</span>
              </div>

            </div>

          </div>
        </div>

<!--        <div class="swiper-button-prev swiper-button-prev-course-sections mt-0" :class="category.slug">-->
<!--          <i class="bi bi-chevron-left"></i>-->
<!--        </div>-->
<!--        <div class="swiper-button-next swiper-button-next-course-sections mt-0" :class="category.slug">-->
<!--          <i class="bi bi-chevron-right"></i>-->
<!--        </div>-->


      </div>

    </div>

  </section>
</template>

<script>

import {mapGetters} from "vuex";
import Swiper from "swiper";

export default {
  name: "CourseSections",
  computed: {
    ...mapGetters('LMS', [
      'getLms'
    ]),
    categories: function () {
      // All categories
      // return this.getLms.categories;
      // Only categories with courses
      if (!this.getLms.categories?.length) {
        return [];
      }
      return this.getLms?.categories.filter(function (category) {
        return category.courses.length;
      });
    }
  },
  data() {
    return {
      swiper: {},
      slidesToShow: 4
    }
  },
  directives: {
    forCallback(el, binding, vnode) {

      let element = binding.value;
      let category = binding.value;
      let key = category.key;
      let coursesLength = category.array.courses.length;
      let slidesToShow = category.slidesToShow;
      let categoryslug = category.categoryslug;
      if (coursesLength >= slidesToShow && key === slidesToShow - 1) {
          (element.callback.bind(vnode.context))(categoryslug);
      } else if(key === coursesLength - 1){
        (element.callback.bind(vnode.context))(categoryslug)
      }
    }
  },
  methods: {
    buildSwiper(categorySlug) {
      let root_ = this;

      let options = {
        slidesPerView: 4,
        spaceBetween: 20,
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1.5
          },
          640: {
            slidesPerView: 2.5
          },
          1024: {
            slidesPerView: 4.5
          }
        },
        autoplay: false,
        loop: false,
        allowTouchMove: true,
        observer: true,
        navigation: {
          nextEl: ".swiper-button-next-course-sections.swiper-button-next." + categorySlug,
          prevEl: ".swiper-button-prev-course-sections.swiper-button-prev." + categorySlug,
        },
      };

      root_.$nextTick(() => {
        root_.swiper = new Swiper(".swiper-container.course-sections." + categorySlug, options);
      });

    },

    favoriteItem(e) {
      const courseElement = e.currentTarget;
      const courseId = courseElement.getAttribute("data-id");
      const objectData = courseElement.getAttribute("data-object");

      let payload = {
        "object": objectData,
        "object_id": courseId
      }


      this.$store.dispatch("LMS/favoriteItem", payload)
          .then(response => {
              this.categories.forEach(category => {
                category.courses.forEach(course => {
                      if (course.id == courseId) {
                        course.is_fav = !course.is_fav;
                        response;
                      }
                })
              })
          })
          .catch(error => {
            console.log('ERROR WHEN FAVORITE COURSE', error)
          });
    },
    viewCourse(courseSlug, courseId, categoryId) {

      this.$store.dispatch("LMS/update_course_selected", courseId);
      this.$router.push({
        name: "LMSCourses",
        params: {
          lmsSlug: this.getLms.slug,
          courseSlug: courseSlug,
          category: categoryId
        }
      });
    },
    endVforCourses(categoryslug) {
      this.buildSwiper(categoryslug);
    },
    goLibrary(categoryId){
      console.log(categoryId);
      this.$router.push({
        name: 'LmsLibrary',
        params: {
          categoryId: categoryId
        }
      });
    }
  }
}

</script>

<style lang="scss" scoped>

.swiper-slide {
  width: 20%;
}

.swiper-container.course-sections {
  height: 300px;
}

.cards {
  position: relative;
  height: 242px;
  cursor: pointer;
  flex: 1 0 auto;

  .cards-img {
    position: relative;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    background-blend-mode: saturation;
    height: 100%;
    width: 100%;
    border-radius: 30px;
  }

  .favorite {
    position: absolute;
    right: 25px;
    top: 15px;
    font-size: 28px;
    z-index: 1;
    cursor: pointer;
    color: #FFFFFF80;

    &.selected {
      color: #E82E2E;
    }
  }


}

span {
  font-size: 20px;
  color: #706F6F;
  text-align: center;
  display: block;
  margin-top: 15px;
}


.all-courses {
  font-family: 'Hanken Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #706F6F;
  cursor: pointer;
}

.swiper-button-next-course-sections, .swiper-button-prev-course-sections {
  position: absolute;
  height: 100%;
  top: 0;
  background: #000;
  opacity: .3;

  .bi {
    color: #FFFFFF;
    font-size: 35px;
  }

}

.swiper-button-prev-course-sections {
  left: 0;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.swiper-button-next-course-sections {
  right: 0;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.course-title {
  font-family: "Space Grotesk";
  font-size: 36px;
  line-height: 48px;
  font-style: normal;
  color: #6954D9;
}

</style>