<template>
    <div>
        <div class="container">
            <div class="left-container">
                <div class="accordion" id="sessionsList">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="sessionsListHeader">
                            <button class="accordion-button" data-bs-toggle="collapse" data-bs-target=".session" aria-controls="collapseOne">
                                {{section.title}}
                            </button>
                        </h2>
                        <div class="sectionList">
                            <div class="accordion-collapse collapse show session" @click="goToLesson(lesson)" v-for="(lesson, index) in section.lessons" :key="index" :class="lesson.id == selectedLesson.id || lesson.is_finished ? '_selected' : ''" aria-labelledby="sessionsListHeader" data-bs-parent="#sessionsList">
                                <div class="accordion-body lesson-desc">
                                    <p>{{lesson.title}}</p>
                                    <svg v-show="lesson.is_finished" xmlns="http://www.w3.org/2000/svg" height="1.0em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" fill="#6954d9"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="accordion" id="notesList">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="notesListHeader">
                            <button class="accordion-button" data-bs-toggle="collapse" data-bs-target=".note-content" aria-controls="collapseOne">
                                Minhas anotações
                            </button>
                        </h2>
                        <div class="accordion-collapse collapse show action-button note-content" aria-labelledby="notesListHeader" data-bs-parent="#notesList">
                            <div class="accordion-body">
                                <span @click="createNewNote"><i class="fas fa-plus"></i> Criar post-it</span>
                            </div>
                        </div>
                        <draggable v-model="notesOrder" @end="onDragEnd" class="notesList">
                            <div class="accordion-collapse collapse show note note-content" :data-id="note.id" v-for="(note, index) in notesOrder" :key="index" aria-labelledby="notesListHeader" data-bs-parent="#notesList">
                                <div class="accordion-body">
                                    <div class="anotation" :style="'background-color:'+ note.color">
                                        <div class="action-anotation-buttons">
                                            
                                            <span @click="editNote(note, 'edit')" v-if="!note.isEditing"><i class="fas fa-edit"></i> Editar nota</span>
                                            <span @click="editNote(note, 'save')" v-if="note.isEditing"><i class="fas fa-check"></i> Salvar</span>
                                            <span @click="editNote(note, 'cancel')" v-if="note.isEditing">Cancelar</span>
                                            <span @click="deleteNote(note)"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg></span>
                                        </div>
                                        <div class="video-times" v-if="note.track_videos != null && note.track_videos.length > 0">
                                            <div class="video-time" v-for="(track, index) in note.track_videos" :key="index">
                                                <span>Video {{index + 1}} <span style="color: #6954d9"><i class="fas fa-play-circle"></i> {{formatTrack(track.current_time)}}</span></span>
                                            </div>
                                        </div>
                                        <textarea v-model="noteText" v-if="note.isEditing" cols="30" rows="10"></textarea>
                                        <div v-else>{{note.note}}</div>
                                    </div>
                                </div>
                            </div>
                        </draggable>
                        <div id="ordenation">
                            <input :id="'order_' + note.id" :data-id="note.id" v-for="(note, index) in notesOrder" :key="index"
                                name="order[]" type="hidden" :value="index" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="right-container">
                <div class="lesson-header">
                    <div class="right-container-breadcrumb">
                        <span @click="$router.push({path: `/lms/${$route.params.lmsSlug}`})">
                            {{ this.lms.title }}
                        </span>
                        <i class="fas fa-chevron-right"></i>
                        <span @click="$router.push({path: `/lms/${lms.slug}/${course.slug}`})">
                            {{ this.course.title }}
                        </span>
                        <i class="fas fa-chevron-right"></i>
                        <span class="selected">
                            {{ this.section.title }}
                        </span>
                        
                    </div>
                    <div class="content-actions">
                        <button class="content-button" @click="previousLesson()" v-show="this.selectedLesson.id != this.section.lessons[0].id">
                            <i class="fas fa-chevron-left"></i> Aula anterior
                        </button>
                        <button class="content-button finish" @click="finishLesson()" v-show="!this.selectedLesson.is_finished">
                            <i class="far fa-check-circle"></i> Concluir aula
                        </button>
                        <button class="content-button" @click="nextLesson()" v-show="this.selectedLesson.id != this.section.lessons.slice(-1)[0].id">
                            <i class="fas fa-chevron-right"></i> Próxima aula
                        </button>
                    </div>
                </div>
                <div class="lesson-content">
                    <h3>{{selectedLesson.title}}</h3>
                    <div v-for="(content, index) in selectedLesson.contents" :key="index">
                        <div class="video-container content" v-if="content.action_type == 'video'">
                            <VideoPlayer :video_id="content.id" :video_url="content.action_value"></VideoPlayer>
                        </div>
                        <div class="image-container content" v-if="content.action_type === 'image'">
                            <img :src="content.action_value" :alt="selectLesson.title">
                        </div>
                        <div class="text-container content" v-if="content.action_type == 'text'">
                            <div v-html="makeHTML(content.action_value)"></div>
                        </div>
                        <div class="audio-container content" v-if="content.action_type == 'audio'">
                            <AudioPlayer :audio_id="content.id" :audio_url="content.action_value"></AudioPlayer>
                        </div>
                        <div class="title-container content" v-if="content.action_type == 'title'">
                            <h2>{{content.action_value}}</h2>
                        </div>
                        <div class="pdf-container content" v-if="content.action_type == 'pdf'">
                            <PdfViewer :pdf_id="content.id" :pdf_url="content.action_value"></PdfViewer>
                        </div>
                        <div class="iframe-container content" v-if="content.action_type == 'iframe' || content.action_type == 'video_public'">
                            <iframe :src="content.action_value" frameborder="0"></iframe>
                        </div>
                        <div class="iframe-container content" v-if="content.action_type == 'quiz' || content.action_type == 'form'">
                            <div class="replay-btn-container" :class="content.action_options.multiple_times && content.answered ? '' : '_wait'" :ref="'replay-btn-'+content.id" :id="'replay-btn-'+content.id">
                                <button class="replay-btn" @click="replayQuizForm(content.id)">Deseja refazer {{content.action_type == 'quiz' ? 'o quiz?' : 'a avaliação?'}}</button>
                            </div>
                            <iframe :class="content.action_options.multiple_times && content.answered ? '_wait' : ''" :src="formatQuizUrl(content.action_value)" :ref="'form-quiz-'+content.id" frameborder="0"></iframe>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        
    </div>


        
</template>
<script>
    import {mapGetters} from "vuex";
    import Draggable from 'vuedraggable'
    import VideoPlayer from './components/VideoPlayer'
    import AudioPlayer from './components/AudioPlayer'
    import PdfViewer from './components/PdfViewer'
    const emitter = require('tiny-emitter/instance');
    import $ from 'jquery';

    export default {
        name: 'LmsLessonContent',
        data() {
            return {
                colorNotes: [
                    '#ffd10f', '#dee365', '#87e0fe', '#ffa3e3'
                ],
                notesOrder: [],
                noteText: '',
                noteToDelete: {},
            }
        },
        computed: {
            ...mapGetters({
                lms: ['LMS/getLms'],
                section: ['LMS/getSection'],
                course: ['LMS/getCourse'],
                notes: ['LMS/getUserNotes'],
                selectedLesson: ['LMS/getLesson']
            }),
        },
        components: {
            Draggable,
            VideoPlayer,
            AudioPlayer,
            PdfViewer
        },
        beforeMount(){
            let vm = this
            let route = vm.$route.params;
            if(route?.lmsSlug){
                vm.$store.dispatch('LMS/getListLMS', { slug: route.lmsSlug });
            }
        },
        mounted() {
            //title, text, audio, image, video, video_public, pdf, iframe, quiz, form
            let vm = this
            vm.$parent.wait(true)
            vm.$store.dispatch('LMS/getCourse', { slug: vm.$route.params.courseSlug }).then(() => {
                vm.$store.dispatch('LMS/getSection', { slug:vm.$route.params.sectionSlug }).then(() => {
                    vm.selectLesson(vm.section.lessons.find(l => l.slug == vm.$route.params.lessonSlug))
                }).then(() => {
                    vm.$parent.wait(false)
                    let videos = document.getElementsByClassName("content-video");
                    console.log('selectLesson =>', vm.selectedLesson)
                    console.log('section lessons =>', vm.section.lessons)
                    emitter.on('play-video', function (video_id) {
                        for (var i = 0; i < videos.length; i++) {
                            if (videos[i].dataset.id != video_id) {
                                videos[i].pause()
                            }
                        }
                    })
                })
            });

            $(window).scroll(function(){
                let $el = $('.left-container');
                let isPositionFixed = ($el.css('position') == 'fixed');
                if ($(this).scrollTop() > 200 && !isPositionFixed){
                    $el.css({'position': 'sticky', 'top': '84px'});
                }
                if ($(this).scrollTop() < 200 && isPositionFixed){
                    $el.css({'position': 'relative'});
                }
            });
        },
        methods: {
            makeHTML(htmlData) {
                const parser = new DOMParser();
                const doc = parser.parseFromString(htmlData, 'text/html');

                // Localize todos os elementos <blockquote> dentro do elemento
                let blockquotes = doc.querySelectorAll('blockquote');
                // Aplique estilos a cada elemento <blockquote>
                blockquotes.forEach((blockquote) => {
                    // Aplique estilos CSS
                    blockquote.style.fontSize = '1.2em';
                    blockquote.style.width = '100%';
                    blockquote.style.margin = '25px auto';
                    blockquote.style.fontStyle = 'italic';
                    blockquote.style.color = '#555555';
                    blockquote.style.padding = '1.2em 30px 1.2em 75px';
                    blockquote.style.borderLeft = '8px solid var(--buttons_color)';
                    blockquote.style.lineHeight = '1.6';
                    blockquote.style.position = 'relative';
                    blockquote.style.background = '#EDEDED';

                    // Crie elementos ::before e ::after
                    const quoteBefore = document.createElement('span');
                    const quoteAfter = document.createElement('span');

                    // Aplique estilos para ::before
                    quoteBefore.style.fontFamily = 'Arial';
                    quoteBefore.innerHTML = '"';
                    quoteBefore.style.color = 'var(--buttons_color)';
                    quoteBefore.style.fontSize = '4em';
                    quoteBefore.style.position = 'absolute';
                    quoteBefore.style.left = '10px';
                    quoteBefore.style.top = '-10px';

                    // Aplique estilos para ::after
                    quoteAfter.style.content = '';

                    // Adicione os elementos ::before e ::after ao bloco de citação
                    blockquote.appendChild(quoteBefore);
                    blockquote.appendChild(quoteAfter);
                });

                // Definição do parágrafo
                let p = doc.querySelectorAll('p');
                p.forEach((p) => {
                    p.style.fontSize = '14px';
                    p.style.lineHeight = '1.6';
                });

                // Converta o documento de volta para uma string HTML
                return new XMLSerializer().serializeToString(doc);
            },
            createNewNote() {
                let vm = this
                let track_videos = vm.getVideosCurrentTime()
                vm.$parent.wait(true)
                let payload = {
                    "lesson_id": vm.selectedLesson.id,
                    "note": 'Texto da anotação',
                    "color": vm.colorNotes[Math.floor(Math.random() * 4)],
                    "pinned": false,
                    "order": 0,
                    "track_videos": track_videos
                }
                vm.$store.dispatch('LMS/createUserNote', payload).then(() => {
                    vm.updateNotes()
                    vm.$parent.wait(false)
                }).catch((error) => {
                    console.log(error.response)
                    vm.$parent.wait(false)
                })
            },
            replayQuizForm(id) {
                this.$refs['replay-btn-'+id][0].classList.add('_wait')
                this.$refs['form-quiz-'+id][0].classList.remove('_wait')
                console.log('replay-btn', this.$refs['replay-btn-'+id][0])
            },
            selectLesson(lesson) {
                let vm = this
                vm.$parent.wait(true)
                vm.$store.dispatch('LMS/getLesson', {slug: lesson.slug}).then(() => {
                    this.getUserNotes()
                    console.log('selectedLesson =>', this.selectedLesson)
                }).then(() => {
                    this.updateNotes()
                }).catch((error) => {
                    console.log(error.user)
                    if (error.user == "User doesn't have permission.") {
                        vm.$parent.wait(false)
                        vm.$router.push({ path: `/lms/${this.$route.params.lmsSlug}/checkout/add-to-cart?object=course&object_id=${this.course.id}`})
                    }
                    vm.$parent.wait(false)
                })

            },
            goToLesson(lesson) {
                this.$router.push({path: `/lms/${this.$route.params.lmsSlug}/${this.$route.params.courseSlug}/${this.$route.params.sectionSlug}/${lesson.slug}`})
            },
            getUserNotes() {
                let vm = this
                vm.$parent.wait(true);
                vm.$store.dispatch('LMS/getUserNotes', {lesson_id: vm.selectedLesson.id}).then(() => {
                    this.updateNotes()
                    vm.$parent.wait(false)
                })
            },
            editNote(note, action) {
                let vm = this
                if (action == 'edit') {
                    vm.noteText = note.note
                    note.isEditing = true
                } else if (action == 'cancel') {
                    note.isEditing = false
                } else if (action == 'save') {
                    vm.$parent.wait(true)
                    vm.$store.dispatch('LMS/updateNote', {note_id: note.id, ...note, note: vm.noteText}).then(() => {
                        vm.updateNotes()
                        vm.$parent.wait(false)
                    })
                }
            },
            deleteNote(note) {
                this.noteToDelete = note
                this.$parent.modal("confirmation", this.$i18n.t('app.atencao'), 'Deseje excluir esta nota?', this.deleteNoteConfirmation);
            },
            deleteNoteConfirmation(r) {
                if (r === 1) {
                    let vm = this
                    vm.$parent.wait(true)
                    vm.$store.dispatch('LMS/deleteNote', {note_id: this.noteToDelete.id}).then(() => {
                        
                        vm.getUserNotes()
                    })
                }
            },
            previousLesson() {
                let lessonIndex = this.section.lessons.filter(l => l.id == this.selectedLesson.id)
                let previousLessonIndex = this.section.lessons.indexOf(lessonIndex[0])
                let previousLesson = this.section.lessons[previousLessonIndex - 1]
                this.$router.push({path: `/lms/${this.$route.params.lmsSlug}/${this.$route.params.courseSlug}/${this.$route.params.sectionSlug}/${previousLesson.slug}`})
            },
            nextLesson() {
                let lessonIndex = this.section.lessons.filter(l => l.id == this.selectedLesson.id)
                let nextLessonIndex = this.section.lessons.indexOf(lessonIndex[0])
                let nextLesson = this.section.lessons[nextLessonIndex + 1]
                this.$router.push({path: `/lms/${this.$route.params.lmsSlug}/${this.$route.params.courseSlug}/${this.$route.params.sectionSlug}/${nextLesson.slug}`})
            },
            finishLesson() {
                let vm = this
                // vm.$parent.wait(true)
                vm.$store.dispatch('LMS/finishLesson', {lesson_id: vm.selectedLesson.id}).then(() => {
                    // vm.$parent.wait(false)
                    console.log("finishLesson done");
                    // if (vm.selectedLesson.id != vm.section.lessons.slice(-1)[0].id) {
                    //     vm.nextLesson()
                    // } else {
                    //     vm.$router.go()
                    // }
                })

            },
            updateNotes() {
                this.notesOrder = this.notes
            },
            formatQuizUrl(quizId) {
                const url = this.$url_api + `form/view/${quizId}`
                return url
            },
            formatTrack(track) {
                let totalSeconds = Math.round(track)
                let hoursLeft = Math.round(totalSeconds / 3600)
                let min = Math.round((totalSeconds - hoursLeft * 3600) / 60)
                let secondsLeft = totalSeconds - hoursLeft * 3600 - min * 60
                secondsLeft = Math.round(secondsLeft * 100) /100
                let formattedTime = ''
                if (hoursLeft > 0) {
                    formattedTime += (hoursLeft < 10 ? "0"+hoursLeft : hoursLeft)
                    formattedTime += ":"+(min < 10 ? "0"+min : min)
                    formattedTime += ":"+(secondsLeft < 10 ? "0"+secondsLeft : secondsLeft)
                } else if  (hoursLeft <= 0) {
                    formattedTime += (min < 10 ? "0"+min : min)
                    formattedTime += ":"+(secondsLeft < 10 ? "0"+secondsLeft : secondsLeft)
                }
                return formattedTime
            },
            onDragEnd() {
                var slides = document.getElementsByClassName("note");
                var order = {};

                for (var i = 0; i < slides.length; i++) {
                    var id = slides[i].dataset.id;
                    document.getElementById("order_" + id).value = i;
                }

                var inputs = document.getElementsByName("order[]");

                for (var j = 0; j < inputs.length; j++) {
                    var id_ = inputs[j].dataset.id;
                    var value = inputs[j].value;
                    order[id_] = value;
                }
                let vm = this
                vm.$store.dispatch('LMS/ordenateUserNotes', {order: order, lesson_id: vm.selectedLesson.id}).then(() => {
                    vm.updateNotes()
                    vm.$parent.wait(false)
                }).catch((error) => {
                    console.log(error.response)
                    vm.$parent.wait(false)
                })
            },
            getVideosCurrentTime() {
                let videos  = document.getElementsByClassName("content-video");
                let array   = [];
                for (var i = 0; i < videos.length; i++) {
                    var object = {};
                    object['lesson_content_id'] = videos[i].dataset.id;
                    object['current_time']      = videos[i].currentTime;
                    array.push(object);
                }
                return array;
            },
        }
    }
</script>
<style lang="scss" scoped>
    .container {
        display: flex;
        margin-top: 8rem;
        justify-content: space-between;
    }
    .right-container {
        width: 67%;
        height: 100%;
    }
    .left-container {
        width: 30%;
        height: 100%;
    }

    .anotations-buttons {
        padding: 10px;
        display: flex;
        justify-content: flex-end;
    }

    #notesList {
        .accordion-body {
            display: flex;
            justify-content: flex-end;
        }
    }

    .anotation {
        width: 100%;
        display: flex;
        align-self: center;
        flex-direction: column;
        height: 266px;
        line-break: anywhere;
        overflow-y: auto;
        margin-bottom: 10px;
        padding: 20px;
        .action-anotation-buttons {
            display: flex;
            justify-content: flex-end;
            span {
                margin-left: 10px;
                cursor: pointer;
                font-size: 13px;
            }
        }
        textarea{
            background-color: transparent;
            resize: none;
        }
    }

    .video-times {
        display: flex
    }
    .video-time {
        width: 50px;
        display: flex;
        width: 200px;
    }
    .lesson-header {
        display: flex;
        justify-content: space-between;
        padding: 0px 0px 25px;
    }

    .lessonidesc {
        display: flex;
        justify-content: space-between;
    }


    #notesList, #sessionsList {
        margin-bottom: 20px;
        .accordion-header {
            .accordion-button{
                &:not(.collapsed){
                background: #f6f6f6;
                }
            }
        }
        .accordion-collapse._selected {
            background-color: #f6f6f6;
        }
        .accordion-collapse.action-button {
            opacity: .6;
            span {
                cursor: pointer;
            }
        }
        /* WebKit and Chromiums */
        ::-webkit-scrollbar {
            width: 8px;
            height: 8px;
            background-color: white;
        }

        ::-webkit-scrollbar-thumb {
            background: var(--buttons_color);
            border-radius: 5px;
        }
    }

    .notesList {
        max-height: 500px;
        overflow-y: auto;
    }

    .sectionList {
        max-height: 300px;
        overflow-y: auto;
        .accordion-body {
            p {
                margin: 0;
                font-size: 12px;
                font-weight: 300;
            }
        }
    }
    
    .session {
        cursor: pointer;
    }

    .right-container-breadcrumb {
        display: flex;
        align-items: center;
        justify-content:flex-start;
        width: 40%;
        font-size: 14px;
        padding: 0;
        font-weight: 500;
        i {
            margin-right: 15px;
        }
        span {
            border: none;
            background-color: transparent;
            cursor: pointer;
            color: #706F6F;
            font-weight: 500;
            width: auto;
            margin-right: 15px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &:hover {
                color: #000;
            }
            &.selected {
                color: #6954d9;
                cursor: default;
            }
        }
    }

    .content-actions {
        display: flex;
        width: 50%;
        justify-content: flex-end;
        opacity: .6;
        button {
            margin-right: 25px;
        }
    }

    .lesson-desc{
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:p {
            max-width: 80%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &:svg {
            align-self: flex-end;
        }
    }

    .content-button {
        border: none;
        background-color: transparent;
        cursor: pointer;
        font-size: 16px;
        color: #b1b1b1;
    }
    .content-button:hover {
        color: #000;
    }
    .content-button.finish:hover {
        color: #6954d9;
    }

    .lesson-content {

        h3 {
            margin-bottom: 50px;
        }

        .content {
            margin-bottom: 40px;
            line-break: anywhere;
        }
        .text-container {
            max-width: 100%;
            .quote {
                color: red;
            }
        }

        .audio-container {
            max-width: 100%;
        }

        .image-container {
            width: 100%;
            img {
                width: 100%;
            }
        }

        .iframe-container {
            width: 100%;
            border-radius: 20px;
            iframe {
                width: 100%;
                border-radius: 20px;
                height: 550px;
            }

            ._wait {
                display: none;
            }

            .replay-btn-container {
                width: 100%;
                height: 100%;
                button{
                    padding: 15px 20px;
                    background-color: transparent;
                    border-radius: 10px;
                    border: 2px solid rgb(10, 170, 10);
                    cursor:pointer;
                    margin-left: 300px;
                    &:hover {
                        background-color: rgb(10, 170, 10);
                        color: #fff;
                    }
                }
            }
        }

        audio {
            width: 100%;
            color: purple;
        }
    }
    .lesson-description {
        padding: 15px;
        p {
            font-size: 15px;
        }
    }

    blockquote {
        color: red !important;
    }
</style>
