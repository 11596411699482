<template>
    <section class="map-mosaico">
        <div v-if="showPlayer" class="contentBanner">
            <div class="swiper-container">
                <div class="swiper-wrapper">
                    <div :key="index" v-for="(item, index) in renderItems" class="swiper-slide alignVertical carousel">
                        <input name="time" v-if="checkSafari" hidden :value="item.time_safari">
                        <input name="time" v-else-if="(checkMobile || checkIOS)" hidden :value="item.time_mobile">
                        <input name="time" v-else hidden :value="item.time">
                        <video v-if="!/\.(jpe?g|png|svg|gif)$/i.test(item.url)" :src="item.url" class="video-swiper" loop>
                            <source :src="item.url">
                        </video>
                        <img v-else :src="item.url" alt="slide" class="image-swiper">
                        <div class="content">
                            <div class="col-6 alignVerticalStart">
                                <h2 class="col-12" v-text="item.title"></h2>
                                <button class="btn" style="margin-top:20px" v-on:click="openLink(item)" v-if="(checkMobile && item.link_mobile)||item.link">{{ $t('map.acessar') }}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="swiper-load">
                    <div></div>
                </div>
                <div class="swiper-pagination"></div>
            </div>
        </div>
        <div class="container-mosaico">
            <div class="mosaico">
                <div class="item-mosaico" v-for="(session, index) in renderSessions" :key="index">
                    <div class="item-container" v-on:click="goToSession(session.name)">
                        <div class="item_img" :id="'_' + session.id">
                            <video v-if="!/\.(jpe?g|png|svg|gif)$/i.test(session.tumbnail)" :src="session.tumbnail">
                                <source :src="session.tumbnail">
                            </video>
                            <img v-else :src="session.tumbnail">
                            <span v-if="lockHandler(session)" class="lock"><img :src="(design.lock_favicon ? design.lock_favicon : $url_api + 'images/lock_32px.png')" alt="Lock" width="32" height="32"></span>
                        </div>
                        <span class="item_name" >{{ session.title != '' ? session.title : $t('mosaic.SessionTitleMissing') }}</span>
                        <span class="item_description" >{{ session.description != '' ? session.description : $t('mosaic.SessionDescMissing') }}</span>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import axios from "axios";
import Swiper from "swiper";

export default {
    name: 'Badges',
    computed: {
        ...mapGetters([
            'logged', 'token', 'avatar', 'switch',"checkMobile", "checkIOS", "checkSafari", "UserBadges", "UserTickets", 'design'
        ]),

        renderSessions(){
            let rsessions = this.sessions.filter(session=>{
                return (session.visible_desktop && !this.checkMobile && !this.checkSafari)||
                (session.visible_4gb && this.checkMobile)||
                (session.visible_safari && this.checkSafari)
            })
            return rsessions;
        },
        renderItems(){
            let ritems = this.player.filter(item=>{
                return ((item.splash_type == 'internal' || item.splash_type == 'both') && item.show_desktop && !this.checkMobile && !this.checkSafari && !this.checkIOS)||
                ((item.splash_type_mobile == 'internal' || item.splash_type_mobile == 'both') && item.show_mobile && (this.checkMobile || this.checkIOS))||
                ((item.splash_type_safari == 'internal' || item.splash_type_safari == 'both') && item.show_safari && this.checkSafari)
            })
            return ritems;
        }
    },
    data() {
        return {
            showPlayer:true,
            player:[],
            sessions:[],
            video: null,
        }
    },
    components: {},
    methods: {
        ...mapActions([
            "update_switch",
        ]),
        check_badges(badge_id, session_badges)
        {
            return session_badges.some(badge => (badge.id == badge_id));
        },
        check_tickets(ticket_id, session_tickets)
        {
            console.log('here => ', ticket_id, session_tickets);
            return session_tickets.some(ticket => (ticket.id == ticket_id))
        },
        lockHandler(session_)
        {
            let root_   = this;
            let badge_  = this.UserBadges. some(badge  => root_.check_badges(badge.id, session_.badges));
            let ticket_ = this.UserTickets.some(ticket => root_.check_tickets(ticket.id, session_.tickets));
            if ((!badge_) && (!ticket_) && ((session_.badges.length) || (session_.tickets.length)))
            {
                return true;
            }
            else
            {
                return false;
            }
        },
        openLink(item){
            let url = item.link;
            if ((this.checkIOS || this.checkMobile) && item.link_mobile != '') {
                url = item.link_mobile;
            } else if (this.checkSafari && item.link_safari != '') {
                url = item.link_safari
            }
            if (url) {
                switch (item.link_type) {
                    case"lightbox":
                        this.$parent.modalI("", url, null, "OK");
                        break;
                    case"self":
                        window.open(url, '_top')
                        break;
                    case"blank":
                        window.open(url, "_blank")
                        break;
                }
            }
        },
        updateSlide(swiper){
            const time = swiper.slides[swiper.activeIndex].querySelector("[name=time]").value*1000;
            const load = document.querySelector(".swiper-load div");
            if (this.video)
                this.video.pause();

            this.video = swiper.slides[swiper.activeIndex].querySelector("video");
            if (this.video)
                this.video.play();

            let count = 0;
            load.style.width = "0%";
            clearInterval(this.interval);
            clearTimeout(this.timeout);
            this.interval = setInterval(()=>{
                count+=100;
                load.style.width = `${(count *100 /time)}%`;
            },100);
            this.timeout = setTimeout(()=>{
                if (swiper.activeIndex<swiper.slides.length-1) {
                    swiper.slideNext();
                }else{
                    swiper.slideToLoop(0)
                }
            },time);
        },

        goToSession(sessionName) {
          const root_ = this;
          let sessions = root_.$store.getters['sessions'];

          let lms_found = false;
          sessions.find(obj => {
            if( String(obj.name) === sessionName ) {
              if( obj.lms && obj.lms.slug ) {
                lms_found = String(obj.lms.slug);
              }
            }
          });

          if(lms_found) {
            root_.$router.push({path: `/lms/${lms_found}`});
          } else {
            root_.$router.push({path: '/e/'+sessionName});
          }

        }
    },
    created() {
        console.log('design => ', this.design);
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access': 'application/json',
            language: this.$i18n.locale, Authorization: `Bearer ${this.token}`
        };
        const _root = this;
        axios.get(this.$url_api + "api/mapMosaico", {headers,params:{event_name: this.switch.Nome_Aba??localStorage.getItem("eventmap")??""}}).then(response=>{
            _root.player = response.data.player
            _root.sessions = response.data.sessions
            if (_root.player.length>0) {
                new Swiper(".swiper-container", {
                    navigation: false,
                    autoplay: false,
                    pagination: {
                        el: ".swiper-pagination",
                        clickable: true,
                    },
                    observer: true,
                    on: {
                        observerUpdate: function (swiper) {
                            _root.updateSlide(swiper);
                        },
                        slideChange: function (swiper) {
                            _root.updateSlide(swiper);
                        },
                    },
                });
            }
            else _root.showPlayer=false;
        }).catch(_=>console.log(_));
    },
    beforeDestroy() {
        clearInterval(this.interval);
        clearTimeout(this.timeout);
    },
}
</script>

<style scoped>
.lock
{
    bottom: 10px;
    display: block;
    right: 20px;
    position: absolute;
}
</style>
