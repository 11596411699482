export default {
    update_lms (state, data) {
        state.lms = data;
        state.isLms = true;
    },
    update_slideshow(state, data) {
        state.slideshow = data;
    },
    update_course(state, data) {
        return state.course = data
    },
    update_section(state, data) {
        return state.section = data
    },
    update_lesson(state, data) {
        return state.lesson = data
    },
    update_user_notes(state, data) {
        return state.notes = data.map((n) => {
            return {
                ...n,
                isEditing: false
            }
        })
    },
    update_user_notes_add(state, data) {
        data.isEditing = false
        return state.notes.push(data)
    },
    update_notes_order(state, data) {
        return state.notes = data
    },
    update_user_notes_note_updated(state, data) {
        data.isEditing = false
        return state.notes = state.notes.map((s) => {
            if (s.id == data.id) {
                s = data
            }
            return s
        })
    },
    update_course_selected(state, id) {
        state.courseSelected = id;
    },
    update_course_data(state, data) {
        state.courseData = data;
    },
    update_course_sections(state, data) {
        state.courseSections = data;
    },
    update_user_courses(state, data) {
        state.userCourses = data.courses
        state.userClasses = data.classes
    },
    update_coin_name(state, data) {
        state.coinsName = data;
    },
    toggleLoading(state, loading) {
        state.loading = loading;
    },
    update_user_information(state, data) {
        state.userInformation = data
    },
    update_welcome_user(state, data) {
        state.welcomeUser = data;
    },
    update_notifications_list(state, data) {
        state.notificationsList = data;
    }
    
};