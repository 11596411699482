<template >
  <div class="box-bg" id="EasterEgg" ref="EasterEgg">
    <form v-if="easterEgg.show" class="ani box" id="easter-form" ref="easter-form" v-on:submit="sendAnswer">
      <h3 class="tab">
        EasterEgg
        <a class="btn-0" v-on:click="$parent.easter=false" ref="btn-close">
          <img :src="$url + 'assets/img/icon_close_box.png'" alt="Fechar" style="float: right;margin: 20px 25px 0 0"
               width="16">
        </a>
      </h3>
      <ul>
        <li class="li-inline"></li>
        <li class="question-name">
          {{ question.name }}
        </li>
        <li v-for="op in question.options" :key="op.id" class="question-opt">
          <label>
            <input :id="op.name" v-on:click="selectAnswer()" name="answer" :value="op.name" type="radio">
            {{ op.name }}
          </label>
        </li>
        <li>
          <button class="btn-red" type="submit">RESPONDER</button>
        </li>
      </ul>
    </form>
    <div v-if="ligthbox.show" class="ani box" id="ligthbox_easter" ref="ligthbox_easter">
      <h3 :class="ligthbox.tabColor">
        <img :src="$url + 'assets/icons/modals/'+ligthbox.icon" alt="check" style="float: left;margin: 18px 0 0"
             width="24">
        {{ ligthbox.header }}
        <a class="btn-0" v-on:click="$parent.easter=false" ref="btn-close">
          <img :src="$url + 'assets/img/icon_close_box.png'" alt="Fechar" style="float: right;margin: 20px 25px 0 0"
               width="16">
        </a>
      </h3>
      <ul>
        <li class="li-inline"></li>
        <li>
          <strong class="ligthbox-text">{{ ligthbox.text }}</strong>
        </li>
        <li>
          <ul class="ligthbox-container">
            <li class="ligthbox-img"><img :src="easterEgg.image" :alt="easterEgg.name"></li>
            <li class="ligthbox-points"><span>Lorem ipsum dolor</span>
              <div class="alignVertical">
                <div class="alignVertical">
                  +{{ ligthbox.points }}
                  <img :src="this.$url+'assets/icons/header/point_header.svg'" alt="">
                </div>
                <div class="alignVertical">
                  +{{ ligthbox.coins }}
                  <img :src="this.$url+'assets/icons/header/coin_header.svg'" alt="">
                </div>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'EasterEgg',
  computed: {
    ...mapGetters([
      'token'
    ]),
  },
  data() {
    return {
      error: null,
      easterEgg: {
        id: null,
        name: "",
        sku: "",
        image: "",
        show:false
      },
      question: {
        id: null,
        name: "",
        options: []
      },
      selectedAnswer: null,
      ligthbox: {
        header: "",
        text: "",
        points: 0,
        coins: 0,
        tabColor: "",
        icon:"",
        show:false
      },
    }
  },
  components: {},
  methods: {
    ...mapActions([]),
    //----- load-friends -----//
    getEaster(id) {
      //console.log(id);
      this.$parent.wait(true);
      let headers = {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access': 'application/json',
        language: this.$i18n.locale, Authorization: `Bearer ${this.token}`
      };
      axios.get(this.$url_api + 'api/GetEasterEgg/'+id, {headers: headers}).then(response => this.getEasterResponse(response)).catch(error => this.getEasterError(error.response));
    },
    getEasterResponse(obj) {
      let data = obj.data
      if (data.status == 'success') {
        this.easterEgg.show = true;
        this.easterEgg.id = data.data.id;
        this.easterEgg.name = data.data.name;
        this.easterEgg.SKU = data.data.SKU;
        this.easterEgg.image = data.data.image;
        this.question = data.data.question;

      }
      this.$parent.wait(false);
    },
    getEasterError(obj) {
      //console.log('loadEventsError -> ', obj);
      obj;
      this.$parent.easter =false;
      this.$parent.wait(false);
    },
    //----- \load-friends\ -----//
    // ----- load-friends -----//
    sendAnswer(e) {
      this.$parent.wait(true);
      let headers = {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access': 'application/json',
        language: this.$i18n.locale, Authorization: `Bearer ${this.token}`
      };
      axios.post(this.$url_api + 'api/EasterEggs/answer/' + this.easterEgg.id, {
        'question': this.question.id,
        'answer': this.selectedAnswer
      }, {headers: headers}).then(response => this.sendAnswerResponse(response)).catch(error => this.sendAnswerError(error.response));
      e.preventDefault();
    },
    sendAnswerResponse(obj) {
      let data = obj.data
      if (data.status == 'success') {
        if (data.data.hits > 0) {
          this.ligthbox.header = "VOCÊ GANHOU!";
          this.ligthbox.text = "Parabéns! lorem ipsum dolor sit amet, consectetur adipiscing elit!";
          this.ligthbox.tabColor = "tab tab-green";
          this.ligthbox.icon = "check.svg";
        }else{
          this.ligthbox.header = "VOCÊ NÃO GANHOU!";
          this.ligthbox.text = "Tente outra vez! lorem ipsum dolor sit amet, consectetur adipiscing elit!";
          this.ligthbox.tabColor = "tab tab-red";
          this.ligthbox.icon = "uncheck.svg";
        }
        this.ligthbox.points = data.data.point;
        this.ligthbox.coins = data.data.coin;
      }
      this.easterEgg.show = false;
      this.ligthbox.show = true;
      this.$parent.wait(false);
    },
    sendAnswerError(obj) {
      //console.log('loadEventsError -> ', obj);
      obj;
      this.$parent.easter =false;
      this.$parent.wait(false);
    },
    //----- \load-friends\ -----//
    selectAnswer() {
      document.getElementsByName('answer').forEach(e => {
        const parent = e.parentElement.parentElement;
        parent.className = parent.className.replace(' active', '');
        if (e.checked) {
          parent.className += ' active';
          this.selectedAnswer = e.value;
        }
      });
    },
  },
  mounted() {
    this.getEaster(this.$parent.easterId);
  },
  updated() {
  },
  destroyed() {
  }
}
</script>
<style scoped>
#EasterEgg > .ani.box > ul > li {
  box-sizing: border-box;
  list-style: none;
  padding: 10px;
  text-align: left;
  color: black;
}

#EasterEgg h3 {
  color: #b62f32;
}

#EasterEgg label {
  text-align: center;
}

#EasterEgg .li-inline * {
  display: inline-block;
  vertical-align: middle;
}

#EasterEgg .btn-blue, .btn-red {
  display: block;
  letter-spacing: 1px;
  padding: 15px 10px;
  width: 100%;
}

#EasterEgg .question-opt.active {
  background: var(--buttons_color);
}

#EasterEgg .question-opt.active label {
  color: white;
}

#EasterEgg .question-opt {
  margin: 10px 0;
  border-radius: 10px;
  border: rgba(0, 0, 0, 0.2) solid 1px;
}

#EasterEgg .question-opt label {
  color: black;
  text-align: left;
}

#EasterEgg .question-opt label input {
  appearance: none;
}

#EasterEgg .tab-green {
  background: #04CE00;
}

#EasterEgg .tab-red {
  background: #B72532;
}

#EasterEgg .tab-red ~ ul .ligthbox-container {
  opacity: 0.2;
}

#EasterEgg .ligthbox-text {
  text-align: center;
  font-size: .75rem;
  color: #474747;
  display: block;
  width: 70%;
  margin: auto;
}

#EasterEgg .ligthbox-container {
  display: block;
  width: 80%;
  margin: auto;
}

#EasterEgg .ligthbox-container .ligthbox-img {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

#EasterEgg .ligthbox-container .ligthbox-img:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: calc(100% * 1);
}

#EasterEgg .ligthbox-container .ligthbox-img img {
  position: absolute;
  height: 100%;
}

#EasterEgg .ligthbox-container .ligthbox-points {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 500px) {
  #EasterEgg .ani.box {
    width: calc(100% - 40px);
  }
}
</style>