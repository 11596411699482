<template>
    <div>
        <button v-on:click="logInWithFacebook" type="button">
            <picture>
                <source :srcset="$url + '/assets/img/facebook.webp'" type="image/webp">
                <source :srcset="$url + '/assets/img/facebook.png'" type="image/png">
                <img alt="Facebook icon" width="46px" height="46px" :src="$url + '/assets/img/facebook.webp'">
            </picture>
        </button>
    </div>
</template>
<script>

    export default {
        name:"LoginFacebook",
        data() {
            return {
                parentComponent: null
            }
        },
        props: ['origin'],
        methods: {
            logInWithFacebook() { 
                let root_ = this;
                window.FB.login(function(response) { 
                    //console.log('response 00 =>', response);
                    if (response.authResponse) {
                        window.FB.api('/me?fields=name,email', function(response) {
                            //console.log('response facebook => ', response);
                            let data = { type:'facebook', origin: root_.origin, id:response.id, email:response.email, name:response.name };
                            root_.$parent.loginSocial(data);
                        });

                    } else { 
                        //console.log("User cancelled login or did not fully authorize.");
                        root_.parentComponent.modal('error', 'Erro!', 'Você cancelou ou não autorizou o login.', null, null, null, "OK");
                    }
                }, 
                { scope: 'public_profile,email', return_scopes: true }
                );
                return false;
            },
            initFacebook() {
                this.parentComponent.decryptUnity(
                    this.parentComponent.keyUnityConf,
                    this.parentComponent.switch.fb_conf.i,
                    this.parentComponent.switch.fb_conf.c
                ).then(objDec => {
                    window.fbAsyncInit = function() {
                        window.FB.init({
                            appId      : objDec.app_id,
                            cookie     : true,
                            xfbml      : true,
                            version    : objDec.version
                        });
                        window.FB.AppEvents.logPageView();
                    };
                });
            },
            loadFacebookSDK(d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {
                    return;
                }
                js = d.createElement(s);
                js.id = id;
                js.src = "https://connect.facebook.net/en_US/sdk.js";
                fjs.parentNode.insertBefore(js, fjs);
            }
        },
        mounted() {
            this.parentComponent = this.$parent.$parent;
            if (this.parentComponent.$parent === undefined){
                this.parentComponent = this.$parent;
            }

            this.loadFacebookSDK(document, "script", "facebook-jssdk");
            this.initFacebook();
        }
    }
</script>

<style scoped>
    button, picture
    {
        border: none;
        display: block;
        padding: 0;
        background: transparent;
    }
    picture
    {
        font-size: 0;
    }
    button{
        border-radius: 5px;
        overflow: hidden;
    }
    img{
        margin: 0!important;
        max-width: 46px;
        max-height: 46px;
    }
</style>