export default {
    getLms(state) {
        return state.lms
    },
    isLms(state) {
        return state.isLms;
    },
    getPageByName:(state) => (page) => {
        return state.pages[page];
    },
    getSection(state) {
        return state.section
    },
    getCourse(state) {
        return state.course
    },
    getUserNotes(state) {
        return state.notes
    },
    getLesson(state) {
        return state.lesson
    },
    getCourseSelected(state) {
        return state.courseSelected;
    },
    getCourseData(state) {
        return state.courseData;
    },
    getUserCourses(state) {
        return state.userCourses
    },
    getUserClasses(state) {
        return state.userClasses
    },
    getCoinsName(state) {
        return state.coinsName;
    },
    getLoading(state) {
        return state.loading;
    },
    getUserInformation(state) {
        return state.userInformation;
    },
    getNotificationsList(state) {
        return state.notificationsList;
    }
};