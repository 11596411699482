/**
 * Inserts HTML, including <script> tags, into a web page and executes the scripts contained within the HTML string.
 *
 * @param {string} htmlString - The HTML string containing the code to be inserted and executed.
 * @param divId
 * @param callback
 */
export function insertHtmlWithScript(htmlString, divId = null) {
    // Create a temporary div element to handle the HTML
    let tempDiv = document.createElement('div');

    divId ?  (tempDiv.id = divId) : (tempDiv.id = 'vt_template_html');

    // Set the HTML content of the div element with the provided HTML string
    tempDiv.innerHTML = htmlString;

    // Find all <script> tags within the div element
    let scripts = tempDiv.querySelectorAll('script');

    // For each <script> tag found, create a new <script> element in the current document
    scripts.forEach(function (script) {
        let newScript = document.createElement('script');
        if (script.src) {
            // If the <script> tag has a src attribute, set it in the new <script> element
            newScript.src = script.src;
        } else {
            // If there is no src attribute, copy the script's content to the new <script> element
            newScript.textContent = script.textContent;
        }
        // Append the new <script> element to the end of the body for execution
        document.body.appendChild(newScript);
    });

    // Insert the HTML content (without <script> tags) wherever you want on the page
    // For example, you can insert it in the body or in a specific element.
    document.body.appendChild(tempDiv);
}


/**
 * Extracts the scene name from a URL.
 *
 * @param {string} url - The URL from which to extract the scene name.
 * @returns {string|null} - The extracted scene name or null if there is no "/" in the URL.
 */
export function extractSceneNameFromURL(url) {
    // Find the position of the last "/" in the URL
    let lastSlashPosition = url.lastIndexOf("/");

    // Check if the last "/" was found
    if (lastSlashPosition !== -1) {
        // Extract the part of the URL after the last "/"
        let sceneName = url.substring(lastSlashPosition + 1);
        // Replace "%20" with spaces
        sceneName = sceneName.replace(/%20/g, " ");
        return sceneName;
    } else {
        return null; // Returns null if there is no "/" in the URL
    }
}


/**
 * Removes a specific div element from the DOM by its ID.
 *
 * @param {string} elementId - The ID of the div element to be removed.
 * @returns {boolean} - True if the element was successfully removed, false if not found.
 */
export function removeDivById(elementId) {
    // Find the div element by its ID
    let elementToRemove = document.getElementById(elementId);

    // Check if the element exists
    if (elementToRemove !== null) {
        // Remove the element from the DOM
        elementToRemove.parentNode.removeChild(elementToRemove);
    }
}
/**
 *  Focus iframe to enable keyboard input
 *
 * @param {boolean} isMobile - true if mobile device
 */
export function focusUnity(isMobile) {
    if (!isMobile && document.getElementById("iframe")){
        document.getElementById("iframe").focus();
    }
}



