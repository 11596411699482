<template>

    <div class="modals bgAzul" id="package" ref="package">
        <div v-on:click="closeModal"></div>
        <div class="package">
            <div class="modals__content" v-if="!this.$parent.modal_guest_buy">
                <div class="modals__content--header alignVertical">
                    <p>{{ $t('package_buy.title') }}</p>
                    <a v-on:click="closeModal" ref="btn-close">
                        <img src="assets/icons/modals/x-black.svg" alt="">
                    </a>
                </div>
                <form class="modals__content--body flex flex-wrap" id="package-form">
                    <div id="options">
                        <ul class="item-center">
                            <li>
                                <input checked id="package_" name="type" type="radio" value="0"
                                    v-on:change="optionsHandler">
                                <label class="border-package" for="package_">{{ $t('package.package') }}</label>
                            </li>
                            <li>
                                <input id="plan" name="type" type="radio" value="1" v-on:change="optionsHandler">
                                <label class="border-plain" for="plan">{{ $t('package.plan') }}</label>
                            </li>
                        </ul>
                    </div>

                    <div class="swiper">
                        <div class="swiper-container swiper__package">
                            <div v-show="started_component" class="swiper-wrapper">
                                <!-- Slides -->
                                <div v-for="(l, index) in packages" :key="index" class="swiper-slide">
                                    <div v-show="package_view" class="item-wrapper">
                                        <img class="package-image" :src="$url_api + 'storage/package/' + l.image" alt="Thumb" />
                                        <p class="item-name">{{ l.name }}</p>
                                        <p class="item-coin">{{ l.amount }} {{ $t('package.coins') }}</p>
                                        <p class="item-price">
                                            <span v-if="l.promotional">{{ $t('package.in') }}
                                            </span>
                                            R$ {{ maskMoney(l.value) }}
                                            <span v-if="l.promotional">{{
                                                $t('package.per')
                                            }}
                                                {{ maskMoney(l.promotional_value) }}
                                            </span>
                                        </p>
                                        <button type="button" :data-id="l.id" :data-name="l.name" :data-image="l.image"
                                            :data-type="l.type" :data-amount="l.amount"
                                            :data-value="l.promotional ? l.promotional_value : l.value" v-on:click="buy">{{
                                                $t('package.buy')
                                            }}
                                        </button>
                                    </div>
                                </div>

                            </div>

                            <div class="swiper-button-prev"></div>
                            <div class="swiper-button-next"></div>
                        </div>
                    </div>
                </form>

                <div class="modals__content--footer flex">
                </div>
            </div>
            <div id="guest-buy" class="modals__content" v-else-if="this.$parent.modal_guest_buy">
                <div class="modals__content--header alignVertical">
                    <p>{{ $t('guest_buy.title') }}</p>
                    <a v-on:click="closeModal" ref="btn-close">
                        <img src="assets/icons/modals/x-black.svg" alt="">
                    </a>
                </div>
                <div class="modals__content--body flex flex-wrap">
                    <div class="item-center">
                        <span class="alignVertical">{{ $t('guest_buy.message') }}</span>
                        <br>
                        <br>
                        <span class="alignVertical"><b>{{ $t('guest_buy.message2') }}</b></span>
                    </div>
                </div>

                <div class="modals__content--footer flex alignVertical">
                    <button class="guest__create" type="button" v-on:click="createAccount">
                        {{ $t('guest_buy.action') }}
                    </button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import axios from 'axios';
import { mapActions, mapGetters } from "vuex";
import Swiper from "swiper/bundle";
export default
    {
        name: 'Package',
        data() {
            return {
                packages: null,
                package_view: false,
                started_component: false,
                swiper_package: null
            }
        },
        computed: {
            ...mapGetters(['token']),
        },
        components: {},
        methods:
        {
            ...mapActions(['update_package']),
            started()
            {
                let root = this;
                root.started_component = true;
                setTimeout(() => {
                    root.swiper_package.update();
                    root.package_view = true;
                }, 100)
            },
            createAccount()
            {
                this.$parent.modal_cadastro_ = true;
                this.$parent.modal_guest_buy = false;
                this.$parent.openPackageAfterRegister = true;
            },
            just_number(v) {
                return String(v).replace(/\D/g, '');
            },
            closeModal(e) {
                e.preventDefault();
                // console.log(this.$parent.modal_coins_buy);
                this.$parent.modal_package = false;
                this.$parent.modal_coins_buy = false;
                this.$parent.modal_guest_buy = false;
            },
            maskMoney(value) {
                let v = this.just_number(value);
                v = (v / 100).toFixed(2);
                v = v.replace(".", ",");
                v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
                v = v.replace(/(\d)(\d{3}),/g, "$1.$2,");
                return v;
            },
            load(type_) {
                let headers = { 'Content-Type': 'application/json;charset=UTF-8', 'Access': 'application/json', language: this.$i18n.locale, Authorization: `Bearer ${this.token}` };
                let form_data = new FormData();
                form_data.append('type', type_);
                axios.post(this.$url_api + "api/package/load", form_data, { headers: headers }).then((response) => this.loadResponse(response)).catch((error) => this.loadError(error.response));
            },
            loadResponse(obj) {
                this.packages = obj.data;
                this.started();
                console.log('response => ', obj);
                this.$parent.wait(false);
            },
            loadError(obj) {
                console.log('loadError => ', obj);
                this.$parent.wait(false);
            },
            optionsHandler(e) {
                e.preventDefault();
                this.$parent.wait(true);
                this.load(e.currentTarget.value);
                setTimeout(() => {
                    this.swiper_package.update();
                }, 500)
            },
            buy(e) {
                e.preventDefault();
                this.$parent.wait(true);

                if(this.$parent.isGuest) {
                    this.$parent.modal_package = false;
                    this.$parent.modal_package_buy = false;
                    this.$parent.modal_coins_buy = false;
                    this.$parent.modal_guest_buy = true;

                    this.$parent.wait(false);

                    return;
                }

                let package_ =
                {
                    id: e.currentTarget.dataset.id,
                    name: e.currentTarget.dataset.name,
                    image: e.currentTarget.dataset.image,
                    type: e.currentTarget.dataset.type,
                    amount: e.currentTarget.dataset.amount,
                    value: e.currentTarget.dataset.value
                };
                this.update_package(package_);
                this.$parent.modal_package = false;
                this.$parent.modal_package_buy = true;
                this.$parent.modal_coins_buy = false;
            },
            buy_old(e) {
                e.preventDefault();

                let headers = { 'Content-Type': 'application/json;charset=UTF-8', 'Access': 'application/json', language: this.$i18n.locale, Authorization: `Bearer ${this.token}` };
                let form_data = new FormData();
                form_data.append("package_id", e.currentTarget.dataset.id);
                form_data.append("value", e.currentTarget.dataset.value);
                form_data.append("type", '1');//1 = cartão de crédito, 2 = pix
                form_data.append("payment_type", '0');//0 = normal, 1 = recurrent
                headers;
                axios.post(this.$url_api + "api/package/buy", form_data, { headers: headers }).then((response) => this.buyResponse(response)).catch((error) => this.buyError(error.response));
            },
            buyResponse(obj) {
                this.$parent.wait(false);
                console.log('buy response => ', obj);
                if (obj.data == 1) {
                    alert('Compra realizada com sucesso.');
                }
            },
            buyError(obj) {
                this.$parent.wait(false);
                console.log('buyError => ', obj);
            },
        },
        mounted() {
            this.$parent.wait(true);
            this.load(0);
            var root = this;
            this.swiper_package = new Swiper(".swiper__package", {
                loop: true,
                slidesPerView: 1,
                centeredSlides: false,
                //spaceBetween: 20,
                breakpoints: {
                    640: {
                        slidesPerView: 3
                    }
                },
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                autoplay: {
                    delay: 6000,
                    disableOnInteraction: false,
                },
                on: {
                    slideChange: function () {
                        root.time = 0;
                    },
                },
            });
        }
    }
</script>

<style scoped>

.guest__create {
    background-color: var(--buttons_color);
    color: var(--fonts_color);
    border: none;
    padding: 10px 20px 10px 20px;
    border-radius: 20px;
    font-weight: 600;
}

#package-form ul,
#package-form li {
    margin: 0;
    padding: 0;
    list-style: none;
}

#package-form>ul>li {
    background-color: #f5f5f5;
    border-radius: 10px;
    box-sizing: border-box;
    display: inline-block;
    margin: 5px;
    min-height: 100px;
    padding: 10px;
    vertical-align: top;
    width: 170px;
    height: 265px;
}

#guest-buy {
    box-sizing: border-box;
    padding: 10px;
    height: 100%;
}

#package-form>ul>li>ul>li {
    box-sizing: border-box;
    display: block;
    padding: 5px 0;
}

#package-form>ul>li>ul>li>img {
    max-width: 150px;
}

#options {
    box-sizing: border-box;
    display: block;
    padding: 10px;
    width: 100%;
}

#options>ul>li {
    display: inline-block;
}
</style>