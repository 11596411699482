<template>
  <section id="profile-navigation" class="my-4">

    <div class="card d-flex flex-row flex-wrap">
      <div class="col-auto">
        <div v-if="!use_avatar" class="card-img avatar" :style="{ 'background-image': 'url(' + avatar + ')' }"></div>
        <div v-else-if="user_picture && use_avatar" class="card-img user-picture" :style="{ 'background-image': 'url(' + user_picture + ')' }"></div>
        <img v-else src="assets/icons/myPerfil/avatar.svg" alt="Avatar" />
      </div>
      <div class="col-auto col-md-6 ms-4 ms-md-1 ms-lg-0 ms-xl-1">
        <div class="card-body px-0">
          <h5 class="card-title">{{ this.name }}</h5>
          <p class="card-text d-flex align-items-center">

            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">-->
              <path
                  d="M4.75437 1.38428C4.72845 1.38405 4.70287 1.39026 4.67995 1.40237C4.65703 1.41447 4.63748 1.43209 4.62307 1.45364C4.60865 1.47519 4.59982 1.49998 4.59738 1.52579C4.59493 1.55159 4.59894 1.5776 4.60906 1.60147L4.6989 1.81358L6.28874 5.52062C6.29955 5.54583 6.3168 5.56775 6.33876 5.58419C6.36073 5.60062 6.38663 5.61098 6.41387 5.61422C6.44111 5.61747 6.46872 5.61349 6.49393 5.60268C6.51914 5.59187 6.54106 5.57462 6.55749 5.55265L9.49187 1.63428C9.50928 1.61107 9.51988 1.58347 9.52249 1.55457C9.52509 1.52567 9.5196 1.49661 9.50662 1.47066C9.49365 1.4447 9.4737 1.42288 9.44901 1.40762C9.42433 1.39236 9.39589 1.38428 9.36687 1.38428H4.75437Z"
                  fill="var(--buttons_color)"/>
              <path
                  d="M16.4034 2.40222L14.7909 6.16667C14.7807 6.19044 14.7766 6.21635 14.779 6.24208C14.7813 6.26782 14.79 6.29257 14.8042 6.31413C14.8184 6.33569 14.8378 6.35338 14.8606 6.36562C14.8833 6.37785 14.9088 6.38426 14.9346 6.38425H18.8799C18.9078 6.38426 18.9351 6.37683 18.9592 6.36271C18.9832 6.3486 19.003 6.32832 19.0165 6.30398C19.0301 6.27963 19.0369 6.25211 19.0362 6.22426C19.0355 6.19641 19.0274 6.16924 19.0127 6.14558L16.6795 2.38152C16.6645 2.35729 16.643 2.33764 16.6176 2.32472C16.5921 2.31181 16.5636 2.3061 16.5352 2.30824C16.5067 2.31037 16.4794 2.32026 16.4561 2.33683C16.4329 2.3534 16.4147 2.37601 16.4034 2.40222Z"
                  fill="var(--buttons_color)"/>
              <path
                  d="M3.32077 2.38313L0.988351 6.14563C0.973656 6.1693 0.965555 6.19647 0.964889 6.22432C0.964222 6.25217 0.971013 6.27969 0.984559 6.30403C0.998106 6.32838 1.01791 6.34866 1.04193 6.36277C1.06595 6.37688 1.09331 6.38432 1.12116 6.38431H5.06648C5.09233 6.38428 5.11778 6.37783 5.14053 6.36554C5.16328 6.35326 5.18263 6.33552 5.19684 6.31392C5.21105 6.29231 5.21967 6.26752 5.22194 6.24177C5.22421 6.21601 5.22005 6.19009 5.20984 6.16634L3.59421 2.40383C3.58266 2.37828 3.56443 2.3563 3.54146 2.34021C3.51848 2.32412 3.4916 2.31451 3.46363 2.3124C3.43566 2.31028 3.40764 2.31573 3.3825 2.32817C3.35737 2.34062 3.33604 2.3596 3.32077 2.38313V2.38313Z"
                  fill="var(--buttons_color)"/>
              <path
                  d="M15.3624 1.38428H10.462C10.4479 1.38419 10.434 1.38805 10.4219 1.39544C10.4099 1.40283 10.4001 1.41344 10.3938 1.42608C10.3874 1.43872 10.3848 1.45288 10.3861 1.46696C10.3873 1.48104 10.3925 1.49448 10.4011 1.50576L13.5229 5.66436C13.5309 5.67504 13.5416 5.68344 13.5538 5.68869C13.5661 5.69395 13.5795 5.69588 13.5927 5.6943C13.6059 5.69271 13.6185 5.68766 13.6292 5.67967C13.6398 5.67167 13.6482 5.661 13.6534 5.64874L15.2972 1.81397L15.43 1.48819C15.4347 1.477 15.4366 1.46485 15.4355 1.45278C15.4345 1.4407 15.4305 1.42905 15.424 1.41884C15.4175 1.40862 15.4085 1.40014 15.398 1.39413C15.3875 1.38811 15.3757 1.38473 15.3636 1.38428H15.3624Z"
                  fill="var(--buttons_color)"/>
              <path
                  d="M9.33616 17.6343L5.8428 7.73232C5.83254 7.70357 5.81362 7.6787 5.78865 7.66114C5.76368 7.64359 5.73387 7.6342 5.70334 7.63428H1.00413C0.976904 7.6342 0.950208 7.64177 0.927079 7.65613C0.90395 7.67048 0.885319 7.69105 0.873308 7.71547C0.861297 7.7399 0.856389 7.76722 0.859145 7.7943C0.8619 7.82138 0.872207 7.84714 0.888892 7.86865L9.32639 18.7987C9.35495 18.8357 9.3957 18.8614 9.4414 18.8712C9.48709 18.881 9.53478 18.8743 9.576 18.8522C9.61746 18.8304 9.64969 18.7944 9.66683 18.7509C9.68397 18.7073 9.68487 18.659 9.66936 18.6147L9.33616 17.6343Z"
                  fill="var(--buttons_color)"/>
              <path
                  d="M18.9956 7.63428H14.2964C14.266 7.63427 14.2364 7.6437 14.2116 7.66127C14.1868 7.67883 14.1681 7.70366 14.1581 7.73232L10.3222 18.5979C10.3061 18.6436 10.3067 18.6934 10.324 18.7386C10.3414 18.7838 10.3742 18.8213 10.4167 18.8444C10.4592 18.8674 10.5085 18.8746 10.5558 18.8647C10.6031 18.8547 10.6453 18.8283 10.6749 18.7901L19.1108 7.86865C19.1275 7.84714 19.1378 7.82138 19.1406 7.7943C19.1433 7.76722 19.1384 7.7399 19.1264 7.71547C19.1144 7.69105 19.0958 7.67048 19.0727 7.65613C19.0495 7.64177 19.0228 7.6342 18.9956 7.63428V7.63428Z"
                  fill="var(--buttons_color)"/>
              <path
                  d="M10.1251 3.21747L12.3126 6.13427C12.33 6.15748 12.3406 6.18508 12.3432 6.21398C12.3458 6.24288 12.3403 6.27194 12.3274 6.29789C12.3144 6.32385 12.2944 6.34567 12.2697 6.36093C12.2451 6.37618 12.2166 6.38426 12.1876 6.38426H7.8126C7.78358 6.38426 7.75514 6.37618 7.73046 6.36093C7.70577 6.34567 7.68582 6.32385 7.67285 6.29789C7.65987 6.27194 7.65438 6.24288 7.65698 6.21398C7.65959 6.18508 7.67019 6.15748 7.6876 6.13427L9.8751 3.21747C9.88966 3.19806 9.90853 3.18231 9.93022 3.17146C9.95192 3.16062 9.97584 3.15497 10.0001 3.15497C10.0244 3.15497 10.0483 3.16062 10.07 3.17146C10.0917 3.18231 10.1105 3.19806 10.1251 3.21747V3.21747ZM9.85166 15.339L7.20713 7.84247C7.19883 7.81891 7.19629 7.79371 7.19973 7.76897C7.20318 7.74423 7.2125 7.72068 7.22693 7.70029C7.24135 7.67989 7.26045 7.66326 7.28263 7.65177C7.30481 7.64028 7.32942 7.63428 7.3544 7.63426H12.6458C12.6708 7.63428 12.6954 7.64028 12.7176 7.65177C12.7397 7.66326 12.7589 7.67989 12.7733 7.70029C12.7877 7.72068 12.797 7.74423 12.8005 7.76897C12.8039 7.79371 12.8014 7.81891 12.7931 7.84247L10.1474 15.339C10.1366 15.3694 10.1166 15.3957 10.0903 15.4144C10.0639 15.433 10.0324 15.443 10.0001 15.443C9.96781 15.443 9.93632 15.433 9.90995 15.4144C9.88358 15.3957 9.86363 15.3694 9.85283 15.339H9.85166Z"
                  fill="var(--buttons_color)"/>
            </svg>
            <span class="total-points">{{ TotalPoints }}</span>
          </p>
        </div>
      </div>
    </div>

  </section>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ProfileNavigation",
  data() {
    return {
      descs: {
        "badges": {
          active: true,
          description: this.$i18n.t('app.vejaSeusBadges')
        },
        "guest": {
          active: false,
          description: this.$i18n.t('guest.criarContaDescription')
        },
        "logo": {
          active: false,
          description: this.$i18n.t('app.voltarAoMapa')
        },
        "nick": {
          active: false,
          description: this.$i18n.t('app.editeSuaConta')
        },
        "points": {
          active: false,
          description: this.$i18n.t('app.vejaSuaPosicao')
        },
        "coin": {
          active: false,
          description: this.$i18n.t('app.customizeSeuAvatar')
        },
        "selfie": {
          active: false,
          description: this.$i18n.t('app.tireSelfies')
        },
        "noti": {
          active: false,
          description: this.$i18n.t('app.leiaNotificacoes')
        },
        "menu": {
          active: false,
          description: this.$i18n.t('app.menuDeNavegacoes')
        },
        "radio": {
          active: false,
          description: this.$i18n.t('app.radio')
        },
        "shutoff": {
          active: false,
          description: this.$i18n.t('app.fazerLogout')
        },
        "guestShutoff": {
          active: false,
          description: this.$i18n.t('guest.fazerLogout')
        },
        "help": {
          active: false,
          description: this.$i18n.t('app.ajudaEContato')
        },
        "conteudo": {
          active: false,
          description: this.$i18n.t('app.conteudo')
        },
      },
    }
  },
  computed: {
    ...mapGetters([
      "avatar", "name", "switch", "TotalPoints", "use_avatar", "user_picture", "avatar_metaverse"
    ]),
  }
}
</script>

<style scoped lang="scss">

#profile-navigation {

  .card {
    border: none;

    .card-img{
      position: relative;
      width: 102px;
      height: 102px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 50%;

    }

    .card-title {
      font-family: "Hanken Grotesk", serif;
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
      letter-spacing: 0em;
      text-align: left;
      color: #706F6F;

    }

    .total-points {
      font-family: "Hanken Grotesk", serif;
      font-size: 20px;
      font-weight: 600;
      line-height: 26px;
      letter-spacing: 0em;
      text-align: center;
      color: #706F6F;
      margin-left: 12px;
    }

    @media(min-width: 1367px){
      .ms-xl-1 {
        margin-left: 1.25rem !important;
      }
    }


  }


}

</style>