<template>
    <!-- <div v-show="openModal" class="modals bgAzul ticket-gate-modal-wrap" style="background-color: rgba(0, 0, 0, 0.6);"> -->
    <div class="modals bgAzul ticket-gate-modal-wrap" style="background-color: rgba(0, 0, 0, 0.6);">
        <div v-on:click="closeModal" style="z-index: -1;width: 100%; height: 100%; position: fixed; top: 0px; left: 0px;"></div>
        <div class="main_modal">
            <div class="main_modal_wrap">
                <div class="header_modal">
                    <span>{{ this.$i18n.t('ticket.ingressos') }}</span>
                    <span style="float: right; cursor: pointer;" v-on:click="closeModal">X</span>
                </div>
                <div class="body_modal">
                    <div v-if="this.switch.event_menu && this.switch.event_menu['header']" class="tickets-gate-modal">
                        <div class="buy_tip">
                            <div>{{ $t('ticket.useMoedas') }}</div>
                            <div class="link" v-on:click="$refs.buyticket.como_funciona=true">
                                {{ $t('ticket.comoFunciona') }}
                            </div>
                        </div>
                        <div class="buy_order">
                            <div class="option right">
                                <label><input type="radio" v-on:click="$refs.buyticket.setOrder('date_start')" name="order" checked style="margin-right:10px;transform: scale(2);">{{ $t('ticket.dataRecente') }}</label>
                            </div>
                            <div class="option left">
                                <label><input type="radio" v-on:click="$refs.buyticket.setOrder('coin_price')" name="order">{{ $t('ticket.precoBarato') }}</label>
                            </div>
                        </div>
                    </div>
                    <TicketNew ref="buyticket" mode="buy"></TicketNew>
                </div>
                <!-- <div class="footer_modal">
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
// import axios from 'axios';
import {mapActions, mapGetters} from "vuex";
import TicketNew from '@/components/TicketNew';

export default {
    name: 'TicketGate',
    computed: {
        ...mapGetters([
            // 'logged', 'token','switch', 'nickname'
            'token', 'switch'
        ]),
    },
    data() {
        return {
            // openModal:false,
        }
    },  
    components: {
        TicketNew,
    },
    methods: {
        ...mapActions([
            
        ]),
        closeModal()
        {
            var url = window.location.href;
            url = url.split(this.$url);
            url = url[1]

            if(url == '#/bilheteria') {
                this.$parent.link('Map');
            }
            
            //this.openModal = false;
            this.$parent.ticket_gate_id = false;
            this.$parent.user_already_have_ticket = false;
            this.$parent.ticket_gate_doesnt_exist = false;
            this.$parent.modal_ticket_gate = false;
        },
        open(){
            // this.openModal = true;

        }
    },
    created() {

    },
    mounted() {
        this.$refs.buyticket.loadTickets();
    },
    updated() {
        // if(this.openModal){
        //     if(this.selfies == null) {
        //         this.popGallery();
        //     }
        //     this.changeType(this.formType);
        //     let drop_ = document.querySelector('.area-upload #upload-file');
        
        //     drop_.addEventListener('dragenter', function(){
        //         document.querySelector('.area-upload .label-upload').classList.add('highlight');
        //     });
        //     drop_.addEventListener('dragleave', function(){
        //         document.querySelector('.area-upload .label-upload').classList.remove('highlight');
        //     });
        //     drop_.addEventListener('drop', function(){
        //         document.querySelector('.area-upload .label-upload').classList.remove('highlight');
        //     });
        // }
    },
    beforeDestroy() {
        this.closeModal();
    },
}
</script>

<style>
    .ticket-gate-modal-wrap{
        overflow: hidden !important;
    }
    .ticket-gate-modal-wrap .main_modal {
        background-color: #fff;
        border-radius: 10px;
        font-family: Arial, Helvetica, sans-serif;
        /* width: 70%; */
        width: 900px;
        max-width: 900px;
        max-height: 80%;
        position: absolute;
        overflow: hidden;
        left: 50%;
        top: 50%;
        /* transform: translate(-50%, -50%); */
        transform: translate(-50%, calc(-50% + 30px));
        min-height: 360px;
    }
    /* .ticket-gate-modal-wrap .main_modal_wrap{

    } */
    .ticket-gate-modal-wrap .title_modal {
        font-weight: 700;
        font-size: 20px;
    }
    .ticket-gate-modal-wrap .header_modal {
        padding: 16px;
        background-color: var(--headers_color);
        text-align: center;
        color: var(--fonts_color);
        letter-spacing: 8px;
        font-family: Arial;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        text-transform: uppercase;
    }
    .ticket-gate-modal-wrap .body_modal {
        /* max-width: 600px; */
        margin-top: 0;
        margin-bottom: 0;
        width: 100%;
        box-sizing: border-box;
    }
    .ticket-gate-modal-wrap .tickets{
        min-height: auto;
        margin: 0;
    }
    .ticket-gate-modal-wrap .tickets .new-list-wrap {
        max-height: 600px;
        overflow-y: auto;
        min-height: 0;
        margin-top: 40px;
        padding: 0 20px;
        box-sizing: border-box;
    }
    .ticket-gate-modal-wrap .tickets .new-list {
        margin: 0;
    }
    .ticket-gate-modal-wrap .tickets .new-list .ticket{
        margin: 20px auto 20px;
    }
    .ticket-gate-modal-wrap .tickets .buy_tip{
        margin-top: 25px;
        display: none;
    }
    .ticket-gate-modal-wrap .tickets .buy_order{
        margin-top: 25px;
        display: none;
    }
    .ticket-gate-modal-wrap .tickets .container-content main .spacing-master{
        padding: 0;
    }
    .ticket-gate-modal-wrap .none-ticket{
        margin: 0 !important;
    }

    /* Confirmation modal */
    .ticket-gate-modal-wrap  #modal-confirm-buyticket{
        background: rgba(0,0,0,.8);
        /* backdrop-filter: blur(15px);
        overflow: hidden; */
    }
    .ticket-gate-modal-wrap  #modal-notifications{
        background: rgba(0,0,0,.8);
        /* backdrop-filter: blur(15px);
        overflow: hidden; */
    }



    .ticket-gate-modal-wrap .tickets .new-list-wrap::-webkit-scrollbar-track
    {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: #F5F5F5;
    }

    .ticket-gate-modal-wrap .tickets .new-list-wrap::-webkit-scrollbar
    {
        width: 10px;
        background-color: #F5F5F5;
    }

    .ticket-gate-modal-wrap .tickets .new-list-wrap::-webkit-scrollbar-thumb
    {
        background-color: #0ae;
        border-radius: 10px;
        background-image: -webkit-gradient(linear, 0 0, 0 100%,
                        color-stop(.5, rgba(255, 255, 255, .2)),
                        color-stop(.5, transparent), to(transparent));
    }


    /* .ticket-gate-modal-wrap .notifyuser{

    } */
    .ticket-gate-modal-wrap .notifyuser .fmodel-content{
        text-align: center;
    }
    .ticket-gate-modal-wrap .notifyuser .content-panel{
        margin-bottom: 20px;
    }
    


    
    @media screen and (max-width: 900px) {
        .ticket-gate-modal-wrap .main_modal {
            width: 80%;
        }
    }
    @media screen and (max-width: 768px)
    {
        .ticket-gate-modal-wrap .main_modal{
            width: 90%;
        }
        .ticket-gate-modal-wrap .tickets .buy_tip,
        .ticket-gate-modal-wrap .tickets .buy_order {
            margin-top: 15px;
        }
        .ticket-gate-modal-wrap .tickets .buy_order {
            font-size: 16px;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            padding: 0 30px;
            justify-content: center;
            text-align: inherit;
            display: none;
        }
        .ticket-gate-modal-wrap .tickets .buy_tip{
            font-size: 18px;
        }
    }
    @media screen and (max-width: 600px)
    {
        .ticket-gate-modal-wrap .modalGerais{
            padding-top: 30px;
        }
    }
    @media screen and (max-width: 583px)
    {
        .ticket-gate-modal-wrap .main_modal{
            width: 90%;
            max-height: 90%;
            transform: translate(-50%, -50%);
        }
        .ticket-gate-modal-wrap .tickets .new-list-wrap {
            max-height: 440px;
            height: 220px;
            margin-top: 20px;
            max-height: 500px;
            height: auto;
        }
        .ticket-gate-modal-wrap .tickets .buy_order .option {
            display: inline-block;
            margin: 8px 0px;
        }
    }

</style>