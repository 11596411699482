import { render, staticRenderFns } from "./MusicPlayer.vue?vue&type=template&id=5b438cdc&scoped=true&"
import script from "./MusicPlayer.vue?vue&type=script&lang=js&"
export * from "./MusicPlayer.vue?vue&type=script&lang=js&"
import style0 from "./MusicPlayer.vue?vue&type=style&index=0&id=5b438cdc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b438cdc",
  null
  
)

export default component.exports