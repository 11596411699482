<template>
    <div id="modal" ref="modal" class="modals bgBlack" :style="isMobileIframe?'align-items: start':''">
        <div style="width: 100vw;height: 100vh;position: fixed;top: 0;left: 0;" data-response="0" v-on:click="confirmationResponse"></div>
        <div ref="buy-handler-content" :class="{
                        'modalGerais':true,
                        'tutorial':t==='iframe'||t==='image',
                        'krpano':sub_t==='krpano',
        }" :style="isMobileIframe?'padding-top: 0;z-index:10001':''">
            <div :class="{'modals__content':true, 'alignVertical':true,'krpano-content':sub_t=='krpano'}">
                <div class="modals__content--header alignVertical">

                    <p>{{ title }} </p>
                    <a id="fs-iframe" v-if="t==='iframe'" href="#" v-on:click="openModalFS">
                        <svg style="position: absolute;width: 20px;height: 20px;right: 60px;top: 50%;transform: translateY(-50%);" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path d="M344 0H488c13.3 0 24 10.7 24 24V168c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-39-39-87 87c-9.4 9.4-24.6 9.4-33.9 0l-32-32c-9.4-9.4-9.4-24.6 0-33.9l87-87L327 41c-6.9-6.9-8.9-17.2-5.2-26.2S334.3 0 344 0zM168 512H24c-13.3 0-24-10.7-24-24V344c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2l39 39 87-87c9.4-9.4 24.6-9.4 33.9 0l32 32c9.4 9.4 9.4 24.6 0 33.9l-87 87 39 39c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8z" fill="#fff"/>
                        </svg>
                    </a>
                    <a href="#" class="close" data-response="0" v-on:click="confirmationResponse">
                        <img src="assets/icons/modals/x.svg" alt="">
                    </a>
                </div>
                <div :class="{'modals__content--body':true, 'alignVertical':true}">

                    <!-- senha alterada -->
                    <div :class="{
                        'modalGerais__semMoedas':t!=='iframe'&&t!=='image',
                        'modalGerais__tutorial':t==='iframe'||t==='image',
                        'alignVertical':t!=='iframe'&&t!=='image'}">
                        <div v-if="t==='iframe'">
                            <div :class="{'lightbox-pdf': modalFs}">
                                <div v-if="modalFs" class="modals__content--header alignVertical">
                                    <p>{{ title }} </p>
                                    <a href="#" class="close" data-response="0" v-on:click="modalFs = false">
                                        <svg style="position: absolute;width: 20px;height: 20px;right: 50px;top: 50%;transform: translateY(-50%);" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path d="M344 0H488c13.3 0 24 10.7 24 24V168c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-39-39-87 87c-9.4 9.4-24.6 9.4-33.9 0l-32-32c-9.4-9.4-9.4-24.6 0-33.9l87-87L327 41c-6.9-6.9-8.9-17.2-5.2-26.2S334.3 0 344 0zM168 512H24c-13.3 0-24-10.7-24-24V344c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2l39 39 87-87c9.4-9.4 24.6-9.4 33.9 0l32 32c9.4 9.4 9.4 24.6 0 33.9l-87 87 39 39c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8z" fill="#fff"/>
                                        </svg>
                                    </a>
                                </div>
                                <div class="iframe-container">
                                    <video v-if="iframe_url.match(/\.mp4$/)" controls>
                                        <source :src="iframe_url+'#t=0.001'"  type="video/mp4">
                                    </video>
                                    <iframe v-else :id="iframe_url" :src="iframe_url" :class="{'video-full': modalFs}" frameborder="0"></iframe>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="t==='image'" class="alignVertical col-12" ref="text-info">
                            <img :src="iframe_url" style="max-width:100%">
                        </div>
                        <div v-else class="alignVertical col-12" ref="text-info">
                            <p :class="{'col-12':t==='error'}" v-for="(item,key) in this.txt" :key="key">{{ item }}</p>
                        </div>

                        <div v-if="t == 'success'||t == 'error'||t == 'status'" class="alignVertical col-12">
                            <button v-if="modal_btn != '' && response!=null" class="button__blue" v-on:click="confirmationResponse">{{ btn }}</button>
                            <button v-if="modal_btn != '' && response==null" class="button__red" v-on:click="close">{{ btn }}</button>
                        </div>
                        <div v-else-if="t!='iframe'&&t!='image'" class="alignVertical col-12">
                            <div v-if="t== 'confirmationCaptcha'">
                                <div class="col-12 flex" :style="'margin-top: 5px;margin-bottom: 10px; justify-content: center;'+(this.switch.captcha?'':'display:none')">
                                    <div class="col-12">
                                        <!-- <img class="float-right" src="assets/images/home/capchat.png"> -->
                                        <!-- <div id="captcha" class="g-recaptcha flex" style="justify-content:center" :data-sitekey="this.switch.captcha_client"></div> -->
                                        <vue-recaptcha ref="recaptcha" @render="checkCaptcha = false" @verify="this.verifyCaptcha" @expired="this.expireCaptcha" :sitekey="this.switch.captcha_client" />
                                    </div>
                                </div>
                            </div>
                            <div v-if="logged" class="alignVertical col-6">
                                <button class="button__blue" data-response="0" v-on:click="confirmationResponse">{{ no }}</button>
                            </div>
                            <div v-if="logged" class="alignVertical col-6">
                                <button v-if="!checkCaptcha" class="button__red button__desactive" data-response="1" disabled="true">{{ yes }}</button>
                                <button v-else class="button__secondary" data-response="1" v-on:click="confirmationResponse">{{ yes }}</button>
                            </div>
                            <div v-if="!logged" class="alignVertical col-6">
                                <button class="button__secondary" data-response="0" v-on:click="confirmationResponse">{{ no }}</button>
                            </div>
                            <div v-if="!logged" class="alignVertical col-6">
                                <button v-if="!checkCaptcha" class="button__red button__desactive" data-response="1" disabled="true">{{ yes }}</button>
                                <button v-else class="button__blue" data-response="1" v-on:click="confirmationResponse">{{ yes }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {mapGetters} from "vuex";
import {VueRecaptcha} from "vue-recaptcha";
import {focusUnity} from "@/helper";


export default {
    name: 'Modal',
    props: ['modal_type', 'modal_title', 'modal_text',
        'modal_response', 'modal_yes', 'modal_no', 'modal_btn','modal_iframe_url'],
    computed: {
        ...mapGetters([
            'logged', 'token','switch','checkMobile'
        ]),
        isMobileIframe(){
            return window.innerWidth < 450 && this.$parent.isMobile && (this.t == 'image' || this.t == 'iframe');
        },
        t(){
            if (this.modal_type)
                return this.modal_type.split("_")[0];
            return this.modal_type;
        },
        sub_t(){
            console.log(this.modal_type);
            if (this.modal_type)
                return this.modal_type.split("_")[1]??"";
            return "";
        },
        txt(){
            if (this.modal_text)
                return this.modal_text.split(/[\n\r]/);
            return [];
        }
    },
    data() {
        return {
            response: this.modal_response,
            title: this.modal_title,
            yes: this.modal_yes ? this.modal_yes : this.$i18n.t('modal.sim'),
            no: this.modal_no ? this.modal_no : this.$i18n.t('modal.nao'),
            btn: this.modal_btn ? this.modal_btn : 'OK',
            iframe_url: this.modal_iframe_url,
            checkCaptcha: false,
            responseCaptcha: "",
            modalFs: false
        }
    },
    components: {VueRecaptcha},
    methods: {
        close(e) {
            this.$parent.modal_ = false;
            focusUnity(this.checkMobile);
            e.preventDefault();
        },
        confirmationResponse(e) {
            this.close(e);
            let value = Number(e.currentTarget.dataset?.response);
            if(value === 1 && this.t == 'confirmationCaptcha' && this.switch.captcha && this.responseCaptcha.length<=0){
                this.$parent.modal('error', this.$i18n.t('modal.erro'),this.$i18n.t('modal.vocePrecisaConfirmar') ,
                    this.$parent.deleteAccount,null,null,"OK");
                return;
            }else if (value === 1 && this.t == 'confirmationCaptcha' && this.switch.captcha){
                // eslint-disable-next-line no-undef
                this.response(value,this.responseCaptcha);
            }else if (this.response != null)
                this.response(value);

            e.preventDefault();
        },
        verifyCaptcha(CapchaRespose){
            this.responseCaptcha =CapchaRespose;
            this.checkCaptcha = true;
        },
        expireCaptcha(){

            this.checkCaptcha = false;
        },
        openModalFS() {
            this.modalFs = true;
        }
    },
    created() {

    },
    mounted() {
        //console.log("mounted");
        //console.log(this.t);
        this.$parent.bodyOverflow();

        console.log(this.iframe_url);
        if (this.t != 'confirmationCaptcha' || !this.switch.captcha) {
            this.$nextTick(function () {
                this.checkCaptcha = true;    // eslint-disable-next-line no-undef
            })
        }
    },
    updated() {
        if (this.t != 'confirmationCaptcha' || !this.switch.captcha) {
            this.$nextTick(function () {
                this.checkCaptcha = true;    // eslint-disable-next-line no-undef
            })
        }
    },
    watch:{
        modal_response(){
            this.response= this.modal_response;
        },
        modal_title(){
            this.title= this.modal_title;
        },
        modal_text(){
            this.txt= this.modal_text;
        },
        modal_type(){
            this.t= this.modal_type;
        },
        modal_yes(){
            this.yes= this.modal_yes ? this.modal_yes : this.$i18n.t('modal.sim');
        },
        modal_no(){
            this.no= this.modal_no ? this.modal_no : this.$i18n.t('modal.nao');
        },
        modal_btn(){
            this.btn= this.modal_btn ? this.modal_btn : 'OK';
        },

    },
    beforeDestroy() {
        this.$parent.bodyOverflow(false)
    },
}
</script>

<style scoped>
    #modal{
        z-index: 1000!important;
    }

.video-full {
  position: fixed;
  width: 100%;
  height: calc(100% - 61px); /* Subtrai a altura do header do iframe */
  top: 61px; /* Define a altura do header do iframe */
  left: 0;
  z-index: 10002; /* Ajuste o valor do z-index conforme necessário */
}

.box {
    width: 450px;
}

.tab {
    text-align: left;
}

.a {
    display: block;
    font-size: 16px;
}

.a-padding {
    box-sizing: border-box;
    margin-top: 15px;
    padding: 15px 25px;
}

.success, .error {
    box-sizing: border-box;
    padding: 15px 0;
}

/*----- body -----*/
.modal-body {
    box-sizing: border-box;
    padding: 25px 15px;
    text-align: center;
    width: 100%;
}

/*----- \body\ -----*/

/*----- footer -----*/
.modal-footer {
    text-align: center;
}

.modal-footer > ul {
    display: inline-block;
    width: auto;
}

.modal-footer > ul > li {
    box-sizing: border-box;
    display: inline-block;
    padding: 10px 4px;
}
/*----- \footer\ -----*/

.lightbox-pdf {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99999999999999;
}
.krpano-content{
    background: black;
}
@media screen and (max-width: 450px) {
    .box {
        width: calc(100% - 40px);
    }
    .modalGerais.krpano{
        min-height: 100%!important;
        background: black;
    }
}
</style>
