<template>
  <div id="MusicPlayer" ref="MusicPlayer">
    <div v-if="!this.$parent.isMobile" class="container">
        <div v-on:click="playPause()" class="on" id="play-on">
            <div v-if="this.audio_active" v-html="this.header_buttons.radio_on_button"></div>
            <div v-if="!this.audio_active" v-html="this.header_buttons.radio_off_button"></div>
        </div>
    </div>
    <div v-if="this.$parent.isMobile" v-on:click="playPause()">
      <div v-if="this.audio_active" v-html="this.header_buttons.radio_on_button"></div>
      <div v-if="!this.audio_active" v-html="this.header_buttons.radio_off_button"></div>
    </div>
    <audio src="/assets/music/blx1-liger.mp3" ref="song"></audio>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MusicPlayer",
  computed: {
    ...mapGetters(["logged", "token", "id", "nickname", "email", "ref", "header_buttons"]),
  },
  props: ["max", "audio", "room", "participants", "radios"],
  data() {
    return {
      count_loaded: 0,
      link_ar: [],
      script_ar: [],
      play: true,
      audio_active: false,
      songIndex: 0
    };
  },
  components: {},
  methods: {
    playPause(){
    
    if (this.play) {
        // songIndex=Math.floor((Math.random() * songs.length) );
        if (!this.audio_active) {
            const songs =  this.radios;
            this.$refs['song'].src = songs[this.songIndex];

            this.$refs['song'].play();
            console.log(this.$refs['song'])
            this.$refs['song'].volume = 1;
            this.play = false;
            this.audio_active = true;
            this.songIndex++
            if (this.songIndex == this.radios.length) {
              this.songIndex = 0
            }
            return;
        }
    }

    //song.pause();
    this.$refs['song'].pause();
    this.audio_active = false;
    // getSoundAndFadeAudio('song');
    this.play = true;
},

// automatically play the next song at the end of the audio object's duration,
    loaded() {
      /*
                let div = document.createElement('div');
                div.id = 'q-app';
                document.body.append(div);

                let script = document.createElement('script');
                script.innerHTML = 'setupChatFrontend();let id = Math.floor(Math.random()*99999);let name = faker.name.findName();vue.setServer("amgen-chat-back.amgen.vrglass.com");vue.setUser(id,name);vue.joinRoom("juligo",3,true,true);';
                document.body.append(script);
                */
    },
  },
  created() {},
  mounted() {
    const root = this;
    this.$refs['song'].addEventListener('ended', function(){
        root.play=true;
        root.audio_active=false;
        root.playPause();
    });

    /*
            let link;
            let script;
            let root_ = this;
            let o;
            this.link_ar =
            [
                'https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900|Material+Icons',
                'https://use.fontawesome.com/releases/v5.0.13/css/all.css',
                'https://cdn.jsdelivr.net/npm/bootstrap-icons@^1.4.0/font/bootstrap-icons.css',
                'https://cdn.jsdelivr.net/npm/quasar@1.15.19/dist/quasar.min.css',
                (this.$url + 'chat/style.css')
            ];
            this.script_ar = 
            [
                'https://cdn.jsdelivr.net/npm/quasar@1.15.19/dist/quasar.ie.polyfills.umd.min.js',
                'https://cdn.jsdelivr.net/npm/vue@^2.0.0/dist/vue.min.js',
                'https://cdn.jsdelivr.net/npm/quasar@1.15.19/dist/quasar.umd.min.js',
                'https://cdn.socket.io/4.0.1/socket.io.min.js',
                'https://unpkg.com/peerjs@1.3.1/dist/peerjs.min.js',
                this.$url + 'assets/chat/polyfill.js',
                this.$url + 'assets/chat/faker.min.js',
                this.$url + 'assets/chat/index.js'
            ];

            for (o = 0; o < this.link_ar.length; o++)
            {
                link      = document.createElement('link');
                link.rel  = 'stylesheet'; 
                link.type = 'text/css';
                link.href = this.link_ar[o]; 
                document.head.append(link);
            }

            for (o = 0; o < this.script_ar.length; o++)
            {
                script     = document.createElement('script');
                script.src = this.script_ar[o];
                script.onload = function() {
                    root_.count_loaded++;
                    console.log(root_.count_loaded);
                    if (root_.count_loaded === 8)
                    {
                        root_.loaded();
                    }
                };
                document.body.append(script);
            }
            */
  },
  updated() {},
  beforeDestroy() {},
};
</script>

<style scoped>
#player-iframe {
  width: 100%;
  height: 100%;
}
</style>
