<template>

    <div class="myTickets">
        <div class="content">
            <h1>{{ $t('ticketpurchased.meusIngressos') }}</h1>

            <h3>{{ $t('ticketpurchased.individuais') }}</h3>

            <div v-if="list_individual.length == 0">
                <p>{{ $t('ticketpurchased.vocAindaNo') }}</p>
            </div>

            <div class="myTickets__box flex" v-for="(item, index) in list_individual" :key="item.id + '.' + index">

                <div class="myTickets__box--button flex alignVerticalStart col-2">
                    <div class="myTickets__box--button---status assistaAgr">
                        <div class="text alignVertical col-12">
                            <div class="col-10">{{ $t('ticketpurchased.assistaAgora') }}</div>
                        </div>
                    </div>
                </div>

                <div class="myTickets__box--date flex col-9">
                    <div class="col-4 alignVertical">
                        <div>
                            <p><b>10 de Julho</b></p>
                            <p>Sex - 20h</p>
                        </div>
                    </div>
                    <div class="col-12 alignVertical">
                        <div class="col-11">
                            <p><b>{{ $t('ticketpurchased.newOrleansPelicans') }}</b></p>
                            <p>{{ $t('ticketpurchased.smoothieKingCenter') }}</p>
                        </div>
                    </div>
                </div>
                <div class="myTickets__box--payment flex col-2">
                    <div class="col-4 alignVertical">
                        <img src="https://via.placeholder.com/61">
                    </div>
                    <div class="col-8 alignVerticalStart">
                        <div>
                            <p>{{ $t('ticketpurchased.convidado') }}</p>
                            <p><b>{{ $t('ticketpurchased.grtis') }}</b></p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="myTickets__box flex alinItem">
                <div class="myTickets__box--date flex col-10">
                    <div class="col-4 alignVertical">
                        <div>
                            <p><b>10 de Julho</b></p>
                            <p>Sex - 20h</p>
                        </div>
                    </div>
                    <div class="col-12 alignVertical">
                        <div class="col-11">
                            <p><b>{{ $t('ticketpurchased.newOrleansPelicans') }}</b></p>
                            <p>{{ $t('ticketpurchased.smoothieKingCenter') }}</p>
                        </div>
                    </div>
                </div>
                <div class="myTickets__box--payment flex col-2">
                    <div class="col-4 alignVertical">
                        <img src="https://via.placeholder.com/61">
                    </div>
                    <div class="col-8 alignVerticalStart">
                        <div>
                            <p>{{ $t('ticketpurchased.convidado') }}</p>
                            <p><b>{{ $t('ticketpurchased.grtis') }}</b></p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="myTickets__box flex alinItem">
                <div class="myTickets__box--date flex col-10">
                    <div class="col-4 alignVertical">
                        <div>
                            <p><b>10 de Julho</b></p>
                            <p>Sex - 20h</p>
                        </div>
                    </div>
                    <div class="col-12 alignVertical">
                        <div class="col-11">
                            <p><b>{{ $t('ticketpurchased.newOrleansPelicans') }}</b></p>
                            <p>{{ $t('ticketpurchased.smoothieKingCenter') }}</p>
                        </div>
                    </div>
                </div>
                <div class="myTickets__box--payment flex col-2">
                    <div class="col-4 alignVertical">
                        <img src="https://via.placeholder.com/61">
                    </div>
                    <div class="col-8 alignVerticalStart">
                        <div>
                            <p>{{ $t('ticketpurchased.convidado') }}</p>
                            <p><b>{{ $t('ticketpurchased.grtis') }}</b></p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="myTickets__box flex">
                <div class="myTickets__box--button flex alignVerticalStart col-2">
                    <div class="myTickets__box--button---status assista">
                        <div class="text alignVertical col-12">
                            <div class="col-10">{{ $t('ticketpurchased.assista') }}</div>
                        </div>
                    </div>
                </div>
                <div class="myTickets__box--date flex col-9">
                    <div class="col-4 alignVertical">
                        <div>
                            <p><b>10 de Julho</b></p>
                            <p>Sex - 20h</p>
                        </div>
                    </div>
                    <div class="col-12 alignVertical">
                        <div class="col-11">
                            <p><b>{{ $t('ticketpurchased.newOrleansPelicans') }}</b></p>
                            <p>{{ $t('ticketpurchased.smoothieKingCenter') }}</p>
                        </div>
                    </div>
                </div>
                <div class="myTickets__box--payment flex col-2">
                    <div class="col-4 alignVertical">
                        <img src="https://via.placeholder.com/61">
                    </div>
                    <div class="col-8 alignVerticalStart">
                        <div>
                            <p>{{ $t('ticketpurchased.convidado') }}</p>
                            <p><b>{{ $t('ticketpurchased.grtis') }}</b></p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="myTickets__box flex">
                <div class="myTickets__box--total col-12 flex alignVerticalEnd">
                    <div class="myTickets__box--total---moeda alignVertical col-3">
                        <div class="flex alignVertical col-9">
                            <div class="col-2 alignVertical">
                                <img src="https://via.placeholder.com/20">
                            </div>
                            <div class="text col-7">{{ $t('ticketpurchased.totalCartoDeCrdito') }}</div>
                            <div class="valor col-3">R$3,99</div>
                        </div>
                    </div>

                    <div class="myTickets__box--total---cartao alignVertical col-2">
                        <div class="flex alignVertical col-11">
                            <div class="col-2 alignVertical">
                                <img src="https://via.placeholder.com/20">
                            </div>
                            <div class="text col-7">{{ $t('ticketpurchased.totalMoedas') }}</div>
                            <div class="valor col-3">R$3,99</div>
                        </div>
                    </div>
                </div>
            </div>

            <h3>{{ $t('ticketpurchased.budweiserVipRooms') }}</h3>

            <div v-if="list_vip.length == 0">
                <p>{{ $t('ticketpurchased.vocAindaNo') }}</p>
            </div>

            <div class="myTickets__box flex" v-for="(item, index) in list_vip" :key="item.id + '.' + index">

                <div class="myTickets__box--button flex alignVerticalStart col-2">
                    <div class="myTickets__box--button---status assistaAgr">
                        <div class="text alignVertical col-12">
                            <div class="col-10">{{ $t('ticketpurchased.assistaAgora') }}</div>
                        </div>
                    </div>
                </div>

                <div class="myTickets__box--date flex col-9">
                    <div class="col-4 alignVertical">
                        <div>
                            <p><b>10 de Julho</b></p>
                            <p>Sex - 20h</p>
                        </div>
                    </div>
                    <div class="col-12 alignVertical">
                        <div class="col-11">
                            <p><b>{{ $t('ticketpurchased.newOrleansPelicans') }}</b></p>
                            <p>{{ $t('ticketpurchased.smoothieKingCenter') }}</p>
                        </div>
                    </div>
                </div>
                <div class="myTickets__box--payment flex col-2">
                    <div class="col-4 alignVertical">
                        <img src="https://via.placeholder.com/61">
                    </div>
                    <div class="col-8 alignVerticalStart">
                        <div>
                            <p>{{ $t('ticketpurchased.convidado') }}</p>
                            <p><b>{{ $t('ticketpurchased.grtis') }}</b></p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="myTickets__box flex">
                <div class="myTickets__box--total col-12 flex alignVerticalEnd">
                    <div class="myTickets__box--total---moeda alignVertical col-3">
                        <div class="flex alignVertical col-9">
                            <div class="col-2 alignVertical">
                                <img src="https://via.placeholder.com/20">
                            </div>
                            <div class="text col-7">{{ $t('ticketpurchased.totalCartoDeCrdito') }}</div>
                            <div class="valor col-3">R$3,99</div>
                        </div>
                    </div>

                    <div class="myTickets__box--total---cartao alignVertical col-2">
                        <div class="flex alignVertical col-11">
                            <div class="col-2 alignVertical">
                                <img src="https://via.placeholder.com/20">
                            </div>
                            <div class="text col-7">{{ $t('ticketpurchased.totalMoedas') }}</div>
                            <div class="valor col-3">R$3,99</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <!-- <div class="myTickets">

        <div class="myTickets__title">
            <h1>Meus Ingressos</h1>
            <p>Individuais</p>
            <div class="myTickets__title--bnt flex">
                <router-link  to="/ingressos" class="flex">
                    <div>Ingressos</div><img :src="$url + 'assets/icons/myTickets/seta.svg'">
                </router-link>
            </div>
        </div>

        <div class="content">

            <div v-if="list_individual.length == 0">
                <p>{{ $t('ticketpurchased.vocAindaNo') }}</p>
            </div>

            <div class="myTickets__box col-12" v-for="(item, index) in list_individual" :key="item.id + '.' + index">
                <div class="myTickets__box--tickets flex flex-wrap col-12">
                    <div :class="'myTickets__box--tickets---date alignVertical'+(item.state == ' On demand' ||item.state == ' Ao Vivo'?' dateColorRed':' dateColorBlue')">
                        <div>
                            <h3>
                                <span :key="indexDate" v-for="(itemDate,indexDate ) in new Intl.DateTimeFormat('pt-br', {day: 'numeric', month: 'short',}).format(Date.parse(item.event_date.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3')))
                                    .replaceAll('.','').split(' de')">{{itemDate}}<br></span>
                            </h3>
                            <p>{{ new Intl.DateTimeFormat("pt-br", {weekday:'short',}).format(Date.parse(item.event_date.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"))).replaceAll('.','') }}</p>
                            <p>{{ item.event_time.replace(/(\d{2}):(\d{2})/, "$1")}}h</p>
                        </div>
                    </div>
                    <div v-if="item.state == 'On demand'" class="myTickets__box--tickets---img" :style="'background-image: url(\''+$url+'assets/images/home/background2.svg\');'">
                        <div class="filtro alignVertical">
                            <div class="alignVertical col-12">
                                <p>ON-DEMAND</p>
                                <button :data-vimeo="item.link_vimeo" v-on:click="playlistHandler">Assistir</button>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="item.state == 'Ao vivo'" class="myTickets__box--tickets---img" :style="'background-image: url(\''+$url+'assets/images/home/background2.svg\');'">
                        <div class="filtro alignVertical">
                            <div class="alignVertical col-12">
                                <img :src="$url + 'assets/icons/myTickets/aovivo.svg'">
                                <p>AO VIVO</p>
                                <button v-on:click="$parent.link('Stadium')">ASSISTIR NA ARENA</button>
                                <button v-if="age<18" v-on:click="$parent.link('Theater')">ASSISTIR AUDITORIO</button>
                                <button v-else v-on:click="$parent.link('Bar')">ASSISTIR NO BUD BAR</button>
                            </div>
                        </div>
                    </div>
                    <div v-else class="myTickets__box--tickets---img" :style="'background-image: url(\''+$url+'assets/images/home/background2.svg\');'">
                    </div>

                    <div class="myTickets__box--tickets---game flex flex-wrap">

                        <div class="border"></div>

                        <div class="col-4 flex flex-wrap alignVertical reverse">
                            <div class="col-9 alignVertical">
                                <p>{{ item.local.split('-')[0] }}</p>
                            </div>
                            <div class="col-3 alignVertical">
                                <img :src="$parent.getImageTeam(item.local.split('-')[0])">
                            </div>
                        </div>
                        <div class="col-2 alignVertical">
                            <h2>VS</h2>
                        </div>
                        <div class="col-4 flex flex-wrap">
                            <div class="col-3 alignVertical">
                                <img :src="$parent.getImageTeam(item.local.split('-')[1])">
                            </div>
                            <div class="col-9 alignVertical">
                                <p>{{ item.local.split('-')[1] }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="myTickets__box--tickets---payment alignVertical">
                        <div v-if="item.payment_method == 'voucher'">
                            <img :src="$url + 'assets/icons/myTickets/sifrao.svg'">
                            <div class="reverse">
                                <p>{{ $t('ticketpurchased.grtis') }}</p>
                                <p><b>{{ $t('ticketpurchased.convidado') }}</b></p>
                            </div>
                        </div>
                        <div v-if="item.payment_method == 'brinde'">
                            <img :src="$url + 'assets/icons/myTickets/sifrao.svg'">
                            <div class="reverse">
                                <p>{{ $t('ticketpurchased.grtis') }}</p>
                                <p><b>Brinde</b></p>
                            </div>
                        </div>
                        <div v-else-if="item.payment_method == 'eventim'">
                            <img :src="$url + 'assets/icons/myTickets/SubtractDark.svg'">
                            <div class="reverse">
                                <p>R$ {{ item.price }}</p>
                                <p><b>Cartão de crédito</b></p>
                                <p><b>{{ item.ticket_eventim }}</b></p>
                            </div>
                        </div>
                        <div v-else-if="item.payment_method == 'coins'">
                            <img :src="$url + 'assets/icons/header/moeda2.png'">
                            <div class="reverse">
                                <p>R$ {{ item.coin_price }}</p>
                                <p><b>Moedas</b></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="myTickets__box alignVerticalEnd col-12">
                <div class="col-6">
                    <div class="myTickets__box--total flex flex-wrap alignVerticalEnd">
                        <div class="col-5">
                            <p>TOTAL</p>
                        </div>

                        <div class="myTickets__box--total---cartao alignVerticalEnd col-4">
                            <div class="alignVertical">
                                <img :src="$url + 'assets/icons/myTickets/Subtract.svg'" />
                            </div>
                            <p>R${{ total_individual_eventim }}</p>
                        </div>

                        <div class="myTickets__box--total---moeda alignVerticalEnd col-3">
                            <div class="alignVertical">
                                <img :src="$url + 'assets/icons/header/moeda2.png'">
                            </div>
                            <p>{{ total_individual_coin }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="age>=18">
                <div class="myTickets__vip flex flex-wrap">
                    <div class="col-6">
                        <p>Vip room <br></p>
                        <b>Sala somente para maiores de 18 anos.</b>
                    </div>

                    <div class="col-6 alignVerticalEnd">
                        <img :src="$url + 'assets/icons/myTickets/logobudweiser.svg'" />
                    </div>
                </div>

                <div v-if="list_vip.length == 0">
                    <p>Você ainda não possui nenhum ingresso VIP</p>
                </div>

                <div class="myTickets__box col-12" v-for="(item, index) in list_vip" :key="item.id + '.' + index">
                    <div v-if="item.state != 'On demand'" class="myTickets__box--tickets flex flex-wrap col-12">
                        <div :class="'myTickets__box--tickets---date alignVertical'+(item.state == 'On demand' ||item.state == ' Ao Vivo'?' dateColorRed':' dateColorBlue')">
                            <div>
                                <h3>
                                    <span :key="indexDate" v-for="(itemDate,indexDate ) in new Intl.DateTimeFormat('pt-br', {day: 'numeric', month: 'short',}).format(Date.parse(item.event_date.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3')))
                                    .replaceAll('.','').split(' de')">{{itemDate}} <br></span>
                                </h3>
                                <p>{{ new Intl.DateTimeFormat("pt-br", {weekday:'short',}).format(Date.parse(item.event_date.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"))).replaceAll('.','') }}</p>
                                <p>{{ item.event_time.replace(/(\d{2}):(\d{2})/, "$1")}}h</p>
                            </div>
                        </div>
                        <div v-if="item.state == 'On demand'" class="myTickets__box--tickets---img" :style="'background-image: url(\''+$url+'assets/images/home/background2.svg\');'">
                            <div class="filtro alignVertical">
                                <div class="alignVertical col-12">
                                    <p>ON-DEMAND</p>
                                    <button :data-vimeo="item.link_vimeo" v-on:click="playlistHandler">Assistir</button>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="item.state == 'Ao vivo'" class="myTickets__box--tickets---img" :style="'background-image: url(\''+$url+'assets/images/home/background2.svg\');'">
                            <div class="filtro alignVertical">
                                <div class="alignVertical col-12">
                                    <img :src="$url + 'assets/icons/myTickets/aovivo.svg'">
                                    <p>AO VIVO</p>
                                    <button v-if="item.user_id==id" class="button__gerenciar"
                                            v-on:click="update_ticket_actual(item);$parent.link('VipRoom')">Gerenciar
                                    </button>
                                    <button class="col-6" v-on:click="update_ticket_actual(item);$parent.chat_not_clear=true;$parent.chat_owner=(item.friend_nickname + ' ' + item.user_id);$parent.updateUser('id_vip_actual', item.user_id);$parent.updateUser('owner_vip_actual', item.friend_nickname);$parent.chat_room=('BarVip' + item.user_id);$parent.link('BarVip')">ASSISTIR NO BUD BAR VIP</button>
                                </div>
                            </div>
                        </div>
                        <div v-else class="myTickets__box--tickets---img" :style="'background-image: url(\''+$url+'assets/images/home/background2.svg\');'">
                            <div v-if="item.user_id==id" class="filtro alignVertical">
                                <div class="alignVertical col-12">
                                    <button class="button__gerenciar"
                                            v-on:click="update_ticket_actual(item);$parent.link('VipRoom')">Gerenciar
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="myTickets__box--tickets---game flex flex-wrap">

                            <div class="border"></div>

                            <div class="col-4 flex flex-wrap alignVertical reverse">
                                <div class="col-9 alignVertical">
                                    <p>{{ item.local.split('-')[0] }}</p>
                                </div>
                                <div class="col-3 alignVertical">
                                    <img :src="$parent.getImageTeam(item.local.split('-')[0])">
                                </div>
                            </div>
                            <div class="col-2 alignVertical">
                                <h2>VS</h2>
                            </div>
                            <div class="col-4 flex flex-wrap">
                                <div class="col-3 alignVertical">
                                    <img :src="$parent.getImageTeam(item.local.split('-')[1])">
                                </div>
                                <div class="col-9 alignVertical">
                                    <p>{{ item.local.split('-')[1] }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="myTickets__box--tickets---payment alignVertical">
                            <div v-if="item.payment_method == 'convite'">
                                <img :src="$url + 'assets/icons/myTickets/sifrao.svg'">
                                <div class="reverse">
                                    <p>{{ $t('ticketpurchased.grtis') }}</p>
                                    <p><b>{{ $t('ticketpurchased.convidado') }}</b></p>
                                    <p><b>{{ item.friend_nickname }}</b></p>
                                </div>
                            </div>
                            <div v-if="item.payment_method == 'voucher'">
                                <img :src="$url + 'assets/icons/myTickets/sifrao.svg'">
                                <div class="reverse">
                                    <p>{{ $t('ticketpurchased.grtis') }}</p>
                                    <p><b>{{ $t('ticketpurchased.convidado') }}</b></p>
                                </div>
                            </div>
                            <div v-else-if="item.payment_method == 'eventim'">
                                <img :src="$url + 'assets/icons/myTickets/SubtractDark.svg'">
                                <div class="reverse">
                                    <p>R$ {{ item.price }}</p>
                                    <p><b>Cartão de crédito</b></p>
                                    <p><b>{{ item.ticket_eventim }}</b></p>
                                </div>
                            </div>
                            <div v-else-if="item.payment_method == 'coins'">
                                <img :src="$url + 'assets/icons/header/moeda2.png'">
                                <div class="reverse">
                                    <p>R$ {{ item.coin_price }}</p>
                                    <p><b>Moedas</b></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="myTickets__box alignVerticalEnd col-12">
                    <div class="col-6">
                        <div class="myTickets__box--total flex flex-wrap alignVerticalEnd">
                            <div class="col-5">
                                <p>TOTAL</p>
                            </div>

                            <div class="myTickets__box--total---cartao alignVerticalEnd col-4">
                                <div class="alignVertical">
                                    <img :src="$url + 'assets/icons/myTickets/Subtract.svg'" />
                                </div>
                                <p>R${{ total_vip_eventim }}</p>
                            </div>

                            <div class="myTickets__box--total---moeda alignVerticalEnd col-3">
                                <div class="alignVertical">
                                    <img :src="$url + 'assets/icons/header/moeda2.png'">
                                </div>
                                <p>{{ total_vip_coin }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="playlist" class="box-bg" id="playlist-handler" ref="playlist-handler">
            <div class="ani box" id="playlist-handler-content" ref="playlist-handler-content">
                <h3 class="tab">
                    PLAYLIST
                    <a class="btn-0" style="float: right;margin: 0 25px 0 0" v-on:click="playlistHandler">
                        <img :src="$url + 'assets/img/icon_close_box.png'" alt="Fechar" width="16">
                    </a>
                </h3>
                <br>
                <iframe width="770" height="400" :src="playlist_actual" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>

    </div> -->
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";

//UserTickets no welcome
//GetUserTickets (atualização)

export default {
    name: "TicketsPurchased",
    computed: {
        ...mapGetters(["token", "UserTickets",'id','age', 'nickname']),
    },
    data() {
        return {
            link_iframe: null,
            list: [],
            list_convidado: [],
            list_individual: [],
            list_vip: [],
            playlist: false,
            playlist_actual: null,
            total_individual_coin: 0,
            total_individual_eventim: 0,
            total_vip_coin: 0,
            total_vip_eventim: 0,
        };
    },
    components: {},
    methods: {
        ...mapActions([
            'update_user_tickets', 'update_ticket_actual'
        ]),
        playlistHandler(e) {
            if (!this.playlist) {
                this.playlist_actual = e.currentTarget.dataset.vimeo;
                this.$parent.viewVideo(this.playlist_actual);
            }

            //console.log("this.playlist_actual -> ", this.playlist_actual);
            this.playlist = !this.playlist;
        },

        loadEvents() {
            this.$parent.wait(true);
            let headers = {
                "Content-Type": "application/json;charset=UTF-8",
                Access: "application/json",
                language: this.$i18n.locale, Authorization: `Bearer ${this.token}`,
            };
            axios
                .get(this.$url_api + "api/GetUserTickets ", { headers: headers })
                .then((response) => this.loadEventsResponse(response))
                .catch((error) => this.loadEventsError(error.response));
        },
        loadEventsResponse(obj) {
            //console.log("loadEventsResponse -> ", obj);
            this.list = obj.data;

            if (obj.data.length > 0)
                this.update_user_tickets(obj.data);

            for (let o in obj.data) {
                if (obj.data[o].type == "normal") {
                    this.list_individual.push(obj.data[o]);
                    if (obj.data[o].payment_method == "eventim") {
                        this.total_individual_eventim += parseFloat(
                            obj.data[o].price
                        );
                    } else if (obj.data[o].payment_method == "coins") {
                        this.total_individual_coin += Number(
                            obj.data[o].coin_price
                        );
                    }
                } else if (obj.data[o].type == "vip") {
                    this.list_vip.push(obj.data[o]);

                    if (obj.data[o].payment_method == "eventim") {
                        this.total_vip_eventim += obj.data[o].price;
                    } else if (obj.data[o].payment_method == "coins") {
                        this.total_vip_coin += obj.data[o].coin_price;
                    }
                }
            }

            //console.log('list_vip => ', this.list_vip);

            this.$parent.wait(false);
        },
        loadEventsError(obj) {
            //console.log("loadEventsError -> ", obj);
            obj;
            this.$parent.wait(false);
        },
    },
    created() {},
    mounted() {
        this.$parent.analytics('visit_my_tickets');
        this.loadEvents();
    },
    beforeDestroy() {},
};
</script>

<style scoped>
/*----- playlist -----*/
#playlist-handler-content
{
    width: 800px;
}
#playlist-handler-content .tab{
    background: #ffffff;
}
@media screen and (max-width: 581px){
    #playlist-handler-content{
        margin-top: 200px;
        width: 97%;
    }
    #playlist-handler-content iframe{
        width: 100%;
        height: 250px;
    }
}
/*----- \playlist\ -----*/
</style>

<style lang="scss" scoped>
</style>