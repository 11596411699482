<template>
    <div class="modals bgBlack" id="noticiation" ref="notification">

        <div class="notificacoes">

            <div class="modals__content alignVertical">

                <div class="modals__content--header alignVertical">
                    <p>{{ $t('notification.messages') }}</p>
                    <a class="btn-0" v-on:click="$parent.notification = false" ref="btn-close">
                        <img src="assets/icons/modals/x-black.svg" alt="" style="width: 11px;">
                    </a>
                </div>
                <div class="modals__content--body"> 
                    <!-- MENU -->
                    <div class="menuOptions flex">
                        <div class="option-notif option" :class="{ tabActive: selectedTab === 'notific' }" v-on:click="changeTab('notific')">
                            <div class="label flex">
                                {{ $t('notification.notify') }}

                                <span v-if="totalUnreadNotifications > 0" class="tab-unread">
                                    {{ totalUnreadNotifications }}
                                </span>
                            </div>
                        </div>
                        <div class="option-inbox option" :class="{ tabActive: selectedTab === 'tabInbox' }" v-on:click="changeTab('tabInbox')">
                            <div class="label flex">
                                {{ $t('pm.titleInbox') }}

                                <span v-if="totalUnreadInbox > 0" class="tab-unread">
                                    {{ totalUnreadInbox }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="divMessages notific" style="display:block;">
                        <div class="swiper-container modalNoticias">
                            <div  class="swiper-wrapper">
                                <div v-if="list.length == 0" id="no" ref="no" class="alignVertical col-12">
                                    <p>{{ $t('notification.nenhumaNotificaoAt') }}</p>
                                </div>
                                <div v-for="(item, index) in list" :key="index" class="swiper-slide">
                                    <!-- notificação de amizade -->
                                    <div :id="'div' + index" :ref="'div' + index" v-if="item.type == 'vip_room_request' || item.type == 'friend_request' || item.type == 'private_message'" class="solicitacao alignVertical col-12">
                                        <div class="solicitacao__avatar alignVertical col-3">
                                            <img v-if="item.icon"
                                                 :style="item.icon.indexOf('ui-avatars.com')===-1?'object-position: 0px;':''"
                                                 :src="item.icon" alt="usuario">
                                            <img v-else :src="$url + 'assets/img/account/perfil.png'" alt="usuario">
                                        </div>
                                        <div class="solicitacao__text alignVerticalStart col-7">
                                            <label>{{item.time_ago}}</label>
                                            <p v-html="formatText(item.text)"></p>
                                        </div>
                                        <div v-if="item.type == 'friend_request'" class="solicitacao__bnt alignVertical col-2">
                                            <button data-v="1" :data-index="index" :data-friend-id="item.friend_id" :data-ticket-id="item.type_ticket_id" v-on:click="requestHandler">
                                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M15.5189 4.25L6.31061 13.4583L2.125 9.27273" stroke="#0FFF35" stroke-width="2" stroke-linejoin="bevel"/>
                                                </svg>
                                            </button>
                                            <button data-v="0" :data-index="index" :data-friend-id="item.friend_id" :data-ticket-id="item.type_ticket_id" v-on:click="requestHandler">
                                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M14.5716 2.42859L2.42871 14.5714M2.42871 2.42859L14.5716 14.5714" stroke="#D91A32" stroke-width="2" stroke-linejoin="round"/>
                                                </svg>
                                            </button>
                                        </div>
                                        <div v-else-if="item.type == 'private_message'" class="solicitacao__bnt alignVertical col-2">
                                            <button data-v="1" :data-index="index" :data-friend-id="item.friend_id" v-on:click="openPrivateMessage(item.friend_id, index, item.notification_id)">
                                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M15.5189 4.25L6.31061 13.4583L2.125 9.27273" stroke="#0FFF35" stroke-width="2" stroke-linejoin="bevel"/>
                                                </svg>
                                            </button>
                                            <button data-v="0" :data-index="index" :data-friend-id="item.friend_id" v-on:click="reed(index,item.notification_id)">
                                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M14.5716 2.42859L2.42871 14.5714M2.42871 2.42859L14.5716 14.5714" stroke="#D91A32" stroke-width="2" stroke-linejoin="round"/>
                                                </svg>
                                            </button>
                                        </div>
                                        <div v-else class="solicitacao__bnt alignVertical col-2">
                                            <button data-v="1" :data-index="index" :data-friend-id="item.friend_id" :data-ticket-id="item.type_ticket_id" v-on:click="invitationHandler">
                                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M15.5189 4.25L6.31061 13.4583L2.125 9.27273" stroke="var(--buttons_color);" stroke-width="2" stroke-linejoin="bevel"/>
                                                </svg>
                                            </button>
                                            <button data-v="0" :data-index="index" :data-friend-id="item.friend_id" :data-ticket-id="item.type_ticket_id" v-on:click="invitationHandler">
                                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M14.5716 2.42859L2.42871 14.5714M2.42871 2.42859L14.5716 14.5714" stroke="#D91A32" stroke-width="2" stroke-linejoin="round"/>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <!-- /notificação de amizade -->
                                    <!-- noticia comum -->
                                    <div :id="'div' + index" :ref="'div' + index" v-if="item.type == 'system'" class="noticia alignVertical col-12"
                                         style="cursor: pointer;"
                                         v-on:click="showNotification(item)">
                                        <div class="noticia__text alignVertical col-8">
                                            <div class="col-10">
                                                <label>{{item.time_ago}}</label>
                                                <p v-html="formatText(item.text.replaceAll('\n','<br>'))"></p>
                                            </div>
                                        </div>

                                        <div class="noticia__img alignVertical col-4">
                                            <img v-if="item.icon" :src="item.icon">
                                            <img v-else :src="'https://via.placeholder.com/300'">
                                        </div>
                                    </div>
                                    <div :id="'div' + index" :ref="'div' + index" v-if="item.type == 'easter_egg'" class="noticia alignVertical col-12"
                                         style="cursor: pointer;"
                                         v-on:click="reed(index,item.notification);$parent.link('City');closeNotification()">
                                        <div class="noticia__text alignVertical col-8">
                                            <div class="col-10">
                                                <label>{{item.time_ago}}</label>
                                                <p v-html="formatText(item.text.replaceAll('\n','<br>'))"></p>
                                            </div>
                                        </div>

                                        <div class="noticia__img alignVertical col-4">
                                            <img v-if="item.icon" :src="item.icon">
                                            <img v-else :src="'https://via.placeholder.com/300'">
                                        </div>
                                    </div>
                                    <div :id="'div' + index" :ref="'div' + index" v-if="item.type == 'max_points'" class="noticia alignVertical col-12"
                                         style="cursor: pointer;"
                                         v-on:click="reed(index,item.notification);">
                                        <div class="noticia__text alignVertical col-8">
                                            <div class="col-12">
                                                <label>{{item.time_ago}}</label>
                                                <p v-html="formatText(item.text.replaceAll('\n','<br>'))"></p>
                                            </div>
                                        </div>

                                        <div class="noticia__img alignVertical col-2">
                                        </div>
                                    </div>
                                    <div :id="'div' + index" :ref="'div' + index" v-if="item.type == 'notify_points'" class="noticia alignVertical col-12"
                                         style="cursor: pointer;"
                                         v-on:click="openNotifyPointsModal(index, item.notification_id)">
                                        <div class="noticia__text alignVertical col-8">
                                            <div class="col-10">
                                                <label>{{item.time_ago}}</label>
                                                <p v-html="formatText(item.text.replaceAll('\n','<br>'))"></p>
                                            </div>
                                        </div>

                                        <!--<div class="noticia__img alignVertical col-4">
                                            <img v-if="item.icon" :src="item.icon">
                                            <img v-else :src="'https://via.placeholder.com/300'">
                                        </div>-->
                                    </div>
                                    <!-- /noticia comum -->
                                </div>
                            </div>
                            <div class="swiper-scrollbar"></div>
                        </div>
                    </div>

                    <div class="divMessages tabInbox">
                        <section class="container-list-inbox">
                            <div class="search-input">
                                <div class="search-input-wrapper">
                                    <input class="search-input-message" type="text" :placeholder="$t('notification.searchMessage')" @keyup.enter="searchMessages" v-model="searchQuery"/>
                                    <i class="fas fa-search"></i>
                                </div>
                            </div>
                            <div v-if="privateMessagesUsers.length == 0" id="no" ref="no" class="alignVertical col-12">
                                <p>{{ $t('notification.nenhumaInboxAt') }}</p>
                            </div>
                            <div v-for="(l, index) in privateMessagesUsers" :key="index" class="card-inbox" v-on:click="openPrivateMessage(l.id)">
                                <div class="inbox-img-wrap">
                                    <img class="profile-pic" :src="l.avatar" alt="imagem de um amigo"/>
                                    <div v-if="l.online" style="padding: 2px;background-color: #0FFF35" class="info__online"></div>
                                    <div v-else style="padding: 2px;background-color: #D0D0D0;" class="info__online"></div>
                                </div>
                                <div class="inbox-info-wrap">
                                    <h4 class="inbox-name">{{ l.name }} <span class="inbox-last-message-date">{{ formatDate(l.inbox.last_message_date) }}</span></h4>
                                    <p class="inbox-last-message">{{ l.inbox.last_message }}</p>
                                </div>
                                <!-- <div v-if="l.inbox.unread > 0" class="inbox-unread"><span>{{ l.inbox.unread }}</span></div> -->
                                <div v-if="l.inbox.unread > 0" class="inbox-unread">
                                    <span v-text="l.inbox.unread > 10 ? '10+' : l.inbox.unread"></span>
                                </div>
                            </div>
                        </section>
                        <div class="load-more-inbox" v-if="!is_end">
                            <button type="button" class="button__blue" v-on:click="loadInbox(offset);">{{ this.$i18n.t('pm.loadMore') }}</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div id="noticiation-content" ref="notification-content"> 

            <!-- <h3 class="tab">{{ $t('notification.notificaes') }}<a class="btn-0" ref="btn-close" v-on:click="$parent.notification = false">
                    <img :src="$url + 'assets/img/icon_close_box.png'" alt="Fechar" style="float: right;margin: 20px 25px 0 0" width="16">
                </a>
            </h3>

            <br>

            <div v-if="list.length == 0" id="no" ref="no">{{ $t('notification.nenhumaNotificaoAt') }}</div>
            <div v-else style="height: auto;max-height: 400px;overflow: auto;margin: -14px;" ref="list_">
                <div id="list_">
                    <ul>
                        <li v-for="(item, index) in list" :key="index"> -->
                            <!--
                            created_at: "2021-05-18T19:28:02.000000Z"
                            flag: "pendent"
                            icon: null
                            id: 2
                            notification_id: 1
                            notification_text: null
                            //system, vip_room_request, friend_request
                            type: "system"
                            updated_at: "2021-05-18T19:28:02.000000Z"
                            user_id: 8
                            user_request_id: null
                            -->
                            <!-- 
                            <ul v-if="item.type == 'system'" style="display: flex;align-items: center;">
                                <li>
                                    <img :src="item.icon" alt="Icon" width="60">
                                </li>
                                <li>
                                    <b>{{ item.title }}</b><br>
                                    {{ item.text.length>{{ $t('notification.itemtextsubstringitemtext') }}<br><br>
                                    {{item.time_ago}}
                                    <a v-on:click="showNotification(item)" class="float-right btn-blue" >{{ $t('notification.ver') }}</a>
                                </li>
                            </ul>
                            <ul v-else-if="item.type == 'vip_room_request'" :id="'div' + index" :ref="'div' + index" style="display: flex;align-items: center;"> -->
                                <!--
                                friend_id:
                                icon:
                                text:
                                time_ago:
                                type: "vip_room_request"
                                type_ticket_id:

                                VipRoomAccept
                                VipRoomRejec
                                -->
                                <!-- <li style="position: relative;">
                                    <img v-if="item.icon" :src="item.icon" alt="usuario" width="60" style="margin: auto 0">
                                    <img v-else :src="$url + 'assets/img/account/perfil.png'" alt="usuario" width="60" style="margin: auto 0">
                                    <img style="position: absolute;right: 12px;top: 5px;" :src="$url + 'assets/img/coroaVip.svg'" alt="coroa">
                                </li>
                                <li>
                                    {{ item.text }}<br><br>
                                    {{item.time_ago}}<br><br>
                                    <a class="btn-red" data-v="0" :data-index="index" :data-friend-id="item.friend_id" :data-ticket-id="item.type_ticket_id" v-on:click="invitationHandler">{{ $t('notification.recusar') }}</a>{{ $t('notification.nbsp') }}<a class="btn-blue" data-v="1" :data-index="index" :data-friend-id="item.friend_id" :data-ticket-id="item.type_ticket_id" v-on:click="invitationHandler">{{ $t('notification.aceitar') }}</a>
                                </li>
                            </ul>

                            <ul v-else-if="item.type == 'friend_request'" :id="'div' + index" :ref="'div' + index" style="display: flex;align-items: center;"> -->
                                <!--
                                friend_id:
                                icon:
                                text:
                                time_ago:
                                type: "vip_room_request"
                                type_ticket_id:
                                -->
                                <!-- <li>
                                    <img v-if="item.icon" :src="item.icon" alt="usuario" width="60" style="margin: auto 0">
                                    <img v-else :src="$url + 'assets/img/account/perfil.png'" alt="usuario" width="60" style="margin: auto 0">
                                </li>
                                <li>
                                    {{ item.text }}<br><br>
                                    {{item.time_ago}}
                                    <br><br>
                                    <a class="btn-red" data-v="0" :data-index="index" :data-friend-id="item.friend_id" :data-ticket-id="item.type_ticket_id" v-on:click="requestHandler">{{ $t('notification.recusar') }}</a>{{ $t('notification.nbsp') }}<a class="btn-blue" data-v="1" :data-index="index" :data-friend-id="item.friend_id" :data-ticket-id="item.type_ticket_id" v-on:click="requestHandler">{{ $t('notification.aceitar') }}</a>
                                </li>
                            </ul>
                            <ul v-else-if="item.type == 'easter_egg'" :id="'div' + index" :ref="'div' + index" style="display: flex;align-items: center;">
                                <li style="width: 100%;text-align: left;">
                                    <div v-html="item.text.replaceAll('\n','<br>')"></div><br><br>
                                    {{item.time_ago}}
                                    <br><br>
                                    <a class="float-right btn-blue" :data-index="index" v-on:click="reed(index,item.notification)" >OK</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div> -->
        </div>

        <!--
        <div v-if="alert" class="box-bg" id="alert-box" ref="alert-box">
            <div class="ani box" id="alert-box-content" ref="alert-box-content">
                <h3 class="tab">{{ $t('notification.ateno') }}<a class="btn-0" ref="btn-close" v-on:click="alert = false">
                        <img :src="$url + 'assets/img/icon_close_box.png'" alt="Fechar" style="float: right;margin: 20px 25px 0 0" width="16">
                    </a>
                </h3>
                <br><br>
                {{ status }}
                <br>
                <br>
                <div>
                    <ul>
                        <li><a class="btn-green" v-on:click="alert = false">OK</a></li>
                    </ul>
                </div>
            </div>
        </div> -->

        <div v-if="notification_actual.on" class="modals bgBlack" id="notification-box" ref="notification-box" style="display: flex !important;">
            <div style="width: 100%;height: 100%;position: fixed;top: 0;left: 0;" v-on:click="$parent.notification=false"></div>

            <div class="modalnoticia">
                <div class="modals__content alignVertical">

                    <div class="modals__content--header alignVertical">
                        <p>{{ $t('notification.notcia') }}</p>
                        <a class="btn-0" v-on:click="$parent.notification=false;" ref="btn-close">
                            <img src="assets/icons/modals/x.svg" alt="">
                        </a>
                    </div>
                    <div class="modals__content--body alignVertical">

                        <div class="modalnoticia__content alignVertical" style="align-items:flex-start">
                            <div class="col-5">
                                <img :src="notification_actual.icon">
                            </div>
                            <div class="col-7">
                                <label v-text="notification_actual.start_date"></label>
                                <p><b v-text="notification_actual.title"></b></p>
                                <div :key="index" v-for="(text,index) in notification_actual.text.split('\n')" style="overflow: auto;margin-bottom: 20px;">
                                    <p>{{text}}</p>
                                    <br>
                                </div>
                                <a class="button__red float-right" v-if="notification_actual.call_to_action"
                                        v-on:click="$parent.notification=false;"
                                        :target="this.notification_actual.same_page_cta?'_self':'_blank'"
                                        :href="notification_actual.link_cta">{{ notification_actual.name_cta }}</a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <!-- <div  class="ani box" id="notification-box-content" style="position:relative;padding-left:80px;padding-right:80px;padding-bottom:60px;border-radius:0px;margin-top:180px;background-color:var(--buttons_color);;" ref="notification-box-content">
                <h3 class="tab2" style="z-index:2;position:relative">
                    <div style="font-size:95px;color:white;margin-left:-50px;margin-top:-35px;font-style:italic;letter-spacing:0.04em;font-family:'Action NBA Cond Web light'">{{ $t('notification.notcia') }}</div>
                    <a class="btn-0" ref="btn-close" v-on:click="$parent.notification=false;">
                        <img :src="$url + 'assets/img/icon_close_box.png'" alt="Fechar" style="float: right;margin: -30px -20px 0 0" width="16">
                    </a>
                </h3>
                <img :src="$url + 'assets/img/news/linha.png'" style="position:absolute;z-index:1;top:-30px;left:-38px;width:108%">
                <div style="z-index:2;position:relative">
                    <div style="margin-top:40px">
                        <img :src="$url + 'assets/img/news/topo.png'" style="width:100%">
                    </div>
                    <div class="flex" style="margin-top:-5px;background-color:white;padding-bottom:35px;max-height: 450px;overflow: visible;">
                        <div class="col-4 alignVertical;" style="overflow:visible">
                            <div :style="`margin-left:-60px;width:250px;height:240px;background-size:cover;background-position:center center;background-image: url('${this.notification_actual.icon}')`">
                            </div>
                            <img style="margin-left:-60px" class=" img-notification" :src="this.notification_actual.icon" :alt="this.notification_actual.title"> -->
                        <!-- </div>
                        <div class="col-8" style="overflow: auto">
                            <span v-text="$t('notification.notificationactualstartdate')" style="color:#333D47;font-size:14px; font-family: 'Roboto', sans-serif"></span><br><br>
                            <div>
                                <b v-text="$t('notification.notificationactualtitle')" style="color:var(--buttons_color);;font-size:23px"></b>
                            </div>
                            <div style="margin-top:20px;padding-right:30px;">
                                <span style="font-size:18px;color:#333D47;line-height:28px;font-family: 'Roboto', sans-serif" v-html="notification_actual.text.replaceAll('\n','<br>')"></span>
                            </div>

                            <div style="margin-top:40px">
                                <a v-if="notification_actual.call_to_action" style="font-size:15px;font-weight:bold;padding:20px 40px;text-transform:uppercase" class="btn-red" v-on:click="$parent.notification=false;closeNotification(true)" :target="this.notification_actual.same_page_cta?'_self':'_blank'" :href="notification_actual.link_cta">{{ notification_actual.name_cta }}</a>
                                <a v-else class="btn-red" style="font-size:15px;font-weight:bold;padding:20px 40px;" v-on:click="$parent.notification=false;closeNotification(true)">{{ $t('notification.aceitar') }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>



    </div>
</template>

<script>
    import Swiper from 'swiper/bundle';
    import axios from 'axios';
    import { mapGetters, mapActions } from "vuex";

    export default {
        name: 'Profile',
        props: ['list'],
        computed: {
            ...mapGetters([
                'logged', 'token'
            ]),
        },
        data() {
            return {
                noticias: null,
                alert: false,
                status: '',
                index_actual: null,
                notification_actual:{
                    title:'',
                    text:'',
                    icon:'',
                    call_to_action:false,
                    same_page_cta:false,
                    name_cta:'',
                    link_cta:'',
                    start_date:'',
                    on:false
                },
                privateMessagesUsers: [],
                is_end: false,
                limit: null,
                offset: 0,
                selectedTab: 'notific',
                totalUnreadInbox: 0,
                totalUnreadNotifications: 0,
                langForDate: 'pt-BR',
                searchQuery: ''
            }
        },
        components: {

        },
        methods: {
            ...mapActions([
               'update_notifications_del'
            ]),
            closeNotification(){
                this.$parent.getNotifications();
                this.notification_actual.on = false;
            },
            showNotification(notification){
                this.notification_actual.title = notification.title;
                this.notification_actual.text = notification.text;
                this.notification_actual.icon = notification.icon;
                this.notification_actual.call_to_action = notification.call_to_action;
                this.notification_actual.name_cta = notification.name_cta;
                this.notification_actual.link_cta = notification.link_cta;
                this.notification_actual.start_date = notification.start_date;
                this.notification_actual.same_page_cta = notification.same_page_cta;
                this.notification_actual.on = true;
            },
            //----- request-handler -----//
            requestHandler(e) {
                this.index_actual = e.currentTarget.dataset.index;
                let headers = { 'Content-Type': 'application/json;charset=UTF-8', 'Access': 'application/json', language: this.$i18n.locale, Authorization : `Bearer ${this.token}`};
                let form_data = new FormData();
                form_data.append('friend_id', e.currentTarget.dataset.friendId);
                // eslint-disable-next-line no-unreachable
                axios.post(this.$url_api + ((e.currentTarget.dataset.v == '0') ? 'api/RejectRequestFriend' : 'api/AcceptRequestFriend'), form_data, { headers:headers }).then(response => this.requestHandlerResponse(response, e)).catch(error => this.requestHandlerError(error.response));
            },
            requestHandlerResponse: function (obj, e) {
                e;
                if (obj.data.status == 'success') {
                    //let pos = this.list_request.map(function(e) { return e.id; }).indexOf(Number(e.target.dataset.id));
                    if (obj.data.message == 'Solicitação recusada') {
                        //this.status = obj.data.message;
                        //this.alert = true;
                        obj.data.message = this.$i18n.t('notification.solicitacaoRecusada');
                        this.$parent.modal('success', this.$i18n.t('notification.sucesso'), obj.data.message, null, null, null, "OK");
                    } else if (obj.data.message == 'Solicitação aceita') {
                        //this.total_friend++;
                        //this.list_friend.push( this.list_request[pos]);
                        //this.status = obj.data.message;
                        //this.alert = true;
                        obj.data.message = this.$i18n.t('notification.solicitacaoAceita');
                        this.$parent.modal('success', this.$i18n.t('notification.sucesso'), obj.data.message, null, null, null, "OK");
                    }

                    this.update_notifications_del(this.index_actual);
                    // this.$refs['div' + this.index_actual][0].parentElement.style.display = 'none';

                    //this.update_user_friends(this.list_friend);
                    //this.list_request.splice(pos, 1);
                }
                this.$parent.wait(false);
                if (this.$route.name == "Friends") {
                    this.$parent.$refs['main'].loadFriend();
                    this.$parent.$refs['main'].blockedRequest();
                    this.$parent.$refs['main'].loadRequest();
                }
            },
            requestHandlerError(obj) {
                //console.log("requestHandlerError -> ", obj);
                if (obj.data.message == 'erro_desconhecido') {
                    let str = '';
                    for (let o in obj.data.errors) {
                        str += obj.data.errors[o] + '\n';
                    }
                    this.$parent.modal('error', this.$i18n.t('notification.erro'), str, null, null, null, "OK");
                }
                this.$parent.varifyLoggedError(obj.data);
                this.$parent.wait(false);
            },
            //----- \request-handler\ -----//

            //----- invitationHandler -----//
            invitationHandler(e) {
                this.index_actual = e.currentTarget.dataset.index;

                this.$parent.wait(true);
                let headers = { 'Content-Type': 'application/json;charset=UTF-8', 'Access': 'application/json', language: this.$i18n.locale, Authorization : `Bearer ${this.token}`};
                this.dataset_actual = e.currentTarget.dataset;

                //console.log(this.dataset_actual);
                let form_data = new FormData();
                form_data.append('friend_id', e.currentTarget.dataset.friendId);
                form_data.append('type_ticket', e.currentTarget.dataset.ticketId);
                axios.post(this.$url_api + ((String(this.dataset_actual.v) ==  "1") ? 'api/VipRoomAccept' : 'api/VipRoomReject'), form_data, { headers:headers }).then(response => this.invitationHandlerResponse(response)).catch(error => this.invitationHandlerError(error.response));
            },
            invitationHandlerResponse(obj)
            {
                //console.log('invitationHandlerResponse => ', obj);
                if (obj.data.status == 'success') {
                    //data.data.message = 'Convite rejeitado com sucesso.'
                    //data.data.message = 'Convite aceito com sucesso.';

                    //this.status = obj.data.data.message;
                    //this.alert = true;
                    this.$parent.modal('success', 'Sucesso!', obj.data.data.message, null, null, null, "OK");

                    this.update_notifications_del(this.index_actual);
                    // this.$refs['div' + this.index_actual][0].parentElement.style.display = 'none';

                    if (obj.data.data.message == 'Convite aceito com sucesso.')
                    {
                        if (this.$parent.checkLive())
                        {
                            /*
                            update_ticket_actual(item);
                            this.$parent.chat_not_clear = true;
                            this.$parent.chat_owner = (item.friend_nickname + ' ' + item.user_id);
                            this.$parent.updateUser('id_vip_actual', item.user_id);
                            this.$parent.updateUser('owner_vip_actual', item.friend_nickname);
                            this.$parent.chat_room=('BarVip' + item.user_id);
                            this.$parent.link('BarVip');
                            */

                           this.$parent.link('TicketPurchased');
                        }
                        else
                            this.$parent.link('TicketPurchased');
                    }
                    

                    /*
                   if (obj.data.data.message == 'Convite enviado com sucesso.') {
                       this.list_invited_friend[this.dataset_actual.index].invited = true;
                   } else if (obj.data.data.message == 'Convite cancelado com sucesso.') {
                       this.list_invited_friend[this.dataset_actual.index].invited = false;
                   }
                   */
                }
                this.$parent.wait(false);
            },
            invitationHandlerError(obj)
            {
                //console.log('invitationHandlerError -> ', obj);
                obj;
                this.$parent.varifyLoggedError(obj.data);
                this.$parent.wait(false);
            },
            //----- \invitationHandler\ -----//
            reed(index,id){
                //console.log(index, id);
                this.index_actual = index;
                this.$parent.wait(true);
                let headers = { 'Content-Type': 'application/json;charset=UTF-8', 'Access': 'application/json', language: this.$i18n.locale, Authorization : `Bearer ${this.token}`};
                let form_data = new FormData();
                form_data.append('notification', id);
                axios.post(this.$url_api + 'api/ReedNotification', form_data, { headers:headers })
                    .then(response => this.reedResponse(response)).catch(error => this.reedError(error.response));
            },
            reedResponse(obj){
                if (obj.data.status == 'success') {

                    this.update_notifications_del(this.index_actual);
                    // this.$refs['div' + this.index_actual][0].parentElement.style.display = 'none';
                }
                this.$parent.wait(false);
            },
            reedError(obj){
                obj;
                this.$parent.varifyLoggedError(obj.data);
                this.$parent.wait(false);
            },
            openPrivateMessage(friend_id, index, notification_id) {
                this.reed(index, notification_id);
                this.loadTotalUnreadMessages();
                this.$parent.notification   = false
                if( this.$parent.private_messages_friend_id != friend_id ) {
                    this.$parent.private_messages_friend_id = Number(friend_id);
                    if( !this.$parent.modal_private_messages ) {
                        this.$parent.modal_private_messages = true;
                    } else {
                        this.$parent.modal_private_messages = false;
                        // setTimeout(() => {
                        //     this.$parent.modal_private_messages = false;
                            setTimeout(() => {
                                this.$parent.modal_private_messages = true;
                            }, 100);
                        // }, 500);
                    }
                }
                else {
                    if( !this.$parent.modal_private_messages ) {
                        this.$parent.modal_private_messages = true;
                    }
                }
            },
            openNotifyPointsModal(index, notification_id) {
                this.reed(index, notification_id);
                console.log('FLUXO NOTIFY POINT 5');
                this.$parent.popUpPoints = true;
            },
            changeTab(tab){
                this.selectedTab = tab;
                let elements = document.querySelectorAll('.divMessages');
                elements.forEach(element => {
                    element.style.display = 'none';
                });

                let tabElements = document.querySelectorAll('.divMessages.' + tab);
                tabElements.forEach(tabElement => {
                        tabElement.style.display = 'block';
                });
            },
            loadInbox(offset = 0) {
                this.$parent.wait(true);
                let form_data   = new FormData();
                form_data.append('offset', offset);
                let headers = {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Access': 'application/json',
                    language: this.$i18n.locale, Authorization: `Bearer ${this.token}`
                };
                axios.post(this.$url_api + 'api/pm/loadInbox', form_data, {headers: headers})
                        .then(response => {this.loadInboxResponse(response)})
                        .catch(error => this.loadInboxError(error.response));
            },
            loadInboxResponse(obj) {
                // console.log('INBOX => ', obj.data);
                let more_inbox      = null;
                this.offset         = obj.data.pagination.offset;
                this.limit          = (this.limit) ? this.limit : obj.data.pagination.limit;
                this.is_end         = obj.data.pagination.is_end;

                // caso nao seja o primeiro load
                if( this.offset > this.limit ) {
                    more_inbox      = obj.data.users;
                    for(let i=0; i<more_inbox.length; i++) {
                        this.privateMessagesUsers.push(more_inbox[i]);
                    }
                }
                // ... é o primeiro load
                else {
                    this.privateMessagesUsers   = obj.data.users;
                }
                this.$parent.wait(false);
            },
            loadInboxError(obj) {
                console.log('loadInboxError => ', obj);
                this.$parent.wait(false);
            },
            loadTotalUnreadMessages() {
                this.$parent.wait(true);
                let form_data   = new FormData();
                let headers = {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Access': 'application/json',
                    language: this.$i18n.locale, Authorization: `Bearer ${this.token}`
                };
                axios.post(this.$url_api + 'api/pm/unreadMessages', form_data, {headers: headers})
                        .then(response => {this.loadTotalUnreadMessagesResponse(response)})
                        .catch(error => this.loadTotalUnreadMessagesError(error.response));
            },
            loadTotalUnreadMessagesResponse(obj) {
                this.totalUnreadInbox = obj.data.totalUnread;
                if(this.totalUnreadInbox > 0) {
                    this.changeTab('tabInbox');
                }
                this.$parent.wait(false);
            },
            loadTotalUnreadMessagesError(obj) {
                console.log('ERROR =>', obj);
                this.$parent.wait(false);
            },
            loadTotalUnreadNotifications() {
                this.$parent.wait(true);
                let form_data   = new FormData();
                let headers = {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Access': 'application/json',
                    language: this.$i18n.locale, Authorization: `Bearer ${this.token}`
                };
                axios.post(this.$url_api + 'api/unreadNotifications', form_data, {headers: headers})
                        .then(response => {this.loadTotalUnreadNotificationsResponse(response)})
                        .catch(error => this.loadTotalUnreadNotificationsError(error.response));
            },
            loadTotalUnreadNotificationsResponse(obj) {
                this.totalUnreadNotifications = obj.data.totalUnread;
                this.$parent.wait(false);
            },
            loadTotalUnreadNotificationsError(obj) {
                console.log('ERROR =>', obj);
                this.$parent.wait(false);
            },
            formatDate(date) {
                const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: undefined, minute: undefined };
                if(this.$parent.$i18n.locale == 'es') {
                    this.langForDate = 'es-ES';
                } else if (this.$parent.$i18n.locale == 'en') {
                    this.langForDate = 'en-US';
                } else if (this.$parent.$i18n.locale == 'fr') {
                    this.langForDate = 'fr-FR';
                } else {
                    this.langForDate = 'pt-BR';
                }
                const formattedDate = new Date(date).toLocaleString(this.langForDate, options);
                return formattedDate;
            },
            formatText(text) {
                const words = text.split(" ");
                const firstWord = words.shift();
                const formattedText =
                    '<span style="color: var(--buttons_color);font-weight: 600;">' + firstWord + "</span> " + words.join(" ");
                return formattedText;
            },
            searchMessages(offset = 0) {
                this.$parent.wait(true);
                let form_data   = new FormData();
                form_data.append('offset', offset);
                form_data.append('searchQuery', this.searchQuery);
                let headers = {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Access': 'application/json',
                    language: this.$i18n.locale, Authorization: `Bearer ${this.token}`
                };
                axios.post(this.$url_api + 'api/pm/searchInbox', form_data, {headers: headers})
                    .then(response => {this.loadInboxResponse(response)})
                    .catch(error => this.loadInboxError(error.response));
            }
        },
        created() {
            
        },
        mounted() {
            //console.log('this.list => ', this.list);
            this.noticias = new Swiper(".modalNoticias", {
                direction: "vertical",
                slidesPerView: "3",
                freeMode: true,
                scrollbar: {
                    el: ".swiper-scrollbar",
                },
                mousewheel: true,
            });
            this.loadInbox();
            this.loadTotalUnreadNotifications();
            this.loadTotalUnreadMessages();
        },
        beforeDestroy() {
            
        },
        updated() {
            this.noticias.update();
        },
    }
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap');
    .img-notification{
        height: 100%;
        max-height: 200px;
    }
    #notification-box-content
    {
       width: 800px;
        border-radius: 10px;
    }
    #noticiation-content,#alert-box-content
    {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        position: fixed;
        right: 100px;
        top: 0;
    }
    .tab
    {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    #no
    {
        box-sizing: border-box;
        padding: 30px 0;
    }

    #list_
    {
        box-sizing: border-box;
        padding: 20px;
    }
    #list_ > ul > li
    {
        border-bottom: solid 1px #e5e5e5;
        box-sizing: border-box;
        padding: 10px 0;
    }
    #list_ > ul > li > ul > li
    {
        box-sizing: border-box;
        display: inline-block;
        padding: 10px;
        vertical-align: top;
    }
    #list_ > ul > li > ul > li:nth-child(1)
    {
        background-color: #f9f9f9;
        text-align: center;
        width: 30%;
    }
    #list_ > ul > li > ul > li:nth-child(2)
    {
        width: 70%;
    }
    .divMessages {
        display: none;
    }

    .divMessages p, .divMessages h4, .modals__content--header p {
        font-family: 'Titillium Web', sans-serif !important;
    }

    .container-list-inbox {
        margin-top: 20px;
        height: 380px;
        overflow: auto;
    }

    .card-inbox {
        border-top: none;
        padding: 10px;
        display: flex;
        align-items: center;
        /* justify-content: space-between;*/
        cursor: pointer;
        position: relative;
        transition: all 0.3s ease;
        border-bottom: 1px solid #f2f2f2;
    }

    .card-inbox .inbox-img-wrap {
        position: relative;
        margin-right: 30px;
    }

    .card-inbox .inbox-img-wrap img {
        vertical-align: top;
        width: 60px;
        border-radius: 100px;
    }

    .card-inbox .inbox-img-wrap .info__online {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        position: absolute;
        top: 0;
        right: 0;
    }

    .card-inbox .inbox-info-wrap {
        max-width: calc(100% - 160px);
    }

    .card-inbox .inbox-info-wrap .inbox-name {
        margin: 0;
        color: #343434;
        transition: all 0.3s ease;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .card-inbox .inbox-info-wrap .inbox-last-message {
        margin: 0;
        font-size: 14px;
        line-height: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .card-inbox .inbox-unread {
        border-radius: 100%;
        width: 35px;
        height: 35px;
        position: absolute;
        right: 0;
        background: var(--buttons_color);
        text-align: center;
        color: #fff;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .card-inbox .inbox-unread span {
        font-family: 'Titillium Web', sans-serif;
        color: #fff;
        font-size: 15px;
        line-height: 15px;
    }

    .load-more-inbox {
        text-align: center;
        padding-top: 20px;
    }

    .load-more-inbox button {
        font-family: 'Titillium Web', sans-serif;
        font-weight: 700;
        color: #fff;
        border: 1px solid transparent;
    }

    /* HOVER */
    .card-inbox:hover {
        box-shadow: 0 6px 10px -10px rgba(0, 0, 0, .4);
    }

    .card-inbox:hover .inbox-info-wrap .inbox-name {
        color: var(--buttons_color);
    }

    .notificacoes .menuOptions {
        color: #47474766;
        justify-content: center;
        gap: 70px;
        position: relative;
        padding-top: 20px;
        padding-bottom: 15px;
    }

    .menuOptions .option {
        cursor: pointer;
        font-weight: 400;
        font-family: 'Titillium Web', sans-serif;
    }

    .menuOptions .option::after {
        content: "";
        position: absolute;
        bottom: 0px;
        left: 0;
        right: 0;
        height: 2px;
        background-color: #47474766;
    }

    .menuOptions .option-inbox::after {
        left: 50%;
        background-color: #47474766;
    }
    .menuOptions .option-notif::after {
        right: 50%;
        background-color: #47474766;
    }

    .menuOptions .option.tabActive {
        color: var(--buttons_color);
        font-weight: 700;
    }

    .menuOptions .option.tabActive::after {
        background-color: var(--buttons_color);
        height: 3px;
    }

    .notificacoes .modals__content {
        border-radius: 20px;
    }
    .notificacoes .modals__content--header {
        background-color: white;
        padding-top: 20px;
        border-radius: 20px;
    }
    .notificacoes .modals__content--header p {
        text-transform: capitalize;
        font-weight: 700;
        font-size: 24px;
        letter-spacing: initial;
        margin-top: 0px;
    }
    .tab-unread {
        border-radius: 100%;
        width: 18px;
        height: 18px;
        background: var(--buttons_color);
        color: #fff;
        font-weight: 700;
        margin-left: 10px;
        text-align: center;
        font-size: 12px;
        line-height: 18px;
    }

    .inbox-last-message-date{
        color: #47474780;
        font-size: 11px;
        margin-left: 5px;
    }

    .search-input {
        text-align: center;
        margin-bottom: 15px;
    }
    .search-input-wrapper {
        position: relative;
        display: inline-block;
        width: 100%;
    }
    .search-input-message {
        border-radius: 30px;
        padding: 10px;
        width: 80%;
        border: 1px solid #47474733;
        color: #474747;
        font-size: 12px;
        font-family: 'Titillium Web', sans-serif;
    }

    .search-input-message::placeholder {
        color: #47474733;
    }

    .search-input-message:focus-visible, .search-input-message:focus {
        border-color: #47474733;
        outline-color: #47474733;
    }

    .search-input-wrapper .fas.fa-search {
        position: absolute;
        top: 50%;
        right: 40px;
        transform: translateY(-50%);
        color: var(--buttons_color);
    }

    @media screen and (max-width: 600px) {
        .container-my-inbox {
            padding: 0 15px;
        }

        .container-title-inbox {
            padding: 0;
        }

        .card-inbox {
            padding: 10px 0;
        }

        .card-inbox .inbox-img-wrap {
            margin-right: 20px;
        }

        .card-inbox .inbox-info-wrap {
            max-width: calc(100% - 130px);
        }
    }
</style>