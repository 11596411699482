<template>
    <div v-if="logged" id="vt">
        <div id="cart-controller-header">
            <a id="cart-icon" :to="{ name:'carrinho' }" v-on:click="cart=true"><img alt="Meu carrinho" :src="$url + 'assets/img/oracle/cart-32.png'" style="cursor: pointer"></a>
            <span id="cart-info">{{ counter }}</span>
        </div>
        <!--<a href="#" v-on:click="teste">teste</a>-->


        <Cart v-if="cart" ref="cart"></Cart>
        <Orders v-if="orders"></Orders>
        <Product v-if="product" :product_sku="product"></Product>
        <AddSuccess v-if="add_success"></AddSuccess>
        <UserAddress v-if="user_address"></UserAddress>
        <KashStore ref="kash-store"></KashStore>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AddSuccess               from '@/components/vt/AddSuccess';
import Cart                     from '@/components/vt/Cart';
import KashStore                from '@/components/vt/KashStore';
import Orders                   from '@/components/vt/Orders';
import Product                  from '@/components/vt/Product';
import UserAddress              from '@/components/vt/UserAddress';
    export default {
        name: "VT",
        computed: {
            ...mapGetters([
                'token', 'logged', 'counter', 'id', 'first_name', 'last_name', 'email', 'points', 'level', 'level_next', 'score_needed_to_reach', 'thumb', 'check_log'
            ]),
        },
        data() {
            return {
                add_success: null,
                cart: null,
                orders: null,
                product: null,
                user_address: null
            }
        },
        components: {
            AddSuccess, Cart, KashStore, Orders, Product, UserAddress
        },
        methods: {
            ...mapActions([
                'update', 'update_cart_products', 'update_profile', 'update_points', 'update_check_log'
            ]),

            setProduct(sku)
            {
                this.product = sku;
                console.log('this.product => ', sku);
                this.$forceUpdate();
            },

            teste()
            {
                this.$refs['kash-store'].login();
            }
        },
        mounted() {
            //this.product = '4bdd04c441';
            //this.product = '7e4ecbff62';

            let cart_store = JSON.parse(localStorage.getItem('cart_store'));
            for (let o in cart_store)
            {
                this.update_cart_products(cart_store[o]);
            }
        }
    }
</script>

<style lang="css" scoped>
    #vt
    {
        color: black;
        display: block;
        font-family: 'Roboto', sans-serif;;
        height: auto;
        left: 0;
        position: fixed;
        top: 0;
        z-index: 1000;
        width: auto;
    }

    :deep() a         { color:#000; text-decoration:none; transition: color .2s;}
    :deep() a:link    { color:#000; text-decoration:none; }
    :deep() a:visited { color:#000; text-decoration:none; }
    :deep() a:active  { color:#000; text-decoration:none; }
    :deep() a:focus   { color:#000; outline-color:#13b5eb; text-decoration:none; }
    :deep() a:hover   { color: #13b5eb; text-decoration:none; transition: color .2s;}
    :deep() ul, :deep() li, :deep() main, :deep() header, :deep() footer, :deep() menu, :deep() a
    {
        display: block;
        font-family: inherit;
        font-size: 14px;
        margin: 0;
        padding: 0;
    }
    :deep() h1, :deep() h2, :deep() h3
    {
        margin: 0;
        padding: 0;
    }
    :deep() hr
    {
        border: none;
        border-top: dashed 1px #ccc;
        box-sizing: border-box;
        padding: 10px 0;
    }

    /*---- class -----*/
    :deep() .box
    {
        background-color: #fff;
        border-radius: 14px;
        box-shadow: 2px 2px 2px rgba(0, 0, 0, .1);
        box-sizing: border-box;
        display: block;
        padding: 10px;
    }
    :deep() .riscado
    {
        text-decoration: line-through;
    }
    :deep() .fm
    {
        font-size: 13px;
    }
    :deep() .boldt
    {
        font-weight: bold !important;
    }
    /*---- \class\ -----*/


    /*----- cart -----*/
    #cart-controller-header
    {
        right: 60px;
        position: fixed;
        top: 24px;
    }
    #cart-icon
    {
        /*filter: brightness(0) invert(1);*/
        filter: invert(71%) sepia(29%) saturate(7330%) hue-rotate(157deg) brightness(97%) contrast(91%);
        position: fixed;
    }
    #cart-controller-header > span
    {
        background-color: #13b5eb;
        border-radius: 50%;
        box-sizing: border-box;
        color: white;
        font-weight: bold;
        height: 26px;
        margin-left: 25px;
        margin-top: -5px;
        padding: 5px;
        position: fixed;
        text-align: center;
        width: 26px;
    }
    /*----- \cart\ -----*/

    /*----- form -----*/
    /*----- placeholder -----*/
    :deep() ::-webkit-input-placeholder
    {
        /* Edge */
        color: #666;
    }

    :deep() :-ms-input-placeholder
    {
        /* Internet Explorer 10-11 */
        color: #666;
    }

    :deep() ::placeholder
    {
        color: #666;
    }
    /*----- \placeholder\ -----*/


    :deep() button, :deep() input, :deep() label, :deep() textarea, :deep() a
    {
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        outline: none;
    }

    :deep() textarea
    {
        resize: vertical;
    }


    :deep() label
    {
        box-sizing: border-box;
        color: #000;
        display: inline-block;
        font-family: inherit;
        letter-spacing: 1px;
        padding: 10px 0;
    }


    :deep() input[type="date"],
    :deep() input[type="datetime-local"],
    :deep() input[type="email"],
    :deep() input[type="password"],
    :deep() input[type="text"],
    :deep() input[type="number"],
    :deep() textarea
    {
        background-color: #f9f9f9;
        border: solid 1px #e5e5e5;
        border-radius: 25px;
        box-shadow: inset 2px 2px 0 rgba(0, 0, 0, .03), 0 1px 0 rgba(255, 255, 255, .5);
        box-sizing: border-box;
        color: #000;
        font-family: inherit;
        font-size: inherit;
        padding: 16px 14px 16px 14px;
        width: 100%;
    }

    :deep() input[type="date"]:focus,
    :deep() input[type="datetime-local"]:focus,
    :deep() input[type="email"]:focus,
    :deep() input[type="password"]:focus,
    :deep() input[type="text"]:focus,
    :deep() input[type="number"]:focus,
    :deep() textarea:focus
    {
        background-color: #fff;
    }


    /*----- btns -----*/
    :deep() input[type="submit"], :deep() .btn-0, :deep() .btn-1, :deep() .btn-2, :deep() .btn-3
    {
        background-color: #13b5eb;
        border: none;
        border-radius: 29px;
        box-sizing: border-box;
        color: #fff !important;
        cursor: pointer;
        display: inline-block;
        font-family: inherit;
        font-size: 15px;
        font-weight: bold;
        padding: 17px 12px;
        text-align: center;
        width: 100%;
    }
    :deep() .btn-0
    {
        background-color: #fff;
        border: solid 1px #999;
        color: #000 !important;
    }
    :deep() .btn-2
    {
        background-color: #4e4e4e;
        border: none;
        color: white !important;
        padding: 6px;
    }
    :deep() .btn-2 img
    {
        float: left;
        height: 42px;
    }
    :deep() .btn-2 span
    {
        display: inline-block;
        margin: 15px 0 0 -20px;
    }

    :deep() .btn-3
    {
        background-color: rgba(0, 0, 0, .7);
        border: none;
        color: white !important;
    }

    :deep() input[type="submit"]:focus, :deep() .btn-0:focus, :deep() .btn-facebook:focus
    {
        opacity: 1;
    }
    /*----- \btns\ -----*/


    /*----- select -----*/
    :deep() select
    {
        -webkit-appearance:none;
        appearance:none;
        background: url('../../assets/img/seta_1.png') calc(100% - 10px) calc(100% - 14px) no-repeat #f9f9f9;
        border: solid 1px #ccc;
        border-radius: 20px;
        box-shadow: 2px 2px 0 rgba(0, 0, 0, .03);
        box-sizing: border-box;
        color: inherit;
        font-family: inherit;
        font-size: inherit;
        font-weight: normal;
        
        outline: none;
        padding: 16px 40px 16px 20px;
        width: 100%;
    }
    :deep() select:focus
    {
        background-color: #fff;
        /*border: solid 1px rgba(135, 206, 235, 1);*/
    }
    /*----- \select\ -----*/



    /*----- checkbox -----*/
    :deep() .checkbox-container
    {
        box-sizing: border-box;
        padding: 10px 0;
    }
    :deep() .styled-checkbox
    {
        opacity: 0;
        position: absolute;
    }
    :deep() .styled-checkbox + label
    {
        cursor: pointer;
        letter-spacing: 0;
        padding: 0;
        position: relative;
    }
    :deep() .styled-checkbox + label:before
    {
        background-color: #f9f9f9;
        border: solid 1px #e5e5e5;
        box-shadow: 1px 1px 0 rgba(0, 0, 0, .06);
        content: '';
        display: inline-block;
        height: 16px;
        margin-right: 10px;
        vertical-align: bottom;
        width: 16px;
    }
    :deep() .styled-checkbox:focus + label:before
    {
        background-color: #fff;
        border: solid 1px #ccc;
    }
    :deep() .styled-checkbox:hover + label:before
    {
        background-color: #fff;
        border: solid 1px #ccc;
    }
    :deep() .styled-checkbox:checked + label:after
    {
        content: '';
        background-color: #fea044;
        position: absolute;
        left: 5px;
        top: 5px;
        height: 8px;
        width: 8px;
    }
    :deep() .styled-checkbox:checked + label:before
    {
        background-color: #fff;
    }
    :deep() .styled-checkbox:disabled + label
    {
        color: #b8b8b8;
        cursor: auto;
    }
    :deep() .styled-checkbox:disabled + label:before
    {
        background-color: #f5f5f5;
        border-color: #e5e5e5;
        box-shadow: none;
    }
    /*----- \checkbox\ -----*/



    /*----- radio -----*/
    :deep() .styled-radio
    {
        opacity: 0;
        position: absolute;
    }
    :deep() .styled-radio + label
    {
        cursor: pointer;
        letter-spacing: 0;
        padding: 0;
        position: relative;
    }
    :deep() .styled-radio + label:before
    {
        background-color: #f9f9f9;
        border: solid 1px #e5e5e5;
        border-radius: 50%;
        box-shadow: 1px 1px 0 rgba(0, 0, 0, .06);
        content: '';
        display: inline-block;
        height: 16px;
        margin-right: 10px;
        vertical-align: bottom;
        width: 16px;
    }
    :deep() .styled-radio:focus + label:before
    {
        background-color: #fff;
        border: solid 1px #ccc;
    }
    :deep() .styled-radio:hover + label:before
    {
        background-color: #fff;
        border: solid 1px #ccc;
    }
    :deep() .styled-radio:checked + label:before
    {
        background-color: #fff;
    }
    :deep() .styled-radio:checked + label:after
    {
        content: '';
        background-color: #bbb;
        border-radius: 50%;
        position: absolute;
        left: 5px;
        top: 5px;
        height: 8px;
        width: 8px;
    }
    :deep() .styled-radio:disabled + label
    {
        color: #b8b8b8;
        cursor: auto;
    }
    :deep() .styled-radio:disabled + label:before
    {
        background-color: #f5f5f5;
        border-color: #e5e5e5;
        box-shadow: none;
    }
    /*----- \radio\ -----*/

    /*----- \form\ -----*/

    @media screen and (max-width: 1000px)
    {
        :deep()  #cart-controller-header
        {
            right: 120px;
            zoom: .8;
        }
    }
</style>