<template>
    <div class="iframe-view" ref="iframe-view" v-if="!template_html_state">
        <iframe v-if="url && enter_local" id="iframe" ref="iframe" :src="url" frameborder="0" allowvr="yes" allow="display-capture *;camera *; microphone *;vr *; xr *; xr-spatial-tracking *; accelerometer; magnetometer; gyroscope; autoplay;" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" scrolling="no"></iframe>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axios from 'axios';
export default {
    props: ['eventName'],
    name: 'MapSession',
    computed: {
        ...mapGetters([
            'id', 'token', 'logged', 'name',"checkIOS", "checkMobile","checkSafari", 'sessions', 'map_sessions'
        ]),
        ...mapGetters({
            template_html_state: 'TemplateHTML/getHTMLTemplateLoaded'
        })
    },
    data() {
        return {
            enter_local: false,
            sessions_temp: null,
            url: null,
            url_temp: null,
        }
    },
    methods: {
        ...mapActions(['update_current_session', 'LMS/update_lms']),
        enterLocal(local, chat_room = null) {
            console.log('neterLocal => ', local);
            this.$parent.$parent.wait(true);
            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                language: this.$i18n.locale, Authorization: `Bearer ${this.token}`
            };
            axios.post(this.$url_api + 'api/credit/enter_ambient', {
                'local': local.name,
                'session_id': local.room_id,
                'chat_room': chat_room,
                'map' : true
            }, {headers: headers})
                .then(response => this.enterLocalResponse(response))
                .catch((error) => this.enterLocalError(error.response));
        },
        enterLocalResponse(obj) {
            // this.changeUrl();
            console.log('enterLocalResponse => ', obj);
            this.enter_local = true;
            obj;
            
            this.$parent.$parent.wait(false);
        },
        enterLocalError(obj) {
            obj;
            console.log('enterLocalError => ', obj);
            /*
            if( obj.status === 401 && obj.data.message.modal === 'no_ticket' ) {
                this.modal_no_ticket = true;
            } 
            else if( obj.status === 401 && obj.data.message.modal === 'no_badge' ) {
                this.modal_no_badge = true;
                this.badge_button = obj.data.message.badge_button;
            }
            else {
                this.changeUrl();
            }
            */
            this.$parent.$parent.wait(false);
        },
        iframeURL()
        {
            this.sessions_temp = this.map_sessions;

            let id = this.sessions_temp.default;
            if (this.checkIOS && this.sessions_temp.ios)
            {
                id = this.sessions_temp.ios;
            }
            else if ((this.checkIOS || this.checkMobile) && this.sessions_temp['4gb'])
            {
                id = this.sessions_temp['4gb'];
            }
            else if ((this.checkSafari) && this.sessions_temp.safari)
            {

                console.log('checking map =>', this.$route.name == 'Map')
                id = this.sessions_temp.safari;
            }

            const sessions_ = this.sessions;
            let root_ = this;
            sessions_.find(obj => {
                /*
                if (String(obj.id) === String(id))
                */

                console.log('checking map =>', this.$route.name == 'Map')
                if (String(obj.room_id) === String(id))
                {
                    console.log('entrou => ', obj);
                    if (obj.privacity == 2) {
                        root_.url_temp = obj.url
                        let password = root_.$parent.$parent.getCookie('map')
                        if (root_.url_temp.indexOf("?") !== -1)
                            root_.url_temp += `&chat_token=${root_.token}`;
                        else
                            root_.url_temp += `?chat_token=${root_.token}`;
                        if (password.length > 0) {
                            root_.url_temp += `&password=${password}`;
                        }

                        root_.enterLocal(obj);
                        root_.update_current_session(obj);
                        root_.$parent.$parent.sessionHandler(obj);
                        root_.url = root_.url_temp;
                        
                        // root_.$parent.$parent.sessionAccess('ask')
                    } else {
                        root_.enterLocal(obj);
                        // console.log('entrou');
                        // root_.enterLocal(obj.name);
                        root_.update_current_session(obj);
                        root_.$parent.$parent.sessionHandler(obj);
                        root_.url = obj.url;
                    }
                    if( obj.lms ) {
                        root_.$store.dispatch('LMS/update_lms', obj.lms);
                        root_.$router.push({path: `/lms/${obj.lms.slug}`});
                    }
                    
                }
            });
        }
    },
    mounted() {
        document.querySelector('html').classList.add('iframehtml');
        this.iframeURL();
        window.addEventListener("message", (e) => {
            if (e.data.data == 'block' || e.data.data == 'ask') {
                this.$parent.$parent.sessionAccess(e.data.data)
            }
        })
    },
    beforeDestroy() {
        document.querySelector('html').classList.remove('iframehtml');
        try {
            this.$refs['iframe'].src = null;
        }
        catch(e) {
            //
        }
    },
}
</script>

<style scoped>
.iframe-view{
    height: calc(100vh - 75px);
    margin-top: 75px;
}
iframe
{
    display: block;
    height: 100%;
    width: 100%;
}
@media screen and (max-width: 1000px) {
    .iframe-view {
        position: absolute;
        height: calc(100% - 121px);
        width: 100%;
        margin-top: 0;
        top: 121px;
    }
}
</style>