<template>
  <div id="profile" ref="profile">
    <div class="myPerfil">
      <div class="myPerfil__title">
        <div class="hamburger" v-on:click="menuHamburger(true)">
          <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0H24V2.6875H0V0ZM0 9.3125V6.6875H24V9.3125H0ZM0 16V13.3125H24V16H0Z" fill="var(--buttons_color)"/>
          </svg>          
        </div>
        <h1>{{ getTabTitle() }}</h1>
      </div>     
      <div class="options-bar">
        <div v-if="!this.$parent.isGuest" class="option" v-on:click="changeTab('profile')" >
          <div :class="{'icon':true, 'selected':currentTab=='profile'}">
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 50 50" fill="none">
            <path d="M8.66797 34.6836C14.3529 32.3711 19.7969 31.2148 25 31.2148C30.2031 31.2148 35.599 32.3711 41.1875 34.6836C46.8724 36.8997 49.7148 39.8385 49.7148 43.5V49.7148H0.285156V43.5C0.285156 39.8385 3.07943 36.8997 8.66797 34.6836ZM33.6719 21.3867C31.263 23.7956 28.3724 25 25 25C21.6276 25 18.737 23.7956 16.3281 21.3867C13.9193 18.9779 12.7148 16.0872 12.7148 12.7148C12.7148 9.34245 13.9193 6.45182 16.3281 4.04297C18.737 1.53776 21.6276 0.285156 25 0.285156C28.3724 0.285156 31.263 1.53776 33.6719 4.04297C36.0807 6.45182 37.2852 9.34245 37.2852 12.7148C37.2852 16.0872 36.0807 18.9779 33.6719 21.3867Z"/>
            </svg>         
          </div>
          <div class="label">{{ $t('profile.meuPerfil') }}</div>
        </div>
        <div v-if="this.switch.finance_profile_menu" class="option" v-on:click="changeTab('finance')">
          <div :class="{'icon':true, 'selected':currentTab=='finance'}">
            <svg xmlns="http://www.w3.org/2000/svg" width="56" height="55" viewBox="0 0 68 50" fill="none">
            <path d="M55.5352 31.2148V6.5C55.5352 4.76562 54.9089 3.32031 53.6562 2.16406C52.5 0.911458 51.1029 0.285156 49.4648 0.285156H6.25C4.51562 0.285156 3.02214 0.911458 1.76953 2.16406C0.613281 3.32031 0.0351562 4.76562 0.0351562 6.5V31.2148C0.0351562 32.8529 0.613281 34.2982 1.76953 35.5508C3.02214 36.707 4.51562 37.2852 6.25 37.2852H49.4648C51.1029 37.2852 52.5 36.707 53.6562 35.5508C54.9089 34.2982 55.5352 32.8529 55.5352 31.2148ZM27.7852 28.0352C26.1471 28.0352 24.6055 27.6497 23.1602 26.8789C21.8112 26.0117 20.7031 24.9036 19.8359 23.5547C18.9688 22.1094 18.5352 20.5195 18.5352 18.7852C18.5352 17.1471 18.9688 15.6536 19.8359 14.3047C20.7031 12.8594 21.8112 11.7031 23.1602 10.8359C24.6055 9.96875 26.1471 9.53516 27.7852 9.53516C29.5195 9.53516 31.0612 9.96875 32.4102 10.8359C33.8555 11.7031 34.9635 12.8594 35.7344 14.3047C36.6016 15.6536 37.0352 17.1471 37.0352 18.7852C37.0352 20.5195 36.6016 22.1094 35.7344 23.5547C34.9635 24.9036 33.8555 26.0117 32.4102 26.8789C31.0612 27.6497 29.5195 28.0352 27.7852 28.0352ZM67.9648 9.53516V43.5C67.9648 45.2344 67.3385 46.7279 66.0859 47.9805C64.9297 49.1367 63.4844 49.7148 61.75 49.7148H9.28516V43.5H61.75V9.53516H67.9648Z"/>
            </svg>            
          </div>
          <div class="label">{{ $t('profile.Financeiro') }}</div>
        </div>
        <div v-if="this.switch.ticket_gate_menu && this.switch.event_menu && this.switch.event_menu['bilheteria']" class="option" v-on:click="changeTab('buy')">
          <div :class="{'icon':true, 'selected':currentTab=='buy'}">
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="55" viewBox="0 0 44 68" fill="none">
            <path d="M37.4648 0.0351562H6.53516C4.89714 0.0351562 3.45182 0.661458 2.19922 1.91406C1.04297 3.07031 0.464844 4.51562 0.464844 6.25V61.75C0.464844 63.4844 1.04297 64.9779 2.19922 66.2305C3.45182 67.3867 4.89714 67.9648 6.53516 67.9648H37.4648C39.1029 67.9648 40.5 67.3867 41.6562 66.2305C42.9089 64.9779 43.5352 63.4844 43.5352 61.75V6.25C43.5352 4.51562 42.9089 3.07031 41.6562 1.91406C40.5 0.661458 39.1029 0.0351562 37.4648 0.0351562ZM6.53516 52.5V15.5H37.4648V52.5H6.53516ZM34.2852 30.9648V25.1836C34.2852 24.2201 33.9961 23.401 33.418 22.7266C32.8398 22.0521 32.1172 21.7148 31.25 21.7148H12.75C11.8828 21.7148 11.1602 22.0521 10.582 22.7266C10.0039 23.401 9.71484 24.2201 9.71484 25.1836V31.2539C10.4857 31.2539 11.1602 31.543 11.7383 32.1211C12.4128 32.6992 12.75 33.4219 12.75 34.2891C12.75 35.1562 12.4128 35.8789 11.7383 36.457C11.1602 37.0352 10.4857 37.3242 9.71484 37.3242V42.8164C9.71484 43.7799 10.0039 44.599 10.582 45.2734C11.1602 45.9479 11.8828 46.2852 12.75 46.2852H31.25C32.1172 46.2852 32.8398 45.9479 33.418 45.2734C33.9961 44.599 34.2852 43.7799 34.2852 42.8164V37.0352C33.5143 37.0352 32.7917 36.7461 32.1172 36.168C31.5391 35.5898 31.25 34.8672 31.25 34C31.25 33.1328 31.5391 32.4102 32.1172 31.832C32.7917 31.2539 33.5143 30.9648 34.2852 30.9648ZM23.5898 41.6602H20.4102V38.625H23.5898V41.6602ZM23.5898 35.5898H20.4102V32.4102H23.5898V35.5898ZM23.5898 29.375H20.4102V26.3398H23.5898V29.375Z"/>
            </svg>
          </div>
          <div class="label">{{ $t('profile.Bilheteria') }}</div>
        </div>
        <div v-if="this.switch.user_ticket_gate_menu" class="option" v-on:click="changeTab('mytickets')">    
          <div :class="{'icon':true, 'selected':currentTab=='mytickets'}">
            <svg xmlns="http://www.w3.org/2000/svg" width="52" height="55" viewBox="0 0 62 50" fill="none">
            <path d="M34.0352 14.1602V8.08984H27.9648V14.1602H34.0352ZM34.0352 28.0352V21.9648H27.9648V28.0352H34.0352ZM34.0352 41.9102V35.8398H27.9648V41.9102H34.0352ZM61.7852 18.7852C60.1471 18.7852 58.7018 19.4115 57.4492 20.6641C56.293 21.9167 55.7148 23.362 55.7148 25C55.7148 26.638 56.293 28.0833 57.4492 29.3359C58.7018 30.5885 60.1471 31.2148 61.7852 31.2148V43.5C61.7852 45.138 61.1589 46.5833 59.9062 47.8359C58.75 49.0885 57.3529 49.7148 55.7148 49.7148H6.28516C4.64714 49.7148 3.20182 49.0885 1.94922 47.8359C0.792969 46.5833 0.214844 45.138 0.214844 43.5V31.2148C1.94922 31.2148 3.39453 30.6367 4.55078 29.4805C5.70703 28.2279 6.28516 26.7344 6.28516 25C6.28516 23.362 5.65885 21.9167 4.40625 20.6641C3.25 19.4115 1.85286 18.7852 0.214844 18.7852V6.5C0.214844 4.76562 0.792969 3.32031 1.94922 2.16406C3.20182 0.911458 4.64714 0.285156 6.28516 0.285156H55.7148C57.4492 0.285156 58.8945 0.911458 60.0508 2.16406C61.207 3.32031 61.7852 4.76562 61.7852 6.5V18.7852Z" />
            </svg>
          </div>
          <div class="label">{{ $t('profile.MeusIngressos') }}</div>
        </div>                
      </div>

      <div :style="'display:'+(currentTab=='profile'?'block':'none')">
        <div class="myPerfil__FormEPerfil" >
          <div class="myPerfil__FormEPerfil__perfil fotoMobile">
            <img v-if="avatar_metaverse && use_avatar" :src="avatar_metaverse" alt="Avatar" />
            <img v-else-if="user_picture && !use_avatar" :src="user_picture" alt="Avatar">
            <img v-else src="assets/icons/myPerfil/avatar.svg" alt="Avatar" />
            <div class="myPerfil__FormEPerfil__perfil--edit" :title="this.$i18n.t('profile.selectAvatarFile')">
              <label for="change_avatar" style="cursor:pointer;">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M23.625 5.375L21.1875 7.8125L16.1875 2.8125L18.625 0.375C18.875 0.125 19.1875 0 19.5625 0C19.9375 0 20.25 0.125 20.5 0.375L23.625 3.5C23.875 3.75 24 4.0625 24 4.4375C24 4.8125 23.875 5.125 23.625 5.375ZM0 19L14.75 4.25L19.75 9.25L5 24H0V19Z" fill="var(--buttons_color)"/>
                </svg>
              </label>
              <input type="file" id="change_avatar" ref="profile_picture_mobile" accept="image/*" style="display:none;position:absolute;width:100%;" v-on:change="changeAvatar">
            </div>
            <div>
              <input type="checkbox" v-model="useAvatar" class="checkbox" ref="use_avatar_mobile" id="UseAvatar">
              <label for="UseAvatar">{{ $t('profile.userAvatar') }}</label>
            </div>
          </div>

          <form
            class="myPerfil__FormEPerfil__form"
            enctype="multipart/form-data"
            ref="profile-form"
            onkeydown="return event.key != 'Enter';"
          >
            <div class="form-group">
                <label>{{ $t('profile.nome') }}</label>
                <input class="text" type="text" name="name" :placeholder="$t('profile.nome')" ref="name" v-model="name" />
            </div>

            <div v-if="this.switch.signup_extra_fields.cpf" class="form-group" style="margin-top: 10px;">
            <label>{{ $t('profile.cpf') }}</label>
            <input
              class="text"
              type="text"
              name="cpf"
              :placeholder="$t('profile.cpf')"
              ref="cpf"
              :value="cpf"
              @keyup="mask_cpf"
            />
          </div>


          <div v-if="this.switch.signup_extra_fields.state" class="user-button" style="margin-top: 10px;">
            <div class="form-group">
            <label>{{ $t('profile.state') }}</label>
            <select class="text" name="state" id="state" ref="state" @change="cityHandler">
                  <option :value="state ? state : ''">{{ state }}</option>
                  <option :key="state.id" v-for="state in states" :value="state.value" :data-value="state.id">{{state.value}}</option>
              </select>
          </div>
          <div class="form-group">
            <label>{{ $t('profile.city') }}</label>
            <select class="text" name="city" id="city" ref="city">
                <option :value="city ? city : ''">{{ city }}</option>
                <option v-for="(item, index) in cities" :key="index" :value="item.name">{{ item.name }}</option>
            </select>
          </div>
          </div>

          <div v-if="this.switch.signup_extra_fields.nationality" class="user-button" style="margin-top: 10px;">
            <div class="form-group">
              <label>{{ $t('signup.nacionalidade') }}</label>
                <select name="nationality" id="nationality" ref="nationality" class="text">
                  <option v-for="(country, index) in countries" :key="index" :value="country.sigla" :data-value="country.sigla" :selected="country.sigla === nationality">{{country.nome_pais_int}}</option>
                </select>
            </div>
          </div>

            <div v-if="this.switch.signup_extra_fields.birth" class="form-group form-group__date" style="margin-top: 10px;">
              <label>{{ $t('profile.dataDeNascimento') }} <span v-if="this.switch.only_major">({{ $t('profile.only_major') }})</span></label>
              <input
                class="text"
                type="date"
                name="birth"
                :placeholder="$t('profile.dataDeNascimento')"
                ref="birth"
                :value="birth ? birth : 'Data de nascimento'"
              />
            </div>
          

          <div v-if="this.switch.signup_extra_fields.company" class="form-group" style="margin-top: 10px;">
            <label>{{ $t('profile.company') }}</label>
            <input
              class="text"
              type="text"
              name="company"
              :placeholder="$t('profile.company')"
              ref="company"
              :value="company"
            />
          </div>

          <div v-if="this.switch.signup_extra_fields.phone" class="form-group" style="margin-top: 10px;">
            <label>{{ $t('profile.phone') }}</label>
            <input
              class="text"
              type="text"
              name="phone"
              :placeholder="$t('profile.phone')"
              ref="phone"
              :value="phone"
              @keyup="mask_phone"
            />
          </div>

          <div class="form-group" style="margin-top: 10px;">
            <label>{{ $t('profile.email') }}</label>
            <input
              class="text"
              type="text"
              name="email"
              :placeholder="$t('profile.email')"
              ref="email"
              :value="email"
              readonly
            />
          </div>

          <div class="user-button">
            <div class="user-button--input">
              <label>{{ $t('profile.nickname') }}</label>
              <input
                class="text"
                v-model="nickname"
                v-mask="'NNNNNNNNNNNNN'"
                maxlength="13"
                type="text"
                name="nickname"
                :placeholder="$t('profile.nickname')"
                ref="nickname"
              />
            </div>

            <div class="user-button--button">
              <button
                type="button"
                class="button__red button__buttonForm"
                v-on:click="change"
              >{{ $t('profile.alterarDados') }}</button>
            </div>
          </div>
        </form>

          <div class="myPerfil__FormEPerfil__perfil">
            <img v-if="avatar_metaverse && use_avatar" :src="avatar_metaverse" alt="Avatar" />
            <img v-else-if="user_picture && !use_avatar" :src="user_picture" alt="Avatar">
            <img v-else src="assets/icons/myPerfil/avatar.svg" alt="Avatar" />

            <div v-if="!useAvatar" class="myPerfil__FormEPerfil__perfil--edit" :title="this.$i18n.t('profile.selectAvatarFile')">
              <label for="change_avatar" style="cursor:pointer;">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M23.625 5.375L21.1875 7.8125L16.1875 2.8125L18.625 0.375C18.875 0.125 19.1875 0 19.5625 0C19.9375 0 20.25 0.125 20.5 0.375L23.625 3.5C23.875 3.75 24 4.0625 24 4.4375C24 4.8125 23.875 5.125 23.625 5.375ZM0 19L14.75 4.25L19.75 9.25L5 24H0V19Z" fill="var(--buttons_color)"/>
                </svg>
              </label>
              <input type="file" id="change_avatar" ref="profile_picture" style="display:none;position:absolute;width:100%;" accept="image/*" v-on:change="changeAvatar">
            </div>
            <div>
              <input type="checkbox" v-model="useAvatar" class="checkbox" ref="use_avatar" id="UseAvatar">
              <label for="UseAvatar">{{ $t('profile.userAvatar') }}</label>
            </div>
          </div>
        </div>



        <form
          class="myPerfil__password"
          id="change-password-handler"
          ref="change-password-handler"
          onkeydown="return event.key != 'Enter';"
        >
          <label v-if="!change_password_social" class="col-12">{{ $t('profile.crieUmaSenha') }}</label>
          <div>
            <div v-if="change_password_social">
              <label class="col-12">{{ $t('profile.senha') }}</label>
              <input
                class="text"
                type="password"
                v-on:change="activeBtn"
                v-on:keydown="activeBtn"
                id="current_password"
                ref="current_password"
                name="current_password"
                :placeholder="$t('profile.senhaAtual')"
              />
            </div>
            <div>
              <label class="col-12">{{this.change_password_social ? this.$i18n.t('profile.novaSenha') : this.$i18n.t('profile.senha')}}</label>
              <input
                class="text"
                type="password"
                v-on:change="activeBtn"
                v-on:keydown="activeBtn"
                id="password"
                name="password"
                ref="password"
                :placeholder="
                  this.change_password_social ? this.$i18n.t('profile.novaSenha') : this.$i18n.t('profile.senha')
                "
              />
            </div>
            <div>
              <label class="col-12">{{ this.change_password_social?$t('profile.repetirNovaSenha'):$t('profile.repetirSenha') }}</label>
              <input
                class="text"
                type="password"
                v-on:change="activeBtn"
                v-on:keydown="activeBtn"
                id="password_confirmation"
                ref="password_confirmation"
                name="password_confirmation"
                :placeholder="
                  this.change_password_social
                    ?  $t('profile.repetirNovaSenha') 
                    :  $t('profile.repetirSenha') 
                "
              />
            </div>

            <div
              class="flex"
              :style="
                'justify-content: center;' +
                  (this.show_captcha ? '' : 'display:none')
              "
            >
              <div class="col-12">
                <div
                  id="captchaEsqueci"
                  class="g-recaptcha flex"
                  style="justify-content:center"
                  :data-sitekey="this.switch.captcha_client"
                />
              </div>
            </div>

            <div class="myPerfil__password--button">
              <button
                type="button"
                :class="
                  bntDesative
                    ? 'myPerfil__password--button--submit'
                    : 'myPerfil__password--button--desactive'
                "
                value="Alterar senha"
                v-on:click="bntDesative ? changePassword() : ''"
              >{{ $t('profile.alterarSenha') }}</button>
            </div>
          </div>
          <div class="col-12">
            <hr />
          </div>
        </form>


        <div class="myPerfil__bottomButtons">
          <button v-on:click="$parent.deleteAccount">{{ $t('profile.deletarConta') }}</button>

          <button
            class="myPerfil__bottomButtons--exit"
            v-on:click="$parent.logout"
          >{{ $t('profile.sair') }}</button>
        </div>
      </div>
    </div>

    <div :style="'display:'+(currentTab=='buy'?'block':'none')">
      <TicketNew ref="buyticket" mode="buy"></TicketNew>
    </div>
    <div :style="'display:'+(currentTab=='mytickets'?'block':'none')">
      <TicketNew ref="ticketnew" mode="my"></TicketNew>
    </div>

    <div :style="'display:'+(currentTab=='finance'?'block':'none')">
      <PackagePayments ref="packagepayment"></PackagePayments>
    </div>

    <div v-if="show_mobile_menu" id="menuMobile">
        <div style="margin-left:40px;margin-top:30px;cursor:pointer" v-on:click="menuHamburger(false)">
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.6875 9.6875V12.3125H5.4375L12.875 19.8125L11 21.6875L0.3125 11L11 0.3125L12.875 2.1875L5.4375 9.6875H21.6875Z" fill="var(--buttons_color)"/>
          </svg>
        </div>
        <div style="margin:25px 40px">
            <div class="buscador" style="display:none">
                <div style="float:left;margin-top:2px">
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.32812 9.70312C4.20312 10.5781 5.26562 11.0156 6.51562 11.0156C7.76562 11.0156 8.82812 10.5781 9.70312 9.70312C10.5781 8.82812 11.0156 7.76562 11.0156 6.51562C11.0156 5.26562 10.5781 4.20313 9.70312 3.32812C8.82812 2.45312 7.76562 2.01562 6.51562 2.01562C5.26562 2.01562 4.20312 2.45312 3.32812 3.32812C2.45312 4.20313 2.01562 5.26562 2.01562 6.51562C2.01562 7.76562 2.45312 8.82812 3.32812 9.70312ZM12.5156 11.0156L17.4844 15.9844L15.9844 17.4844L11.0156 12.5156V11.7188L10.7344 11.4375C9.54688 12.4688 8.14062 12.9844 6.51562 12.9844C4.70312 12.9844 3.15625 12.3594 1.875 11.1094C0.625 9.85938 0 8.32812 0 6.51562C0 4.70312 0.625 3.17188 1.875 1.92188C3.15625 0.640625 4.70312 0 6.51562 0C8.32812 0 9.85938 0.640625 11.1094 1.92188C12.3594 3.17188 12.9844 4.70312 12.9844 6.51562C12.9844 7.17188 12.8281 7.92188 12.5156 8.76562C12.2031 9.57812 11.8438 10.2344 11.4375 10.7344L11.7188 11.0156H12.5156Z" fill="#DFDFDF"/>
                  </svg>                  
                </div>
                <div style="float:left; margin-left:20px;margin-top:2px;width:calc(100% - 40px)">
                  <input type="text" placeholder="Buscar no menu...">
                </div>
            </div>
            <div style="margin-top:40px;">
              <div v-if="!this.$parent.isGuest" :class="{'item':true, 'selected':currentTab=='profile'}" v-on:click="changeTab('profile')">
                <div class="img">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.82031 13.6641C5.97135 12.7891 8.03125 12.3516 10 12.3516C11.9688 12.3516 14.0104 12.7891 16.125 13.6641C18.276 14.5026 19.3516 15.6146 19.3516 17V19.3516H0.648438V17C0.648438 15.6146 1.70573 14.5026 3.82031 13.6641ZM13.2812 8.63281C12.3698 9.54427 11.276 10 10 10C8.72396 10 7.63021 9.54427 6.71875 8.63281C5.80729 7.72135 5.35156 6.6276 5.35156 5.35156C5.35156 4.07552 5.80729 2.98177 6.71875 2.07031C7.63021 1.1224 8.72396 0.648438 10 0.648438C11.276 0.648438 12.3698 1.1224 13.2812 2.07031C14.1927 2.98177 14.6484 4.07552 14.6484 5.35156C14.6484 6.6276 14.1927 7.72135 13.2812 8.63281Z" fill="#AEAEAE"/>
                  </svg>                
                </div>
                <div class="txt">
                  Dados Pessoais
                </div>
              </div>
              <div :class="{'item':true, 'selected':currentTab=='finance'}" v-on:click="changeTab('finance')">
                <div class="img">
                  <svg width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M21.1484 12.3516V3C21.1484 2.34375 20.9115 1.79688 20.4375 1.35938C20 0.885417 19.4714 0.648438 18.8516 0.648438H2.5C1.84375 0.648438 1.27865 0.885417 0.804688 1.35938C0.367188 1.79688 0.148438 2.34375 0.148438 3V12.3516C0.148438 12.9714 0.367188 13.5182 0.804688 13.9922C1.27865 14.4297 1.84375 14.6484 2.5 14.6484H18.8516C19.4714 14.6484 20 14.4297 20.4375 13.9922C20.9115 13.5182 21.1484 12.9714 21.1484 12.3516ZM10.6484 11.1484C10.0286 11.1484 9.44531 11.0026 8.89844 10.7109C8.38802 10.3828 7.96875 9.96354 7.64062 9.45312C7.3125 8.90625 7.14844 8.30469 7.14844 7.64844C7.14844 7.02865 7.3125 6.46354 7.64062 5.95312C7.96875 5.40625 8.38802 4.96875 8.89844 4.64062C9.44531 4.3125 10.0286 4.14844 10.6484 4.14844C11.3047 4.14844 11.888 4.3125 12.3984 4.64062C12.9453 4.96875 13.3646 5.40625 13.6562 5.95312C13.9844 6.46354 14.1484 7.02865 14.1484 7.64844C14.1484 8.30469 13.9844 8.90625 13.6562 9.45312C13.3646 9.96354 12.9453 10.3828 12.3984 10.7109C11.888 11.0026 11.3047 11.1484 10.6484 11.1484ZM25.8516 4.14844V17C25.8516 17.6562 25.6146 18.2214 25.1406 18.6953C24.7031 19.1328 24.1562 19.3516 23.5 19.3516H3.64844V17H23.5V4.14844H25.8516Z" fill="#AEAEAE"/>
                  </svg>                  
                </div>
                <div class="txt">
                  Financeiro
                </div>
              </div>              
              <div :class="{'item':true, 'selected':currentTab=='buy'}" v-on:click="changeTab('buy')">
                <div class="img">
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 44 68" fill="none">
                  <path d="M37.4648 0.0351562H6.53516C4.89714 0.0351562 3.45182 0.661458 2.19922 1.91406C1.04297 3.07031 0.464844 4.51562 0.464844 6.25V61.75C0.464844 63.4844 1.04297 64.9779 2.19922 66.2305C3.45182 67.3867 4.89714 67.9648 6.53516 67.9648H37.4648C39.1029 67.9648 40.5 67.3867 41.6562 66.2305C42.9089 64.9779 43.5352 63.4844 43.5352 61.75V6.25C43.5352 4.51562 42.9089 3.07031 41.6562 1.91406C40.5 0.661458 39.1029 0.0351562 37.4648 0.0351562ZM6.53516 52.5V15.5H37.4648V52.5H6.53516ZM34.2852 30.9648V25.1836C34.2852 24.2201 33.9961 23.401 33.418 22.7266C32.8398 22.0521 32.1172 21.7148 31.25 21.7148H12.75C11.8828 21.7148 11.1602 22.0521 10.582 22.7266C10.0039 23.401 9.71484 24.2201 9.71484 25.1836V31.2539C10.4857 31.2539 11.1602 31.543 11.7383 32.1211C12.4128 32.6992 12.75 33.4219 12.75 34.2891C12.75 35.1562 12.4128 35.8789 11.7383 36.457C11.1602 37.0352 10.4857 37.3242 9.71484 37.3242V42.8164C9.71484 43.7799 10.0039 44.599 10.582 45.2734C11.1602 45.9479 11.8828 46.2852 12.75 46.2852H31.25C32.1172 46.2852 32.8398 45.9479 33.418 45.2734C33.9961 44.599 34.2852 43.7799 34.2852 42.8164V37.0352C33.5143 37.0352 32.7917 36.7461 32.1172 36.168C31.5391 35.5898 31.25 34.8672 31.25 34C31.25 33.1328 31.5391 32.4102 32.1172 31.832C32.7917 31.2539 33.5143 30.9648 34.2852 30.9648ZM23.5898 41.6602H20.4102V38.625H23.5898V41.6602ZM23.5898 35.5898H20.4102V32.4102H23.5898V35.5898ZM23.5898 29.375H20.4102V26.3398H23.5898V29.375Z" fill="#AEAEAE"/>
                  </svg>
                </div>
                <div class="txt">
                  Bilheteria
                </div>
              </div>              
              <div :class="{'item':true, 'selected':currentTab=='mytickets'}" v-on:click="changeTab('mytickets')">
                <div class="img">
                  <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.1484 5.89844V3.60156H10.8516V5.89844H13.1484ZM13.1484 11.1484V8.85156H10.8516V11.1484H13.1484ZM13.1484 16.3984V14.1016H10.8516V16.3984H13.1484ZM23.6484 7.64844C23.0286 7.64844 22.4818 7.88542 22.0078 8.35938C21.5703 8.83333 21.3516 9.38021 21.3516 10C21.3516 10.6198 21.5703 11.1667 22.0078 11.6406C22.4818 12.1146 23.0286 12.3516 23.6484 12.3516V17C23.6484 17.6198 23.4115 18.1667 22.9375 18.6406C22.5 19.1146 21.9714 19.3516 21.3516 19.3516H2.64844C2.02865 19.3516 1.48177 19.1146 1.00781 18.6406C0.570312 18.1667 0.351562 17.6198 0.351562 17V12.3516C1.00781 12.3516 1.55469 12.1328 1.99219 11.6953C2.42969 11.2214 2.64844 10.6562 2.64844 10C2.64844 9.38021 2.41146 8.83333 1.9375 8.35938C1.5 7.88542 0.971354 7.64844 0.351562 7.64844V3C0.351562 2.34375 0.570312 1.79688 1.00781 1.35938C1.48177 0.885417 2.02865 0.648438 2.64844 0.648438H21.3516C22.0078 0.648438 22.5547 0.885417 22.9922 1.35938C23.4297 1.79688 23.6484 2.34375 23.6484 3V7.64844Z" fill="#AEAEAE"/>
                  </svg>                
                </div>
                <div class="txt">
                  Meus Ingressos
                </div>
              </div>              
              <div class="item" v-on:click="$parent.link('Support')">
                <div class="img">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.5547 11.125C16.2839 10.3958 16.6484 9.52083 16.6484 8.5C16.6484 7.22396 16.1927 6.13021 15.2812 5.21875C14.3698 4.30729 13.276 3.85156 12 3.85156C10.724 3.85156 9.63021 4.30729 8.71875 5.21875C7.80729 6.13021 7.35156 7.22396 7.35156 8.5H9.64844C9.64844 7.88021 9.88542 7.33333 10.3594 6.85938C10.8333 6.38542 11.3802 6.14844 12 6.14844C12.6198 6.14844 13.1667 6.38542 13.6406 6.85938C14.1146 7.33333 14.3516 7.88021 14.3516 8.5C14.3516 9.11979 14.1146 9.66667 13.6406 10.1406L12.2188 11.6172C11.3073 12.6016 10.8516 13.6953 10.8516 14.8984V15.5H13.1484C13.1484 14.2969 13.6042 13.2031 14.5156 12.2188L15.5547 11.125ZM13.1484 20.1484V17.8516H10.8516V20.1484H13.1484ZM3.74219 3.79688C6.03906 1.5 8.79167 0.351562 12 0.351562C15.2083 0.351562 17.9427 1.5 20.2031 3.79688C22.5 6.05729 23.6484 8.79167 23.6484 12C23.6484 15.2083 22.5 17.9609 20.2031 20.2578C17.9427 22.5182 15.2083 23.6484 12 23.6484C8.79167 23.6484 6.03906 22.5182 3.74219 20.2578C1.48177 17.9609 0.351562 15.2083 0.351562 12C0.351562 8.79167 1.48177 6.05729 3.74219 3.79688Z" fill="#AEAEAE"/>
                  </svg>
                </div>
                <div class="txt">
                  Ajuda
                </div>
              </div>              
              <div class="item" style="margin-top:60px" v-on:click="$parent.logout">
                <div class="img">
                  <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.64844 2.85156V19.1484H12V21.5H2.64844C2.02865 21.5 1.48177 21.263 1.00781 20.7891C0.570312 20.3151 0.351562 19.7682 0.351562 19.1484V2.85156C0.351562 2.23177 0.570312 1.6849 1.00781 1.21094C1.48177 0.736979 2.02865 0.5 2.64844 0.5H12V2.85156H2.64844ZM17.8516 5.14844L23.6484 11L17.8516 16.8516L16.2109 15.1562L19.2188 12.1484H7.35156V9.85156H19.2188L16.2109 6.78906L17.8516 5.14844Z" fill="#AEAEAE"/>
                  </svg>
                </div>
                <div class="txt">
                  Sair
                </div>
              </div>              

            </div>
        </div>
    </div>
  </div>

</template>

<script>
import axios from "axios";
import countries from './../../public/js/countries.js';
import { mapActions, mapGetters } from "vuex";
import TicketNew from '@/components/TicketNew';
import PackagePayments from '@/components/PackagePayments';

export default {
  name: "Profile",
  computed: {
    ...mapGetters([
      "token",
      "change_password_social",
      "avatar",
      "switch",
      "use_avatar",
      "user_picture",
      "avatar_metaverse"
      //, 'name', 'email', 'birth_day'
    ]),
    
    useAvatar: {
      get() {
        return this.use_avatar
      },
      set (newValue) {
        this.update_use_avatar(newValue)
      }
    }

  },
  data() {
    return {
      currentTab: this.$parent.isGuest ? 'finance' : 'profile',
      show_mobile_menu: false,
      bntDesative: false,
      change_password: false,
      birth: null,
      company: null,
      phone: null,
      state: null,
      city: null,
      cpf: null,
      coins: null,
      nationality: null,
      current_team_id: null,
      daily_point: null,
      email: null,
      name: null,
      nba_team: null,
      nickname: null,
      points: null,
      profile_photo_path: null,
      profile_photo_url: null,
      show_captcha: false,
      cities: null,
      states:[
                {id:1,value:"Acre",capital:"Rio Branco"},{id:2,value:"Alagoas",capital:"Maceió"},{id:3,value:"Amapá",capital:"Macapá"},
                {id:4,value:"Amazonas",capital:"Manaus"}, {id:5,value:"Bahia",capital:"Salvador"}, {id:6,value:"Ceará",capital:"Fortaleza"},
                {id:7,value:"Distrito Federal",capital:"Brasília"}, {id:8,value:"Espírito Santo",capital:"Vitória"},
                {id:9,value:"Goiás",capital:"Goiânia"}, {id:10,value:"Maranhão",capital:"São Luís"},
                {id:11,value:"Mato Grosso",capital:"Cuiabá"}, {id:12,value:"Mato Grosso do Sul",capital:"Campo Grande"},
                {id:13,value:"Minas Gerais",capital:"Belo Horizonte"}, {id:14,value:"Pará",capital:"Belém"},
                {id:15,value:"Paraíba",capital:"João Pessoa"}, {id:16,value:"Paraná",capital:"Curitiba"},
                {id:17,value:"Pernambuco",capital:"Recife"}, {id:18,value:"Piauí",capital:"Teresina"},
                {id:19,value:"Rio de Janeiro",capital:"Rio de Janeiro"},
                {id:20,value:"Rio Grande do Norte",capital:"Natal"}, {id:21,value:"Rio Grande do Sul",capital:"Porto Alegre"},
                {id:22,value:"Rondônia",capital:"Porto Velho"}, {id:23,value:"Roraima",capital:"Boa Vista"},
                {id:24,value:"Santa Catarina",capital:"Florianópolis"}, {id:25,value:"São Paulo",capital:"São Paulo"},
                {id:26,value:"Sergipe",capital:"Aracaju"}, {id:27,value:"Tocantins",capital:"Palmas"},
            ],
      countries: countries,
      upload_profile_file: null,
    };
  },
  watch: {
    use_avatar(newValue) {
      this.update_use_avatar(newValue)
    },
    user_picture(newValue) {
      this.update_user_picture(newValue)
    }
  },
  components: {
    TicketNew,
    PackagePayments
  },
  methods: {
    ...mapActions([
      "update_nickname",
      "update_name",
      "update_change_password_social",
      "update_cpf",
      "update_birth",
      "update_company",
      "update_phone",
      "update_state",
      "update_nationality",
      "update_city",
      "update_use_avatar",
      "update_user_picture"
    ]),
    changeTab(tab){
      this.menuHamburger(false);
      this.currentTab = tab;
      if (tab == 'mytickets')
        // this.$refs.ticketnew.loadTickets();
        this.$refs.ticketnew.loadUserTickets();
      if (tab == 'buy')
        this.$refs.buyticket.loadTickets();
      if (tab == 'finance')
        this.$refs.packagepayment.getPayments();
    },
    menuHamburger(open){
      this.show_mobile_menu = open;
    },
    getTabTitle()
    {
      switch(this.currentTab)
      {
        case 'profile': return this.$i18n.t('profile.meuPerfil'); 
        case 'finance': return this.$i18n.t('profile.Financeiro'); 
        case 'buy': return this.$i18n.t('profile.Bilheteria'); 
        case 'mytickets': return this.$i18n.t('profile.MeusIngressos'); 
      }
    },
    cityHandler(e)
      {
          let form_data = new FormData();
          let root = this;
          let state;
          if (e) {
              form_data.append('uf', this.$refs['state'].options[e.currentTarget.selectedIndex].dataset.value);
              state = document.getElementById('state').value;
          }else if (root.state){
              state = this.states.find(_ => _.value == root.state).value;
              if(state) form_data.append('uf', this.states.find(_ => _.value == root.state).id);
              else return;
          }else return;
          let headers = { "Content-Type": "application/json;charset=UTF-8", Access: "application/json", language: this.$i18n.locale, Authorization: `Bearer ${this.token}` };
          axios.post(this.$url_api + 'api/store/get_cities', form_data, { headers: headers }).then(response => this.cityHandlerResponse(response, state));
          if (e)
              e.preventDefault();
      },
      cityHandlerResponse(r, s)
      {
        const citySelect = this.$refs.city;
        citySelect.selectedIndex = -1;
          let data = r.data;
          let state = s;
          let capital = this.states.find(_=>_.value===state).capital;
          const capitalArray = data.filter(_=>_.name===capital)
          data = data.filter(_=>_.name!==capital)
          this.cities = [...capitalArray,...data];
      },

    changePasswordHandler(e) {
      e;
      this.change_password = !this.change_password;
    },
    changePassword(e) {
      e;
      this.$parent.wait(true);
      let headers = {
        "Content-Type": "application/json;charset=UTF-8",
        Access: "application/json",
        language: this.$i18n.locale, Authorization: `Bearer ${this.token}`,
      };
      let form_data = new FormData();
      if (this.change_password_social)
        form_data.append(
          "current_password",
          this.$refs["current_password"].value
        );
      form_data.append("password", this.$refs["password"].value);
      form_data.append(
        "password_confirmation",
        this.$refs["password_confirmation"].value
      );
      axios
        .post(this.$url_api + "api/user/changePassword", form_data, {
          headers: headers,
        })
        .then((response) => this.changePasswordResponse(response))
        .catch((error) => this.changePasswordError(error.response));
    },
    // getSetup() {
    //         axios
    //             .get(this.$url_api + "api/setup", {headers: {'language': this.$i18n.locale,}})
    //             .then((response) => this.getSetupResponse(response));
    //     },
    //     getSetupResponse(data) {
    //         this.show_captcha = (data && data.data && data.data.captcha && data.data.captcha == 1);
    //         this.$parent.tabname = data.data.Nome_Aba;
    //         document.title = this.$parent.tabname + ' - ' + this.$route.meta.title;
    //         this.update_switch(data.data);
    //     },
    changePasswordResponse(obj) {
      //console.log('changePasswordResponse -> ', obj);
      if (obj.data.status == "success") {
        //this.status = "Sua senha foi alterada com sucesso";
        if (this.change_password_social)
          this.$refs["current_password"].value = "";
        this.$refs["password"].value = "";
        this.$refs["password_confirmation"].value = "";
        this.update_change_password_social(true);
        this.$parent.modal(
          "success",
          this.$i18n.t('profile.sucesso'),
          this.$i18n.t('profile.suaSenhaFoi'),
          null,
          null,
          null,
          "OK"
        );
      }
      this.$parent.wait(false);
    },
    changeAvatar(event) {
      const reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload = () => {
        this.update_user_picture(reader.result);
        this.upload_profile_file = event.target.files[0];
      }
    },
    changePasswordError(obj) {
      //console.log('changePasswordError -> ', obj);
      let str = "";
      for (let o in obj.data.errors) {
        for (let b in obj.data.errors[o]) str += obj.data.errors[o][b] + "\r";
      }
      this.$parent.wait(false);
      this.$parent.modal("error", this.$i18n.t('profile.erro'), str, null, null, null, "OK");
    },
    //----- user -----//
    userLoadEvents() {
      this.$parent.wait(true);
      let headers = {
        "Content-Type": "application/json;charset=UTF-8",
        Access: "application/json",
        language: this.$i18n.locale, Authorization: `Bearer ${this.token}`,
      };
      axios
        .get(this.$url_api + "api/user", { headers: headers })
        .then((response) => this.userLoadResponse(response))
        .catch((error) => this.userLoadError(error.response));
    },
    userLoadResponse(obj) {
      console.log('userLoadResponse -> ', obj);
      this.birth = (obj.data.birth) ? obj.data.birth.split('/').reverse().join('-') : null;      
      this.coins = obj.data.coins ?? 0;
      this.current_team_id = obj.data.current_team_id ?? null;
      this.daily_point = obj.data.daily_point ?? 0;
      this.company = obj.data.company ?? null;
      this.phone = (obj.data.phone) ? this.mask_phone(false, obj.data.phone) : null;
      this.cpf = (obj.data.cpf) ? this.mask_cpf(false, obj.data.cpf) : null;
      this.city = obj.data.city ?? null;
      this.nationality = obj.data.nationality ?? null;
      this.state = obj.data.state ?? null;
      this.nba_team = obj.data.nba_team ?? null;
      this.points = obj.data.points ?? 0;
      this.profile_photo_path = obj.data.profile_photo_path ?? null;
      this.profile_photo_url = obj.data.profile_photo_url ?? null;

      this.name = obj.data.name;
      this.nickname = obj.data.nickname;
      this.email = obj.data.email;
      
      this.$parent.wait(false);
    },
    userLoadError(obj) {
      //console.log('userError -> ', obj);
      obj;
      this.$parent.wait(false);
    },
    //----- \user\ -----//

    activeBtn() {
      let current_password = this.$refs["current_password"];
      let password = this.$refs["password"];
      let password_confirmation = this.$refs["password_confirmation"];

      if (current_password != null && current_password.value.length == 0) {
        this.bntDesative = false;

        return;
      }

      if (password != null && password.value.length == 0) {
        this.bntDesative = false;

        return;
      }

      if (
        password_confirmation != null &&
        password_confirmation.value.length == 0
      ) {
        this.bntDesative = false;

        return;
      }

      this.bntDesative = true;
    },

    //----- change -----//
    change(e) {
      e.disabled = true;
      this.$parent.wait(true);
      let headers = {
        "Content-Type": "application/json;charset=UTF-8",
        Access: "application/json",
        language: this.$i18n.locale, Authorization: `Bearer ${this.token}`,
      };

      let form_data = new FormData();
      if (this.switch.signup_extra_fields.cpf) {
        form_data.append('cpf',this.just_number(this.$refs["cpf"].value));
      }
      if (this.switch.signup_extra_fields.birth) {
        form_data.append('birth', this.$refs["birth"].value);
      }
      if (this.switch.signup_extra_fields.state) {
        form_data.append('state', this.$refs["state"].value);
        form_data.append('city', this.$refs["city"].value);
      }

      if (this.switch.signup_extra_fields.nationality) {
        form_data.append('nationality', this.$refs["nationality"].value);
      }

      if (this.switch.signup_extra_fields.company) {
        form_data.append('company', this.$refs["company"].value);
      }
      if (this.switch.signup_extra_fields.phone) {
        form_data.append('phone', this.just_number(this.$refs["phone"].value));
      }

      if (this.$parent.isMobile){
        form_data.append('use_avatar', this.$refs['use_avatar_mobile'].checked)
        form_data.append('profile_picture', !this.$refs['use_avatar_mobile'].checked ? this.upload_profile_file : '')
      }
      else{
        form_data.append('use_avatar', this.$refs['use_avatar'].checked)
        form_data.append('profile_picture', !this.$refs['use_avatar'].checked ? this.upload_profile_file : '')
      }
      form_data.append('name', this.$refs["name"].value);
      form_data.append('nickname', this.$refs["nickname"].value);
      
      axios.post(this.$url_api + "api/user", form_data, {
          headers: headers,
      })
      .then((response) => this.changeResponse(response))
      .catch((error) => this.changeError(error.response));
    },
    changeResponse(obj) {
      if (obj.data.status == "success") {
        this.name = this.$refs["name"].value;
        this.nickname = this.$refs["nickname"].value;
        if(this.switch.signup_extra_fields.cpf){
          this.cpf = this.$refs["cpf"].value;
          this.update_cpf(this.$refs["cpf"].value);
        }
        if(this.switch.signup_extra_fields.company){
          this.company = this.$refs["company"].value;
          this.update_company(this.$refs["company"].value);
        }
        if(this.switch.signup_extra_fields.phone){
          this.phone = this.$refs["phone"].value;
          this.update_phone(this.$refs["phone"].value);
        }
        if(this.switch.signup_extra_fields.birth){
          this.birth = this.$refs["birth"].value;
          this.update_birth(this.$refs["birth"].value);
        }
        if(this.switch.signup_extra_fields.state) {
          this.state = this.$refs["state"].value;
          this.city = this.$refs["city"].value;
          this.update_state(this.$refs["state"].value);
          this.update_city(this.$refs["city"].value);
        }

        if (this.switch.signup_extra_fields.nationality) {
          this.nationality = this.$refs["nationality"].value;
          this.update_nationality(this.$refs["nationality"].value);
        }

        this.update_nickname(this.$refs["nickname"].value);
        this.update_name(this.$refs["name"].value);

        this.$parent.updateUser("email", this.$refs["email"].value);
        this.$parent.updateUser("nickname", this.$refs["nickname"].value);

        //this.status = "Seus dados pessoais foram alterados com sucesso.";
        this.$parent.modal(
          "status",
          this.$i18n.t('profile.sucesso'),
          this.$i18n.t('profile.seusDadosPessoais'),
          null,
          null,
          null,
          "OK"
        );
      }
      this.$parent.wait(false);
    },
    changeError(obj) {
      let str = "";
      for (let o in obj.data.errors) {
        str += obj.data.errors[o] + "\r";
      }
      this.$parent.wait(false);
      this.$parent.modal("error", this.$i18n.t('profile.erro'), str, null, null, null, "OK");
    },
    //----- \change\ -----//
    wait(s){
      this.$parent.wait(s);
    },
    mask_phone(e, value)
    {
        if (e)
        {
            this.check(e);
            let x = this.just_number(e.currentTarget.value).match(/(\d{0,2})(\d{0,5})(\d{0,5})/);
            e.currentTarget.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '' + x[3] : '');
        }
        else if (value)
        {
            let x = this.just_number(value).match(/(\d{0,2})(\d{0,5})(\d{0,5})/);
            return !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '' + x[3] : '');
        }
        else
        {
            return null;
        }
    },
    mask_cpf(e, value)
    {
        if (e)
        {
            this.check(e);
            let v = this.just_number(e.currentTarget.value).match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/);
            e.currentTarget.value = !v[2] ? v[1] : v[1] + '.' + v[2] + (v[3] ? '.' + v[3] : '') + (v[4] ? '-' + v[4] : '');
        }
        else if (value)
        {
            let v = this.just_number(value).match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/);
            return !v[2] ? v[1] : v[1] + '.' + v[2] + (v[3] ? '.' + v[3] : '') + (v[4] ? '-' + v[4] : '');
        }
        else
        {
            return null;
        }
    },    
    just_number(v)
    {
        return v.replace(/\D/g, '');
    },
    check(e)
    {
        if ((e.key !== "Backspace") || (e.key !== "ArrowLeft") || (e.key !== "ArrowRight") || (e.key !== "Tab") || (e.key !== "Delete"))
        {
            if (!/^[0-9]*$/.test(e.key))
            {
                e.preventDefault();
                return false;
            }
        }
    },

  },
  created() {},
  mounted() {
    console.log('user', this.user)
    console.log('userPicture ',this.userPicture)
    console.log('useAvatar', this.useAvatar);
    this.$parent.analytics("visit_my_profile");
    this.userLoadEvents();   
    let uri = this.$route.fullPath.split('?')[1]; 
    let params = new URLSearchParams(uri);
    if(params.get('tab') == 'buy') {
      this.changeTab('buy');
    }
    /*if(this.$route.params.tab == 'finance') {
      this.changeTab('finance');
    }*/
    if (this.$route && this.$route.meta && this.$route.meta.tab)
      this.changeTab(this.$route.meta.tab);
  },
  beforeDestroy() {},
};
</script>

<style scoped>
#change-password-handler-content > ul > li {
  box-sizing: border-box;
  list-style: none;
  padding: 10px;
}

.btn-blue,
.btn-red {
  display: block;
  letter-spacing: 1px;
  padding: 15px 10px;
  width: 100%;
}

.checkbox {
  width: 15px;
  height: 15px;
}
</style>
