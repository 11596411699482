<template>
    <div class="modals bgAzul" id="email-confirmation" ref="email-confirmation">
        <div v-on:click="$parent.logoutConfirmation(1)"
             style="z-index: -1;width: 100%; height: 100%; position: fixed; top: 0px; left: 0px;"></div>
        <div class="email-confirmation">
            <div class="modals__content">
                <div class="modals__content--header alignVertical">
                    <p>{{ $t('emailconfirmation.validarConta') }}</p>
                    <a class="btn-0" v-on:click="$parent.logoutConfirmation(1)" ref="btn-close">
                        <img src="assets/icons/modals/x.svg" alt="">
                    </a>
                </div>
                <form class="modals__content--body flex flex-wrap" id="email-confirmation-form"
                      ref="email-confirmation-form" v-on:submit="send">
                    <div class="col-12 flex">
                        <p v-if="this.type_validation==='sms'">{{ $t('emailconfirmation.umCdigoFoiSms') }}</p>
                        <p v-else>{{ $t('emailconfirmation.umCdigoFoiEmail') }}</p>
                    </div>
                    <div class="col-12 flex">
                        <input class="col-12 text" id="email_code" name="email_code"
                               :placeholder="$t('emailconfirmation.cdigo')" ref="email_code" type="text"
                               :value="codeNumber ? codeNumber : ''">
                    </div>

                    <div class="col-12 flex alinItem">
                        <div class="col-12 flex">
                            <a href="#" v-on:click="sendAgain"
                               ref="btn-reenviar">{{ $t('emailconfirmation.reenviarCdigo') }}</a>
                        </div>
                    </div>

                    <div class="email-confirmation__button flex col-12">
                        <input class="button__buttonForm button__red col-12" ref="btn-submit" type="submit"
                               :value="btn_submit_value">
                    </div>
                    <p class="col-12" style="text-align:center" v-if="this.type_validation!=='sms'">
                        {{ $t('emailconfirmation.verifySpam') }}</p>
                </form>
                <div class="modals__content--footer flex">

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import {mapActions, mapGetters} from "vuex";

export default {
    props: ['codeNumber'],
    name: 'EmailConfirmation',
    computed: {
        ...mapGetters([
            'logged', 'token', 'type_validation'
        ])
    },
    data() {
        return {
            btn_submit: false,
            btn_submit_value: this.$i18n.t('emailconfirmation.confirmar'),
            error: null,
            message: null
        }
    },
    components: {},
    methods: {
        ...mapActions([
            'update_email_verified'
        ]),
        block(v) {
            this.btn_submit_value = (v ? (this.$i18n.t('emailconfirmation.aguarde')) : this.$i18n.t('emailconfirmation.entrar'));
            this.$refs["btn-submit"].style.pointerEvents = (v ? 'none' : 'auto');
            this.$refs["btn-submit"].style.opacity = (v ? '.4' : '1');
            this.$refs['btn-reenviar'].style.pointerEvents = (v ? 'none' : 'auto');
            this.$refs['btn-reenviar'].style.opacity = (v ? '.4' : '1');
        },
        send(e) {
            this.error = null;
            if (this.$refs["email_code"].value.length == 0) {
                this.error = this.$i18n.t('emailconfirmation.oCampoCodigo');
            }

            if (this.error) {
                this.$parent.modal('error', this.$i18n.t('emailconfirmation.erro'), this.error, null, null, null, "OK");
                e.preventDefault();
            } else {
                this.sendRemember(e);
            }
        },
        sendRemember(e) {
            if (!this.submit) {
                this.block(true);

                let headers = {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Access': 'application/json',
                    language: this.$i18n.locale,
                    Authorization: `Bearer ${this.token}`
                };
                let form_data = new FormData(this.$refs["email-confirmation-form"]);
                axios.post(this.$url_api + 'api/registervalidate', form_data, {headers: headers}).then(response => this.rememberResponse(response)).catch(error => this.rememberError(error.response));
            }
            e.preventDefault();
        },
        rememberResponse(obj) {
            if (obj.data.status == 'success') {
                this.update_email_verified(true);
                this.$parent.updateUser('email_verified', true);
                this.message = obj.data.message;
                this.$parent.modal('success', this.$i18n.t('emailconfirmation.sucesso'), this.message, null, null, null, "OK");
                if (localStorage.getItem('redirectPath')) {
                    this.$router.push({path: localStorage.getItem('redirectPath')});
                    localStorage.removeItem('redirectPath');
                } else {
                    this.$parent.link('Map');
                }
            }

            this.block(false);
        },
        rememberError(obj) {
            this.block(false);
            let str = '';
            for (let o in obj.data.errors) {
                str += obj.data.errors[o] + '\n';
            }
            this.error = str;
            this.$parent.modal('error', this.$i18n.t('emailconfirmation.erro'), this.error, null, null, null, "OK");
        },

        sendAgain() {
            this.block(true);

            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                language: this.$i18n.locale,
                Authorization: `Bearer ${this.token}`
            };
            let form_data = new FormData(this.$refs["email-confirmation-form"]);
            axios.post(this.$url_api + 'api/resendEmail', form_data, {headers: headers}).then(response => this.sendAgainResponse(response)).catch(error => this.sendAgainError(error.response));
        },
        sendAgainResponse(obj) {
            if (obj.data.status == 'success') {
                this.message = obj.data.message;
                this.$parent.modal('success', this.$i18n.t('emailconfirmation.sucesso'), this.message, null, null, null, "OK");
            }

            this.block(false);
        },
        sendAgainError(obj) {
            let str = '';
            for (let o in obj.data.errors) {
                str += obj.data.errors[o] + '\n';
            }
            this.error = str;
            this.$parent.modal('error', this.$i18n.t('emailconfirmation.erro'), this.error, null, null, null, "OK");

            this.block(false);
        },
        verifyRemember() {
            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                language: this.$i18n.locale,
                Authorization: `Bearer ${this.token}`
            };
            axios.get(this.$url_api + 'api/registervalidateverifyed', {headers: headers}).then(response => {
                if (response.data.verifyed) {
                    this.update_email_verified(true);
                    this.$parent.updateUser('email_verified', true);
                    if (localStorage.getItem('redirectPath')) {
                        this.$router.push({path: localStorage.getItem('redirectPath')});
                        localStorage.removeItem('redirectPath');
                    } else {
                        this.$parent.link('Map');
                    }
                }
            }).catch(error => error);

        }
    },
    mounted() {
        this.verifyRemember()
        this.$refs['email_code'].focus();
    },
    updated() {
        this.btn_submit_value = this.$i18n.t('emailconfirmation.confirmar');
    },
    destroyed() {
    }
}
</script>
