<template>
    <div class="body_messages">
        <div v-if="is_loading" class="is_loading"></div>
        <div id="messages-wrap" class="messages-wrap" @scroll="onScroll">
            <div v-for="(l, index) in messages" :key="index" class="message-wrap">
                <div v-if="l.dateGroup && (index === 0 || l.dateGroup !== messages[index - 1].dateGroup)" class="message-date-header-container">
                    <div class="message-date-header">{{ l.dateGroup === (new Date()).toLocaleDateString(langForDate, { weekday: 'long', day: '2-digit', month: 'short' }) ? $i18n.t('pm.today') : l.dateGroup }}</div>
                </div>

                <div v-if="l.from_user == friend.id" class="from-friend">
                    <p class="item-message" v-html="l.message.replace(/(?:\r\n|\r|\n)/g, '<br>')"></p>
                    <p class="item-name" v-if="l.nickname">{{ friend.nickname }} <span class="item-hour-message">{{ formHour(l.created_at) }}</span></p>
                </div>
                <div v-else class="from-me">
                    <p class="item-message" v-html="l.message.replace(/(?:\r\n|\r|\n)/g, '<br>')"></p>
                    <p class="item-name" v-if="l.nickname">{{nickname}} <span class="item-hour-message">{{ formHour(l.created_at) }}</span></p>
                </div>
                <div class="clear"></div>
            </div>
            <!-- <div class="is_end" v-if="is_end">{{ this.$i18n.t('pm.start') }}</div> -->
        </div>
        <div v-if="friend" class="form">
            <textarea id="type-pm" name="message" :placeholder="$t('pm.typeMessage')" rows="1"
                @keydown.enter.exact="handleEnter()"
                @keyup.enter.exact.prevent
                @keydown.enter.shift.exact.="handleEnter('newline')"
                @keyup.enter.shift.exact.prevent
            ></textarea>
            <button id="send-pm" type="button" v-on:click="sendPM" :data-friend="friend.id">
                <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.275667 15.7402V9.91992L12.7757 8.24023L0.275667 6.56055V0.740234L17.7757 8.24023L0.275667 15.7402Z" fill="var(--buttons_color)"/>
                </svg>
            </button>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import {mapActions, mapGetters} from "vuex";

export default {
    name: 'LmsPrivateMessages',
    props: ['friend_id'],
    computed: {
        ...mapGetters([
            'token', 'nickname', 'id'
        ])
    },
    data() {
        return {
            messages: null,
            friend: null,
            flag: null,         // para agrupar as mensagens por usuario quando carrega a primeira vez
            flag2: null,        // para agrupar as mensagens por usuario quando carrega no scroll
            is_loading: false,  // pagination: para checar se está carregando mais mensagens
            is_end: false,      // pagination: para checar se chegou ao fim do total de mensagens
            limit: null,        // pagination: para saber qual é o limit vindo do back
            offset: 0,           // pagination: para o total de mensagens carregadas ate o momento
            langForDate: 'pt-BR'
        }
    },  
    components: {
        
    },
    methods: {
        ...mapActions([
            
        ]),
        formDate(date) {
            const options = {
                weekday: 'long',
                day: '2-digit',
                month: 'short',
            };
            return new Date(date).toLocaleString(this.langForDate, options);
        },
        formHour(hour) {
            const options = {
                hour: '2-digit',
                minute: '2-digit',
            };
            return new Date(hour).toLocaleTimeString(this.langForDate, options);
        },
        load(offset = 0) {
            let headers     = { 'Content-Type': 'application/json;charset=UTF-8', 'Access': 'application/json', language: this.$i18n.locale, Authorization: `Bearer ${this.token}` };
            let form_data   = new FormData();
            form_data.append('friend', this.friend_id);
            form_data.append('offset', offset);
            axios.post(this.$url_api + "api/pm/load", form_data, { headers: headers }).then((response) => this.loadResponse(response)).catch((error) => this.loadError(error.response));
        },
        loadResponse(obj) {
             // console.log(obj.data.messages);
            // console.log(obj.data.pagination);
            
            let more_messages   = null;
            this.friend         = obj.data.friend;
            this.offset         = obj.data.pagination.offset;
            this.limit          = (this.limit) ? this.limit : obj.data.pagination.limit;
            this.is_end         = obj.data.pagination.is_end;

            const processMessagesByDate = (messages) => {
                let processedMessages = [];
                let prevDateGroup = null;

                for (let i = messages.length - 1; i >= 0; i--) {
                    const currentDateGroup = this.formDate(messages[i].created_at);
                    if (prevDateGroup !== currentDateGroup) {
                        messages[i].dateGroup = currentDateGroup;
                        prevDateGroup = currentDateGroup;
                    }

                    // Verificar se a mensagem atual é a última da data para evitar adicionar a div
                    if (!messages[i + 1] || messages[i].dateGroup !== messages[i + 1].dateGroup) {
                        messages[i].isLastMessageOfDate = true;
                    } else {
                        messages[i].isLastMessageOfDate = false;
                    }

                    processedMessages.unshift(messages[i]);
                }
                return processedMessages;
            };

            // caso nao seja o primeiro load
            if( this.offset > this.limit ) {

                more_messages   = obj.data.messages;
                // agrupar as mensagens: a primeira mensagem é a mais recente... (somente quando carrega a primeira vez)
                for(let i=0; i<more_messages.length; i++) {
                    if( (this.flag2 && (this.flag2 == more_messages[i].from_user)) ) {
                        // +1 e nao -0 por causa que do css flex-direction: column-reverse;
                        if(more_messages[i+1] !== undefined) {
                            more_messages[i+1].nickname = true; // acrescentado o nickname nas mensagem (bool)
                        }
                        more_messages[i].nickname = false;
                    } else {
                        more_messages[i].nickname = true;
                    }
                    this.flag2 = more_messages[i].from_user;
                    this.messages.push(more_messages[i]);
                }
                this.messages = processMessagesByDate(this.messages);

            }
            // ... é o primeiro load
            else {

                this.messages   = obj.data.messages;
                // agrupar as mensagens: a primeira mensagem é a mais recente... (somente quando carrega a primeira vez)
                for(let i=0; i<this.messages.length; i++) {
                    if( (this.flag && (this.flag == this.messages[i].from_user)) ) {
                        // +1 e nao -0 por causa que do css flex-direction: column-reverse;
                        if(this.messages[i+1] !== undefined) {
                            this.messages[i+1].nickname = true; // acrescentado o nickname nas mensagem (bool)
                        }
                        this.messages[i].nickname = false;
                    } else {
                        this.messages[i].nickname = true;
                    }
                    this.flag = this.messages[i].from_user;
                }
                this.messages = processMessagesByDate(obj.data.messages);

            }

            this.is_loading = false;
            this.$parent.wait(false);

            setTimeout(() => {
                document.getElementById("type-pm").focus();
            }, 100);
        },
        loadError(obj) {
            console.log('loadError => ', obj);
            this.is_loading = false;
            this.$parent.wait(false);
        },
        sendPM(e) {
            e.preventDefault();
            const _root_    = this;

            console.log(_root_.$route.name);
            let button      = e.currentTarget;
            let input       = document.getElementById('type-pm');
            let friend_id   = button.dataset.friend;
            let message     = input.value.trim();
            button.setAttribute('disabled', true);
            input.setAttribute('disabled', true);
            
            if( message.length > 0 ) {
                let headers     = { 'Content-Type': 'application/json;charset=UTF-8', 'Access': 'application/json', language: this.$i18n.locale, Authorization: `Bearer ${this.token}` };
                let form_data   = new FormData();
                form_data.append('friend', friend_id);
                form_data.append('message', message);
                axios.post(this.$url_api + "api/pm/send", form_data, { headers: headers }).then((response) => this.loadSendResponse(response)).catch((error) => this.loadSendError(error.response));
            } else {
                button.removeAttribute('disabled');
                input.removeAttribute('disabled');
                input.value = '';
                input.focus();
            }
        },
        loadSendResponse(obj) {
            let button      = document.getElementById("send-pm");
            let input       = document.getElementById("type-pm");
            let message     = obj.data.message;
            button.removeAttribute('disabled');
            input.removeAttribute('disabled');
            input.value     = "";
            input.focus();
            this.createMessageElement(message, true);
        },
        loadSendError(obj) {
            console.log('loadSendError => ', obj);
            let button      = document.getElementById("send-pm");
            let input       = document.getElementById("type-pm");
            button.removeAttribute('disabled');
            input.removeAttribute('disabled');
        },
        createMessageElement(message, fromme = false) {
            let from_user   = (fromme) ? this.id : this.friend.id;
            let to_user     = (fromme) ? this.friend.id : this.id;
            let new________ = {
                'from_user' : from_user,
                'message'   : message.message,
                'created_at': message.created_at,
                'nickname'  : true,
                'to_user'   : to_user
            };
            this.offset     = this.offset+1;    // aumenta o offset quando cria a mensagem (para o pagination)

            // agrupar as mensagens: a primeira mensagem é a mais recente...
            if( (this.flag && (this.messages[0].from_user == from_user)) ) {
                this.messages[0].nickname = false;
            }
            this.flag = from_user;
            this.messages.unshift(new________);
        },
        onScroll(e) {
            const { scrollTop, offsetHeight, scrollHeight } = e.target
            if( ((Math.abs(scrollTop) + offsetHeight) >= (scrollHeight - 1)) && !this.is_loading && !this.is_end) {
                this.is_loading = true;
                this.load(this.offset);
            }
        },
        handleEnter(job = 'enter') {
            if( job === 'enter' ) {
                document.getElementById('send-pm').click();
            }
            else if ( job === 'newline' ) {
                let textarea   = document.getElementById('type-pm');
                textarea.value = `${textarea.value}\n`;
            }
        }
    },
    created() {

    },
    mounted() {
        if(this.$parent.$i18n.locale == 'es') {
            this.langForDate = 'es-ES';
        } else if (this.$parent.$i18n.locale == 'en') {
            this.langForDate = 'en-US';
        } else if (this.$parent.$i18n.locale == 'fr') {
            this.langForDate = 'fr-FR';
        } else {
            this.langForDate = 'pt-BR';
        }
        this.$parent.wait(true);
        this.load();
    },
    updated() {

    },
    beforeDestroy() {

    },
}
</script>

<style lang="scss">
    .body_messages {
        margin-top: 25px;
        margin-bottom: 25px;
        padding: 20px;
        box-sizing: border-box;
        position: relative;

        .is_loading {
            border-width: 3px;
            border-style: solid;
            width: 25px;
            height: 25px;
            top: 5px;
            top: -15px;
            left: 50%;
            position: absolute;
            border-radius: 100%;
            border-color: #33bcee;
            border-top-color: #eee;
            border-right-color: #eee;
            border-bottom-color: #eee;
            animation: ldr-spin 0.5s linear infinite;
            box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3);
        }

        .clear {
            clear: both;
        }


        .is_end {
            font-family: Hanken Grotesk;
            text-align: center;
            text-transform: uppercase;
            color: #CCCBCB;
            border-bottom: 1px solid #E1E1E1;
            padding: 10px;
            margin-bottom: 10px;
        }

        .messages-wrap {
            max-height: 60vh;
            display: flex;
            flex-direction: column-reverse;
            overflow-y: auto;
            overflow-x: hidden;
            padding: 0 5px;

            .message-wrap {
                width: 100%;

                .message-date-header-container {
                    text-align: center;
                    border-bottom: 1px solid #e1e1e1;
                    margin-bottom: 10px;
                    .message-date-header {
                        font-family: Hanken Grotesk;
                        text-align: center;
                        text-transform: capitalize;
                        color: #CCCBCB;
                        padding: 10px;
                    }
                }

                .item-name {
                    margin: 3px 0 0 0;
                    color: #474747;
                    font-family: Hanken Grotesk;
                    font-size: 12px;
                    line-height: 16px;

                    .item-hour-message {
                        color: rgba(71, 71, 71, 0.5019607843);
                    }
                }
                .from-friend {
                    text-align: left;
                    float: left;
                    width: auto;
                    max-width: 75%;
                    margin: 5px 0;

                    .item-message {
                        color: #404040;
                        background: #E3E8E7;
                        border-radius: 20px 20px 20px 0px !important;
                        margin: 0;
                        word-break: break-word;
                        padding: 20px 28px 20px 28px;
                        font-family: Hanken Grotesk;
                        font-size: 16px;
                        line-height: 20px;
                        margin: 0 !important;
                    }
                }

                .from-me {
                    text-align: right;
                    float: right;
                    width: auto;
                    max-width: 75%;
                    margin: 5px 0;

                    .item-message {
                        color: #FFFFFF;
                        background: #6954D9;
                        border-radius: 20px 20px 0px 20px !important;
                        word-break: break-word;
                        padding: 20px 28px 20px 28px;
                        font-family: Hanken Grotesk;
                        font-size: 16px;
                        line-height: 20px;
                        margin: 0 !important;
                    }
                }
            }
        }

        .form {
            display: flex;
            margin: 20px 0 0 0;
            textarea {
                width: 100%;
                padding: 13px 10px;
                box-sizing: border-box;
                resize: none;
                height: 50px;
                font-family: Hanken Grotesk;
                font-size: 14px;
                line-height: 18px;
                color: #666;
                border: 2px solid #E1E1E1;
                border-radius: 15px;
                outline: none !important;
            }

            button {
                border: none;
                padding: 10px;
                border-radius: 20px;
                font-weight: 800;
                margin-left: 10px;
                background-color: transparent;
                svg {
                    vertical-align: middle;
                }
            }
        }
    }

    @keyframes ldr-spin {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
</style>