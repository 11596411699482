<template>
  <section id="lms-course" class="container">

    <div class="row course-info">
      <div class="description col-md-6 col-sm-12">
        <div class="category-title" v-text="category.title"></div>
        <div class="course-title my-3" v-text="data.title"></div>
        <div class="course-description" v-text="data.description"></div>
      </div>
      <div class="course-thumb col-md-6 col-sm-12">
        <div v-if="data.video_url" class="course-video mt-0 mt-sm-4" @click="togglePlay">
          <video ref="courseVideo" class="video w-100 h-100" playsinline poster="">
            <source :src="data.video_url" type="video/mp4">
          </video>
          <i class="bi bi-play-circle-fill"></i>
        </div>
        <div v-else-if="data.thumbnail" class="course-thumbnail">
          <img class="thumbnail" :src="data.thumbnail" :alt="data.title">
        </div>

      </div>
    </div>


    <div class="row breadcrumb-actions mt-5">
      <nav aria-label="breadcrumb" class="col-sm-12 col-md-6">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><span href="#" v-text="category.title"></span></li>
          <li class="breadcrumb-item active"><span href="#" v-text="data.title"></span></li>
        </ol>
      </nav>
      <div class="actions-buttons col-md-6 col-sm-12 mx-0 d-flex justify-content-end gap-3">
        <button type="button" class="btn d-flex px-4 py-3">
          <i class="material-icons me-1">support_agent</i>
          Assistente
        </button>
        <button type="button" class="btn d-flex px-4 py-3">
          <span class="material-symbols-outlined me-1">recommend</span>
          Letz Indica
        </button>
        <button v-if="userCanBuy" type="button" class="btn d-flex px-4 py-3" @click="toggleToCart()">
          <span class="material-symbols-outlined me-1">shopping_cart</span>
          <span v-if="!toggleCart">Adquirir Curso</span>
          <span v-else>Retirar do carrinho</span>

        </button>
      </div>
    </div>


    <div class="row accordion mt-5" id="accordionSections">
      <div class="accordion-item" :class="section.slug" v-for="(section, idxSection) in data.sections"
           :key="idxSection">
        <div class="accordion-header" :id="`heading${section.slug}`">
          <div class="accordion-group">
            <div class="accordion-button collapsed justify-content-between" :class="section.slug" type="button"
                 data-bs-toggle="collapse"
                 :data-bs-target="`#collapse${section.slug}`" aria-expanded="false"
                 :aria-controls="`collapse${section.slug}`"
                 @click="getSectionLessons(section);">

              <div class="info d-flex align-items-center">
                <img class="section-thumb" :src="section.thumbnail" :alt="section.title">
                <div class="title-subtitle d-flex flex-column">
                  <span class="title">{{ section.title }}</span>
                  <span class="description mt-2" :title="section.description">{{ section.description }}</span>
                </div>
              </div>

              <div class="actions-section d-flex align-items-center gap-5">
                <div v-if="section.credit_coins" class="points-value d-flex flex-column align-items-center">
                  <svg width="28" height="28" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M17.6185 10.9523V13.8095C17.6185 15.0476 14.6337 16.6666 10.9518 16.6666C7.26992 16.6666 4.28516 15.0476 4.28516 13.8095V11.4285"
                        stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path
                        d="M4.56445 11.6752C5.38826 12.7686 7.93588 13.7943 10.9511 13.7943C14.633 13.7943 17.6178 12.2648 17.6178 10.9505C17.6178 10.2124 16.6778 9.4038 15.2025 8.83618"
                        stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path
                        d="M14.7611 6.19043V9.04757C14.7611 10.2857 11.7763 11.9047 8.0944 11.9047C4.4125 11.9047 1.42773 10.2857 1.42773 9.04757V6.19043"
                        stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M8.0944 9.03236C11.7763 9.03236 14.7611 7.50284 14.7611 6.18855C14.7611 4.87331 11.7763 3.33331 8.0944 3.33331C4.4125 3.33331 1.42773 4.87331 1.42773 6.18855C1.42773 7.50284 4.4125 9.03236 8.0944 9.03236Z"
                          stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <span>{{ section.credit_coins }}</span>
                </div>
                <i class="bi bi-suit-heart-fill" :class="{ 'selected': section.is_fav }" :data-id="section.id"
                   data-object="section" @click="favoriteItem($event)"></i>
                <i class="bi bi-chevron-down"></i>
              </div>

            </div>
          </div>

        </div>
        <div :id="`collapse${section.slug}`" class="accordion-collapse collapse"
             :aria-labelledby="`heading${section.slug}`" data-bs-parent="#accordionSections">
          <div class="accordion-body p-0" :key="accordionBodyKey">
            <div v-if="sectionsData[section.id] && sectionsData[section.id].lessons.length">
              <ul class="px-0">
                <li class="lesson d-flex align-items-center justify-content-between px-4 py-4"
                    v-for="(lesson, idxLesson) in sectionsData[section.id].lessons"
                    @click="openLesson(lesson, section.slug)" :key="idxLesson">
                  <div class="lesson-finished d-flex align-items-center">
                    <span v-if="lesson.is_finished" class="material-symbols-outlined">
                      done
                    </span>
                    <span v-else class="material-symbols-outlined">
                      play_circle
                    </span>
                    <span class="title ms-3">{{ idxLesson + 1 }}. {{ lesson.title }}</span>
                  </div>


                  <div v-if="lesson.credit_coins" class="points-value d-flex align-items-center"
                       :class="{'finished ': lesson.is_finished}">
                    <svg width="28" height="28" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M17.6185 10.9523V13.8095C17.6185 15.0476 14.6337 16.6666 10.9518 16.6666C7.26992 16.6666 4.28516 15.0476 4.28516 13.8095V11.4285"
                          stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path
                          d="M4.56445 11.6752C5.38826 12.7686 7.93588 13.7943 10.9511 13.7943C14.633 13.7943 17.6178 12.2648 17.6178 10.9505C17.6178 10.2124 16.6778 9.4038 15.2025 8.83618"
                          stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path
                          d="M14.7611 6.19043V9.04757C14.7611 10.2857 11.7763 11.9047 8.0944 11.9047C4.4125 11.9047 1.42773 10.2857 1.42773 9.04757V6.19043"
                          stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M8.0944 9.03236C11.7763 9.03236 14.7611 7.50284 14.7611 6.18855C14.7611 4.87331 11.7763 3.33331 8.0944 3.33331C4.4125 3.33331 1.42773 4.87331 1.42773 6.18855C1.42773 7.50284 4.4125 9.03236 8.0944 9.03236Z"
                            stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span class="ms-3">{{ lesson.credit_coins }}</span>
                  </div>
                </li>
              </ul>
            </div>

            <div v-if="!sectionsData[section.id]" class="px-4 py-4">
              <span>Carregando ...</span>
            </div>

            <div v-if="sectionsData[section.id] && sectionsData[section.id].lessons.length < 1">
              <div class="h-100 p-5 rounded-3" style="color: var(--fonts_color_2nd); background-color: var(--buttons_color); margin: 14px;">
                <h2>Estamos construindo algo novo</h2>
                <p>Estamos trabalhando nessa área para criar algo novo</p>
              </div>
            </div>

          </div>
        </div>
      </div>


    </div>


    <link rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,1,0"/>
  </section>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'LmsCourse',
  components: {},
  data() {
    return {
      categoryId: 0,
      category: {
        title: ""
      },
      data: [],
      courseVideo: null,
      sectionsData: {},
      accordionBodyKey: 0,
      userCanBuy: false,
    }
  },
  beforeMount() {

    let root_ = this;
    let courseSelected = root_.getCourseSelected;
    root_.categoryId = root_.$route.params.category;

    let payload = {
      "lmsId": root_.getLms.id,
      "courseId": courseSelected,
      "slug": root_.$route.params.courseSlug
    };
    root_.$store.dispatch("LmsCourse/getCourseInfo", payload)
        .then(data => {

          root_.data = data.course;
          let registrationStart = root_.$moment(root_.data.registration_start);
          let registrationEnd = root_.$moment(root_.data.registration_end);


          if (!data.user_courses.includes(data.course.id)) {

            if (registrationStart.isValid() && registrationEnd.isValid()) {
              root_.userCanBuy = root_.$moment().isBetween(registrationStart, registrationEnd);

            } else if (registrationStart.isValid()) {
              root_.userCanBuy = root_.$moment().isAfter(registrationStart);

            } else if (registrationEnd.isValid()) {
              root_.userCanBuy = root_.$moment().isBefore(registrationEnd);
            } else {
              root_.userCanBuy = true;
            }

          }

          root_.getCategory();

          root_.accordionBodyKey++;
        });


  },
  methods: {
    getCategory() {
      let root_ = this;
      if (root_.categoryId) {
        root_.category = root_.data.categories.filter(category => {
          return category.id == root_.categoryId;
        });
        root_.category = root_.category[0];
      } else {
        root_.category = root_.data.categories[0] ?? {title: "Sem categoria"};
      }
    },

    togglePlay() {

      if (this.$refs.courseVideo.paused) {
        this.$refs.courseVideo.play();
      } else {
        this.$refs.courseVideo.pause();
      }

      let playIcon = document.querySelector(".course-thumb .bi-play-circle-fill");
      playIcon.classList.toggle("hidden");
    },

    getSectionLessons(section) {
      let root_ = this;

      root_.sectionsData = root_.getSectionById;

      if (!root_.sectionsData[section.id]?.lessons && root_.sectionsData[section.id]?.lessons?.length === undefined) {
        let payload = {
          "slug": section.slug
        };

        root_.$store.dispatch("LmsCourse/getCourseSections", payload)
            .then(() => {
              root_.sectionsData = root_.getSectionById;
              root_.accordionBodyKey++;
            })
      } else {
        root_.accordionBodyKey++;
      }

    },
    openLesson(lesson, sectionSlug) {

      this.$router.push({
        name: "LmsLessonContent",
        params: {
          lmsSlug: this.getLms.slug,
          courseSlug: this.data.slug,
          sectionSlug: sectionSlug,
          lessonSlug: lesson.slug
        }
      });

    },
    favoriteItem(e) {
      const element = e.currentTarget;
      const objectId = element.getAttribute("data-id");
      const objectData = element.getAttribute("data-object");

      let payload = {
        "object": objectData,
        "object_id": objectId
      }
      this.$store.dispatch("LMS/favoriteItem", payload)
          .then(response => {
            this.data.sections.forEach(section => {
              console.log('Sections foreach')
              if (section.id == objectId) {
                console.log('Set Fav to', !section.is_fav)
                section.is_fav = !section.is_fav;
                response;
              }
            })
          })
          .catch(error => {
            console.log('ERROR WHEN FAVORITE SECTION', error)
          });
      e.currentTarget.classList.toggle("selected");
    },
    toggleToCart() {
      this.data["object"] = "course";
      this.data["quantity"] = 1;
      this.$store.dispatch("LmsCart/toggleItemToCart", this.data)
          .then(data => {
            console.log("then toggleToCart: ", data);
          })
          .catch(error => {
            console.error("catch toggleToCart: ", error);
          });

    }
  },
  computed: {
    ...mapGetters('LmsCourse', [
      "getCourseData", "getSectionById"
    ]),
    ...mapGetters('LMS', [
      "getLms", "getCourseSelected"
    ]),
    ...mapGetters("LmsCart", [
      "getCart"
    ]),
    toggleCart() {
      let root_ = this;
      return root_.getCart.some(course => course.item.id === root_.data.id);
    }

  },
}
</script>

<style scoped lang="scss">

#lms-course {
  margin-top: 6rem;

  #accordionSections {

    .accordion-item {
      border: none;

      &.opened {
        border-radius: 10px;
        border: 1px solid #F6F6F6;
        background: #FFF;
      }

      .accordion-collapse.collapse.show {
        border-radius: 0px 0px 10px 10px;
        border: 1px solid #F6F6F6;
      }


    }


    .accordion-header {

      .accordion-group {

        position: relative;

        .accordion-button {
          position: relative;

          &:not(.collapsed) {
            border-radius: 10px 10px 0 0;
            background: #F6F6F6;
            box-shadow: none;

            .bi-chevron-down {
              transform: rotate(180deg);
            }


          }

          &:after {
            content: none; /* Remove a seta padrão */
          }

          .points-value {
            border-radius: 20px;
            border: 1px solid #B1B1B1;
            padding: 18px 30px;
            margin-right: 60px;

            span {
              color: #B1B1B1;
              text-align: center;
              font-family: "Hanken Grotesk";
              font-size: 20px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }

            &.completed {
              svg {
                fill: #6954D9;
              }
            }

            svg {
              fill: #B1B1B1;
            }


          }

        }

        .bi-suit-heart-fill {
          cursor: pointer;
          font-size: 40px;
          color: #B1B1B1;
          top: 30%;
          z-index: 5;
          right: 80px;

          &.selected {
            color: #E82E2E;
          }
        }

        .title-subtitle {

          font-style: normal;
          line-height: normal;
          margin-left: 1.5rem;
          max-width: 90%;


          .title {
            color: #6954D9;
            font-family: "Hanken Grotesk";
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
          }

          .description {
            color: #706F6F;
            font-family: "Hanken Grotesk";
            font-size: 16px;
            font-weight: 400;
            overflow: hidden;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            display: -webkit-box;

          }

        }

      }


      .section-thumb {
        height: 112px;
        width: 200px;
        flex-shrink: 0;
        object-fit: cover;
        object-position: top center;
      }
    }

    .lesson {
      cursor: pointer;

      .material-symbols-outlined {
        color: #6954D9;
        font-size: 32px;
      }

      .title {

        color: #706F6F;
        font-family: "Hanken Grotesk";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .points-value {

        &.finished {
          svg {
            fill: #6954D9;
          }
        }
      }

      &:hover {
        background-color: #F6F6F6;
        transition: all 0.5s ease;
      }
    }

    .bi-chevron-down {
      color: #6954D9;
      font-size: 25px;
      font-style: normal;
      line-height: normal;
      transition: all 0.5s ease;
    }

    .rotate-180 {
      //transform: rotate(180deg);

    }

  }


  .course-info {

    line-height: normal;
    font-style: normal;

    .category-title {
      color: #706F6F;
      font-family: "Space Grotesk", sans-serif;
      font-size: 24px;
      font-weight: 500;
    }

    .course-title {
      color: #6954D9;
      font-family: "Space Grotesk", sans-serif;
      font-size: 44px;
      font-style: normal;
      font-weight: 700;
    }

    .course-description {
      color: #706F6F;
      font-family: "Space Grotesk", sans-serif;
      font-size: 16px;
      font-weight: 400;
      max-height: 300px;
      overflow: auto;
    }

    .play {
      display: inline-flex;
      padding: 18px 38px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 30px;
      background: #6954D9;
      cursor: pointer;
      transition: transform 0.3s ease;

      .bi-play-circle-fill {
        color: #FFF;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      span {
        color: #FFF;
        font-family: "Space Grotesk", sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      &:hover {
        box-shadow: 0 0 10px 5px rgba(112, 111, 111, 0.1882352941);
        transition: 0.3s ease;
      }

      &:active {
        transform: translateY(2px);
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
      }
    }

    .course-video {
      position: relative;
      display: inline-block;
      border-radius: 30px;
      overflow: hidden;
      cursor: pointer;

      video {
        display: block;
      }

      .bi-play-circle-fill {
        position: absolute;
        top: 25%;
        left: 45%;
        font-size: 96px;

        color: #FFFFFF;
      }
    }

    .course-thumb {

      display: flex;
      justify-content: center;


      .course-thumbnail {
        img {
          width: 100%;
          border-radius: 30px;
          object-fit: cover;
          object-position: top center;
          max-height: 430px;
        }
      }

    }

  }

  .hidden {
    display: none;
  }


  nav {
    .breadcrumb-item {

      color: #706F6F;
      font-family: "Space Grotesk", sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &.active {
        color: #6954D9 !important;
        background-color: transparent;
        font-weight: 500;
      }

      & + &::before, & + ::before {
        content: ">";
      }

    }

  }

  .breadcrumb-actions {
    .actions-buttons {
      margin-top: 0;

      .btn {
        color: #706F6F;
        font-family: "Hanken Grotesk";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        border-radius: 30px;
        background: #FFFFFF;
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);


        .material-icons, .material-symbols-outlined {
          color: #6954D9 !important;
        }

      }

    }

  }


  @media (max-width: 480px) {

    .course-thumb {
      margin-top: 15px;
    }

    .actions-buttons {
      flex-direction: column;
      gap: 20px;

      .title-subtitle {
        margin-left: 0;
      }

    }

    #accordionSections {
      .accordion-header {
        .accordion-group {
          .actions-section {
            .points-value {
              padding: 9px 15px;
            }
          }
        }
      }
    }


  }

  @media (min-width: 768px) {


  }

  @media (min-width: 1400px) {

  }


}

</style>

