<template>
    <div class="container">
        <div class="left-container">
            <div class="user_info_container">
                <div class="dropdown_menu" v-if="!canEdit && showMoreMenu">
                    <button v-if="!userInformation.friend_relation.isBlocker && !userInformation.friend_relation.isBlocked && !canEdit" class="dropdown_item" @click="friendAction('block')">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 15.6797C10.8229 15.6797 12.3854 15.0286 13.6875 13.7266C15.0156 12.3984 15.6797 10.8229 15.6797 9C15.6797 8.375 15.5365 7.65885 15.25 6.85156C14.9896 6.04427 14.6641 5.39323 14.2734 4.89844L4.89844 14.2734C6.07031 15.2109 7.4375 15.6797 9 15.6797ZM2.32031 9C2.32031 9.625 2.45052 10.3411 2.71094 11.1484C2.9974 11.9557 3.33594 12.6068 3.72656 13.1016L13.1016 3.72656C11.9297 2.78906 10.5625 2.32031 9 2.32031C7.17708 2.32031 5.60156 2.98438 4.27344 4.3125C2.97135 5.61458 2.32031 7.17708 2.32031 9ZM3.10156 3.14062C4.74219 1.5 6.70833 0.679688 9 0.679688C11.2917 0.679688 13.2448 1.5 14.8594 3.14062C16.5 4.75521 17.3203 6.70833 17.3203 9C17.3203 11.2917 16.5 13.2578 14.8594 14.8984C13.2448 16.513 11.2917 17.3203 9 17.3203C6.70833 17.3203 4.74219 16.513 3.10156 14.8984C1.48698 13.2578 0.679688 11.2917 0.679688 9C0.679688 6.70833 1.48698 4.75521 3.10156 3.14062Z" fill="currentColor"/>
                        </svg>
                        &nbsp;
                        Bloquear usuário
                    </button>
                    <button v-if="userInformation.friend_relation.isBlocker && !canEdit" class="dropdown_item" @click="friendAction('unblock')">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 15.6797C10.8229 15.6797 12.3854 15.0286 13.6875 13.7266C15.0156 12.3984 15.6797 10.8229 15.6797 9C15.6797 8.375 15.5365 7.65885 15.25 6.85156C14.9896 6.04427 14.6641 5.39323 14.2734 4.89844L4.89844 14.2734C6.07031 15.2109 7.4375 15.6797 9 15.6797ZM2.32031 9C2.32031 9.625 2.45052 10.3411 2.71094 11.1484C2.9974 11.9557 3.33594 12.6068 3.72656 13.1016L13.1016 3.72656C11.9297 2.78906 10.5625 2.32031 9 2.32031C7.17708 2.32031 5.60156 2.98438 4.27344 4.3125C2.97135 5.61458 2.32031 7.17708 2.32031 9ZM3.10156 3.14062C4.74219 1.5 6.70833 0.679688 9 0.679688C11.2917 0.679688 13.2448 1.5 14.8594 3.14062C16.5 4.75521 17.3203 6.70833 17.3203 9C17.3203 11.2917 16.5 13.2578 14.8594 14.8984C13.2448 16.513 11.2917 17.3203 9 17.3203C6.70833 17.3203 4.74219 16.513 3.10156 14.8984C1.48698 13.2578 0.679688 11.2917 0.679688 9C0.679688 6.70833 1.48698 4.75521 3.10156 3.14062Z" fill="currentColor"/>
                        </svg>
                        &nbsp;
                        Desbloquear usuário
                    </button>
                    <button v-if="!userInformation.friend_relation.isFriend && !userInformation.friend_relation.isRequester && userInformation.friend_relation.isRequested && !canEdit" class="dropdown_item" @click="friendAction('reject')">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 15.6797C10.8229 15.6797 12.3854 15.0286 13.6875 13.7266C15.0156 12.3984 15.6797 10.8229 15.6797 9C15.6797 8.375 15.5365 7.65885 15.25 6.85156C14.9896 6.04427 14.6641 5.39323 14.2734 4.89844L4.89844 14.2734C6.07031 15.2109 7.4375 15.6797 9 15.6797ZM2.32031 9C2.32031 9.625 2.45052 10.3411 2.71094 11.1484C2.9974 11.9557 3.33594 12.6068 3.72656 13.1016L13.1016 3.72656C11.9297 2.78906 10.5625 2.32031 9 2.32031C7.17708 2.32031 5.60156 2.98438 4.27344 4.3125C2.97135 5.61458 2.32031 7.17708 2.32031 9ZM3.10156 3.14062C4.74219 1.5 6.70833 0.679688 9 0.679688C11.2917 0.679688 13.2448 1.5 14.8594 3.14062C16.5 4.75521 17.3203 6.70833 17.3203 9C17.3203 11.2917 16.5 13.2578 14.8594 14.8984C13.2448 16.513 11.2917 17.3203 9 17.3203C6.70833 17.3203 4.74219 16.513 3.10156 14.8984C1.48698 13.2578 0.679688 11.2917 0.679688 9C0.679688 6.70833 1.48698 4.75521 3.10156 3.14062Z" fill="currentColor"/>
                        </svg>
                        &nbsp;
                        Rejeitar amizade
                    </button>
                </div>
                <div class="user_info_container_header">
                    
                </div>
                <div class="user_info" v-if="canEdit">
                    <label for="change_avatar">
                        <img :src="userInformation.profile.profile_photo_url" alt="" class="avatar_bottom_half">
                    </label>
                    <input type="file" id="change_avatar" ref="profile_picture" style="display:none;position:absolute;width:100%;" accept="image/png,image/jpeg" v-on:change="changeAvatar">
                    
                    <div class="user_info_field text-center">
                        <span>{{userInformation.profile.name}}</span>
                        <p>@{{userInformation.profile.nickname}}</p>
                    </div>
                </div>
                <div class="user_info" v-else>
                    <img :src="userInformation.profile.profile_photo_url" alt="" style="cursor: default;" class="avatar_bottom_half">
                    
                    <div class="user_info_field text-center">
                        <span>{{userInformation.profile.name}}</span>
                        <p>@{{userInformation.profile.nickname}}</p>
                    </div>
                </div>
                <div class="user_info_container_footer mt-2">
                    <button class="profile_action d-flex align-items-center" v-if="userInformation.profile.phone != null && !userInformation.friend_relation.isBlocked" @click="openLink('whatsapp', userInformation.profile.phone)">
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.0001 1.30005C4.92731 1.30005 0.800108 5.42725 0.800108 10.5C0.800108 12.084 1.20931 13.6416 1.98531 15.016L0.814908 19.192C0.776508 19.3292 0.813708 19.4764 0.912908 19.5784C0.989308 19.6572 1.09331 19.7 1.20011 19.7C1.23211 19.7 1.26451 19.696 1.29611 19.6884L5.65451 18.6088C6.98531 19.3232 8.48411 19.7 10.0001 19.7C15.0729 19.7 19.2001 15.5728 19.2001 10.5C19.2001 5.42725 15.0729 1.30005 10.0001 1.30005ZM14.6281 13.7464C14.4313 14.2912 13.4873 14.7884 13.0337 14.8552C12.6265 14.9148 12.1113 14.9404 11.5457 14.7628C11.2029 14.6549 10.7629 14.5116 10.1993 14.2712C7.83011 13.2608 6.28291 10.9052 6.16451 10.7496C6.04651 10.594 5.20011 9.48525 5.20011 8.33765C5.20011 7.19005 5.81011 6.62565 6.02691 6.39205C6.24371 6.15845 6.49931 6.10005 6.65691 6.10005C6.81451 6.10005 6.97171 6.10205 7.10971 6.10845C7.25491 6.11565 7.44971 6.05365 7.64131 6.50885C7.83811 6.97605 8.31051 8.12365 8.36891 8.24085C8.42811 8.35765 8.46731 8.49405 8.38891 8.64965C8.31051 8.80525 8.27131 8.90245 8.15291 9.03885C8.03451 9.17525 7.90491 9.34285 7.79851 9.44765C7.68011 9.56405 7.55731 9.69005 7.69491 9.92365C7.83251 10.1572 8.30651 10.9208 9.00891 11.5392C9.91091 12.3336 10.6721 12.58 10.9081 12.6968C11.1441 12.8136 11.2821 12.794 11.4197 12.6384C11.5573 12.4824 12.0101 11.9572 12.1673 11.724C12.3245 11.4908 12.4821 11.5292 12.6989 11.6072C12.9157 11.6848 14.0769 12.2488 14.3129 12.3656C14.5489 12.4824 14.7065 12.5408 14.7657 12.638C14.8249 12.7348 14.8249 13.202 14.6281 13.7464Z" fill="currentColor"/>
                        </svg>
                        &nbsp;
                        Whatsapp
                    </button>
                    <button class="profile_action d-flex align-items-center" v-if="userInformation.profile.linkedin && !userInformation.friend_relation.isBlocked" @click="openLink('linkedin', userInformation.profile.linkedin)">
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.4 2.1001H3.59998C2.49598 2.1001 1.59998 2.9961 1.59998 4.1001V16.9001C1.59998 18.0041 2.49598 18.9001 3.59998 18.9001H16.4C17.504 18.9001 18.4 18.0041 18.4 16.9001V4.1001C18.4 2.9961 17.504 2.1001 16.4 2.1001ZM6.79998 8.5001V16.1001H4.39998V8.5001H6.79998ZM4.39998 6.2881C4.39998 5.7281 4.87998 5.3001 5.59998 5.3001C6.31998 5.3001 6.77198 5.7281 6.79998 6.2881C6.79998 6.8481 6.35198 7.3001 5.59998 7.3001C4.87998 7.3001 4.39998 6.8481 4.39998 6.2881ZM15.6 16.1001H13.2C13.2 16.1001 13.2 12.3961 13.2 12.1001C13.2 11.3001 12.8 10.5001 11.8 10.4841H11.768C10.8 10.4841 10.4 11.3081 10.4 12.1001C10.4 12.4641 10.4 16.1001 10.4 16.1001H7.99998V8.5001H10.4V9.5241C10.4 9.5241 11.172 8.5001 12.724 8.5001C14.312 8.5001 15.6 9.5921 15.6 11.8041V16.1001Z" fill="currentColor"/>
                        </svg>
                        &nbsp;
                        LinkedIn
                    </button>
                    <button class="profile_action d-flex align-items-center" @click="openMessage()" v-if="!userInformation.friend_relation.isBlocked && !userInformation.friend_relation.isBlocker && !canEdit">
                        <svg width="20" height="21" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.6797 0.179688C16.1224 0.179688 16.5 0.348958 16.8125 0.6875C17.151 1 17.3203 1.3776 17.3203 1.82031V11.8203C17.3203 12.263 17.151 12.6536 16.8125 12.9922C16.5 13.3307 16.1224 13.5 15.6797 13.5H4L0.679688 16.8203V1.82031C0.679688 1.3776 0.835938 1 1.14844 0.6875C1.48698 0.348958 1.8776 0.179688 2.32031 0.179688H15.6797Z" fill="currentColor"/>
                        </svg>
                        &nbsp;
                        Mensagem
                    </button>
                    <button class="profile_action d-flex align-items-center" @click.stop="friendAction('add')" v-if="!userInformation.friend_relation.isFriend && !userInformation.friend_relation.isRequested && !userInformation.friend_relation.isRequester && !userInformation.friend_relation.isBlocked && !canEdit">
                        <svg width="20" height="21" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.3203 5H20V6.67969H18.3203V8.32031H16.6797V6.67969H15V5H16.6797V3.32031H18.3203V5ZM6.67969 7.5C6.05469 7.5 5.48177 7.35677 4.96094 7.07031C4.46615 6.75781 4.0625 6.35417 3.75 5.85938C3.46354 5.33854 3.32031 4.77865 3.32031 4.17969C3.32031 3.55469 3.46354 2.99479 3.75 2.5C4.0625 1.97917 4.46615 1.57552 4.96094 1.28906C5.48177 0.976562 6.05469 0.820312 6.67969 0.820312C7.27865 0.820312 7.82552 0.976562 8.32031 1.28906C8.84115 1.57552 9.24479 1.97917 9.53125 2.5C9.84375 2.99479 10 3.55469 10 4.17969C10 4.77865 9.84375 5.33854 9.53125 5.85938C9.24479 6.35417 8.84115 6.75781 8.32031 7.07031C7.82552 7.35677 7.27865 7.5 6.67969 7.5ZM6.67969 8.32031C7.1224 8.32031 7.63021 8.3724 8.20312 8.47656C8.77604 8.55469 9.36198 8.6849 9.96094 8.86719C10.5599 9.04948 11.1068 9.27083 11.6016 9.53125C12.1224 9.79167 12.5391 10.1042 12.8516 10.4688C13.1641 10.8333 13.3203 11.237 13.3203 11.6797V14.1797H0V11.6797C0 11.237 0.15625 10.8333 0.46875 10.4688C0.78125 10.1042 1.1849 9.79167 1.67969 9.53125C2.20052 9.27083 2.76042 9.04948 3.35938 8.86719C3.95833 8.6849 4.54427 8.55469 5.11719 8.47656C5.71615 8.3724 6.23698 8.32031 6.67969 8.32031ZM10.4297 0.859375C10.9766 0.9375 11.4714 1.13281 11.9141 1.44531C12.3568 1.75781 12.6953 2.14844 12.9297 2.61719C13.1901 3.08594 13.3203 3.60677 13.3203 4.17969C13.3203 4.72656 13.1901 5.2474 12.9297 5.74219C12.6953 6.21094 12.3568 6.60156 11.9141 6.91406C11.4714 7.20052 10.9766 7.38281 10.4297 7.46094C10.8203 7.01823 11.1198 6.52344 11.3281 5.97656C11.5625 5.40365 11.6797 4.80469 11.6797 4.17969C11.6797 3.55469 11.5625 2.95573 11.3281 2.38281C11.1198 1.8099 10.8203 1.30208 10.4297 0.859375ZM13.7891 9.02344C14.3099 9.20573 14.7786 9.42708 15.1953 9.6875C15.638 9.94792 15.9896 10.2474 16.25 10.5859C16.5365 10.8984 16.6797 11.263 16.6797 11.6797V14.1797H15V11.6797C15 11.1328 14.8828 10.638 14.6484 10.1953C14.4401 9.7526 14.1536 9.36198 13.7891 9.02344Z" fill="currentColor"/>
                        </svg>

                        &nbsp;
                        Adicionar
                    </button>
                    <button class="profile_action sent d-flex align-items-center" @click.stop="friendAction('cancel')" v-if="!userInformation.friend_relation.isFriend && !userInformation.friend_relation.isRequested && userInformation.friend_relation.isRequester && !canEdit">
                        <svg width="20" height="21" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.3203 5H20V6.67969H18.3203V8.32031H16.6797V6.67969H15V5H16.6797V3.32031H18.3203V5ZM6.67969 7.5C6.05469 7.5 5.48177 7.35677 4.96094 7.07031C4.46615 6.75781 4.0625 6.35417 3.75 5.85938C3.46354 5.33854 3.32031 4.77865 3.32031 4.17969C3.32031 3.55469 3.46354 2.99479 3.75 2.5C4.0625 1.97917 4.46615 1.57552 4.96094 1.28906C5.48177 0.976562 6.05469 0.820312 6.67969 0.820312C7.27865 0.820312 7.82552 0.976562 8.32031 1.28906C8.84115 1.57552 9.24479 1.97917 9.53125 2.5C9.84375 2.99479 10 3.55469 10 4.17969C10 4.77865 9.84375 5.33854 9.53125 5.85938C9.24479 6.35417 8.84115 6.75781 8.32031 7.07031C7.82552 7.35677 7.27865 7.5 6.67969 7.5ZM6.67969 8.32031C7.1224 8.32031 7.63021 8.3724 8.20312 8.47656C8.77604 8.55469 9.36198 8.6849 9.96094 8.86719C10.5599 9.04948 11.1068 9.27083 11.6016 9.53125C12.1224 9.79167 12.5391 10.1042 12.8516 10.4688C13.1641 10.8333 13.3203 11.237 13.3203 11.6797V14.1797H0V11.6797C0 11.237 0.15625 10.8333 0.46875 10.4688C0.78125 10.1042 1.1849 9.79167 1.67969 9.53125C2.20052 9.27083 2.76042 9.04948 3.35938 8.86719C3.95833 8.6849 4.54427 8.55469 5.11719 8.47656C5.71615 8.3724 6.23698 8.32031 6.67969 8.32031ZM10.4297 0.859375C10.9766 0.9375 11.4714 1.13281 11.9141 1.44531C12.3568 1.75781 12.6953 2.14844 12.9297 2.61719C13.1901 3.08594 13.3203 3.60677 13.3203 4.17969C13.3203 4.72656 13.1901 5.2474 12.9297 5.74219C12.6953 6.21094 12.3568 6.60156 11.9141 6.91406C11.4714 7.20052 10.9766 7.38281 10.4297 7.46094C10.8203 7.01823 11.1198 6.52344 11.3281 5.97656C11.5625 5.40365 11.6797 4.80469 11.6797 4.17969C11.6797 3.55469 11.5625 2.95573 11.3281 2.38281C11.1198 1.8099 10.8203 1.30208 10.4297 0.859375ZM13.7891 9.02344C14.3099 9.20573 14.7786 9.42708 15.1953 9.6875C15.638 9.94792 15.9896 10.2474 16.25 10.5859C16.5365 10.8984 16.6797 11.263 16.6797 11.6797V14.1797H15V11.6797C15 11.1328 14.8828 10.638 14.6484 10.1953C14.4401 9.7526 14.1536 9.36198 13.7891 9.02344Z" fill="#6954D9"/>
                        </svg>
                        &nbsp;
                        Solicitação enviada
                    </button>
                    <button class="profile_action d-flex align-items-center" @click.stop="friendAction('remove')" v-if="userInformation.friend_relation.isFriend && !userInformation.friend_relation.isRequested && !userInformation.friend_relation.isRequester && !canEdit">
                        <svg width="21" height="21" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.6797 4.17969C10.6797 4.77865 10.5234 5.33854 10.2109 5.85938C9.92448 6.35417 9.52083 6.75781 9 7.07031C8.50521 7.35677 7.94531 7.5 7.32031 7.5C6.72135 7.5 6.16146 7.35677 5.64062 7.07031C5.14583 6.75781 4.74219 6.35417 4.42969 5.85938C4.14323 5.33854 4 4.77865 4 4.17969C4 3.55469 4.14323 2.99479 4.42969 2.5C4.74219 1.97917 5.14583 1.57552 5.64062 1.28906C6.16146 0.976562 6.72135 0.820312 7.32031 0.820312C7.94531 0.820312 8.50521 0.976562 9 1.28906C9.52083 1.57552 9.92448 1.97917 10.2109 2.5C10.5234 2.99479 10.6797 3.55469 10.6797 4.17969ZM13.1797 5.82031H18.1797V7.5H13.1797V5.82031ZM0.679688 12.5C0.679688 12.0573 0.835938 11.6667 1.14844 11.3281C1.46094 10.9635 1.86458 10.651 2.35938 10.3906C2.88021 10.1042 3.4401 9.88281 4.03906 9.72656C4.63802 9.54427 5.22396 9.41406 5.79688 9.33594C6.36979 9.23177 6.8776 9.17969 7.32031 9.17969C7.76302 9.17969 8.27083 9.23177 8.84375 9.33594C9.44271 9.41406 10.0417 9.54427 10.6406 9.72656C11.2396 9.88281 11.7865 10.1042 12.2812 10.3906C12.8021 10.651 13.2188 10.9635 13.5312 11.3281C13.8438 11.6667 14 12.0573 14 12.5V14.1797H0.679688V12.5Z" fill="currentColor"/>
                        </svg>
                        &nbsp;
                        Remover amigo
                    </button>
                    <button class="profile_action d-flex align-items-center" @click.stop="friendAction('accept')" v-if="!userInformation.friend_relation.isFriend && userInformation.friend_relation.isRequested && !userInformation.friend_relation.isRequester && !canEdit">
                        <svg width="20" height="21" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.3203 5H20V6.67969H18.3203V8.32031H16.6797V6.67969H15V5H16.6797V3.32031H18.3203V5ZM6.67969 7.5C6.05469 7.5 5.48177 7.35677 4.96094 7.07031C4.46615 6.75781 4.0625 6.35417 3.75 5.85938C3.46354 5.33854 3.32031 4.77865 3.32031 4.17969C3.32031 3.55469 3.46354 2.99479 3.75 2.5C4.0625 1.97917 4.46615 1.57552 4.96094 1.28906C5.48177 0.976562 6.05469 0.820312 6.67969 0.820312C7.27865 0.820312 7.82552 0.976562 8.32031 1.28906C8.84115 1.57552 9.24479 1.97917 9.53125 2.5C9.84375 2.99479 10 3.55469 10 4.17969C10 4.77865 9.84375 5.33854 9.53125 5.85938C9.24479 6.35417 8.84115 6.75781 8.32031 7.07031C7.82552 7.35677 7.27865 7.5 6.67969 7.5ZM6.67969 8.32031C7.1224 8.32031 7.63021 8.3724 8.20312 8.47656C8.77604 8.55469 9.36198 8.6849 9.96094 8.86719C10.5599 9.04948 11.1068 9.27083 11.6016 9.53125C12.1224 9.79167 12.5391 10.1042 12.8516 10.4688C13.1641 10.8333 13.3203 11.237 13.3203 11.6797V14.1797H0V11.6797C0 11.237 0.15625 10.8333 0.46875 10.4688C0.78125 10.1042 1.1849 9.79167 1.67969 9.53125C2.20052 9.27083 2.76042 9.04948 3.35938 8.86719C3.95833 8.6849 4.54427 8.55469 5.11719 8.47656C5.71615 8.3724 6.23698 8.32031 6.67969 8.32031ZM10.4297 0.859375C10.9766 0.9375 11.4714 1.13281 11.9141 1.44531C12.3568 1.75781 12.6953 2.14844 12.9297 2.61719C13.1901 3.08594 13.3203 3.60677 13.3203 4.17969C13.3203 4.72656 13.1901 5.2474 12.9297 5.74219C12.6953 6.21094 12.3568 6.60156 11.9141 6.91406C11.4714 7.20052 10.9766 7.38281 10.4297 7.46094C10.8203 7.01823 11.1198 6.52344 11.3281 5.97656C11.5625 5.40365 11.6797 4.80469 11.6797 4.17969C11.6797 3.55469 11.5625 2.95573 11.3281 2.38281C11.1198 1.8099 10.8203 1.30208 10.4297 0.859375ZM13.7891 9.02344C14.3099 9.20573 14.7786 9.42708 15.1953 9.6875C15.638 9.94792 15.9896 10.2474 16.25 10.5859C16.5365 10.8984 16.6797 11.263 16.6797 11.6797V14.1797H15V11.6797C15 11.1328 14.8828 10.638 14.6484 10.1953C14.4401 9.7526 14.1536 9.36198 13.7891 9.02344Z" fill="currentColor"/>
                        </svg>
                        &nbsp;
                        Aceitar amizade
                    </button>
                    
                    <button class="profile_action d-flex align-items-center" style="margin-right: 12px;" v-if="!canEdit && !userInformation.friend_relation.isBlocked" @click="showMoreMenu = !showMoreMenu">
                        <svg width="6" height="21" viewBox="0 0 4 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.828125 10.8281C1.16667 10.4896 1.55729 10.3203 2 10.3203C2.44271 10.3203 2.83333 10.4896 3.17188 10.8281C3.51042 11.1667 3.67969 11.5573 3.67969 12C3.67969 12.4427 3.51042 12.8333 3.17188 13.1719C2.83333 13.5104 2.44271 13.6797 2 13.6797C1.55729 13.6797 1.16667 13.5104 0.828125 13.1719C0.489583 12.8333 0.320312 12.4427 0.320312 12C0.320312 11.5573 0.489583 11.1667 0.828125 10.8281ZM0.828125 5.82812C1.16667 5.48958 1.55729 5.32031 2 5.32031C2.44271 5.32031 2.83333 5.48958 3.17188 5.82812C3.51042 6.16667 3.67969 6.55729 3.67969 7C3.67969 7.44271 3.51042 7.83333 3.17188 8.17188C2.83333 8.51042 2.44271 8.67969 2 8.67969C1.55729 8.67969 1.16667 8.51042 0.828125 8.17188C0.489583 7.83333 0.320312 7.44271 0.320312 7C0.320312 6.55729 0.489583 6.16667 0.828125 5.82812ZM3.17188 3.17188C2.83333 3.51042 2.44271 3.67969 2 3.67969C1.55729 3.67969 1.16667 3.51042 0.828125 3.17188C0.489583 2.83333 0.320312 2.44271 0.320312 2C0.320312 1.55729 0.489583 1.16667 0.828125 0.828125C1.16667 0.489583 1.55729 0.320312 2 0.320312C2.44271 0.320312 2.83333 0.489583 3.17188 0.828125C3.51042 1.16667 3.67969 1.55729 3.67969 2C3.67969 2.44271 3.51042 2.83333 3.17188 3.17188Z" fill="currentColor"/>
                        </svg>
                    </button>

                    <button class="profile_action d-flex align-items-center" v-if="canEdit" @click="showUserDataModal = true">
                        <svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z"/></svg>
                        &nbsp;
                        Editar
                    </button>
                </div>
            </div>
            <div class="user_about" v-if="canEdit || userInformation.profile.about_you">
                <div class="about_you">
                    <div class="d-flex justify-content-between align-items-center">
                        <span class="about_you_title">Descrição</span> 
                        <div @click="showSaveBtn = true" v-if="canEdit && !showSaveBtn" class="about_you_edit d-flex align-items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z"/></svg>
                            &nbsp;
                            <span>Editar</span>
                        </div>
                    </div>
                </div>
                <textarea v-if="canEdit && showSaveBtn" rows="6" v-model="userInformation.profile.about_you" style="padding: 10px; border-radius: 10px; border-color: #c1c1c1;"></textarea>
                <p v-else-if="canEdit && !showSaveBtn">{{ userInformation.profile.about_you ? userInformation.profile.about_you : 'Sobre você...' }}</p>
                <p v-else>{{ userInformation.profile.about_you ? userInformation.profile.about_you : '' }}</p>
                <div class="edit_action_buttons mt-2" v-if="showSaveBtn  && canEdit">
                    <button class="save_about_you" @click="updateUserProfile();showSaveBtn = false">
                        Salvar
                    </button>
                    <button class="cancel_about_you" @click="showSaveBtn = false">
                        Cancelar
                    </button>
                </div>
            </div>
            <div v-if="eTalent != null">
                <ETalentVisualizer :canEdit="canEdit" :eTalent="eTalent"></ETalentVisualizer>
            </div>
            <div class="courses">
                <div class="courses_title" v-if="canEdit">
                    <p>Meus cursos</p>
                </div>
                <div class="courses_title" v-else>
                    <p>Cursos em comum</p>
                </div>
                <div class="courses_list" v-if="userInformation.courses">
                    <div class="course_container" v-for="(course, index) in userInformation.courses" @click="goTo(course.slug)" :key="index">
                        <div class="course_thumb">
                            <div :src="course.thumbnail" class="course_thumb_image" :style="'background-image: url(' + course.thumbnail + ')'" v-if="course.thumbnail" alt=""></div>
                            <svg v-else xmlns="http://www.w3.org/2000/svg" height="3em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M448 80c8.8 0 16 7.2 16 16V415.8l-5-6.5-136-176c-4.5-5.9-11.6-9.3-19-9.3s-14.4 3.4-19 9.3L202 340.7l-30.5-42.7C167 291.7 159.8 288 152 288s-15 3.7-19.5 10.1l-80 112L48 416.3l0-.3V96c0-8.8 7.2-16 16-16H448zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm80 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"/></svg>
                        </div>
                        <div class="course_title">
                            <p>{{course.title}}</p>
                        </div>
                        <div class="course_description">
                            <p>{{course.description ?  course.description : ''}}</p>
                        </div>
                    </div>
                    <div class="no_data" v-if="userInformation.courses <= 0">
                        <svg xmlns="http://www.w3.org/2000/svg" height="8em" viewBox="0 0 640 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M320 32c-8.1 0-16.1 1.4-23.7 4.1L15.8 137.4C6.3 140.9 0 149.9 0 160s6.3 19.1 15.8 22.6l57.9 20.9C57.3 229.3 48 259.8 48 291.9v28.1c0 28.4-10.8 57.7-22.3 80.8c-6.5 13-13.9 25.8-22.5 37.6C0 442.7-.9 448.3 .9 453.4s6 8.9 11.2 10.2l64 16c4.2 1.1 8.7 .3 12.4-2s6.3-6.1 7.1-10.4c8.6-42.8 4.3-81.2-2.1-108.7C90.3 344.3 86 329.8 80 316.5V291.9c0-30.2 10.2-58.7 27.9-81.5c12.9-15.5 29.6-28 49.2-35.7l157-61.7c8.2-3.2 17.5 .8 20.7 9s-.8 17.5-9 20.7l-157 61.7c-12.4 4.9-23.3 12.4-32.2 21.6l159.6 57.6c7.6 2.7 15.6 4.1 23.7 4.1s16.1-1.4 23.7-4.1L624.2 182.6c9.5-3.4 15.8-12.5 15.8-22.6s-6.3-19.1-15.8-22.6L343.7 36.1C336.1 33.4 328.1 32 320 32zM128 408c0 35.3 86 72 192 72s192-36.7 192-72L496.7 262.6 354.5 314c-11.1 4-22.8 6-34.5 6s-23.5-2-34.5-6L143.3 262.6 128 408z"/></svg>
                        Sem cursos por enquanto
                    </div>
                </div>
            </div>
            <div class="courses">
                <div class="courses_title">
                    <p>Certificados</p>
                </div>
                <div class="courses_list" v-if="userInformation.certificates">
                    <div class="course_container" v-for="(certificate, index) in userInformation.certificates" :key="index" @click="goTo()">
                        <div class="course_thumb">
                            <div :src="certificate.url" class="course_thumb_image" :style="'background-image: url(' + certificate.url + ')'" alt=""></div>
                        </div>
                        <div class="course_title">
                            <p>{{certificate.title}}</p>
                        </div>
                        
                    </div>
                    <div class="no_data" v-if="userInformation.certificates.length <= 0">
                        <svg xmlns="http://www.w3.org/2000/svg" height="8em" viewBox="0 0 576 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M512 80c8.8 0 16 7.2 16 16V416c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V96c0-8.8 7.2-16 16-16H512zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM208 256a64 64 0 1 0 0-128 64 64 0 1 0 0 128zm-32 32c-44.2 0-80 35.8-80 80c0 8.8 7.2 16 16 16H304c8.8 0 16-7.2 16-16c0-44.2-35.8-80-80-80H176zM376 144c-13.3 0-24 10.7-24 24s10.7 24 24 24h80c13.3 0 24-10.7 24-24s-10.7-24-24-24H376zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24h80c13.3 0 24-10.7 24-24s-10.7-24-24-24H376z"/></svg>
                        Sem certificados por enquanto
                    </div>
                </div>
            </div>
        </div>
        <div class="right-container">
            <div class="about">
                <p>Sugestões de amizade</p>
            </div>
            <div class="friend_suggestion" v-for="(friend, index) in userInformation.friends_suggestions" :key="index">
                <img :src="friend.profile_photo_url" alt="" @click="goToUserPage(friend.id)">
                <div class="friend_card">
                    <div class="friend_data" @click="goToUserPage(friend.id)">
                        <p class="friend_name" :title="friend.name">
                            {{ friend.name }}
                        </p>
                        <p :title="friend.nickname"> @{{friend.nickname}} </p>
                    </div>
                    <div style="display: flex; justify-content: space-between;">
                        <button v-if="!friend.is_friend && !friend.user_friend_requested && !friend.user_friend_received" class="profile_action" @click.stop="friendAction('add', friend.id)">
                            <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.3203 5H20V6.67969H18.3203V8.32031H16.6797V6.67969H15V5H16.6797V3.32031H18.3203V5ZM6.67969 7.5C6.05469 7.5 5.48177 7.35677 4.96094 7.07031C4.46615 6.75781 4.0625 6.35417 3.75 5.85938C3.46354 5.33854 3.32031 4.77865 3.32031 4.17969C3.32031 3.55469 3.46354 2.99479 3.75 2.5C4.0625 1.97917 4.46615 1.57552 4.96094 1.28906C5.48177 0.976562 6.05469 0.820312 6.67969 0.820312C7.27865 0.820312 7.82552 0.976562 8.32031 1.28906C8.84115 1.57552 9.24479 1.97917 9.53125 2.5C9.84375 2.99479 10 3.55469 10 4.17969C10 4.77865 9.84375 5.33854 9.53125 5.85938C9.24479 6.35417 8.84115 6.75781 8.32031 7.07031C7.82552 7.35677 7.27865 7.5 6.67969 7.5ZM6.67969 8.32031C7.1224 8.32031 7.63021 8.3724 8.20312 8.47656C8.77604 8.55469 9.36198 8.6849 9.96094 8.86719C10.5599 9.04948 11.1068 9.27083 11.6016 9.53125C12.1224 9.79167 12.5391 10.1042 12.8516 10.4688C13.1641 10.8333 13.3203 11.237 13.3203 11.6797V14.1797H0V11.6797C0 11.237 0.15625 10.8333 0.46875 10.4688C0.78125 10.1042 1.1849 9.79167 1.67969 9.53125C2.20052 9.27083 2.76042 9.04948 3.35938 8.86719C3.95833 8.6849 4.54427 8.55469 5.11719 8.47656C5.71615 8.3724 6.23698 8.32031 6.67969 8.32031ZM10.4297 0.859375C10.9766 0.9375 11.4714 1.13281 11.9141 1.44531C12.3568 1.75781 12.6953 2.14844 12.9297 2.61719C13.1901 3.08594 13.3203 3.60677 13.3203 4.17969C13.3203 4.72656 13.1901 5.2474 12.9297 5.74219C12.6953 6.21094 12.3568 6.60156 11.9141 6.91406C11.4714 7.20052 10.9766 7.38281 10.4297 7.46094C10.8203 7.01823 11.1198 6.52344 11.3281 5.97656C11.5625 5.40365 11.6797 4.80469 11.6797 4.17969C11.6797 3.55469 11.5625 2.95573 11.3281 2.38281C11.1198 1.8099 10.8203 1.30208 10.4297 0.859375ZM13.7891 9.02344C14.3099 9.20573 14.7786 9.42708 15.1953 9.6875C15.638 9.94792 15.9896 10.2474 16.25 10.5859C16.5365 10.8984 16.6797 11.263 16.6797 11.6797V14.1797H15V11.6797C15 11.1328 14.8828 10.638 14.6484 10.1953C14.4401 9.7526 14.1536 9.36198 13.7891 9.02344Z" fill="currentColor"/>
                            </svg>
                            Adicionar
                        </button>
                        <button v-if="!friend.is_friend && friend.user_friend_requested && !friend.user_friend_received" class="profile_action sent" @click.stop="friendAction('cancel', friend.id)">
                            <svg width="20" height="21" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.3203 5H20V6.67969H18.3203V8.32031H16.6797V6.67969H15V5H16.6797V3.32031H18.3203V5ZM6.67969 7.5C6.05469 7.5 5.48177 7.35677 4.96094 7.07031C4.46615 6.75781 4.0625 6.35417 3.75 5.85938C3.46354 5.33854 3.32031 4.77865 3.32031 4.17969C3.32031 3.55469 3.46354 2.99479 3.75 2.5C4.0625 1.97917 4.46615 1.57552 4.96094 1.28906C5.48177 0.976562 6.05469 0.820312 6.67969 0.820312C7.27865 0.820312 7.82552 0.976562 8.32031 1.28906C8.84115 1.57552 9.24479 1.97917 9.53125 2.5C9.84375 2.99479 10 3.55469 10 4.17969C10 4.77865 9.84375 5.33854 9.53125 5.85938C9.24479 6.35417 8.84115 6.75781 8.32031 7.07031C7.82552 7.35677 7.27865 7.5 6.67969 7.5ZM6.67969 8.32031C7.1224 8.32031 7.63021 8.3724 8.20312 8.47656C8.77604 8.55469 9.36198 8.6849 9.96094 8.86719C10.5599 9.04948 11.1068 9.27083 11.6016 9.53125C12.1224 9.79167 12.5391 10.1042 12.8516 10.4688C13.1641 10.8333 13.3203 11.237 13.3203 11.6797V14.1797H0V11.6797C0 11.237 0.15625 10.8333 0.46875 10.4688C0.78125 10.1042 1.1849 9.79167 1.67969 9.53125C2.20052 9.27083 2.76042 9.04948 3.35938 8.86719C3.95833 8.6849 4.54427 8.55469 5.11719 8.47656C5.71615 8.3724 6.23698 8.32031 6.67969 8.32031ZM10.4297 0.859375C10.9766 0.9375 11.4714 1.13281 11.9141 1.44531C12.3568 1.75781 12.6953 2.14844 12.9297 2.61719C13.1901 3.08594 13.3203 3.60677 13.3203 4.17969C13.3203 4.72656 13.1901 5.2474 12.9297 5.74219C12.6953 6.21094 12.3568 6.60156 11.9141 6.91406C11.4714 7.20052 10.9766 7.38281 10.4297 7.46094C10.8203 7.01823 11.1198 6.52344 11.3281 5.97656C11.5625 5.40365 11.6797 4.80469 11.6797 4.17969C11.6797 3.55469 11.5625 2.95573 11.3281 2.38281C11.1198 1.8099 10.8203 1.30208 10.4297 0.859375ZM13.7891 9.02344C14.3099 9.20573 14.7786 9.42708 15.1953 9.6875C15.638 9.94792 15.9896 10.2474 16.25 10.5859C16.5365 10.8984 16.6797 11.263 16.6797 11.6797V14.1797H15V11.6797C15 11.1328 14.8828 10.638 14.6484 10.1953C14.4401 9.7526 14.1536 9.36198 13.7891 9.02344Z" fill="#6954D9"/>
                            </svg>
                            &nbsp;
                            Enviada
                        </button>
                        <button v-if="!friend.is_friend && !friend.user_friend_requested && friend.user_friend_received" class="profile_action sent" @click.stop="goToUserPage(friend.id)">
                            <svg width="20" height="21" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.3203 5H20V6.67969H18.3203V8.32031H16.6797V6.67969H15V5H16.6797V3.32031H18.3203V5ZM6.67969 7.5C6.05469 7.5 5.48177 7.35677 4.96094 7.07031C4.46615 6.75781 4.0625 6.35417 3.75 5.85938C3.46354 5.33854 3.32031 4.77865 3.32031 4.17969C3.32031 3.55469 3.46354 2.99479 3.75 2.5C4.0625 1.97917 4.46615 1.57552 4.96094 1.28906C5.48177 0.976562 6.05469 0.820312 6.67969 0.820312C7.27865 0.820312 7.82552 0.976562 8.32031 1.28906C8.84115 1.57552 9.24479 1.97917 9.53125 2.5C9.84375 2.99479 10 3.55469 10 4.17969C10 4.77865 9.84375 5.33854 9.53125 5.85938C9.24479 6.35417 8.84115 6.75781 8.32031 7.07031C7.82552 7.35677 7.27865 7.5 6.67969 7.5ZM6.67969 8.32031C7.1224 8.32031 7.63021 8.3724 8.20312 8.47656C8.77604 8.55469 9.36198 8.6849 9.96094 8.86719C10.5599 9.04948 11.1068 9.27083 11.6016 9.53125C12.1224 9.79167 12.5391 10.1042 12.8516 10.4688C13.1641 10.8333 13.3203 11.237 13.3203 11.6797V14.1797H0V11.6797C0 11.237 0.15625 10.8333 0.46875 10.4688C0.78125 10.1042 1.1849 9.79167 1.67969 9.53125C2.20052 9.27083 2.76042 9.04948 3.35938 8.86719C3.95833 8.6849 4.54427 8.55469 5.11719 8.47656C5.71615 8.3724 6.23698 8.32031 6.67969 8.32031ZM10.4297 0.859375C10.9766 0.9375 11.4714 1.13281 11.9141 1.44531C12.3568 1.75781 12.6953 2.14844 12.9297 2.61719C13.1901 3.08594 13.3203 3.60677 13.3203 4.17969C13.3203 4.72656 13.1901 5.2474 12.9297 5.74219C12.6953 6.21094 12.3568 6.60156 11.9141 6.91406C11.4714 7.20052 10.9766 7.38281 10.4297 7.46094C10.8203 7.01823 11.1198 6.52344 11.3281 5.97656C11.5625 5.40365 11.6797 4.80469 11.6797 4.17969C11.6797 3.55469 11.5625 2.95573 11.3281 2.38281C11.1198 1.8099 10.8203 1.30208 10.4297 0.859375ZM13.7891 9.02344C14.3099 9.20573 14.7786 9.42708 15.1953 9.6875C15.638 9.94792 15.9896 10.2474 16.25 10.5859C16.5365 10.8984 16.6797 11.263 16.6797 11.6797V14.1797H15V11.6797C15 11.1328 14.8828 10.638 14.6484 10.1953C14.4401 9.7526 14.1536 9.36198 13.7891 9.02344Z" fill="#6954D9"/>
                            </svg>
                            &nbsp;
                            Recebida
                        </button>
                        <button class="profile_action" @click.stop="openMessage(friend.id)">
                            <svg width="16" height="15" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.6797 0.179688C16.1224 0.179688 16.5 0.348958 16.8125 0.6875C17.151 1 17.3203 1.3776 17.3203 1.82031V11.8203C17.3203 12.263 17.151 12.6536 16.8125 12.9922C16.5 13.3307 16.1224 13.5 15.6797 13.5H4L0.679688 16.8203V1.82031C0.679688 1.3776 0.835938 1 1.14844 0.6875C1.48698 0.348958 1.8776 0.179688 2.32031 0.179688H15.6797Z" fill="currentColor"/>
                            </svg>
                        </button>
                    </div>
                </div>
                
            </div>
            <div class="no_data" v-if="userInformation.friends_suggestions.length <= 0">
                Sem sugestões no momento
            </div>
        </div>
        <div class="modals bgBlack" style="left: 0;postition: absolute;" v-if="showUserDataModal">
            <div class="modal_ modals__content alignVertical">
                <div class="header">
                    Editar informações pessoais
                    <svg @click="closeModal" xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
                </div>
                <div class="body">
                    <div class="field_group">
                        <label for="user_name">Nome</label>
                        <input id="user_name" name="user_name" type="text" v-model="userInformation.profile.name">
                    </div>
                    <div class="field_group">
                        <label for="user_nickname">Nome de Usuário</label>
                        <label>@<input id="user_nickname" name="user_nickname" type="text" v-model="userInformation.profile.nickname"></label>
                    </div>
                    <div class="field_group">
                        <label for="user_phone">Whatsapp</label>
                        <input @keyup="mask_phone" id="user_phone" name="user_phone" type="text" v-model="userInformation.profile.phone" placeholder="Digite o número do seu whatsapp">
                    </div>
                    <div class="field_group">
                        <label for="user_linkedin">LinkedIn</label>
                        <input id="user_linkedin" name="user_linkedin" type="text" v-model="userInformation.profile.linkedin"  placeholder="Digite o link do seu linkedin">
                    </div>
                    <div class="modal_btn_actions">
                        <button class="cancel_user_data" @click="showUserDataModal = false">
                            Cancelar
                        </button>
                        <button class="save_user_data" @click="updateUserProfile();showUserDataModal = false">
                            Salvar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</template>
<script>
// const emitter = require('tiny-emitter/instance')
import {mapGetters} from 'vuex'
import ETalentVisualizer from './components/ETalentVisualizer.vue'
export default {
    name: 'LmsUserProfile',
    data: () => ({
        lmsSlug: '',
        user_id: '',
        showUserDataModal: false,
        profile_picture: '',
        showSaveBtn: false,
        canEdit: false,
        eTalent: null,
        showMoreMenu: false,
    }),
    computed: {
        ...mapGetters({
            userInfo: 'UserProfile/getUserInformation',
            loggedUserId: 'id',
            
        }),
        userInformation: {
            get() {
                var result = this.userInfo
                result.profile.phone = this.mask_phone(false, result.profile.phone)
                return result
            },
            set(newValue) {
                this.userInfo = newValue
            }
        }
    },
    components: {
        ETalentVisualizer
    },
    beforeMount() {
        let root_ = this;
        root_.checkResult();
    },
    mounted() {
        let root_ = this
        root_.lmsSlug = root_.$route.params.lmsSlug
        root_.user_id = root_.$route.params.userId
        root_.$parent.wait(true)
        root_.$store.dispatch('UserProfile/getUser', {slug: root_.lmsSlug, user_id: root_.user_id}).then(() => {
            root_.$parent.wait(false)
            root_.canEdit = (root_.userInformation.profile.id == root_.loggedUserId)
            console.log('userInformation =>', root_.userInformation)
            console.log('loggedUserId =>', root_.loggedUserId)
            console.log('canEdit =>', root_.canEdit)
        }).catch((error) => {
            root_.$parent.wait(false)
            console.log(error)
            root_.$router.push({path: `/lms/${root_.lmsSlug}`})
        })
        // emitter.on('thumb::changed', (file) => {
        //     console.log('caiu no emitter;')
        //     let form = new FormData()
        //     form.append('profile_picture', file)
        //     root_.$parent.wait(true)
        //     root_.$store.dispatch('UserProfile/saveProfilePicture', form).then(() =>{
        //         root_.$parent.wait(false)
        //     })
        // })
    },
    methods: {
        openMessage(friend_id = null) {
            let root_ = this;
            if(!friend_id) {
                friend_id = root_.$route.params.userId;
            }
            this.$parent.modal_private_messages = true;
            this.$parent.private_messages_friend_id = Number(friend_id);
            this.$parent.refreshNotificationPrivateMessages(friend_id);
            // find index on private messages to set unread to 0
            /*
                        if( this.privateMessagesUsers.length > 0 ) {
                let index = Object.keys(this.privateMessagesUsers).find(x => this.privateMessagesUsers[x].id === friend_id);
                if( this.privateMessagesUsers[index] ) {
                    this.privateMessagesUsers[index].inbox.unread = 0;
                }
            }
            */
        },
        getUser() {
            let root_ = this
            root_.lmsSlug = root_.$route.params.lmsSlug
            root_.user_id = root_.$route.params.userId
            root_.$parent.wait(true)
            root_.$store.dispatch('UserProfile/getUser', {slug: root_.lmsSlug, user_id: root_.user_id}).then(() => {
                root_.$parent.wait(false)
                root_.canEdit = (root_.userInformation.profile.id == root_.loggedUserId)
            }).catch((error) => {
                root_.$parent.wait(false)
                console.log(error)
            })
        },
        checkResult() {
            let root_ = this;

            let payload = {
                "lms_id": root_.$route.params.lmsSlug,
                "user_id": root_.$route.params.userId
            };

            this.$store.dispatch('LMS/checkEtalentResult', payload).then( response => {
                this.eTalent = response.data.data.current.response_data;
            }).catch( error => {
                error;
                this.eTalent = null;
            });
        },
        closeModal() {
            this.showUserDataModal = false
        },
        goTo(slug = 'certificates') {
            this.$router.push({path: `/lms/${this.lmsSlug}/${slug}`});
        },
        goToUserPage(userId) {
            this.$router.push({path: `/lms/${this.lmsSlug}/profile/${userId}`})
        },
        selectPicture() {
            this.$parent.openFileManager()
        },
        checkWhatChanges() {
            console.log(this.userInformation)
        },
        openLink(media, url) {
            switch(media) {
                case 'linkedin':
                window.open(url, '_blank')
                    break;
                case 'whatsapp':
                    var phone = this.just_number(url);
                    url = 'https://wa.me/+55'+phone;
                    window.open(url, '_blank')
                    break;
            }
        },
        changeAvatar(event) {
            const reader = new FileReader()
            reader.readAsDataURL(event.target.files[0])
            reader.onload = () => {
                this.profile_picture = event.target.files[0];
                this.updateUserProfile()
            }
        },
        friendAction(action, id = null) {
            let root_ = this;
            let payload;

            if(id) {
                payload = {
                    "friend_id": id
                };    
            } else {
                payload = {
                    "friend_id": root_.$route.params.userId
                };    
            }

            switch (action) {
                case 'add':
                    root_.$store.dispatch('Friends/AddFriend', payload).then( response => {
                        response;
                        root_.getUser();
                    }).catch( error => {
                        error;
                        root_.getUser();
                    });
                    break;
                case 'remove':
                    if(confirm('Tem certeza que deseja desfazer a amizade?')) {
                        root_.$store.dispatch('Friends/RemoveFriend', payload).then( response => {
                            response;
                            root_.getUser();
                        }).catch( error => {
                            error;
                            root_.getUser();
                        });
                    }
                    break;
                case 'cancel':
                    if(confirm('Tem certeza que deseja cancelar o pedido de amizade?')) {
                        root_.$store.dispatch('Friends/RemoveFriend', payload).then( response => {
                            response;
                            root_.getUser();
                        }).catch( error => {
                            error;
                            root_.getUser();
                        });
                    }
                    break;
                case 'accept':
                    root_.$store.dispatch('Friends/AcceptRequest', payload).then( response => {
                        response;
                        root_.getUser();
                    }).catch( error => {
                        error;
                        root_.getUser();
                    });
                    break;
                case 'reject':
                    root_.$store.dispatch('Friends/RejectRequest', payload).then( response => {
                        response;
                        root_.getUser();
                    }).catch( error => {
                        error;
                        root_.getUser();
                    });
                    break;
                case 'block':
                    root_.$store.dispatch('Friends/BlockUser', payload).then( response => {
                        response;
                        root_.getUser();
                    }).catch( error => {
                        error;
                        root_.getUser();
                    });
                    break;
                case 'unblock':
                    root_.$store.dispatch('Friends/UnblockUser', payload).then( response => {
                        response;
                        root_.getUser();
                    }).catch( error => {
                        error;
                        root_.getUser();
                    });
                    break;
            }
        },
        mask_phone(e, value)
        {
            if (e)
            {
                this.check(e);
                let x = this.just_number(e.currentTarget.value).match(/(\d{0,2})(\d{0,5})(\d{0,5})/);
                e.currentTarget.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '' + x[3] : '');
            }
            else if (value)
            {
                let x = this.just_number(value).match(/(\d{0,2})(\d{0,5})(\d{0,5})/);
                return !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '' + x[3] : '');
            }
            else
            {
                return null;
            }
        },
        just_number(v)
        {
            return v.replace(/\D/g, '');
        },
        check(e)
        {
            if ((e.key !== "Backspace") || (e.key !== "ArrowLeft") || (e.key !== "ArrowRight") || (e.key !== "Tab") || (e.key !== "Delete"))
            {
                if (!/^[0-9]*$/.test(e.key))
                {
                    e.preventDefault();
                    return false;
                }
            }
        },
        updateUserProfile() {
            let vm = this
            let form = new FormData()
            form.append('cpf', vm.userInformation.profile.cpf)
            form.append('name', vm.userInformation.profile.name)
            form.append('nickname', vm.userInformation.profile.nickname)
            form.append('phone', vm.userInformation.profile.phone)
            form.append('nationality', vm.userInformation.profile.nationality)
            form.append('linkedin', vm.userInformation.profile.linkedin)
            form.append('state', vm.userInformation.profile.state)
            form.append('city', null)
            form.append('about_you', vm.userInformation.profile.about_you ?? '')
            form.append('company', vm.userInformation.profile.company)
            if (vm.profile_picture != '') {
                form.append('profile_picture', vm.profile_picture)
            }
            
            vm.$parent.wait(true)

            vm.$store.dispatch('UserProfile/updateUser', form).then(() => {
                vm.$store.dispatch('UserProfile/getUser', {slug: vm.lmsSlug, user_id: vm.user_id}).then(() => {
                    vm.$parent.wait(false)
                }).catch((error) => {
                    vm.$parent.wait(false)
                    console.log(error)
                })
            }).catch((res) => {
                console.log('error =>', res)
                let str = ''
                vm.$parent.wait(false)
                for (let o in res.data.errors) {
                    str += res.data.errors[o] + '\r';
                }
                this.$parent.modal('error', this.$i18n.t('signup.erro'), str, null, null, null, "OK");        
                
            })
        }
    }
}
</script>
<style lang="scss" scoped>
    .avatar_bottom_half {
        position: relative;
        top: 25%;
        border: 6px solid #FFF;
    }
    .container {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: space-between;
        margin-top: 9rem;
    }

    .user_info_field {
        margin-top: 15%;
    }

    .dropdown_menu {
        position: absolute;
        top: 55.9%;
        right: 0;
        margin-right: 10px;
        background-color: white;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 200px;
        z-index: 100;
        overflow: hidden;
    }

    .dropdown_item {
        height: 35px;
        border: none;
        background-color: transparent;
        color: #B1B1B1;
        display: flex;
        align-items: center;
        width: cover;
        margin: 0px 5px;
        padding: 0px 7px;
        svg {
            fill: #B1B1B1;
        }
        &:hover {
            color: #6954D9;
            svg {
                fill: #6954D9;
            }
        }
    }


    .left-container {
        height: 100%;
        width: 73%;
        .user_info_container {
            position: relative;
            width: 100%;
            height: 300px;
            border-radius: 50px;
            background: #FFF;
            box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.10);
            margin-bottom: 60px;
            border: 1px solid #E1E1E1;
            .user_info_container_header {
                background: linear-gradient(to right, #A3E000, #8E9A46, #6954D9);;
                height: 45%;
                border-top-left-radius: 50px;
                border-top-right-radius: 50px;
            }

            .user_info {
                position: absolute;
                width: 300px;
                height: 200px;
                margin-top: -7em;
                display: flex;
                align-items: center;
                flex-direction: column;
                
                img {
                    width: 110px;
                    height: 110px;
                    border-radius: 55px;
                    cursor: pointer;
                    object-fit: cover;
                    object-position: center;
                }
                span {
                    font-size: 20px;
                    color: #706F6F;
                    font-weight: 700;
                    font-family: Hanken Grotesk;
                }
                p {
                    font-size: 16px;
                    font-weight: 400;
                    font-family: Hanken Grotesk;
                    color: #706F6F
                }
            }
            .user_info_container_footer {
                height: 55%;
                border-bottom-left-radius: 20px;
                border-bottom-right-radius: 20px;
                display: flex;
                justify-content: right;
                align-items:baseline;
                .profile_action {
                    border: none;
                    background-color: transparent;
                    color: #B1B1B1;
                    display: flex;
                    align-items: center;
                    width: cover;
                    margin: 0px 5px;
                    padding: 0px 7px;

                    svg {
                        fill: #B1B1B1;
                    }
                    &:hover {
                        color: #6954D9;
                        svg {
                            fill: #6954D9;
                        }
                    }
                }

                .sent {
                    font-weight: 500;
                }
            }
        }

        .about_you {

            padding-bottom: 15px;

            .about_you_title {
                color: #706F6F;
                font-family: Hanken Grotesk;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }

            .about_you_edit {
                color: #B1B1B1;
                font-family: Hanken Grotesk;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                svg {
                        fill: #B1B1B1;
                    }
                    &:hover {
                        color: #6954D9;
                        cursor: pointer;
                        svg {
                            fill: #6954D9;
                        }
                    }
            }

        }

        .user_about {
            width: 100%;
            height: 330px;
            border-radius: 50px;
            background: #FFF;
            box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.10);
            margin-bottom: 60px;
            border: 1px solid #E1E1E1;
            padding: 40px;

            p {
                font-size: 16px;
                font-family: Hanken Grotesk;
                color: #706F6F;
            }
            textarea {
                resize: none;
                border: none;
                width: 100%;
                border: 2px solid #000;
            }

            .edit_action_buttons {
                display: flex;
                .save_about_you {
                    color: #FFFFFF;
                    border: none;
                    background-color: #6954D9;
                    padding: 10px 27px;
                    border-radius: 25px;
                    margin-right: 10px;
                }
                .cancel_about_you {
                    color: #000;
                    border: 2px solid #6954D9;
                    background-color: transparent;
                    padding: 10px 27px;
                    border-radius: 25px;
                }
            }
            
        }

        .etalent {
            width: 100%;
            height: 100%;
            border-radius: 50px;
            background: #FFF;
            box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.10);
            margin-bottom: 60px;
            border: 1px solid #E1E1E1;
        }
        .courses {
            .courses_title {
                p {
                    font-size: 20px;
                    font-family: Hanken Grotesk;
                    font-weight: 700;
                    color: #706F6F;
                }
            }
            .courses_list {
                display: flex;
                width: 100%;
                height: 440px;
                align-items: center;
                overflow-x: auto;
                margin-bottom: 60px;
                .course_container {
                    display: flex;
                    flex-direction: column;
                    height: 400px;
                    border-radius: 30px;
                    padding: 30px;
                    border: 1px solid #E1E1E1;
                    cursor: pointer;
                    margin: 1px 10px;
                    &:hover {
                        background: #FFF;
                        box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.10);
                    }

                    .course_thumb {
                        width: 220px;
                        height: 150px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: #dbdbdb;
                        .course_thumb_image {
                            width: 220px;
                            height: 150px;
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: cover;
                        }
                    }
                    .course_title {
                        margin-top: 20px;
                        width: 220px;
                        height: 50px;
                        text-align: center;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        p {
                            font-size: 18px;
                            color: #706F6F;
                            font-weight: 700;
                        }
                    }

                    .course_description {
                        width: 220px;
                        height: 150px;
                        text-align: start;
                        overflow-y: auto;
                        p {
                            font-size: 14px;
                            color: #706F6F;
                            font-weight: 400;
                        }
                    }

                }
                .no_data {
                    width: 100%;
                    height: 400px;
                    color: #706F6F;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    svg {
                        margin-bottom: 40px;
                        fill: #E1E1E1;
                    }
                }
            }

        }
    }
    .right-container {
        height: 50%;
        width: 23%;
        border-radius: 30px;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 30px 20px;
        background: #FFF;
        box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.10);
        border: 1px solid #E1E1E1;

        .no_data {
            width: 100%;
            margin-top: 10%;
            color: #706F6F;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .about {
            display: flex;
                align-items: center;
                p {
                    font-size: 20px;
                    font-family: Hanken Grotesk;
                    font-weight: 700;
                    color: #706F6F;
                }
        }
        .friend_suggestion {
            display: flex;
            margin: 10px 0px;
            width: 250px;
            border-bottom: 1px solid #E1E1E1;
            overflow: hidden;
            padding-bottom: 5px;

            img{
                height: 70px;
                width: 70px;
                border-radius: 70px;
            }
            .friend_card {
                width: 100%;
                height: 100%;
                overflow: hidden;
                .friend_data {
                    margin-left: 10px;
                    margin-top: 10px;
                    font-family: Hanken Grotesk;
                    cursor: pointer;
                    p {
                        font-size: 16px;
                        line-height: 18px;
                        font-weight: 400;
                        color: #706F6F;
                        overflow: hidden;
                        text-wrap: nowrap;
                        text-overflow: ellipsis;
                        width: 100%;
                        margin: 5px 0 !important;
                    }
                    .friend_name {
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 18px;
                        color: #706F6F;
                        overflow: hidden;
                        text-wrap: nowrap;
                        text-overflow: ellipsis;
                        width: 100%;
                        margin: 0 !important;
                    }
                }
                .profile_action {
                    border: none;
                    background-color: transparent;
                    color: #B1B1B1;
                    display: flex;
                    align-items: center;
                    margin: 2px 5px;
                    font-size: 14px;
                    svg {
                        margin-right: 4px;
                        fill: #B1B1B1;
                    }
                    &:hover {
                        color: #6954D9;
                        svg {
                            fill: #6954D9;
                        }
                    }
                }
            }
            
        }
    }

    .modal_ {
        border-radius: 20px;
        padding: 60px;
        width: 800px;
        .header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            font-size: 32px;
            color: #6954D9;
            font-family: Space Grotesk;
            font-weight: 700;
            svg {
                cursor: pointer;
            }
        }
        .body {
            width: 100%;
            margin-top: 60px;
            .field_group {
                width: 100%;
                display: flex;
                margin-top: 20px;
                flex-direction: column;
                label {
                    font-family: Hanken Grotesk;
                    font-weight: 700;
                    font-size: 16px;
                    color: #706F6F;
                    width: 100%;
                    input {
                        border: 1px solid #E1E1E1;
                        border-radius: 10px;
                        padding: 18px, 30px, 18px, 30px;
                        gap: 12px;
                        height: 57px;
                        width: 97.5%;
                    }
                }
                input {
                    border: 1px solid #E1E1E1;
                    border-radius: 10px;
                    padding: 18px, 30px, 18px, 30px;
                    gap: 12px;
                    height: 57px;
                }
            }
            .modal_btn_actions {
                display: flex;
                width: 100%;
                justify-content: center;
                margin-top: 20px;
                .cancel_user_data {
                    color: #000;
                    border: 2px solid #6954D9;
                    background-color: transparent;
                    padding: 10px 27px;
                    border-radius: 25px;

                }
                .save_user_data {
                    color: #FFFFFF;
                    border: none;
                    background-color: #6954D9;
                    padding: 10px 27px;
                    border-radius: 25px;
                    margin-left: 10px;
                }
            }
        }

    }
    
</style>