<template>
    <div v-show="loading_enabled" id="loader" ref="loader">
        <div v-if="icon" id="loader-icon" ref="loader-icon">
            <img :src="icon_url" alt="Loading Icon">
        </div>
        <div v-else id="loader-ani">
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: 'Loading',
    data() {
        return {
            icon: null,
            icon_url: null,
            loading_enabled: false
        }
    },
    computed: {
        ...mapGetters([
            'design', "checkIOS", "checkMobile","checkSafari"
        ])
    },
    methods: {
        hexToRGB(hex, alpha)
        {
            let r = parseInt(hex.slice(1, 3), 16), g = parseInt(hex.slice(3, 5), 16), b = parseInt(hex.slice(5, 7), 16);
            if (alpha)
            {
                return "rgba(" + r + ", " + g + ", " + b + ", " + (alpha / 100) + ")";
            }
            else
            {
                return "rgb(" + r + ", " + g + ", " + b + ")";
            }
        }
    },
    mounted() {
        let active = false;
        if (!this.design.loading_icon)
        {
            this.icon = false;
            this.loading_enabled = true;
            return;
        }
        else if ((!this.design.loading_use_mobile) && ((this.checkIOS) || (this.checkMobile)))
        {
            this.loading_enabled = false;
            return;
        }
        else
        {
            active = true;
        }

        // if (this.design.loading_icon && !this.design.loading_only_unity)
        // if (this.design.loading_icon)
        if (active)
        {
            if (this.$parent.loading_unity)
            {
                this.$refs['loader'].style.backgroundColor = this.hexToRGB(this.design.loading_bg_color, Number(this.design.loading_bg_alpha));
                this.icon_url = this.design.loading_icon;
                document.getElementById('loader').style.top    = '75px';
                document.getElementById('loader').style.height = 'calc(100% - 75px)';
                this.icon     = true;
                this.loading_enabled = true;
            }
            else if (this.design.loading_only_unity && !this.$parent.loading_unity)
            {
                // this.$refs['loader'].style.backgroundColor = this.hexToRGB(this.design.loading_bg_color, Number(this.design.loading_bg_alpha));
                // this.icon_url = this.design.loading_icon;
                this.loading_enabled = true;
                this.icon = false;
            }
            else
            {
                this.$refs['loader'].style.backgroundColor = this.hexToRGB(this.design.loading_bg_color, Number(this.design.loading_bg_alpha));
                this.icon_url = this.design.loading_icon;
                this.icon     = true;
                this.loading_enabled = true;
            }
        }
    }
}
</script>

<style scoped>
    #loader
    {
        background-color: rgba(0, 0, 0, .8);
        display         : block;
        height          : 100%;
        left            : 0;
        position        : fixed;
        top             : 0;
        width           : 100%;
        z-index         : 99999;
    }
    #loader-ani
    {
        -webkit-animation: spin 2s linear infinite;
        animation        : spin 2s linear infinite;
        border           : 6px solid #f5f5f5;
        border-radius    : 50%;
        border-top       : 6px solid #13b5eb;
        height           : 40px;
        left             : 50%;
        margin-left      : -20px;
        margin-top       : -20px;
        position         : fixed;
        top              : 50%;
        width            : 40px;
    }
    #loader-icon
    {
        box-sizing: border-box;
        display: block;
        height: auto;
        left: 50%;
        max-height: 100%;
        overflow: hidden;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: auto;
    }

    @media screen and (max-width: 399px)
    {
        #audioPlayers img{
            max-width: 35px;
        }
    }

    @media screen and (min-width: 400px)
    {
        #audioPlayers img{
            max-width: 45px;
        }
    }

    @media screen and (min-width: 800px)
    {
        #audioPlayers img{
            max-width: 55px;
        }
    }

    @-webkit-keyframes spin
    {
        0%   { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
    }

    @keyframes spin
    {
        0%   { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
</style>
