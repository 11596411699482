<template>
    <div id="modalQrCode" ref="modalQrCode" class="modals bgBlack">
        <div style="width: 100vw;height: 100vh;position: fixed;top: 0;left: 0;" data-response="0" v-on:click="$parent.qrcodeOpen=false;$parent.qrcodeUrl=''"></div>
        <div ref="buy-handler-content" class="modalGerais">
            <div class="modals__content alignVertical">
                <div class="modals__content--header alignVertical">
                    <p>{{ titleHeader }} </p>
                    <a href="#" class="close" data-response="0" v-on:click="$parent.qrcodeOpen=false;$parent.qrcodeUrl=''">
                        <img src="assets/icons/modals/x.svg" alt="">
                    </a>
                </div>
                <div class="modals__content--body alignVertical">
                    <!-- senha alterada -->
                    <div class="modalGerais__semMoedas alignVertical">
                        <div class="alignVertical col-12" ref="text-info">
                            <QrcodeVue :value="url" size="300" level="H"></QrcodeVue>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {mapGetters} from "vuex";
import QrcodeVue from "qrcode.vue";


export default {
    name: 'QrCodeGen',
    props: ['url','titleHeader'],
    computed: {
        ...mapGetters([
            'logged', 'token','switch'
        ]),
    },
    data() {
        return {
        }
    },
    components: {QrcodeVue},
    methods: {
    },
    created() {
    },
    mounted() {
    },
}
</script>

<style scoped>
    .popup-position {
        position: relative;
        top: 75px;
        right: 10px;
        max-width: 350px;
        float: right;
        width: 80%;
        display: flex;
        flex-direction: column;
    }

    .notification {
        width: 100%;
        cursor: pointer;
        max-height: 100px;
        height: 20%;
        position: relative;
        margin: 10px 0;
        background: rgba(1, 1, 1, 0.5);
        padding: .5rem 1rem;
        float: right;
    }
    .popup-close{
        position: absolute;
        top: 3px;
        right: 10px;
        color: black;
        font-family: cursive;
    }

    .notification-img img {
        width: 100%
    }
    .notification-text{
        color: #e2e2e2;
    }
    .notification-text .notification-title {
        font-weight: 900;
    }
    @media screen and (max-width: 500px)
    {

    }
</style>