<template>
    <div id="public_ranking" ref="public_ranking">
        <div v-if="currentScreen == 'ranking'" class="ranking">
            <div class="content">
                
                <button style="display: none;" v-on:click="$parent.modalI('Título do vídeo',my_link_video)">Assista</button>

                <!-- <div class="ranking__imgHeader flex flex-wrap">
                    <div class="swiper-container ranking__imgHeader--swiper">
                        <div class="swiper-wrapper">
                            <div v-for="(item, index) in reward" :key="`reward_${index}`" class="swiper-slide">
                                <img :src="item.image">
                            </div>
                        </div>
                    </div>
                </div> -->
                
                <div v-if="my_ranking_daily && ranking_daily_public && (Date.now() >=  (new Date(my_ranking_daily_ini)).getTime() && Date.now() <=  (new Date(my_ranking_daily_fin)).getTime())" class="container-master-ranking">
                    <div class="container-table-ranking">
                        <div class="ranking__table--box flex flex-wrap border">
                            <div class="container-header-ranking">
                                <div class="col-6 alignVerticalStart">
                                    <p><b>{{ $t('ranking.rankingDoDia') }}</b></p>
                                </div>
                                <div class="col-6 alignVerticalEnd">

                                    <p style="font-size: 12px">{{ $t('ranking.publicadoAs') }} {{ timestamp_daily }}</p>
                                </div>
                            </div>
                            <div class="container-gifts">
                                <div class="content-gift">
                                    <!-- <img :src="my_daily_reward_image"> -->
                                    <div class="present-desktop">
                                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.58984 10H2.58984V18C2.58984 18.5304 2.80056 19.0391 3.17563 19.4142C3.5507 19.7893 4.05941 20 4.58984 20H9.58984V10H3.58984ZM16.5898 10H11.5898V20H16.5898C17.1203 20 17.629 19.7893 18.0041 19.4142C18.3791 19.0391 18.5898 18.5304 18.5898 18V10H16.5898ZM17.3808 5C17.5275 4.51378 17.598 4.00779 17.5898 3.5C17.5898 1.57 16.0198 0 14.0898 0C12.4678 0 11.3848 1.482 10.6858 3.085C9.99684 1.57 8.85884 0 7.08984 0C5.15984 0 3.58984 1.57 3.58984 3.5C3.58984 4.096 3.66884 4.589 3.79884 5H0.589844V9H9.58984V7H11.5898V9H20.5898V5H17.3808ZM5.58984 3.5C5.58984 2.673 6.26284 2 7.08984 2C7.97784 2 8.80384 3.525 9.28784 5H6.58984C6.21584 5 5.58984 5 5.58984 3.5ZM14.0898 2C14.9168 2 15.5898 2.673 15.5898 3.5C15.5898 5 14.9638 5 14.5898 5H12.1128C12.6228 3.424 13.3638 2 14.0898 2Z"
                                                  fill="var(--buttons_color)"/>
                                        </svg>
                                    </div>
                                    <div class="present-mobile">
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M20.8574 28.8627H11.1431M3.04785 4.48178V7.49749C3.04785 9.49773 3.79353 11.4266 5.14018 12.9099L7.90499 15.9552M28.9526 4.48178V7.49749C28.9526 9.49773 28.2069 11.4266 26.8603 12.9099L24.0955 15.9552M7.90499 3.04761H24.0955V14.3417C24.0955 18.7971 20.4711 22.409 16.0002 22.409C11.5294 22.409 7.90499 18.7971 7.90499 14.3417V3.04761Z"
                                                  stroke="white" stroke-width="2"/>
                                        </svg>
                                    </div>
                                    <div>
                                        <span><b class="text-premiation desktop" >{{ $t('ranking.premiacao') }}</b></span>
                                        <span class="text-premiation" >{{my_daily_reward_description}}</span>
                                    </div>
                                </div>
                                <div class="content-gift container-time">
                                    <span class="rest-time">{{ $t('ranking.tempoRestante') }}</span>
                                    <span class="time">{{ relogio_restante_dia }}</span>
                                </div>
                            </div>
                            <!-- <div class="ranking__table--box---youRank flex col-12 new-layout-person">
                                <div class="col-6 alignVerticalStart flex">
                                    <div class="container-character-especification">
                                        <h3>{{ my_daily_ranking }}º</h3>
                                        <img :src="avatar" alt="imagem do meu personagem">
                                        <p><b>{{ nickname }}</b></p>
                                    </div>
                                </div>
                                <div class="col-6 alignVerticalEnd">
                                    <div class="col-10 alignVerticalEnd">
                                        <p><b>{{my_daily_point}}</b><span class="points" style="margin-left: 6px;">pts</span></p>
                                    </div>
                                    <div class="col-2 alignVerticalEnd">
                                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.75437 1.38428C4.72845 1.38405 4.70287 1.39026 4.67995 1.40237C4.65703 1.41447 4.63748 1.43209 4.62307 1.45364C4.60865 1.47519 4.59982 1.49998 4.59738 1.52579C4.59493 1.55159 4.59894 1.5776 4.60906 1.60147L4.6989 1.81358L6.28874 5.52062C6.29955 5.54583 6.3168 5.56775 6.33876 5.58419C6.36073 5.60062 6.38663 5.61098 6.41387 5.61422C6.44111 5.61747 6.46872 5.61349 6.49393 5.60268C6.51914 5.59187 6.54106 5.57462 6.55749 5.55265L9.49187 1.63428C9.50928 1.61107 9.51988 1.58347 9.52249 1.55457C9.52509 1.52567 9.5196 1.49661 9.50662 1.47066C9.49365 1.4447 9.4737 1.42288 9.44901 1.40762C9.42433 1.39236 9.39589 1.38428 9.36687 1.38428H4.75437Z" fill="white"/>
                                            <path d="M16.4034 2.40222L14.7909 6.16667C14.7807 6.19044 14.7766 6.21635 14.779 6.24208C14.7813 6.26782 14.79 6.29257 14.8042 6.31413C14.8184 6.33569 14.8378 6.35338 14.8606 6.36562C14.8833 6.37785 14.9088 6.38426 14.9346 6.38425H18.8799C18.9078 6.38426 18.9351 6.37683 18.9592 6.36271C18.9832 6.3486 19.003 6.32832 19.0165 6.30398C19.0301 6.27963 19.0369 6.25211 19.0362 6.22426C19.0355 6.19641 19.0274 6.16924 19.0127 6.14558L16.6795 2.38152C16.6645 2.35729 16.643 2.33764 16.6176 2.32472C16.5921 2.31181 16.5636 2.3061 16.5352 2.30824C16.5067 2.31037 16.4794 2.32026 16.4561 2.33683C16.4329 2.3534 16.4147 2.37601 16.4034 2.40222Z" fill="white"/>
                                            <path d="M3.32077 2.38313L0.988351 6.14563C0.973656 6.1693 0.965555 6.19647 0.964889 6.22432C0.964222 6.25217 0.971013 6.27969 0.984559 6.30403C0.998106 6.32838 1.01791 6.34866 1.04193 6.36277C1.06595 6.37688 1.09331 6.38432 1.12116 6.38431H5.06648C5.09233 6.38428 5.11778 6.37783 5.14053 6.36554C5.16328 6.35326 5.18263 6.33552 5.19684 6.31392C5.21105 6.29231 5.21967 6.26752 5.22194 6.24177C5.22421 6.21601 5.22005 6.19009 5.20984 6.16634L3.59421 2.40383C3.58266 2.37828 3.56443 2.3563 3.54146 2.34021C3.51848 2.32412 3.4916 2.31451 3.46363 2.3124C3.43566 2.31028 3.40764 2.31573 3.3825 2.32817C3.35737 2.34062 3.33604 2.3596 3.32077 2.38313V2.38313Z" fill="white"/>
                                            <path d="M15.3624 1.38428H10.462C10.4479 1.38419 10.434 1.38805 10.4219 1.39544C10.4099 1.40283 10.4001 1.41344 10.3938 1.42608C10.3874 1.43872 10.3848 1.45288 10.3861 1.46696C10.3873 1.48104 10.3925 1.49448 10.4011 1.50576L13.5229 5.66436C13.5309 5.67504 13.5416 5.68344 13.5538 5.68869C13.5661 5.69395 13.5795 5.69588 13.5927 5.6943C13.6059 5.69271 13.6185 5.68766 13.6292 5.67967C13.6398 5.67167 13.6482 5.661 13.6534 5.64874L15.2972 1.81397L15.43 1.48819C15.4347 1.477 15.4366 1.46485 15.4355 1.45278C15.4345 1.4407 15.4305 1.42905 15.424 1.41884C15.4175 1.40862 15.4085 1.40014 15.398 1.39413C15.3875 1.38811 15.3757 1.38473 15.3636 1.38428H15.3624Z" fill="white"/>
                                            <path d="M9.33616 17.6343L5.8428 7.73232C5.83254 7.70357 5.81362 7.6787 5.78865 7.66114C5.76368 7.64359 5.73387 7.6342 5.70334 7.63428H1.00413C0.976904 7.6342 0.950208 7.64177 0.927079 7.65613C0.90395 7.67048 0.885319 7.69105 0.873308 7.71547C0.861297 7.7399 0.856389 7.76722 0.859145 7.7943C0.8619 7.82138 0.872207 7.84714 0.888892 7.86865L9.32639 18.7987C9.35495 18.8357 9.3957 18.8614 9.4414 18.8712C9.48709 18.881 9.53478 18.8743 9.576 18.8522C9.61746 18.8304 9.64969 18.7944 9.66683 18.7509C9.68397 18.7073 9.68487 18.659 9.66936 18.6147L9.33616 17.6343Z" fill="white"/>
                                            <path d="M18.9956 7.63428H14.2964C14.266 7.63427 14.2364 7.6437 14.2116 7.66127C14.1868 7.67883 14.1681 7.70366 14.1581 7.73232L10.3222 18.5979C10.3061 18.6436 10.3067 18.6934 10.324 18.7386C10.3414 18.7838 10.3742 18.8213 10.4167 18.8444C10.4592 18.8674 10.5085 18.8746 10.5558 18.8647C10.6031 18.8547 10.6453 18.8283 10.6749 18.7901L19.1108 7.86865C19.1275 7.84714 19.1378 7.82138 19.1406 7.7943C19.1433 7.76722 19.1384 7.7399 19.1264 7.71547C19.1144 7.69105 19.0958 7.67048 19.0727 7.65613C19.0495 7.64177 19.0228 7.6342 18.9956 7.63428V7.63428Z" fill="white"/>
                                            <path d="M10.1251 3.21747L12.3126 6.13427C12.33 6.15748 12.3406 6.18508 12.3432 6.21398C12.3458 6.24288 12.3403 6.27194 12.3274 6.29789C12.3144 6.32385 12.2944 6.34567 12.2697 6.36093C12.2451 6.37618 12.2166 6.38426 12.1876 6.38426H7.8126C7.78358 6.38426 7.75514 6.37618 7.73046 6.36093C7.70577 6.34567 7.68582 6.32385 7.67285 6.29789C7.65987 6.27194 7.65438 6.24288 7.65698 6.21398C7.65959 6.18508 7.67019 6.15748 7.6876 6.13427L9.8751 3.21747C9.88966 3.19806 9.90853 3.18231 9.93022 3.17146C9.95192 3.16062 9.97584 3.15497 10.0001 3.15497C10.0244 3.15497 10.0483 3.16062 10.07 3.17146C10.0917 3.18231 10.1105 3.19806 10.1251 3.21747V3.21747ZM9.85166 15.339L7.20713 7.84247C7.19883 7.81891 7.19629 7.79371 7.19973 7.76897C7.20318 7.74423 7.2125 7.72068 7.22693 7.70029C7.24135 7.67989 7.26045 7.66326 7.28263 7.65177C7.30481 7.64028 7.32942 7.63428 7.3544 7.63426H12.6458C12.6708 7.63428 12.6954 7.64028 12.7176 7.65177C12.7397 7.66326 12.7589 7.67989 12.7733 7.70029C12.7877 7.72068 12.797 7.74423 12.8005 7.76897C12.8039 7.79371 12.8014 7.81891 12.7931 7.84247L10.1474 15.339C10.1366 15.3694 10.1166 15.3957 10.0903 15.4144C10.0639 15.433 10.0324 15.443 10.0001 15.443C9.96781 15.443 9.93632 15.433 9.90995 15.4144C9.88358 15.3957 9.86363 15.3694 9.85283 15.339H9.85166Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>
                            </div> -->
                            <div v-for="(item, index) in list_daily" :key="index" class="flex col-12">
                                <div class="container-players-painel">
                                    <div class="player-placement">
                                        <h3 class="colocation">{{ item ? item.ranking : '' }}</h3>
                                        <img :src="nationalityFlag(item.nationality)" alt="">
                                        <img :style="item.thumb.indexOf('ui-avatars.com')===-1?'object-position: 0px;':''" :src="item.thumb" alt="imagem do meu personagem">
                                        <!-- <img src="../../public/assets/icons/ranking/my-character.svg" alt="imagem do meu personagem"> -->
                                        <p class="player-name"><b>{{ item ? item.nickname : '' }}</b></p>
                                    </div>

                                    <div class="container-pontuation">
                                        <div>
                                            <p><b style="color: var(--buttons_color);font-size: 18px">{{ item ? item.daily_point : '' }}</b><span class="points" style="margin-left: 6px;color: #474747;font-size: 14px;">pts</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="my_ranking_general && ranking_general_public && (Date.now() >=  (new Date(my_ranking_general_ini)).getTime() && Date.now() <=  (new Date(my_ranking_general_fin)).getTime())" class="container-master-ranking">
                    <div class="container-table-ranking">
                        <div class="ranking__table--box flex flex-wrap border">
                            <div class="container-header-ranking">
                                <div class="col-6 alignVerticalStart">
                                    <p><b>{{ $t('ranking.rankingGeral') }}</b></p>
                                </div>
                                <div class="col-6 alignVerticalEnd">
                                    <p class="publicated" style="font-size: 14px; font-weight: 300;">{{ $t('ranking.publicadoAs') }} {{ timestamp_general }}</p>
                                </div>
                            </div>

                            <div class="container-gifts">
                                <div class="content-gift">
                                    <div>
<!--                                        <span><b class="text-premiation desktop" >{{ $t('ranking.premiacao') }}</b></span>-->
                                        <span class="text-premiation" v-html="regulation"></span>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="ranking__table--box---youRank flex col-12 new-layout-person">
                                <div class="col-6 alignVerticalStart flex">
                                    <div class="container-character-especification">
                                        <h3>{{ my_general_ranking }}º</h3>
                                        <img :src="avatar" style="background-position: center; background-size: cover;" alt="imagem do meu personagem">
                                        <p><b>{{ nickname }}</b></p>
                                    </div>
                                </div>
                                <div class="col-6 alignVerticalEnd">
                                    <div class="col-10 alignVerticalEnd">
                                        <p><b>{{my_point}}</b><span class="points" style="margin-left: 6px;">pts</span></p>
                                    </div>
                                    <div class="col-2 alignVerticalEnd">
                                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.75437 1.38428C4.72845 1.38405 4.70287 1.39026 4.67995 1.40237C4.65703 1.41447 4.63748 1.43209 4.62307 1.45364C4.60865 1.47519 4.59982 1.49998 4.59738 1.52579C4.59493 1.55159 4.59894 1.5776 4.60906 1.60147L4.6989 1.81358L6.28874 5.52062C6.29955 5.54583 6.3168 5.56775 6.33876 5.58419C6.36073 5.60062 6.38663 5.61098 6.41387 5.61422C6.44111 5.61747 6.46872 5.61349 6.49393 5.60268C6.51914 5.59187 6.54106 5.57462 6.55749 5.55265L9.49187 1.63428C9.50928 1.61107 9.51988 1.58347 9.52249 1.55457C9.52509 1.52567 9.5196 1.49661 9.50662 1.47066C9.49365 1.4447 9.4737 1.42288 9.44901 1.40762C9.42433 1.39236 9.39589 1.38428 9.36687 1.38428H4.75437Z" fill="white"/>
                                            <path d="M16.4034 2.40222L14.7909 6.16667C14.7807 6.19044 14.7766 6.21635 14.779 6.24208C14.7813 6.26782 14.79 6.29257 14.8042 6.31413C14.8184 6.33569 14.8378 6.35338 14.8606 6.36562C14.8833 6.37785 14.9088 6.38426 14.9346 6.38425H18.8799C18.9078 6.38426 18.9351 6.37683 18.9592 6.36271C18.9832 6.3486 19.003 6.32832 19.0165 6.30398C19.0301 6.27963 19.0369 6.25211 19.0362 6.22426C19.0355 6.19641 19.0274 6.16924 19.0127 6.14558L16.6795 2.38152C16.6645 2.35729 16.643 2.33764 16.6176 2.32472C16.5921 2.31181 16.5636 2.3061 16.5352 2.30824C16.5067 2.31037 16.4794 2.32026 16.4561 2.33683C16.4329 2.3534 16.4147 2.37601 16.4034 2.40222Z" fill="white"/>
                                            <path d="M3.32077 2.38313L0.988351 6.14563C0.973656 6.1693 0.965555 6.19647 0.964889 6.22432C0.964222 6.25217 0.971013 6.27969 0.984559 6.30403C0.998106 6.32838 1.01791 6.34866 1.04193 6.36277C1.06595 6.37688 1.09331 6.38432 1.12116 6.38431H5.06648C5.09233 6.38428 5.11778 6.37783 5.14053 6.36554C5.16328 6.35326 5.18263 6.33552 5.19684 6.31392C5.21105 6.29231 5.21967 6.26752 5.22194 6.24177C5.22421 6.21601 5.22005 6.19009 5.20984 6.16634L3.59421 2.40383C3.58266 2.37828 3.56443 2.3563 3.54146 2.34021C3.51848 2.32412 3.4916 2.31451 3.46363 2.3124C3.43566 2.31028 3.40764 2.31573 3.3825 2.32817C3.35737 2.34062 3.33604 2.3596 3.32077 2.38313V2.38313Z" fill="white"/>
                                            <path d="M15.3624 1.38428H10.462C10.4479 1.38419 10.434 1.38805 10.4219 1.39544C10.4099 1.40283 10.4001 1.41344 10.3938 1.42608C10.3874 1.43872 10.3848 1.45288 10.3861 1.46696C10.3873 1.48104 10.3925 1.49448 10.4011 1.50576L13.5229 5.66436C13.5309 5.67504 13.5416 5.68344 13.5538 5.68869C13.5661 5.69395 13.5795 5.69588 13.5927 5.6943C13.6059 5.69271 13.6185 5.68766 13.6292 5.67967C13.6398 5.67167 13.6482 5.661 13.6534 5.64874L15.2972 1.81397L15.43 1.48819C15.4347 1.477 15.4366 1.46485 15.4355 1.45278C15.4345 1.4407 15.4305 1.42905 15.424 1.41884C15.4175 1.40862 15.4085 1.40014 15.398 1.39413C15.3875 1.38811 15.3757 1.38473 15.3636 1.38428H15.3624Z" fill="white"/>
                                            <path d="M9.33616 17.6343L5.8428 7.73232C5.83254 7.70357 5.81362 7.6787 5.78865 7.66114C5.76368 7.64359 5.73387 7.6342 5.70334 7.63428H1.00413C0.976904 7.6342 0.950208 7.64177 0.927079 7.65613C0.90395 7.67048 0.885319 7.69105 0.873308 7.71547C0.861297 7.7399 0.856389 7.76722 0.859145 7.7943C0.8619 7.82138 0.872207 7.84714 0.888892 7.86865L9.32639 18.7987C9.35495 18.8357 9.3957 18.8614 9.4414 18.8712C9.48709 18.881 9.53478 18.8743 9.576 18.8522C9.61746 18.8304 9.64969 18.7944 9.66683 18.7509C9.68397 18.7073 9.68487 18.659 9.66936 18.6147L9.33616 17.6343Z" fill="white"/>
                                            <path d="M18.9956 7.63428H14.2964C14.266 7.63427 14.2364 7.6437 14.2116 7.66127C14.1868 7.67883 14.1681 7.70366 14.1581 7.73232L10.3222 18.5979C10.3061 18.6436 10.3067 18.6934 10.324 18.7386C10.3414 18.7838 10.3742 18.8213 10.4167 18.8444C10.4592 18.8674 10.5085 18.8746 10.5558 18.8647C10.6031 18.8547 10.6453 18.8283 10.6749 18.7901L19.1108 7.86865C19.1275 7.84714 19.1378 7.82138 19.1406 7.7943C19.1433 7.76722 19.1384 7.7399 19.1264 7.71547C19.1144 7.69105 19.0958 7.67048 19.0727 7.65613C19.0495 7.64177 19.0228 7.6342 18.9956 7.63428V7.63428Z" fill="white"/>
                                            <path d="M10.1251 3.21747L12.3126 6.13427C12.33 6.15748 12.3406 6.18508 12.3432 6.21398C12.3458 6.24288 12.3403 6.27194 12.3274 6.29789C12.3144 6.32385 12.2944 6.34567 12.2697 6.36093C12.2451 6.37618 12.2166 6.38426 12.1876 6.38426H7.8126C7.78358 6.38426 7.75514 6.37618 7.73046 6.36093C7.70577 6.34567 7.68582 6.32385 7.67285 6.29789C7.65987 6.27194 7.65438 6.24288 7.65698 6.21398C7.65959 6.18508 7.67019 6.15748 7.6876 6.13427L9.8751 3.21747C9.88966 3.19806 9.90853 3.18231 9.93022 3.17146C9.95192 3.16062 9.97584 3.15497 10.0001 3.15497C10.0244 3.15497 10.0483 3.16062 10.07 3.17146C10.0917 3.18231 10.1105 3.19806 10.1251 3.21747V3.21747ZM9.85166 15.339L7.20713 7.84247C7.19883 7.81891 7.19629 7.79371 7.19973 7.76897C7.20318 7.74423 7.2125 7.72068 7.22693 7.70029C7.24135 7.67989 7.26045 7.66326 7.28263 7.65177C7.30481 7.64028 7.32942 7.63428 7.3544 7.63426H12.6458C12.6708 7.63428 12.6954 7.64028 12.7176 7.65177C12.7397 7.66326 12.7589 7.67989 12.7733 7.70029C12.7877 7.72068 12.797 7.74423 12.8005 7.76897C12.8039 7.79371 12.8014 7.81891 12.7931 7.84247L10.1474 15.339C10.1366 15.3694 10.1166 15.3957 10.0903 15.4144C10.0639 15.433 10.0324 15.443 10.0001 15.443C9.96781 15.443 9.93632 15.433 9.90995 15.4144C9.88358 15.3957 9.86363 15.3694 9.85283 15.339H9.85166Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>
                            </div> -->

                            <div v-for="(item, index) in list_general" :key="index" class="flex col-12">
                                <div class="container-players-painel">

                                    <div class="player-placement" style="max-width: 33%; min-width: 33%;">
                                        <h3 class="colocation">{{ item ? item.ranking : '' }}</h3>
                                        <img :src="nationalityFlag(item.nationality)" alt="">
                                        <img :style="item.thumb.indexOf('ui-avatars.com')===-1?'object-position: 0px;':''"
                                        :src="item.thumb" alt="imagem do meu personagem">
                                        <!-- <img src="../../public/assets/icons/ranking/my-character.svg" alt="imagem do meu personagem"> -->
                                        <p class="player-name"><b>{{ item ? item.nickname : '' }}</b></p>
                                    </div>

                                    <div class="container-pontuation">
                                        <div>
                                            <p><b style="color: var(--buttons_color);font-size: 18px">{{ item ? item.total_points : '' }}</b><span class="points" style="margin-left: 6px;color: var(--buttons_color);font-size: 14px;">pts</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <h1 v-if="list_winner_daily.length>0 && list_winner_general.length>0" class="col-12 alignVerticalEnd" style="font-weight: 300;font-size: 24px;color: var(--buttons_color);">{{ $t('ranking.ganhadores') }}</h1>
                <div class="ranking__winners flex flex-wrap  mobile-change">
                    <h1 v-if="list_winner_daily.length>0" class="col-12 alignVerticalStart" style="font-weight: 300;font-size: 21px;color: var(--buttons_color);">{{ $t('ranking.diario') }}</h1>


                    <div v-if="list_winner_daily.length>0" class="swiper-container ranking__winners--winner">
                        <div class="swiper-wrapper" style="display: flex;justify-content: space-around;">
                            <!-- for -->
                            <div v-for="(item,index) in list_winner_daily" :key="index+'winner_daily_mobile'" class="swiper-slide">
                                <div class="card-ranking">
                                    <div class="box__imgAvatar">
                                        <!-- <img src="../../public/assets/icons/ranking/my-friends.svg" alt="imagem de um dos seus amigos"> -->
                                        <img v-if="item.avatar"
                                             :style="item.avatar.indexOf('ui-avatars.com')===-1?'object-position: 0px;':''"
                                             :src="item.avatar" alt="avatar">
<!--                                        <img v-else src="https://via.placeholder.com/300" alt="avatar">-->
                                    </div>
                                    
                                    <div class="col-12 flex">
                                        <img src="../../public/assets/icons/ranking/premiation.svg" alt="imagem de premiação">
                                    </div>
                                    <div class="col-12 flex">
                                        <p style="font-size: 16px;text-transform: uppercase;color: var(--buttons_color);">{{item.points}}</p>
                                    </div>
                                    <div class="col-8 flex">
                                        <h3 style="font-weight: bold;font-size: 18px;text-transform: uppercase;color: var(--buttons_color);">{{item.nickname}}</h3>
                                    </div>
                                    <h2 class="player-position" >{{item.date}}</h2>
                                </div>
                            </div>
                            
                        </div>
                        <div class="swiper-pagination"></div>
                    </div>
                </div>

                <div  v-if="list_winner_general.length>0" class="ranking__winners flex flex-wrap  mobile-change">

                    <h1 class="col-12 alignVerticalStart" style="font-weight: 300;font-size: 21px;color: var(--buttons_color);">{{ $t('ranking.geral') }}</h1>


                    <div class="swiper-container ranking__winners--winner">
                        <div class="swiper-wrapper" style="display: flex;justify-content: space-around;">
                            <!-- for -->
                            <div v-for="(item,index) in list_winner_general" :key="index+'winner_general_mobile'" class="swiper-slide">
                                <div class="card-ranking">
                                    <div class="box__imgAvatar">
                                        <img v-if="item.avatar"
                                             :style="item.avatar.indexOf('ui-avatars.com')===-1?'object-position: 0px;':''"
                                             :src="item.avatar" alt="avatar">
<!--                                        <img v-else src="https://via.placeholder.com/300" alt="avatar">-->
                                    </div>
                                    
                                    <div class="col-12 flex">
                                        <img src="../../public/assets/icons/ranking/premiation.svg" alt="imagem de premiação">
                                    </div>
                                    <div class="col-12 flex">
                                        <p style="font-size: 16px;text-transform: uppercase;color: var(--buttons_color);">{{item.points}}</p>
                                    </div>
                                    <div class="col-8 flex">
                                        <h3 style="font-weight: bold;font-size: 18px;text-transform: uppercase;color: var(--buttons_color);">{{item.nickname}}</h3>
                                    </div>
                                    <h2 class="player-position" >{{item.position}}º</h2>
                                </div>
                            </div>
                            <!-- fim for -->

                            
                        </div>
                        <div class="swiper-pagination"></div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="currentScreen == 'qrcode' && qrcode" class="alignVertical" style="height: 100vh">
            <img :src="qrcode" style="height: 100vh; width: 100vw; position: absolute; object-fit: cover; object-position: center;">
        </div>
    </div>
</template>

<script>

import axios from 'axios';
import { mapGetters } from "vuex";
import Swiper from 'swiper/bundle';

export default {
    name: 'PublicRanking',
    computed: {
        ...mapGetters([
            'logged', 'switch'
        ]),
    },
    data() {
        return {
            currentScreen: 'ranking',
            qrcode: '',
            timer: 30,
            timer_countdown: null,
            swiper_imgHeader: null,
            swiper_winners: null,
            timestamp_general: null,
            timestamp_daily: null,
            list_daily: [],
            list_general: [],
            list_daily_friends: [],
            list_general_friends: [],
            list_winner_daily: [],
            list_winner_general: [],
            winner_general:{
                divulgation:false,
                date:'',
                time:'',
                winner:[],
            },
            my_daily_point: 0,
            my_daily_ranking: 0,
            my_general_ranking: 0,
            my_point: 0,
            active_tab: 'geral',
            relogio_restante_dia: '-',
            relogio_restante_geral: '-',
            tutorial: false,
            my_link_video: null,
            my_daily_reward_description: null,
            my_general_reward_description: null,
            my_daily_reward_image: null,
            my_general_reward_image: null,
            my_ranking_general_fin: null,
            my_ranking_general_ini: null,
            my_ranking_daily_fin: null,
            my_ranking_daily_ini: null,
            my_ranking_general: false,
            my_ranking_daily: false,
            ranking_general_public: false,
            ranking_daily_public: false,
            reward: [],
            regulation:''
        }
    },
    components: {

    },
    methods: {
        changeScreen()
        {
            setInterval(() => {
                if(this.qrcode) {
                    if(this.currentScreen == 'ranking') {
                        this.currentScreen = 'qrcode';
                    } else {
                        this.generalLoadEvents();
                        this.dailyLoadEvents();
                        this.generalWinners();
                        this.dailyWinners();
                        this.currentScreen = 'ranking';
                    }
                }
            }, this.getScreenTimer());
        },
        getScreenTimer()
        {
            return this.timer * 1000;
        },
        nationalityFlag(country) {
            let path = this.$url + `assets/img/flags/${country}.svg`
            return  path
        },
        tutorialHandler(e){
            e;
            this.$parent.analytics('tutorial_ranking');
            this.tutorial = !this.tutorial;
        },
        //----- general -----//
        generalLoadEvents() {
            this.loading = true;
            this.$parent.wait(true);
            let headers = { 'Content-Type': 'application/json;charset=UTF-8', 'Access': 'application/json', language: this.$i18n.locale};
            axios.get(this.$url_api + 'api/GetPublicGeneralRanking?limit=30', {headers:headers }).then(response => this.generalLoadResponse(response)).catch(error => this.generalLoadError(error.response));
        },
        generalLoadResponse(obj)
        {
            obj;
            // console.log('generalLoadResponse -> ', obj);

            if (obj.data.status == 'success') {
                this.list_general = obj.data.GetGeneralRanking;
                this.timestamp_general = obj.data.timestamp;
            }

            this.$parent.wait(false);
        },
        generalLoadError(obj)
        {
            // console.log('generalLoadError -> ', obj);
            obj;
            this.$parent.wait(false);
        },
        //----- \general\ -----//
        // ----- general -----//
        generalWinners() {
            this.loading = true;
            this.$parent.wait(true);
            let headers = { 'Content-Type': 'application/json;charset=UTF-8', 'Access': 'application/json', language: this.$i18n.locale};
            axios.get(this.$url_api + 'api/GetPublicWinnerGeneral', { headers:headers }).then(response => this.generalWinnersResponse(response)).catch(error => this.generalWinnersError(error.response));
        },
        generalWinnersResponse(obj)
        {
            obj;
            // console.log('generalWinnerResponse -> ', obj);

            if (obj.data.status == 'success') {
                this.list_winner_general = obj.data.data.winner ?? [];
                this.winner_general = obj.data.data;
            }

            console.log('generalWinnerResponse -> ', this.list_winner_general);

            this.$parent.wait(false);
        },
        generalWinnersError(obj)
        {
            //console.log('generalLoadError -> ', obj);
            obj;
            this.$parent.wait(false);
        },
        //----- \general\ -----//
        // ----- general -----//
        dailyWinners() {
            this.loading = true;
            this.$parent.wait(true);
            let headers = { 'Content-Type': 'application/json;charset=UTF-8', 'Access': 'application/json', language: this.$i18n.locale};
            axios.get(this.$url_api + 'api/GetPublicWinnerDaily', { headers:headers }).then(response => this.dailyWinnersResponse(response)).catch(error => this.dailyWinnersError(error.response));
        },
        dailyWinnersResponse(obj)
        {
            obj;
            console.log('dailyWinnersResponse -> ', obj);

            if (obj.data.status == 'success') {
                this.list_winner_daily = obj.data.data ?? [];
            }

            this.$parent.wait(false);
        },
        dailyWinnersError(obj)
        {
            //console.log('generalLoadError -> ', obj);
            obj;
            this.$parent.wait(false);
        },
        //----- \general\ -----//

        //----- daily -----//
        dailyLoadEvents() {
            this.loading = true;
            this.$parent.wait(true);
            let headers = { 'Content-Type': 'application/json;charset=UTF-8', 'Access': 'application/json', language: this.$i18n.locale};
            axios.get(this.$url_api + 'api/GetPublicDailyRanking?limit=30',{ headers:headers }).then(response => this.dailyLoadResponse(response)).catch(error => this.dailyLoadError(error.response));
        },
        dailyLoadResponse(obj)
        {
            obj;
            // console.log('dailyLoadResponse -> ', obj);

            if (obj.data.status == 'success') {
                this.list_daily = obj.data.GetDailyRanking;
                this.timestamp_daily = obj.data.timestamp;
            }

            this.$parent.wait(false);
        },
        dailyLoadError(obj)
        {
            // console.log('dailyLoadError -> ', obj);
            obj;
            this.$parent.wait(false);
        },
        //----- \general\ -----//

        //Obtendo infos de ranking (prêmios, regulamento, diversos)
        rankingConfigLoadEvents() {
            this.loading = true;
            this.$parent.wait(true);
            let headers = { 'Content-Type': 'application/json;charset=UTF-8', 'Access': 'application/json', language: this.$i18n.locale};
            axios.get(this.$url_api + 'api/public_ranking_config', { headers:headers }).then(response => this.rankingConfigLoadResponse(response)).catch(error => this.rankingConfigLoadError(error.response));
        },
        rankingConfigLoadResponse(obj)
        {
            obj;
            console.log('rankingConfigLoadResponse -> ', obj.data);

            if (obj.data.status == 'success') {
                this.qrcode = obj.data.qrcode;
                this.timer = obj.data.timer ?? 30;
                this.my_link_video                 = obj.data.link_video;
                this.reward        = obj.data.reward;
                this.my_ranking_general        = obj.data.ranking_general;
                this.ranking_general_public    = obj.data.ranking_general_public;
                this.my_ranking_general_fin        = obj.data.ranking_general_fin;
                this.my_ranking_general_ini        = obj.data.ranking_general_ini;
                this.my_ranking_daily        = obj.data.ranking_daily;
                this.ranking_daily_public       =  obj.data.ranking_daily_public;
                this.my_ranking_daily_fin        = obj.data.ranking_daily_fin;
                this.my_ranking_daily_ini        = obj.data.ranking_daily_ini;
                axios.get(this.$url_api + "api/getPublicRankingRegulation").then(reponse=>{
                    this.regulation = reponse.data.data
                });

                this.changeScreen();
            }

            this.$parent.wait(false);
        },
        rankingConfigLoadError(obj)
        {
            obj;
            this.$parent.wait(false);
        }
    },
    created() {
        console.log('caindo no created')
    },
    mounted() {
        var root = this;
        this.rankingConfigLoadEvents();

        // console.log('aqui');
        // console.log({generalFin:this.my_ranking_general_fin, tutorial:this.tutorial});

        this.swiper_winners = new Swiper(".ranking__winners--winner", {
            slidesPerView: 5,
            spaceBetween: 15,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
        });

        this.swiper_imgHeader = new Swiper(".ranking__imgHeader--swiper", {
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            autoplay: {
                delay: 6000,
                disableOnInteraction: false,
            },
        });

        this.timer_countdown = setInterval(function(){
            // countdown diario
            let dt = new Date();
            let segundos_diff = 24*60*60 - (dt.getHours()*3600 + dt.getMinutes()*60 + dt.getSeconds());
            segundos_diff = Math.max(0,segundos_diff);
            let hh = parseInt(segundos_diff/3600);   
            segundos_diff -= hh*3600;
            let mm = parseInt(segundos_diff/60);   
            segundos_diff -= mm*60;
            hh =("0"+hh).slice(-2); // 00
            mm =("0"+mm).slice(-2); // 00 
            segundos_diff =("0"+segundos_diff).slice(-2); // 00
            root.relogio_restante_dia = `${hh}:${mm}:${segundos_diff}`;

            // countdown geral fim 
            let dt_fim = new Date(root.my_ranking_general_fin);
            let diff_geral = parseInt((dt_fim - dt)/1000);
            diff_geral = Math.max(0,diff_geral);
            let g_dia = parseInt(diff_geral/(24*60*60));
            diff_geral -= g_dia*24*60*60;
            let g_hh = parseInt(diff_geral/3600);   
            diff_geral -= g_hh*3600;
            let g_mm = parseInt(diff_geral/60);   
            diff_geral -= g_mm*60;
            g_hh =("0"+g_hh).slice(-2); // 00
            g_mm =("0"+g_mm).slice(-2); // 00 
            diff_geral =("0"+diff_geral).slice(-2); //00

            root.relogio_restante_geral = '';
            if (g_dia > 1)
                root.relogio_restante_geral = `${g_dia}`;
            else if (g_dia == 1)
                root.relogio_restante_geral = root.$i18n.t('account.1dia');

            root.relogio_restante_geral += `${g_hh}:${g_mm}:${diff_geral}`;            
        },1000);
        this.$parent.analytics('visit_ranking');
        let d = new Date();
        this.actual_date = (d.getHours() + ':' + d.getMinutes() + ' ' + d.getDate() + '/' + d.getMonth() + '/' + d.getFullYear());
        this.generalLoadEvents();
        this.dailyLoadEvents();
        this.generalWinners();
        this.dailyWinners();
    },
    beforeDestroy() {
        clearInterval(this.timer_countdown);
    },
    updated: function () {
        if (this.swiper_imgHeader)
            this.swiper_imgHeader.update();

        if (this.swiper_winners)
            this.swiper_winners.update();
    },
}
</script>