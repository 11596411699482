<template>
    <div id="success" ref="sucess">
        <div class="box" id="success-container">
            <ul>
                <li><h1 v-text="$ml.get('sucesso.title')"></h1></li>
                <li><h3 v-text="$ml.get('sucesso.success')"></h3></li>
                <li><img :src="$parent.$parent.url + '/assets/img/oracle/sucesso.webp'"></li>
                <li>
                    <!--<router-link to="/" class="btn-1" id="btn-close" ref="btn-close" v-text="$ml.get('sucesso.back')"></router-link>-->
                    <a href="#" class="btn-1" id="btn-close" ref="btn-close" v-on-click="$parent.success = false" v-text="$ml.get('sucesso.back')"></a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Sucesso",
        mounted() {
            this.$parent.getPoints();
        }
    }
</script>

<style scoped>
    #success
    {
        background-color: rgba(0, 0, 0, .6);
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
    }
    h1
    {
        box-sizing: border-box;
        text-align: center;
        padding: 10px 0;
    }
    h3
    {
        font-weight: normal;
    }

    #success-container
    {
        display: block;
        margin: 150px  auto;
        max-height: 80%;
        max-width: 500px;
        overflow-y: auto;
        text-align: center;
    }

    #success-container > ul > li
    {
        box-sizing: border-box;
        padding: 15px 10px;
    }

    @media screen and (max-width: 500px) {
        #success-container {
            width: calc(100% - 40px);
        }
    }
</style>