<template>
    <div id="orders">
        <div class="box" id="orders-container">
            <a href="#" id="btn-close" ref="btn-close" v-on:click="$parent.orders=false"><img :src="$url + 'assets/img/oracle/x_24.png'"></a>
            <br>
            <h3>Minhas compras</h3>
            <span v-if="!orders.length" style="display:block;padding: 20px 0">Nenhuma compra efetuada até o momento.</span>
            <hr>
            <ul>
                <li v-for="(item, index) in orders" :key="index">
                    ORDER ID: <b>{{ item.order_id }}</b><br><br>
                    Data: <b>{{ item.created_at }}</b><br><br>
                    Status: <b>{{ item.status }}</b><br><br>
                    <div id="order-items">
                        <ul>
                            <li v-for="(item_cart, index_cart) in JSON.parse(item.items)" :key="index_cart">
                                <img :src="store_urls.url_api + 'storage/product/' + item_cart.id + '/' + item_cart.files[0].name + '_100.' + item_cart.files[0].ext" alt="Thumb">
                                <div class="proudct-info">
                                    Preço: <b>R$ {{ String(Number(item_cart.price).toFixed(2)).replace(".", ",") }}</b><br>
                                    Quantidade: <b>{{ item_cart.quantity }}</b><br>
                                    Total: <b>R$ {{ String(Number(item_cart.amount).toFixed(2)).replace(".", ",") }}</b>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <hr>
                </li>
            </ul>
            <button class="btn-1" type="button" v-on:click="$parent.orders=false">Cancelar</button>
        </div>
    </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import axios from "axios";
    export default {
        name: "Order",
        computed: {
            ...mapGetters([
                'counter', 'token', 'logged', 'products', 'products_ids', 'products_n', 'points', 'id', 'email', 'first_name', 'last_name', 'store_urls'
            ])
        },
        data() {
            return {
                orders: []
            }
        },
        methods: {
            ...mapActions([
                'update', 'update_cart', 'update_cart_products', 'del_product_cart', 'update_points'
            ]),
            get_orders()
            {
                this.$parent.$parent.wait(true);
                let headers = { "Content-Type": "application/json;charset=UTF-8", Access: "application/json", language: this.$i18n.locale, Authorization: `Bearer ${this.token}` };
                axios.post(this.store_urls.url_api + 'api/store/get_orders', null, { headers: headers }).then(response => this.getOrdersResponse(response));
            },
            getOrdersResponse(r)
            {
                this.$parent.$parent.wait(false);
                this.orders = r.data;
            }
        },
        mounted() {
            this.get_orders();
        }
    }
</script>

<style scoped>
    #orders
    {
        background-color: rgba(0, 0, 0, .6);
        color: black;
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
    }
    #orders-container
    {
        box-sizing: border-box;
        display: block;
        margin: 150px  auto;
        max-height: 80%;
        max-width: 600px;
        overflow-y: auto;
        padding: 20px;
    }
    #orders-container > ul > li
    {
        box-sizing: border-box;
        display: block;
        padding: 10px;
    }
    #order-items > ul > li
    {
        box-sizing: border-box;
        display: block;
        padding: 10px;
    }
    #order-items > ul > li *
    {
        display: inline-block;
        vertical-align: top;
    }
    #order-items > ul > li:nth-child(odd)
    {
        background-color: #e5e5e5;
    }
    .proudct-info
    {
        line-height: 25px;
        margin-left: 10px;
    }
</style>