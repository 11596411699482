<template>
    <div id="product">
        <div v-if="product" class="box" id="product-container">
            <a href="#" id="btn-close" ref="btn-close" v-on:click="$parent.product=false"><img :src="$url + 'assets/img/oracle/x_24.png'"></a>
            <div>
                <img v-if="product_actual" :src="product_actual" style="max-width: 100%;max-height:270px">
            </div>
            <hr v-show="product.files.length > 1">
            <div v-show="product.files.length > 1" id="thumbs" ref="thumbs">
                <ul>
                    <li v-for="(item, index) in this.product.files" :key="index" :data-index="index" v-on:click="image_handler">
                        <img :src="store_urls.url_api + 'storage/product/' + product.id + '/' + item.name + '_100.' + item.ext" alt="Thumb" width="70">
                    </li>
                </ul>
            </div>
            <hr>
            <div>
                <ul>
                    <li>
                        <h3>{{ product.title }}</h3>
                    </li>
                    <li>
                        {{ product.description }}
                    </li>
                    <li>
                        <h3 style="padding: 10px"><b>R$ {{ Number(product.price).toFixed(2).replace(".", ",") }}</b></h3>
                    </li>
                    <li>
                        <ul>
                            <li><a class="less" v-on:click="decrement">-</a></li>
                            <li id="product-count">{{ count }}</li>
                            <li><a class="more" v-on:click="increment">+</a></li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div id="product-button">
                <ul>
                    <li>
                        <a class="btn-0" href="#" v-on:click="$parent.product = null">Cancelar</a>
                    </li>
                    <li><button class="btn-1" ref="btn-submit" type="button" v-on:click="add">Adicionar</button></li>
                </ul>
            </div>
        </div>
    </div>

</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import axios from "axios";
    export default {
        name: "Product",
        props: ['product_sku'],
        computed: {
            ...mapGetters([
                'token', 'logged', "store_urls"
            ]),
        },
        data() {
            return {
                count: 1,
                product: null,
                product_actual: null
            }
        },
        methods: {
            ...mapActions([
                'update_cart_products'
            ]),

            image_handler(e)
            {
                console.log(e.currentTarget.dataset.index);
                this.product_actual = this.store_urls.url_api + 'storage/product/' + this.product.id + '/' + this.product.files[e.currentTarget.dataset.index].name + '_600.' + this.product.files[e.currentTarget.dataset.index].ext;
                e.preventDefault();
            },

            increment(e) {
                this.count++;

                e.preventDefault();
            },
            decrement (e) {
                if (this.count > 1)
                    this.count--;

                e.preventDefault();
            },

            add()
            {
                let l_ = { id:this.product.id, quantity:this.count, description:this.product.description, price:this.product.price, amount:(this.count * this.product.price), files:this.product.files };
                this.update_cart_products(l_);

                this.$parent.add_success = true;
                this.$parent.product = null;
                this.$parent.cart = true;
            },

            get()
            {
                this.$parent.$parent.wait(true);
                let form_data = new FormData();
                form_data.append('sku', this.product_sku);
                let headers = { "Content-Type": "application/json;charset=UTF-8", Access: "application/json", language: this.$i18n.locale, Authorization: `Bearer ${this.token}` };
                axios.post(this.store_urls.url_api + 'api/store/product', form_data, { headers: headers }).then(response => this.getResponse(response));
            },
            getResponse(obj)
            {
                console.log('get product response => ', obj);
                if (obj.data)
                {
                    this.product = obj.data;
                    let files_final = [];
                    for (let o in obj.data.files)
                    {
                        files_final.push({ name: obj.data.files[o].split("_")[0], ext:obj.data.files[o].split(".")[1] });
                    }
                    this.product.files = files_final;
                    this.product_actual = this.store_urls.url_api + 'storage/product/' + this.product.id + '/' + this.product.files[0].name + '_600.' + this.product.files[0].ext;
                }
                this.$parent.$parent.wait(false);
                
            }
        },
        mounted() {
            console.log('product mounted => ', this.product_sku);
            
            this.get();
        }
    }
</script>

<style scoped>
    #product
    {
        background-color: rgba(0, 0, 0, .6);
        font-family: inherit;
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
    }
    h1
    {
        box-sizing: border-box;
        text-align: center;
        padding: 10px 0;
    }
    h3
    {
        font-weight: normal;
    }
    #product #product-container
    {
        display: block;
        margin: 150px  auto;
        max-height: 80%;
        max-width: 500px;
        overflow-y: auto;
    }
    #product #product-container div
    {
        display: inline-block;
        vertical-align: top;
        text-align: center;
        width: 100%;
    }
    @media screen and (max-width: 500px)
    {
        #product-container
        {
            width: calc(100% - 40px);
        }
    }

    #product ul
    {
        margin: 0;
        padding: 0;
        width: 100%;
    }
    #product ul > li > ul > li
    {
        box-sizing: border-box;
        cursor: pointer;
        display: inline-block;
        padding: 10px;
    }
    #product .less, #product .more
    {
        background-color: black;
        border-radius: 10px;
        color: white !important;
        display: block;
        font-family: inherit;
        font-size: 30px;
        font-weight: bold;
        outline: none;
        height: 50px;
        line-height: 50px;
        text-align: center;
        user-select: none;
        width: 70px;
    }
    #product #product-count
    {
        color: #13b5eb;
        font-family: inherit;
        font-size: 35px;
        font-weight: bold;
        height: 50px;
        text-align: center;
        width: 50px;
    }


    #product-button
    {
        width: 100%;
    }
    #product-button > ul > li
    {
        box-sizing: border-box;
        list-style: none;
        display: inline-block;
        padding: 5px;
        width: calc(100% / 2);
    }

    #thumbs
    {
        border-radius: 10px;
        box-sizing: border-box;
        display: block;
        margin: 5px 0;
    }
    #thumbs > ul > li
    {
        box-sizing: border-box;
        cursor: pointer;
        display: inline-block;
        padding: 2px 5px;
    }
</style>