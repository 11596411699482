<template>
    <div>
        <button :disabled="!this.active" v-on:click="logInWithAzure" type="button">
            <picture>
                <img v-if="!image" alt="Azure icon" width="46px" height="46px" :src="$url + '/assets/img/azure.webp'">
                <img v-else alt="Azure icon" width="46px" height="46px" :src="image">
            </picture>
            <span v-if="['login','register'].includes(this.origin)">{{ this.text?this.text:$t('login.azure')}}</span>
        </button>
    </div>
</template>
<script>
    import {PublicClientApplication} from "@azure/msal-browser";

    export default {
        name:"LoginAzure",
        data() {
            return {
                image:null,
                text:null,
                msalInstance: {},
                parentComponent: null
            }
        },
        props: ['origin','active'],
        methods: {
            async logInWithAzure() {
                let root_ = this;
                root_.parentComponent.wait(true);
                await root_.msalInstance
                .loginPopup({scopes: ["user.read"],})
                .then((account) => {
                    let data = { type:'azure', origin: root_.origin, id:account.account.localAccountId,token:account.accessToken, email:account.account.username, name:account.account.username.split("@")[0] };
                    root_.$parent.loginSocial(data);
                    root_.parentComponent.wait(false);
                    })
                    .catch(error => {
                        console.error(`error during authentication: ${error}`);
                        root_.parentComponent.wait(false);
                    });
                return false;
            },
            initAzure(){
                const _root = this;
                this.parentComponent.decryptUnity(
                    this.parentComponent.keyUnityConf,
                    this.parentComponent.switch.azure_conf.i,
                    this.parentComponent.switch.azure_conf.c
                ).then(objDec => {
                    _root.image = objDec.image;
                    _root.text = objDec.text;
                    _root.msalInstance = new PublicClientApplication({
                        auth: {
                            clientId: objDec.client_id,
                            authority: "https://login.microsoftonline.com/"+objDec.directory_id,
                        },
                        cache: {
                            cacheLocation: 'localStorage',
                        },
                    });
                });
            }
        },
        mounted() {
            this.parentComponent = this.$parent.$parent;
            if (this.parentComponent.$parent === undefined){
                this.parentComponent = this.$parent;
            }
            this.initAzure();
        }
    }
</script>

<style scoped>
    button, picture
    {
        background: #fdfdfc;
        border: none;
        display: block;
    }
    picture
    {
        font-size: 0;
    }
    button{
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 2px 5px;
        display: flex;
        align-items: center;
        gap: 5px;
    }
    span{
        font-size: 1rem;
        font-weight: 600;
        color: #474747;
    }
    button:disabled{
        cursor: not-allowed;
        opacity: 0.8;
    }
    img{
        margin: 0!important;
        width: 46px;
        height: 46px;
    }
</style>