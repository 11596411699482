<template>
  <button class="personal-advisor">
    <i class="material-icons">support_agent</i>
    <span>Fale com seu Mentor Pessoal</span>
  </button>
</template>

<script>
  export default {
    name: "PersonalAdvisor"
  }
</script>

<style scoped lang="scss">

  .personal-advisor {
    position: fixed;
    bottom: 48px;
    right: 220px;
    background-color: #FFFFFF;
    color: #706F6F;
    padding: 16px 30px;
    display: flex;
    font-size: 20px;
    z-index: 1;

    border: none;
    border-radius: 30px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);

    .material-icons {
      color: #6954D9;
      margin-right: 12px;
    }
  }

</style>