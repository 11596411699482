<template>
  <div class="col-12 col-sm-6 col-md-4 mb-3">
    <div class="course-card card text-center">
      <h5 class="card-title mt-4" @click="viewCourse()">{{coursedata.title}}</h5>
      <div class="card-body infos d-flex flex-column p-0 mt-4">
        <div class="course-status d-flex justify-content-center mb-3">
          <div class="checklist w-100">
            <i class="bi bi-check2"></i>
            <span class="d-block">Checklist</span>
          </div>
          <div class="notebook w-100">
            <i class="bi bi-journal-text"></i>
            <span class="d-block">My notebook</span>
          </div>
        </div>
        <div class="content-progress d-flex align-items-center h-100">
          <div id="chart" >
            <apexchart ref="chart" :options="chartOptions" :series="chartSeries" type="radialBar" height="160" />
          </div>
          <span class="content-completed">Conclusão de conteúdo</span>
        </div>
      </div>
    </div>
  </div>

</template>

<script>


export default {
  name: "CourseCard",
  props: [
    "coursename",
    "coursedata"
  ],
  watch:{
    coursedata: function(newValue) {
      this.updateChart(newValue);
    }
  },
  data() {
    return {
      chartOptions: {
        chart: {
          height: 160,
          type: 'radialBar',
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 5, // 15
              size: '40%'
            },
            dataLabels: {
              name: {
                show: true,
                color: '#5F5F5F',
                offsetY: 5,
                fontSize: '18px'
              },
              value: {
                show: false
              }
            },
            track: { // Track left
              background: ['#5F5F5F'],
              strokeWidth: '87%',
              dropShadow: {
                enabled: true,
                top: 3,
                left: 0,
                blur: 3,
                opacity: 0.3
              }
            },
          },
        },
        fill: {
          colors: ['#A18AE5'],
        },
        stroke: {
          lineCap: 'round',
        },
        labels: ['0%'],
        responsive: [
          {
            breakpoint: 1025, // sempre 1px a mais do objetivo
            options: {
              chart: {
                height: 150
              },
            }
          },
          {
            breakpoint: 769,
            options: {
              chart: {
                height: 115
              }
            }
          },
          {
            breakpoint: 481,
            options: {
              chart: {
                height: 180
              },
            }
          }
        ]
      },
      chartSeries: [0],
    }
  },
  mounted(){
    this.chartSeries = [this.coursedata.user_progress];
    this.chartOptions.labels = [`${this.coursedata.user_progress}%`];
    this.$refs.chart.updateOptions(this.chartOptions);
    this.$refs.chart.updateSeries([this.coursedata.user_progress]);

  },
  methods: {
    updateChart(newValue){
      this.chartOptions.labels = [`${newValue.user_progress}%`];
      this.chartSeries = [newValue.user_progress];
      this.$refs.chart.updateOptions(this.chartOptions);
      this.$refs.chart.updateSeries([this.coursedata.user_progress]);
    },
    viewCourse() {

      this.$router.push({
        name: "LMSCourses",
        params: {
          lmsSlug: this.$route.params.lmsSlug,
          courseSlug: this.coursedata.slug
        }
      });
    },
  }
}
</script>

<style lang="scss">
.course-card {
  height: 335px;
  flex-shrink: 0;

  &:hover {
    box-shadow: 0 0 10px 5px #706F6F30;
  }

  &.card {
    border-radius: 20px;
    border: 2px solid #F6F6F6;
    background: #FFF;
    transition: all .3s ease;
  }

  .card-title {
    color: #706F6F;
    font-size: 18px;
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    text-wrap: nowrap;
    overflow: hidden;
    cursor: pointer;
  }

  .card-body {
    .content-progress {
      background-color: #F6F6F6;
      height: 100%;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;

      .content-completed {
        //margin-left: -55px;
        width: 50%;
        color: #706F6F;
        font-size: 16px;
        font-family: "Hanken Grotesk";
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: left;
        margin-left: -8rem;
      }

    }

  }


  .bi {
    font-size: 2rem;
  }

  .checklist {
    color: #706F6F;

  }

  .notebook {
    color: #706F6F;

  }

  .infos {
    span {
      color: #706F6F;
      text-align: center;
      font-size: 14px;
      font-family: "Space Grotesk";
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

  }

  .apexcharts-canvas {
    right: 4rem;
  }

  @media(min-width: 768px){
    .card-body {
      .content-progress {
        .content-completed {
          font-size: 12px;
        }
      }
    }
  }
  @media(min-width: 1024px){
    .card-body {
      .content-progress {
        .content-completed {
          font-size: 16px;
        }
      }
    }
  }

}
</style>