<template>
  <section class="container courses-cards-wrapper">

      <div class="swiper-pagination-lms"></div>
      <div class="swiper-container featured-courses px-0">
        <div class="swiper-wrapper">

          <div class="swiper-slide" v-for="(course, idx) in courses" :key="idx" @click="goToCourse(course.slug)">
            <div class="featured-cards" :style="'background-image:url('+course.thumbnail+')'">
              <div class="course-name d-flex flex-column justify-content-end h-100 pb-5 ps-4 fs-3">
                <span class="subtitle w-75">{{ getSubtitleAndTitle(course.title).subtitle.toUpperCase() }}</span>
                <span class="title w-75">{{ getSubtitleAndTitle(course.title).title.toUpperCase() }}</span>
              </div>
            </div>
          </div>
        </div>

<!--        <div class="swiper-button-prev swiper-button-prev-featured-courses mt-0">-->
<!--          <i class="bi bi-chevron-left"></i>-->
<!--        </div>-->
<!--        <div class="swiper-button-next swiper-button-next-featured-courses mt-0">-->
<!--          <i class="bi bi-chevron-right"></i>-->
<!--        </div>-->

      </div>



  </section>
</template>

<script>

import Swiper from "swiper";

// Import Swiper styles
import "swiper/swiper.min.css";

export default {
  name: "FeaturedCurses",
  props: ["courses", "lmsSlug"],
  setup() {
    const onSwiper = (swiper) => {
      swiper;
      // console.log(swiper);
    };
    const onSlideChange = () => {
      // console.log('slide change');
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
  data() {
    return {}
  },
  mounted() {
    this.buildSwiper();
  },
  methods: {
    goToCourse(slug) {
      this.$router.push({path: '/lms/' + this.lmsSlug + '/' + slug});
    },
    getSubtitleAndTitle(fulltitle) {
      let titleSplit = fulltitle.split(' ');
      let subtitle = titleSplit.length > 1 ? titleSplit[0] : '';
      let title = titleSplit.length > 1 ? titleSplit[1] : titleSplit[0];

      return {subtitle, title};
    },
    buildSwiper() {
      let root_ = this;

      let options = {
        slidesPerView: 5,
        spaceBetween: 20,
        autoplay: false,
        loop: false,
        allowTouchMove: true,
        // autoHeight       : true,
        observer: true,
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1.5
          },
          640: {
            slidesPerView: 2.5
          },
          1024: {
            slidesPerView: 3.5
          },
          1280: {
            slidesPerView: 3.5
          },
          1366: {
            slidesPerView: 3.5
          },
          1400: {
            slidesPerView: 5
          }
        },
        pagination: {
          el: '.swiper-pagination-feature-courses',
          clickable: true,
          // type   : root_.slideshowData?.pagination?.type ?? 'bullets',
        },
        navigation: {
          nextEl: ".swiper-button-next-featured-courses.swiper-button-next",
          prevEl: ".swiper-button-prev-featured-courses.swiper-button-prev"
        },
      };

      root_.swiper = new Swiper(".swiper-container.featured-courses", options);
    },

  }
}
</script>

<style scoped lang="scss">

.course-name {
  font-family: 'Space Grotesk', sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #B0E000;
  word-wrap: break-word;
  overflow: hidden;

  .title {
    color: #FFFFFF;
    line-height: 60px;
  }

}

.featured-cards {
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-blend-mode: saturation;
  height: 385px;
  border-radius: 30px;
}

.swiper-slide {
  cursor: pointer;
}

.swiper-button-next-featured-courses, .swiper-button-prev-featured-courses {
  position: absolute;
  height: 100%;
  top: 0;
  background: #000;
  opacity: .3;

  .bi {
    color: #FFFFFF;
    font-size: 35px;
  }

}

.swiper-button-prev-featured-courses {
  left: 0;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.swiper-button-next-featured-courses {
  right: 0;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

</style>