import Vue from "vue";
import Router from "vue-router";
import { store } from "../store/vuex";

import Home from "@/components/Home";
import Iframe from "@/components/Iframe";
import Account from "@/components/Account";
import Age from "@/components/Age";
import Agenda from "@/components/Agenda";
import Profile from "@/components/Profile";
import Friends from "@/components/Friends";
import FriendsInvited from "@/components/FriendsInvited";
import BlockedUsers from "@/components/BlockedUsers";
import Support from "@/components/Support";
import Map from "@/components/Map";
// import MapEvent from "@/components/MapEvent";
// import Ticket from '@/components/Ticket'
import TicketPurchased from '@/components/TicketPurchased'
import Partners from '@/components/Partners'
import Ranking from '@/components/Ranking'
import Biblioteca from '@/components/Biblioteca'
import Print from '@/components/Print'
import VipRoom from '@/components/VipRoom'
import Termos from "@/components/Termos";
import PoliticaDePrivacidade from "@/components/PoliticaDePrivacidade";
import LandingPreEvento from "@/components/LandingPreEvento";
import Badges from "@/components/pages/Badges";
import PublicRanking from "@/components/PublicRanking";
import EmailConfirmation from "@/components/EmailConfirmation";
import TicketGate from "@/components/Modals/TicketGate";
import ListBadges from "@/components/pages/ListBadges";
import i18n from '@/i18n';
/* LMS */
// import LMS from '@/components/lms/Controller'
import LmsHome from "@/components/lms/LmsHome";
import LmsCourse from "@/components/lms/LmsCourse";
import LmsStudentProgress from "@/components/lms/LmsStudentProgress";
import LmsLibrary from "@/components/lms/LmsLibrary.vue";
import LmsMyJourney from "@/components/lms/LmsMyJourney.vue";
import LmsFriends from "@/components/lms/LmsFriends.vue";
import LmsNotifications from "@/components/lms/LmsNotifications.vue";
import LmsCourseSection from "@/components/lms/LmsCourseSection.vue";
import LmsLessonContent from "@/components/lms/LmsLessonContent.vue";
import LmsCheckout from "@/components/lms/LmsCheckout.vue";
import LmsCertificates from "@/components/lms/LmsCertificates.vue";
import LmsStudentClasses from "@/components/lms/LmsStudentClasses.vue";
import LmsUserProfile from "@/components/lms/LmsUserProfile.vue";
import LmsPayments from "@/components/lms/LmsPayments.vue";
import LmsCheckoutAddToCart from "@/components/lms/LmsCheckoutAddToCart.vue";
import LmsFeedSocial from "@/components/lms/feed/LmsFeedSocial.vue";
import LmsMyFeedSocial from "@/components/lms/feed/LmsMyFeedSocial.vue";

Vue.use(Router);

// const prefix = "RIR -";

const routes = [
  {
    name: "Home",
    path: "/",
    props: route => ({award: route.query.award}),
    component: Home,
    meta: {
      title: `Home`,//${prefix}
      requiresLogin: false,
      requiresEmail: false,
      requiresAge: false,
    },
  },
  {
    name: "Login",
    path: "/login",
    component: Home,
    meta: {
      title: `Login`,//${prefix}
      requiresLogin: false,
      requiresEmail: false,
      requiresAge: false,
    },
  },
  {
    name: "RememberPassword",
    path: "/requisitar-senha",
    component: Home,
    meta: {
      title: i18n.t('login.titleEsqueciSenha'),
      requiresLogin: false,
      requiresEmail: false,
      requiresAge: false,
    },
  },
  {
    name: "ResetPassword",
    path: i18n.t('resetpassword.url'),
    component: Home,
    meta: {
      title: i18n.t('resetpassword.recadastrarSenhaTitle'),
      requiresLogin: false,
      requiresEmail: false,
      requiresAge: false,
    },
  },
    {
        name: 'Signup',
        path: '/cadastro',
        component: Home,
        meta: { title: `Cadastro`, requiresLogin: false, requiresEmail: false, requiresAge:false },//${prefix}
    },
  {
    name: "EmailConfirmation",
    path: "/confirmacao-de-email",
    component: Map,
    meta: {
      title: i18n.t('app.confirmeSeuEmail'),
      requiresLogin: true,
      requiresEmail: false,
      requiresAge: false,
    },
  },
  {
    name: "EmailConfirmationCode",
    path: "/confirmacao-de-email/:codeNumber?",
    props: true,
    component: EmailConfirmation,
    meta: {
      title: i18n.t('app.confirmeSeuEmail'),
      requiresLogin: true,
      requiresEmail: false,
      requiresAge: false,
    },
  },
  {
    name: "Age",
    path: "/confirme-sua-idade",
    component: Age,
    meta: {
      title: `Confirme sua idade`,//${prefix}
      requiresLogin: true,
      requiresEmail: true,
      requiresAge: false,
    },
  },
  {
    name: "Account",
    path: "/minha-conta",
    component: Account,
    meta: {
      title: `Minha conta`,//${prefix}
      requiresLogin: true,
      requiresEmail: true,
      requiresAge: true,
    },
  },
  {
    name: "Profile",
    path: "/meu-perfil",
    component: Profile,
    meta: {
      title: i18n.t('profile.meuPerfil'),
      requiresLogin: true,
      requiresEmail: true,
      requiresAge: true,
    },
  },
  {
    name: "Friends",
    path: "/amigos",
    component: Friends,
    meta: {
      title: i18n.t('friends.meusAmigos'),
      requiresLogin: true,
      requiresEmail: true,
      requiresAge: true,
    },
  },
  {
    name: "FriendsInvited",
    path: "/usuarios-convidados",
    component: FriendsInvited,
    meta: {
      title: i18n.t('friends.usuariosConvidados'),
      requiresLogin: true,
      requiresEmail: true,
      requiresAge: true,
    },
  },
  {
    name: "BlockedUsers",
    path: "/usuarios-bloqueados",
    component: BlockedUsers,
    meta: {
      title: i18n.t('blockedusers.usuriosBloqueados'),
      requiresLogin: true,
      requiresEmail: true,
      requiresAge: true,
    },
  },
  {
    name: "Support",
    path: "/suporte",
    component: Support,
    meta: {
      title: i18n.t('support.suporte'),
      requiresLogin: false,
      requiresEmail: false,
      requiresAge: false,
    },
  },
  {
    name: "Partners",
    path: "/parceiros",
    component: Partners,
    meta: {
      title: `Parceiros`,//${prefix}
      requiresLogin: true,
      requiresEmail: true,
      requiresAge: true,
    },
  },
  {
    name: "Agenda",
    path: "/agenda",
    component: Agenda,
    meta: {
      title: `Agenda`,//${prefix}
      requiresLogin: true,
      requiresEmail: true,
      requiresAge: true,
    },
  },
  {
    name: "VipRoom",
    path: "/administrar-sala-vip",
    component: VipRoom,
    meta: {
      title: `Administrar sala VIP`,//${prefix}
      requiresLogin: true,
      requiresEmail: true,
      requiresAge: true,
    },
  },
  {
    name: "Map",
    path: "/map",
    component: Map,
    meta: {
      title: i18n.t('app.mapa'),
      requiresLogin: true,
      requiresEmail: true,
      requiresAge: true,
    },
  },

  // /* LMS */
  // {
  //   name: "LMS",
  //   path: "/lms",
  //   props: true,
  //   component: LMS,
  //   meta: {
  //     //title: `VT`,//${prefix}
  //     requiresLogin: true,
  //     requiresEmail: true,
  //     requiresAge: true,
  //   },
  // },


  {
    name: "EventMap",
    path: "/evento/:eventName",
    props: true,
    component: Home,
    meta: {
      title: `Home`,//${prefix}
      requiresLogin: false,
      requiresEmail: false,
      requiresAge: false,
    },
  },
  {
    name: "EventLink",
    path: "/eventos/:eventName/:sessionName",
    props: true,
    component: Home,
    meta: {
      title: `Home`,//${prefix}
      requiresLogin: false,
      requiresEmail: false,
      requiresAge: false,
    },
  },

  {
    name: "Iframe",
    path: "/e/:eventName",
    props: true,
    component: Iframe,
    meta: {
      //title: `VT`,//${prefix}
      requiresLogin: true,
      requiresEmail: true,
      requiresAge: true,
    },
  },

  {
    name: "City",
    path: "/avatar-shop",
    component: Iframe,
    meta: {
      title: `Cidade`,//${prefix}
      requiresLogin: true,
      requiresEmail: true,
      requiresAge: true,
    },
  },
  {
    name: "FanZone",
    path: "/fan-zone",
    component: Iframe,
    meta: {
      title: `Fan Zone`,//${prefix}
      requiresLogin: true,
      requiresEmail: true,
      requiresAge: true,
    },
  },
  {
    name: "FeelZone",
    path: "/feel-zone",
    component: Iframe,
    meta: {
      title: `Feel Zone`,//${prefix}
      requiresLogin: true,
      requiresEmail: true,
      requiresAge: true,
    },
  },
  {
    name: "PlayZone",
    path: "/play-zone",
    component: Iframe,
    meta: {
      title: `Play Zone`,//${prefix}
      requiresLogin: true,
      requiresEmail: true,
      requiresAge: true,
    },
  },

  //Badges
  {
    name: "Badges",
    path: "/badges",
    component: Badges,
    meta: {
      title: `Badges`,//${prefix}
      requiresLogin: true,
      requiresEmail: true,
      requiresAge: true,
    },
  },

  {
    name: "Bank",
    path: "/bank",
    component: Iframe,
    meta: {
      title: `Banco`,//${prefix}
      requiresLogin: true,
      requiresEmail: true,
      requiresAge: true,
    },
  },
  {
    name: "Museum",
    path: "/museu",
    component: Iframe,
    meta: {
      title: `Museu`,//${prefix}
      requiresLogin: true,
      requiresEmail: true,
      requiresAge: true,
    },
  },
  {
    name: "Store",
    path: "/loja",
    component: Iframe,
    meta: {
      title: `Loja`,//${prefix}
      requiresLogin: true,
      requiresEmail: true,
      requiresAge: true,
    },
  },

  //Stadium
  //underage
  {
    name: "StadiumUnderage",
    path: "/arena-show-menores",
    component: Iframe,
    meta: {
      title: `Estádio Show`,//${prefix}
      requiresLogin: true,
      requiresEmail: true,
      requiresAge: true,
    },
  },
  {
    name: "StadiumGameUnderage",
    path: "/arena-jogo-menores",
    component: Iframe,
    meta: {
      title: `Estádio Jogo`,//${prefix}
      requiresLogin: true,
      requiresEmail: true,
      requiresAge: true,
    },
  },
  //
  {
    name: "Stadium",
    path: "/arena-show",
    component: Iframe,
    meta: {
      title: `Estádio Show`,//${prefix}
      requiresLogin: true,
      requiresEmail: true,
      requiresAge: true,
    },
  },
  {
    name: "StadiumGame",
    path: "/arena-jogo",
    component: Iframe,
    meta: {
      title: `Estádio Jogo`,//${prefix}
      requiresLogin: true,
      requiresEmail: true,
      requiresAge: true,
    },
  },
  {
    name: "StadiumAvatarGame",
    path: "/arena-avatar-jogo",
    component: Iframe,
    meta: {
      title: `Estádio Avatar Jogo`,//${prefix}
      requiresLogin: true,
      requiresEmail: true,
      requiresAge: true,
    },
  },
  {
    name: "StadiumAvatarGameUnderage",
    path: "/arena-avatar-jogo-menores",
    component: Iframe,
    meta: {
      title: `Estádio Avatar Jogo para menores`,//${prefix}
      requiresLogin: true,
      requiresEmail: true,
      requiresAge: true,
    },
  },
  {
    name: "StadiumAvatarShow",
    path: "/arena-avatar-show",
    component: Iframe,
    meta: {
      title: `Estádio Avatar Show`,//${prefix}
      requiresLogin: true,
      requiresEmail: true,
      requiresAge: true,
    },
  },
  //

  {
    name: "Theater",
    path: "/auditorio",
    component: Iframe,
    meta: {
      title: `Theater`,//${prefix}
      requiresLogin: true,
      requiresEmail: true,
      requiresAge: true,
    },
  },
  {
    name: "TheaterAvatar",
    path: "/auditorio-avatar",
    component: Iframe,
    meta: {
      title: `Theater Avatar`,//${prefix}
      requiresLogin: true,
      requiresEmail: true,
      requiresAge: true,
    },
  },

  //Bar
  {
    name: "Bar",
    path: "/bar",
    component: Iframe,
    meta: {
      title: `Bar`,//${prefix}
      requiresLogin: true,
      requiresEmail: true,
      requiresAge: true,
    },
  },
  {
    name: "BudBar",
    path: "/bud-bar",
    component: Iframe,
    meta: {
      title: `Bud Bar`,//${prefix}
      requiresLogin: true,
      requiresEmail: true,
      requiresAge: true,
    },
  },

  {
    name: "BarVip",
    path: "/bar-vip",
    component: Iframe,
    meta: {
      title: `Bar Vip`,//${prefix}
      requiresLogin: true,
      requiresEmail: true,
      requiresAge: true,
    },
  },
  {
    name: "BudBarVip",
    path: "/bud-bar-vip",
    component: Iframe,
    meta: {
      title: `Bud Bar Vip`,//${prefix}
      requiresLogin: true,
      requiresEmail: true,
      requiresAge: true,
    },
  },
  {
      name: 'Biblioteca',
      path: '/biblioteca',
      component: Biblioteca,
      meta: { title: `Biblioteca`, requiresLogin: true, requiresEmail: true, requiresAge:true }//${prefix}
  },

  {
    name: "Room",
    path: "/vip-room",
    component: Iframe,
    meta: {
      title: `Sala VIP`,//${prefix}
      requiresLogin: true,
      requiresEmail: true,
      requiresAge: true,
    },
  },
  {
    name: "TicketNew",
    path: "/ingressos",
    component: Profile,
    meta: {
      title: i18n.t('ticket.ingressos'),
      requiresLogin: true,
      requiresEmail: true,
      requiresAge: true,
      tab: 'mytickets'
    },
  },
  {
    name: "TicketPurchased",
    path: "/meus-ingressos",
    component: TicketPurchased,
    meta: {
      title: i18n.t('ticketpurchased.meusIngressos'),
      requiresLogin: true,
      requiresEmail: true,
      requiresAge: true,
    },
  },
  {
    name: "Ranking",
    path: "/ranking",
    component: Ranking,
    meta: {
      title: `Ranking`,//${prefix}
      requiresLogin: true,
      requiresEmail: true,
      requiresAge: true,
    },
  },
  {
    name: "PublicRanking",
    path: "/public_ranking",
    component: PublicRanking,
    meta: {
      title: i18n.t('ranking.publicRanking'),
      requiresLogin: false,
      requiresEmail: false,
      requiresAge: false
    },
  },
  {
    name: "Print",
    path: "/print",
    component: Print,
    meta: {
      title: `Ranking`,//${prefix}
      requiresLogin: true,
      requiresEmail: true,
      requiresAge: true,
    },
  },
  {
    name: "LockerRoom",
    path: "/locker-room",
    component: Iframe,
    meta: {
      title: `Locker Room`,//${prefix}
      requiresLogin: true,
      requiresEmail: true,
      requiresAge: true,
    },
  },
  {
    name: "TermosPage",
    path: "/termos/:txt",
    props: true,
    component: Termos,
    meta: {
      title: i18n.t('signup.termosECondicoesTitle'),
      requiresLogin: false,
      requiresEmail: false,
      requiresAge: false,
    },
  },
  {
    name: "PoliticaPage",
    path: "/politica",
    component: PoliticaDePrivacidade,
    meta: {
      title: i18n.t('app.politica'),
      requiresLogin: false,
      requiresEmail: false,
      requiresAge: false,
    },
  },
  {
    name: "LandingPreEventoPage",
    path: "/landing",
    component: LandingPreEvento,
    meta: {
      title: `Landing`,//${prefix}
      requiresLogin: false,
      requiresEmail: false,
      requiresAge: false,
    },
  },
  {
    name: "TicketGate",
    path: "/bilheteria",
    component: TicketGate,
    meta: {
      title: i18n.t('profile.Bilheteria'),
      requiresLogin: true,
      requiresEmail: true,
      requiresAge: true,
    },
  },
  {
    name: "ConquistarBadges",
    path: "/conquistar-badges",
    component: ListBadges,
    meta: {
      title: i18n.t('badges.conquistarBadge'),
      requiresLogin: true,
      requiresEmail: true,
      requiresAge: true,
    },
  },
  // LMS EVENTO (LMS)
  {
    name: "LmsHome",
    path: "/lms/:lmsSlug",
    props: true,
    component: LmsHome,
    meta: {
      title: i18n.t('lms.home.title'),
      requiresLogin: true,
      requiresEmail: false,
      requiresAge: false,
    },
  },
  {
    name: 'LmsFeedSocial',
    path: '/lms/:lmsSlug/feed',
    component: LmsFeedSocial,
    props: true,
    meta: {
      title: i18n.t('lms.profile.title'),
      requiresLogin: true,
      requiresEmail: false,
      requiresAge: false
    }
  },
  {
    name: 'LmsMyFeedSocial',
    path: '/lms/:lmsSlug/myFeed',
    component: LmsMyFeedSocial,
    props: true,
    meta: {
      title: i18n.t('lms.profile.title'),
      requiresLogin: true,
      requiresEmail: false,
      requiresAge: false
    }
  },
  {
    name: 'LmsUserProfile',
    path: '/lms/:lmsSlug/profile/:userId?',
    component: LmsUserProfile,
    props: true,
    meta: {
      title: i18n.t('lms.profile.title'),
      requiresLogin: true,
      requiresEmail: false,
      requiresAge: false
    }
  },
  // Essa rota precisa ficar acima da rota LMSCourses por conta do path :courseSlug
  {
    name: "LmsStudentProgress",
    path: "/lms/:lmsSlug/studentprogress",
    props: true,
    component: LmsStudentProgress,
    meta: {
      title: i18n.t('lms.studentprogress.title'),
      requiresLogin: true,
      requiresEmail: false,
      requiresAge: false,
    }

  },
  {
    name: "LmsCertificates",
    path: "/lms/:lmsSlug/certificates",
    props: true,
    component: LmsCertificates,
    meta: {
      title: i18n.t('lms.certificates.title'),
      requiresLogin: true
    }
  },
  {
    name: "LmsClasses",
    path: "/lms/:lmsSlug/userClasses",
    props: true,
    component: LmsStudentClasses,
    meta: {
      title: i18n.t('lms.classes.title'),
      requiresLogin: true
    }
  },
  {
    name: "LmsLibrary",
    path: "/lms/:lmsSlug/library",
    props: true,
    component: LmsLibrary,
    meta: {
      title: i18n.t('lms.library.title'),
      requiresLogin: true
    },
  },
  {
    name: "LmsMyJourney",
    path: "/lms/:lmsSlug/myJourney",
    props: true,
    component: LmsMyJourney,
    meta: {
      title: 'Minha Jornada',
      requiresLogin: true
    },
  },
  {
    name: "LmsFriends",
    path: "/lms/:lmsSlug/myFriends",
    props: true,
    component: LmsFriends,
    meta: {
      title: 'Amigos',
      requiresLogin: true
    },
  },
  {
    name: "LmsNotifications",
    path: "/lms/:lmsSlug/notifications",
    props: true,
    component: LmsNotifications,
    meta: {
      title: i18n.t('lms.notifications.title'),
      requiresLogin: true
    },
  },
  {
    name: "LmsCheckout",
    path: "/lms/:lmsSlug/checkout",
    props: true,
    component: LmsCheckout,
    meta: {
      title: i18n.t('lms.checkout.title'),
      requiresLogin: true
    }
  },
  {
    name: "LmsCheckoutAddToCart",
    path: "/lms/:lmsSlug/checkout/add-to-cart",
    props: true,
    component: LmsCheckoutAddToCart,
    meta: {
      title: i18n.t('lms.checkout.add_to_cart_title'),
      requiresLogin: true
    }
  },
  {
    name: "LmsPayments",
    path: "/lms/:lmsSlug/payments",
    props: true,
    component: LmsPayments,
    meta: {
      title: i18n.t('lms.payments.title'),
      requiresLogin: true
    },
  },
  // LMS SESSOES (cursos)
  {
    name: "LMSCourses",
    path: "/lms/:lmsSlug/:courseSlug",
    props: true,
    component: LmsCourse,
    meta: {
      title: i18n.t('lms.course.title'),
      requiresLogin: true
    },
  },
  {
    name: "LMSCourseSection",
    path: "/lms/:lmsSlug/:courseSlug/:sectionSlug",
    props: true,
    component: LmsCourseSection,
    meta: {
      title: i18n.t('lms.section.title'),
      requiresLogin: true
    },
  },
  {
    name: "LmsLessonContent",
    path: "/lms/:lmsSlug/:courseSlug/:sectionSlug/:lessonSlug",
    props: true,
    component: LmsLessonContent,
    meta: {
      title: i18n.t('lms.content.title'),
      requiresLogin: true,
      requiresEmail: false,
      requiresAge: false
    }
  }
//   {
//     name: "Package",
//     path: "/pacotes",
//     component: Package,
//     meta: {
//       title: `Pacotes`,//${prefix}
//       requiresLogin: true,
//       requiresEmail: false,
//       requiresAge: false,
//     },
//   },
];

/*Router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresLogin)) {
        // You can use store variable here to access globalError or commit mutation
        next("/Login")
    } else {
        next()
    }
})*/

const router = new Router({ routes });
router.beforeEach((to, from, next) => {
    let uri_qrcode = to.fullPath.split('?')[1];
    let params_qrcode = new URLSearchParams(uri_qrcode);
    if (params_qrcode.get('award')) {
        store.state.reward_slug = params_qrcode.get('award')
    }
    if (["PoliticaPage","Support","TermosPage","EventMap","EventLink","PublicRanking"].includes(to.name))
    {
        next();
    }
    else if (store.state.started)
    {
        if (to.matched.some((record) => record.meta.requiresLogin) && !store.state.logged && !store.state.isGuest)
        {
            localStorage.setItem('redirectPath', to.fullPath);
            next({ name: "Login" });
        }
        else if (to.matched.some((record) => record.meta.requiresEmail) && !store.state.email_verified && store.state.name != "")
        {
          if ((to.name != "EmailConfirmation") && (to.name != "EmailConfirmationCode"))
            {
                next({ name: "EmailConfirmation" });
            }
        }
        else if (to.matched.some((record) => record.meta.requiresAge) && !store.state.age_verified && store.state.name != "")
        {
            if (to.name != "Age")
            {
                next({ name: "Age" });
            }
        }
        else if (to.matched.some((record) => record.meta.requiresLogin) == false && (store.state.logged || store.state.isGuest))
        {
            next({ name: "Map" });
        }
        else if (to.fullPath.includes("/lms/"))
        {
          router.app.$store.dispatch("LmsCart/getCart");
          next();
        }
        else
        {
            next();
        }
    }
    else
    {
        next();
    }
});

export default router;
