<template>
    <div style="margin-left: 10px;">
        <div class="g_id_signin" data-type="standard" :data-locale="parentComponent.$i18n.locale"></div>
    </div>
</template>
<script>

        export default {
        name:"LoginGoogle",
        data() {
            return {
                parentComponent: null
            }
        },
        props: ['origin'],
        methods: {
            parseJwt(token) {
                var base64Url = token.split('.')[1];
                var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));

                return JSON.parse(jsonPayload);
            },
            onSignIn(googleUser) {
                if(googleUser && googleUser.credential) {
                    const profile = this.parseJwt(googleUser.credential);
                    let data = { type:'google', origin: this.origin, id:profile.sub, email:profile.email, name:profile.name };
                    this.$parent.loginSocial(data);
                }
            },
            loadGoogleSDK(d, s, id) {
                this.parentComponent.decryptUnity(
                    this.parentComponent.keyUnityConf,
                    this.parentComponent.switch.google_conf.i,
                    this.parentComponent.switch.google_conf.c
                ).then(objDec => {
                    window.onSignIn = this.onSignIn;
                    
                    let js = d.getElementsByTagName(s)[0];

                    const div = d.createElement('div');
                    div.id = 'g_id_onload';
                    div.setAttribute('data-client_id', objDec);
                    div.setAttribute('data-callback', 'onSignIn');
                    d.head.appendChild(div);

                    js = d.createElement(s);
                    js.id = id;
                    js.src = `https://accounts.google.com/gsi/client?h1=${this.parentComponent.$i18n.locale}`;
                    js.async = true;
                    js.defer = true;
                    d.head.appendChild(js);
                });
            }
        },
        mounted() {
            this.parentComponent = this.$parent.$parent;
            if (this.parentComponent.$parent === undefined){
                this.parentComponent = this.$parent;
            }
            this.loadGoogleSDK(document, "script", "google-jssdk");
        }
    }
</script>

<style scoped>
    a, picture
    {
        display: block;
    }
    picture
    {
        font-size: 0;
    }
</style>
