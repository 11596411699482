<template>
    <div id="profile" ref="profile">
        
        <button v-on:click="teste">{{ nome }}</button>
    </div>
</template>

<script>
    
    import { mapGetters, mapActions } from "vuex";
    
    export default {
        name: 'Print',
        computed: {
            ...mapGetters([
                'logged', 'token'
            ]),
        },
        data() {
            return {
                nome: 'ok',
            }
        },
        components: {
            ...mapActions([
                'update_user_friends'
            ]),
        },
        methods: {
            teste() {
                //console.log('teste');
                this.nome;
                this.$parent.link('');
            }
        },
        created() {
            
        },
        mounted() {

        },
        beforeDestroy() {
            
        },
    }
</script>

<style scoped>
    #profile
    {
       
    }
</style>