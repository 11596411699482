<template>
    <div id="ticket-gate" ref="ticket-gate">
        
    </div>
</template>

<script>
    import axios from 'axios';
    import { mapGetters } from "vuex";
    
    export default {
        name: 'TicketGate',
        computed: {
            ...mapGetters([
                'logged', 'token', 'dat', 'UserTickets', 'EventimTickets', 'TotalPoints', 'TotalCoins'
            ]),
        },
        data() {
            return {
                date_ticket_live: null,
                id_actual: null,
                local: null,
                payment_method_actual: null,
                ticket_actual: null
            }
        },
        components: {
            
        },
        methods: {
            analyticsTicketsHandler(type) {
                type;
                switch(type)
                {
                    case "normal":
                        switch(String(this.id_actual))
                        {
                            case 'GAME 1':
                                this.$parent.analytics('watch_game1_regular', {});
                            break;
                            case 'GAME 2':
                                this.$parent.analytics('watch_game2_regular', {});
                            break;
                            case 'GAME 3':
                                this.$parent.analytics('watch_game3_regular', {});
                            break;
                            case 'GAME 4':
                                this.$parent.analytics('watch_game4_regular', {});
                            break;
                            case 'GAME 5':
                                this.$parent.analytics('watch_game5_regular', {});
                            break;
                            case 'GAME 6':
                                this.$parent.analytics('watch_game6_regular', {});
                            break;
                            case 'GAME 7':
                                this.$parent.analytics('watch_game7_regular', {});
                            break;
                            case 'GAME 8':
                                this.$parent.analytics('watch_game8_regular', {});
                            break;
                        }
                    break;
                    case "vip":
                        switch(String(this.id_actual))
                        {
                            case 'GAME 1 - VIP':
                                if (this.payment_method_actual == 'convite')
                                    this.$parent.analytics('watch_game1_invited_vip', {});
                                else
                                    this.$parent.analytics('watch_game1_owner_vip', {});
                            break;
                            case 'GAME 2 - VIP':
                                if (this.payment_method_actual == 'convite')
                                    this.$parent.analytics('watch_game2_invited_vip', {});
                                else
                                    this.$parent.analytics('watch_game2_owner_vip', {});
                            break;
                            case 'GAME 3 - VIP':
                                if (this.payment_method_actual == 'convite')
                                    this.$parent.analytics('watch_game3_invited_vip', {});
                                else
                                    this.$parent.analytics('watch_game3_owner_vip', {});
                            break;
                            case 'GAME 4 - VIP':
                                if (this.payment_method_actual == 'convite')
                                    this.$parent.analytics('watch_game4_invited_vip', {});
                                else
                                    this.$parent.analytics('watch_game4_owner_vip', {});
                            break;
                            case 'GAME 5 - VIP':
                                if (this.payment_method_actual == 'convite')
                                    this.$parent.analytics('watch_game5_invited_vip', {});
                                else
                                    this.$parent.analytics('watch_game5_owner_vip', {});
                            break;
                            case 'GAME 6 - VIP':
                                if (this.payment_method_actual == 'convite')
                                    this.$parent.analytics('watch_game6_invited_vip', {});
                                else
                                    this.$parent.analytics('watch_game6_owner_vip', {});
                            break;
                            case 'GAME 7 - VIP':
                                if (this.payment_method_actual == 'convite')
                                    this.$parent.analytics('watch_game7_invited_vip', {});
                                else
                                    this.$parent.analytics('watch_game7_owner_vip', {});
                            break;
                            case 'GAME 8 - VIP':
                                if (this.payment_method_actual == 'convite')
                                    this.$parent.analytics('watch_game8_invited_vip', {});
                                else
                                    this.$parent.analytics('watch_game8_owner_vip', {});
                            break;
                        }
                    break;
                }
            },



            //----- buyWithCoins -----//
            updateUserTicketsLocal() {
                let my_tickets = this.UserTickets;
                my_tickets.push(this.ticket_actual);

                
                this.$parent.updateUser('UserTickets', my_tickets);
                //this.update_user_tickets(my_tickets);
                
            },
            buyWithCoins(id) {
                this.$parent.wait(true);
                let headers = { 'Content-Type': 'application/json;charset=UTF-8', 'Access': 'application/json', language: this.$i18n.locale, Authorization : `Bearer ${this.token}`};
                let form_data = new FormData();
                form_data.append('type_ticket', id);
                axios.post(this.$url_api + 'api/buyWithCoins', form_data, { headers:headers }).then(response => this.buyWithCoinsResponse(response)).catch(error => this.buyWithCoinsError(error.response));
            },
            buyWithCoinsResponse(obj)
            {
                
                if (obj.data.status == 'success') {
                    this.updateUserTicketsLocal();

                    setTimeout(function(){ window.location.reload(); }, 1000);
                    


                    //this.$parent.link(this.local);

                    /*
                    switch(this.local)
                    {
                        case 'StadiumGameUnderage':
                            window.location.href = this.$url + '#/arena-jogo-menores';
                        break;
                        case 'Stadium':
                            window.location.href = this.$url + '#/arena-show';
                        break;
                        case 'StadiumAvatarShow':
                            window.location.href = this.$url + '#/arena-avatar-show';
                        break;
                        case 'StadiumAvatarShowUnderage':
                            window.location.href = this.$url + '#/arena-avatar-show';
                        break;
                        case 'StadiumGame':
                            window.location.href = this.$url + '#/arena-jogo';
                        break;
                        case 'StadiumAvatarGame':
                            window.location.href = this.$url + '#/arena-avatar-jogo';
                        break;
                        case 'StadiumAvatarGameUnderage':
                            window.location.href = this.$url + '#/arena-avatar-jogo';
                        break;
                        case 'Theater':
                            window.location.href = this.$url + '#/auditorio';
                        break;
                        case 'TheaterAvatar':
                            window.location.href = this.$url + '#/auditorio-avatar';
                        break;
                        case 'Bar':
                            window.location.href = this.$url + '#/bar';
                        break;
                        case 'BudBar':
                            window.location.href = this.$url + '#/bud-bar';
                        break;
                        case 'BarVip':
                            window.location.href = this.$url + '#/bar-vip';
                        break;
                        case 'BudBarVip':
                            window.location.href = this.$url + '#/bud-bar-vip';
                        break;
                    }
                    */
                }
                else
                    this.$parent.wait(false);
            },
            buyWithCoinsError(obj)
            {
                //console.log('error => ', obj);
                if (obj.data.status == 'error') {
                    this.$parent.modal('error', 'Erro!', obj.data.message, null, null, null, "OK");
                }
                this.$parent.wait(false);
            },
            //----- \buyWithCoinsz -----//



            noTicket() {
                if ((this.$parent.checkLive()) && (this.TotalCoins >= 500))
                {
                    for (let o = 0; o < this.EventimTickets.length; o++)
                    {
                        if ((this.EventimTickets[o].state == 'Ao vivo') && (this.EventimTickets[o].type == 'normal')) {
                            this.ticket_actual = this.EventimTickets[o];
                            this.buyWithCoins(this.EventimTickets[o].id);
                            break;
                        }
                    }
                    
                }
                else
                {
                    //this.$parent.modal('confirmation', 'Atenção!', 'Vocẽ não possui ingresso para esse evento(' + this.dat.getDate() + '/' + this.dat.getMonth() + '/' + this.dat.getFullYear()  + '). Deseja comprar agora?', this.noTicketConfirmation);
                    this.$parent.modal('confirmation', 'Atenção!', 'A transmissão ainda não começou ou vocẽ não possui ingresso para esse evento(' + this.date_ticket_live  + '). Deseja comprar agora?', this.noTicketConfirmation);
                }
            },
            noTicketConfirmation(r) {
                if (r === 1)
                {
                    this.$parent.link('Ticket');
                }
                else
                {
                    //this.$parent.link('Map');
                    this.$router.go(-1);
                }
            },
            enterCheck(local_)
            {
                this.local = local_;

                let live = false;
                let lives_id = [];
                let count = 0;
                let count_vip = 0;
                for (let o = 0; o < this.EventimTickets.length; o++)
                {
                    if (this.EventimTickets[o].state == 'Ao vivo')
                    {
                        live                  = true;
                        let dat_event         = new Date(this.EventimTickets[o].event_date);
                        this.date_ticket_live = ((('0'+Number(dat_event.getDate() + 1)).slice(-2)) + '/' + (('0'+Number(dat_event.getMonth() + 1)).slice(-2)) + '/' + dat_event.getFullYear());
                        lives_id.push(String(this.EventimTickets[o].id));
                    }
                }

                if (live == true)
                {
                    count_vip = 0;
                    count     = 0;
                    for (let o = 0; o < this.UserTickets.length; o++)
                    {
                        if ((this.UserTickets[o].type == 'vip') && (lives_id.indexOf(String(this.UserTickets[o].id)) !== -1))
                        {
                            this.id_actual = String(this.UserTickets[o].name);
                            this.payment_method_actual = this.UserTickets[o].payment_method;
                            count_vip++;
                            break;
                        }
                        else if ((this.UserTickets[o].type == 'normal') && (lives_id.indexOf(String(this.UserTickets[o].id)) !== -1))
                        {
                            this.id_actual = String(this.UserTickets[o].name);
                            this.payment_method_actual = this.UserTickets[o].payment_method;
                            count++;
                            break;
                        }
                    }

                    if (((this.local == 'BarVip') || (this.local == 'BudBarVip')) && (count_vip > 0))
                    {
                        this.analyticsTicketsHandler('vip');
                        this.$parent.ticket_gate_ok = true;
                        return true;
                    }
                    else if (   ((this.local != 'BarVip') || (this.local != 'BudBarVip'))   &&    ((count > 0) || (count_vip > 0))   )
                    {
                        this.analyticsTicketsHandler('normal');
                        this.$parent.ticket_gate_ok = true;
                        return true;
                    }
                    else
                    {
                        this.noTicket();
                        this.$parent.ticket_gate_ok = false;
                        return false;
                    }
                }
                else
                {
                    this.$parent.ticket_gate_ok = true;
                    return true;
                }

                
                
            }
        },
        created() {
            
        },
        mounted() {
           
        },
        beforeDestroy() {
            
        },
    }
</script>

<style scoped>
    #ticket-gate
    {
       display: none;
    }
</style>