import { render, staticRenderFns } from "./PackageSingle.vue?vue&type=template&id=30f24967&scoped=true&"
import script from "./PackageSingle.vue?vue&type=script&lang=js&"
export * from "./PackageSingle.vue?vue&type=script&lang=js&"
import style0 from "./PackageSingle.vue?vue&type=style&index=0&id=30f24967&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30f24967",
  null
  
)

export default component.exports