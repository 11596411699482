<template>
    <div>
        <audio :src="audio_url" :id="'audio_'+audio_id" :ref="'audio_'+audio_id"></audio>
        <div class="audio_controls">
            <div class="control_buttons">
                <button v-if="!isPlaying" :id="'playpause_'+audio_id" :ref="'playpause_'+audio_id" type="button" @click="playAudio">
                    <svg xmlns="http://www.w3.org/2000/svg" height="3em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path fill="#6954d9" d="M0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM188.3 147.1c-7.6 4.2-12.3 12.3-12.3 20.9V344c0 8.7 4.7 16.7 12.3 20.9s16.8 4.1 24.3-.5l144-88c7.1-4.4 11.5-12.1 11.5-20.5s-4.4-16.1-11.5-20.5l-144-88c-7.4-4.5-16.7-4.7-24.3-.5z"/></svg>
                </button>
                <button :id="'stop_audio_'+audio_id" :ref="'stop_audio_'+audio_id" type="button" v-else @click="pauseAudio">
                    <svg xmlns="http://www.w3.org/2000/svg" height="3em" viewBox="0 0 320 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path fill="#6954d9" d="M48 64C21.5 64 0 85.5 0 112V400c0 26.5 21.5 48 48 48H80c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H48zm192 0c-26.5 0-48 21.5-48 48V400c0 26.5 21.5 48 48 48h32c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H240z"/></svg>
                </button>
            </div>
            <div class="progress" @click="selectPointInAudio">
                <div class="progress_bar" :id="'progress_audio_'+audio_id" :ref="'progress_audio_'+audio_id"></div>
            </div>
            <div class="control-right-side">
                <span>{{currentAudioTime}} / {{totalAudioLength}}</span>
                <div class="audio_button" @mouseover="showVolume" @mouseout="hideVolume" @click="muteAndUnmute">
                    <button v-if="!isMuted && volumeTotal <= 1 && volumeTotal >= .4" :id="'mute_audio_'+audio_id" :ref="'mute_audio_'+audio_id" type="button" data-state="mute">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M533.6 32.5C598.5 85.3 640 165.8 640 256s-41.5 170.8-106.4 223.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C557.5 398.2 592 331.2 592 256s-34.5-142.2-88.7-186.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM473.1 107c43.2 35.2 70.9 88.9 70.9 149s-27.7 113.8-70.9 149c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C475.3 341.3 496 301.1 496 256s-20.7-85.3-53.2-111.8c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zm-60.5 74.5C434.1 199.1 448 225.9 448 256s-13.9 56.9-35.4 74.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C393.1 284.4 400 271 400 256s-6.9-28.4-17.7-37.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM301.1 34.8C312.6 40 320 51.4 320 64V448c0 12.6-7.4 24-18.9 29.2s-25 3.1-34.4-5.3L131.8 352H64c-35.3 0-64-28.7-64-64V224c0-35.3 28.7-64 64-64h67.8L266.7 40.1c9.4-8.4 22.9-10.4 34.4-5.3z"/></svg>
                    </button>
                    <button v-else-if="!isMuted && volumeTotal > 0 && volumeTotal < .4" :id="'low_volume_audio_'+audio_id" :ref="'low_volume_audio_'+audio_id" type="button" data-state="low_volume">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M301.1 34.8C312.6 40 320 51.4 320 64V448c0 12.6-7.4 24-18.9 29.2s-25 3.1-34.4-5.3L131.8 352H64c-35.3 0-64-28.7-64-64V224c0-35.3 28.7-64 64-64h67.8L266.7 40.1c9.4-8.4 22.9-10.4 34.4-5.3zM412.6 181.5C434.1 199.1 448 225.9 448 256s-13.9 56.9-35.4 74.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C393.1 284.4 400 271 400 256s-6.9-28.4-17.7-37.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5z"/></svg>
                    </button>
                    <button v-else-if="isMuted || volumeTotal == 0" :id="'unmute_audio_'+audio_id" :ref="'unmute_audio_'+audio_id" type="button" data-state="unmute">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M301.1 34.8C312.6 40 320 51.4 320 64V448c0 12.6-7.4 24-18.9 29.2s-25 3.1-34.4-5.3L131.8 352H64c-35.3 0-64-28.7-64-64V224c0-35.3 28.7-64 64-64h67.8L266.7 40.1c9.4-8.4 22.9-10.4 34.4-5.3zM425 167l55 55 55-55c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-55 55 55 55c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-55-55-55 55c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l55-55-55-55c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0z"/></svg>
                    </button>
                </div>
                
                <div class="volume_container" v-show="showVolumeBar" @mouseover="showVolume">
                    <div class="volume" @click="selectPointInVolume">
                        <div class="volume_bar" :id="'volume_audio_'+audio_id" :ref="'volume_audio_'+audio_id"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'AudioPlayer',
        props: ['audio_id', 'audio_url'],
        data: () => ({
            isPlaying: false,
            currentAudioTime: '',
            totalAudioLength: '',
            showVolumeBar: false,
            isMuted: false,
            volumeTotal: 1,
            timeOut: ''
        }),
        mounted() {
            let audio = this.$refs['audio_'+this.audio_id]
            audio.addEventListener('ended',() => {
                this.isPlaying = false
            })
            audio.addEventListener('canplay', () => {
                this.totalAudioLength = this.getTotalAudioLength(audio.duration)
                this.currentAudioTime = this.getTotalAudioLength(audio.currentTime)
                
                setInterval(() => {
                    if(!audio.paused) {
                        this.currentAudioTime = this.getTotalAudioLength(audio.currentTime)
                        this.updateProgressBar(audio.duration, audio.currentTime)
                        this.isPlaying = true
                    } else {
                        this.isPlaying = false
                    }
                }, 50)
            })
            audio.addEventListener('ended',() => {
                this.isPlaying = false
            })
        },
        methods: {
            playAudio() {
                let audio = this.$refs['audio_'+this.audio_id]
                if (audio.currentTime == audio.duration) {
                    this.currentAudioTime = this.getTotalAudioLength(0)
                }
                audio.play()
                this.updateVolumeBar()
                this.isPlaying = true
            },
            pauseAudio() {
                let audio = this.$refs['audio_'+this.audio_id]
                audio.pause()
                this.isPlaying = false
            },
            getTotalAudioLength(track) {
                let totalSeconds = Math.round(track)
                let hoursLeft = Math.round(totalSeconds / 3600)
                let min = Math.round((totalSeconds - hoursLeft * 3600) / 60)
                let secondsLeft = totalSeconds - hoursLeft * 3600 - min * 60
                secondsLeft = Math.round(secondsLeft * 100) /100
                let formattedTime = ''
                if (hoursLeft > 0) {
                    formattedTime += (hoursLeft < 10 ? "0"+hoursLeft : hoursLeft)
                    formattedTime += ":"+(min < 10 ? "0"+min : min)
                    formattedTime += ":"+(secondsLeft < 10 ? "0"+secondsLeft : secondsLeft)
                } else if  (hoursLeft <= 0) {
                    formattedTime += (min < 10 ? "0"+min : min)
                    formattedTime += ":"+(secondsLeft < 10 ? "0"+secondsLeft : secondsLeft)
                }
                return formattedTime
            },
            selectPointInAudio(event) {
                let totalBar = this.$refs['progress_audio_'+this.audio_id]
                totalBar.style.width = '100%'
                let totalBarPosition = Math.round(totalBar.getBoundingClientRect().x)
                let barSize = ((event.clientX - totalBarPosition) / totalBar.clientWidth) * 100
                totalBar.style.width = barSize+'%'
                let audio = this.$refs['audio_'+this.audio_id]
                let audioTime = (audio.duration / 100) * barSize
                audio.currentTime = audioTime
            },
            showVolume() {
                clearTimeout(this.timeOut)
                this.showVolumeBar = true
                this.updateVolumeBar()
            },
            hideVolume() {
                this.timeOut = setTimeout(() => {
                    this.showVolumeBar = false
                }, 2000)
            },
            updateProgressBar(audioDuration, currentAudioTime) {
                let bar = this.$refs['progress_audio_'+this.audio_id]
                let barSize = Math.round((currentAudioTime / audioDuration) * 100)
                bar.style.width = barSize+'%'
            },
            selectPointInVolume(event) {
                this.isMuted = false;
                let totalBar = this.$refs['volume_audio_'+this.audio_id]
                let audio = this.$refs['audio_'+this.audio_id]
                totalBar.style.width = '100%';
                let totalBarPosition = Math.round(totalBar.getBoundingClientRect().x)
                let barSize = ((event.clientX - totalBarPosition) / totalBar.clientWidth) * 100
                totalBar.style.width = barSize+'%'
                let audioVolume = (1 / 100) * barSize
                this.volumeTotal = audioVolume
                audio.volume = audioVolume
            },
            muteAndUnmute() {
                this.isMuted = !this.isMuted
                let audio = this.$refs['audio_'+this.audio_id]
                audio.muted = this.isMuted
                let volume = this.$refs['volume_audio_'+this.audio_id]
                if (this.isMuted) {
                    volume.style.width = '0%'
                } else {
                    this.updateVolumeBar()
                }
            },
            updateVolumeBar() {
                let volume = this.$refs['volume_audio_'+this.audio_id]
                let audio = this.$refs['audio_'+this.audio_id]
                if (this.isMuted) {
                    volume.style.width = '0%'
                } else {
                    let bar = (audio.volume / 1) * 100
                    volume.style.width = bar+'%'
                }
            },
        }
    }
</script>
<style scoped>
    .audio_controls {
        width: 100%;
        height: 100px;
        padding: 30px 0px 30px 50px;
        border: 2px solid #e1e1e1;
        border-radius: 50px;
        display: flex;
        align-items: center;
    }
    .audio_controls .control_buttons {
        height: 3.3em;
        width: 3.3em;
        display: flex;
        align-items: center;
        margin-right: 30px;
    }
    .audio_controls .control_buttons button {
        border: none;
        cursor: pointer;
        background: transparent;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .audio_controls .control_buttons button:hover,
    .audio_controls .control_buttons button:focus {
        opacity: 0.5;
    }

    .audio_controls .control-right-side .audio_button {
        margin-right: 70px;
        width: 50px;
    }

    .audio_controls .control-right-side .audio_button button {
        border: none;
        cursor: pointer;
        background: transparent;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .audio_controls .control-right-side .audio_button button:hover,
    .audio_controls .control-right-side .audio_button button:focus {
        opacity: 0.5;
    }

    .audio_controls .progress {
        height: 20%;
        width: 500px;
        margin-right: 10px;
        border-radius: 5px;
        background-color: #e9e9e9;
        cursor: pointer;
    }

    .audio_controls .progress .progress_bar {
        height: 100%;
        width: 0%;
        border-radius: 5px;
        background-color: #6954d9;
    }

    .audio_controls .control-right-side {
        display: flex;
        align-items: center;
    }

    .audio_controls .control-right-side .volume_container {
        height: 25px;
        width: 80px;
        margin-top:5px;
        display: flex;
        border-radius: 20px;
        justify-content: center;
        align-items: center;
        position:absolute;
        margin-left: 120px;
    }

    .audio_controls .control-right-side .volume_container .volume {
        height: 20%;
        width:90%;
        background-color: #e9ecef;
        border-radius: 10px;
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    .audio_controls .control-right-side .volume_container .volume .volume_bar {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        display: inline-block;
        background-color: #6954d9;
    }
</style>