<template>
  <div class="col-12 col-sm-6 col-md-4 mb-3">
    <div class="course-card card text-center">
      <div class="card-body infos d-flex flex-column p-0 mt-4">
        <span class="material-symbols-outlined" @click="showMoreMenu = !showMoreMenu">
          more_vert
        </span>

        <div class="dropdown_menu" v-if="showMoreMenu">
            <button class="dropdown_item" @click="handleAction('block', parseInt(showingFriend.id))">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 15.6797C10.8229 15.6797 12.3854 15.0286 13.6875 13.7266C15.0156 12.3984 15.6797 10.8229 15.6797 9C15.6797 8.375 15.5365 7.65885 15.25 6.85156C14.9896 6.04427 14.6641 5.39323 14.2734 4.89844L4.89844 14.2734C6.07031 15.2109 7.4375 15.6797 9 15.6797ZM2.32031 9C2.32031 9.625 2.45052 10.3411 2.71094 11.1484C2.9974 11.9557 3.33594 12.6068 3.72656 13.1016L13.1016 3.72656C11.9297 2.78906 10.5625 2.32031 9 2.32031C7.17708 2.32031 5.60156 2.98438 4.27344 4.3125C2.97135 5.61458 2.32031 7.17708 2.32031 9ZM3.10156 3.14062C4.74219 1.5 6.70833 0.679688 9 0.679688C11.2917 0.679688 13.2448 1.5 14.8594 3.14062C16.5 4.75521 17.3203 6.70833 17.3203 9C17.3203 11.2917 16.5 13.2578 14.8594 14.8984C13.2448 16.513 11.2917 17.3203 9 17.3203C6.70833 17.3203 4.74219 16.513 3.10156 14.8984C1.48698 13.2578 0.679688 11.2917 0.679688 9C0.679688 6.70833 1.48698 4.75521 3.10156 3.14062Z" fill="currentColor"/>
                </svg>
                &nbsp;
                Bloquear usuário
            </button>
        </div>

        <div>
          <img :src="showingFriend.avatar" class="img-fluid" style="height: 134px; width: 134px; border-radius: 50%; object-fit: contain; object-position: center;">
        </div>
        <h5 class="card-title mt-4">{{showingFriend.name}}</h5>
        <h5 class="card-desc">@{{showingFriend.nickname}}</h5>
        <div class="course-status d-flex justify-content-center my-3">
          <div class="d-flex justify-content-between m-2 card-action" :data-id="showingFriend.id" @click="handleAction('remove', parseInt(showingFriend.id))">
            <span class="material-symbols-outlined">
              person_remove
            </span>
            <span class="action-text">Remover</span>
          </div>
          <div class="d-flex justify-content-between m-2 card-action" @click="handleMessage(parseInt(showingFriend.id))">
            <span class="material-symbols-outlined">
              chat_bubble
            </span>
            <span class="action-text">Mensagem</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: "FriendCard",
  props: ['friend', 'friendAction', 'openMessage'],
  
  watch:{

  },
  data() {
    return {
      showingFriend: null,
      showMoreMenu: false,
    }
  },
  beforeMount(){
    let root_ = this;

    root_.showingFriend = root_.friend;
  },
  mounted(){

  },
  methods: {
    handleAction(action, id) {
      this.friendAction(action, id);
    },
    handleMessage(id) {
      this.openMessage(id);
    }
  }
}
</script>

<style lang="scss">


.dropdown_menu {
    position: absolute;
    top: 13%;
    right: 0;
    margin-right: 10px;
    background-color: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 200px;
    z-index: 100;
    overflow: hidden;
}

.dropdown_item {
    height: 35px;
    border: none;
    background-color: transparent;
    color: #B1B1B1;
    display: flex;
    align-items: center;
    width: cover;
    margin: 0px 5px;
    padding: 0px 7px;
    svg {
        fill: #B1B1B1;
    }
    &:hover {
        color: #6954D9;
        svg {
            fill: #6954D9;
        }
    }
}

.course-card {
  height: cover;
  flex-shrink: 0;

  &:hover {
    box-shadow: 0 0 10px 5px #706F6F30;
  }

  &.card {
    border-radius: 20px;
    border: 2px solid #F6F6F6;
    background: #FFF;
    transition: all .3s ease;
  }

  .card-title {
    font-family: Hanken Grotesk;
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    color: #706F6F;
  }

  .card-desc {
    font-family: Hanken Grotesk;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    color: #706F6F;
  }

  .card-body {

    .material-symbols-outlined {
      font-family: "Material Symbols Outlined";
      font-size: 24px;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0em;
      margin-right: 4px;
      color: #B1B1B1;
      display: flex;
      justify-content: flex-end;
      margin-right: 7px;

      &:hover {
        color: #6954D9;
        cursor: pointer;
      }
    }

    .material-symbols-outlined {
    font-variation-settings:
        'FILL' 0,
        'wght' 400,
        'GRAD' 0,
        'opsz' 48
    }

    .card-action {

      .material-symbols-outlined {
        font-family: "Material Symbols Outlined";
        font-size: 18px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0em;
        margin-right: 4px;
        color: #6954D9;
      }

      .material-symbols-outlined {
      font-variation-settings:
          'FILL' 0,
          'wght' 400,
          'GRAD' 0,
          'opsz' 48
      }
      
      .action-text {
        font-family: Hanken Grotesk;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
      }

      &:hover {
        cursor: pointer;

        .material-symbols-outlined {
          font-weight: 700;
        }
        .action-text {
          font-weight: 700;
        }
      }
    }
    .content-progress {
      background-color: #F6F6F6;
      height: 100%;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;

      .content-completed {
        //margin-left: -55px;
        width: 50%;
        color: #706F6F;
        font-size: 16px;
        font-family: "Hanken Grotesk";
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: left;
        margin-left: -8rem;
      }

    }

  }


  .bi {
    font-size: 2rem;
  }

  .checklist {
    color: #706F6F;

  }

  .notebook {
    color: #706F6F;

  }

  .infos {
    span {
      color: #706F6F;
      text-align: center;
      font-size: 14px;
      font-family: "Space Grotesk";
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

  }

  .apexcharts-canvas {
    right: 4rem;
  }

  @media(min-width: 768px){
    .card-body {
      .content-progress {
        .content-completed {
          font-size: 12px;
        }
      }
    }
  }
  @media(min-width: 1024px){
    .card-body {
      .content-progress {
        .content-completed {
          font-size: 16px;
        }
      }
    }
  }

}
</style>