<template>
    <div id="kash-store">
    
    </div>
</template>

<script>
    import axios from "axios";
    export default {
        name: "AddSuccess",
        data() {
            return {

            }
        },
        methods: {
            login()
            {
                axios;

                //let form_data = new FormData();
                //sandbox
                //form_data.append('token_type', 'bearer');
                ///form_data.append('grant_type', 'password');
                //form_data.append('username', 'C427BC8533B7AE00F072D95A4DE23A072C@3DA5222E855E66A89A3C2510.FB0734');
                //form_data.append('password', '9FB5CA9C63F644F12C657DC3AFA89CFC60649D97062FFBCE4F750C0281433E84');
                //axios.post('https://api-sandbox.kahshpay.com/login', form_data).then(response => this.loginResponse(response)).catch(error => console.log(error.response));


                //produção
                //form_data.append('username', 'jH9vNZ1Oxvb5QFy0JPhycG9pIMpi5wJhoV@bjyCUElYyaQc212spG2jkL4I.I4rmc4');
                //form_data.append('password', 'OiMGv3Vst7sRTjVetIW4Nx8f0BWR5xJED3Z8YsMstCYTT6SfkJovxQaro3S3DOzX');
                //axios.post('https://api.kahshpay.com/login', form_data).then(response => this.loginResponse(response)).catch(error => console.log(error.response));
            },
            loginResponse(r)
            {
                console.log('r => ', r);
            }
        },
        mounted() {
            
        }
    }
</script>

<style scoped>
   
</style>