import axios from "axios";

export default {

    getCourseInfo({ rootState, commit }, payload) {
        let endpoint = this._vm.$url_api + `api/lms/welcome/course`;

        return new Promise((resolve, reject) => {

            let params = {}
            if (payload.id) {
                params.id = payload.id;
            } else if (payload.slug) {
                params.slug = payload.slug;
            }

            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                Authorization : `Bearer ${rootState.token}`
            };

            axios.get(endpoint,
                {
                    headers:headers,
                    params: params
                })
                .then(response => {
                    commit("update_course_data", response.data.data.course);
                    resolve(response.data.data);
                })
                .catch(error => {
                    console.log(error.response.data);
                    reject(error.response.data.data);
                });
        });
    },

    getCourseSections({ rootState, commit }, payload) {
        let endpoint = this._vm.$url_api + `api/lms/welcome/section`;

        return new Promise((resolve, reject) => {

            let params = {}
            if (payload.id) {
                params.id = payload.id;
            } else if (payload.slug) {
                params.slug = payload.slug;
            }

            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                Authorization : `Bearer ${rootState.token}`
            };

            axios.get(endpoint,
                {
                    headers:headers,
                    params: params
                })
                .then(response => {
                    commit("update_course_sections", response.data.data.section);
                    resolve(response.data.data.section);
                })
                .catch(error => {
                    console.log(error.response.data);
                    reject(error.response.data.data);
                });
        });
    },

    getSectionLessons({ rootState, commit }, payload) {
        let endpoint     = this._vm.$url_api + `api/lms/welcome/lesson`;

        return new Promise((resolve, reject) => {

            let params = {}
            if (payload.id) {
                params.id = payload.id;
            } else if (payload.slug) {
                params.slug = payload.slug;
            }

            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                Authorization : `Bearer ${rootState.token}`
            };

            axios.get(endpoint,
                {
                    headers:headers,
                    params: params
                })
                .then(response => {
                    commit("update_section_lessons", response.data.data);
                    resolve(response.data.data);
                })
                .catch(error => {
                    console.log(error.response.data);
                    reject(error.response.data.data);
                });
        });
    }

};