<template>
  <div class="notification-friend-request d-flex gap-3 px-1 py-2 align-items-center">
    <div class="notification-icon d-flex align-items-center justify-content-center">

      <i class="material-icons">
        {{ getIcon() }}
      </i>


    </div>
    <div class="notification-text d-flex flex-column justify-content-between flex-grow-1">
      <div class="d-flex flex-column gap-2">
        <span class="title">{{ title }}</span>
        <div v-if="is_friend">
          <span class="description">{{ $i18n.t('lms.notifications.friend_request_accept', {user: friendNickname})}}</span>
        </div>
        <span v-else class="description">{{ text }}</span>

        <div v-if="is_pending" class="actions d-flex gap-2 align-items-center">
          <span class="accept-request" @click="acceptFriendRequest">{{ $i18n.t('lms.notifications.accept')}}</span>
          <span class="remove-request" @click="removeFriendRequest">{{ $i18n.t('lms.notifications.remove')}}</span>
        </div>
        <div v-else-if="is_reject">
          <span class="description">{{ $i18n.t('lms.notifications.friend_request_reject')}}</span>
        </div>
        <span class="created_at mt-2">{{ createdAt }}</span>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: "FriendRequest",
  props: {
    data: [],
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    createdAt: {
      type: String,
      default: ''
    },
    flag: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      notificationId: 0,
      is_pending: false,
      is_friend: false,
      is_reject: false,
      friendNickname: ''
    }
  },
  beforeMount(){

    let root_ = this;

    root_.notificationId = this.data.notification_id ?? this.data.object.notification_id;
    root_.friendNickname = this.data.nickname ?? this.data.object.nickname;
    root_.is_pending = root_.data.is_pending;
    root_.is_friend = root_.data.is_friend;
    root_.is_reject = !root_.data.is_pending && !root_.data.is_friend;

  },
  methods: {
    getIcon() {

      let icon = "";

      if(this.is_friend) {
        icon = "how_to_reg";
      } else if(this.is_pending) {
        icon = "group_add";
      } else if(this.is_reject) {
        icon = "group_off";
      }

      return icon;

    },
    acceptFriendRequest() {
      console.log("aceitar pedido");
    },
    removeFriendRequest() {
      console.log("remover pedido");
    }
  }
}

</script>

<style scoped lang="scss">

.notification-friend-request {

  flex-grow: 1;
  cursor: default;

  .done {
    cursor: pointer;
    color: #6954D9;
    font-size: 25px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: -10px;
      bottom: -10px;
      left: -10px;
      right: -10px;
    }
  }

  .notification-icon {
    width: 140px;
    height: 89px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #F6F6F6;


  }

  .notification-text {

    .title {
      color: #6954D9;
      font-family: "Hanken Grotesk", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .description {
      color: #706F6F;
      font-family: "Hanken Grotesk", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .created_at {
      color: #B1B1B1;
      font-family: "Hanken Grotesk", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .accept-request {
      cursor: pointer;
      color: #FFF;
      font-family: "Hanken Grotesk", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding: 10px 28px;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
      background: #6954D9;
    }

    .remove-request {
      cursor: pointer;
      color: #706F6F;
      font-family: "Hanken Grotesk", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 10px 28px;
    }

  }


  i.material-icons {
    color: #B1B1B1;
    font-family: "Material Icons";
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }


  &.pendent {
    background-color: #00C4FF1F;

    &:hover {
      background-color: #F9F9F9;
    }
  }

  &.viewed {
    background-color: #FFFFFF;


    &:hover {
      background-color: #F9F9F9;
    }
  }

  &.new-notification {
    background-color: pink;
  }

}




</style>