<template>
  <div class="notification-generic d-flex gap-3"
       @click="handleClick">

    <div class="notification-icon d-flex align-items-center justify-content-center">
      <i class="material-icons">
        {{ icon }}
      </i>
    </div>

    <div class="notification-text d-flex flex-column justify-content-between flex-grow-1">
      <div class="d-flex flex-column gap-2">
        <span class="title">{{ title }}</span>
        <span class="description">{{ text }}</span>
      </div>

      <span class="created_at mt-2">{{ createdAt }}</span>
    </div>

  </div>


</template>

<script>

export default {
  name: "GenericNotification",
  props: {
    data: [],
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    createdAt: {
      type: String,
      default: ''
    },
    flag: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    newNotification: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {

      notificationId: 0
    }
  },
  beforeMount(){

    let root_ = this;

    console.log("Generic Notification: ", root_.type);
    root_.notificationId = this.data.notification_id ?? this.data.object.notification_id;

  },
  methods: {
    handleClick() {
      let click = `handle_${this.type}`;

      // To create new custom functions for notifications, declare it as handle_NOTIFICATION_TYPE
      if (typeof this[click] === 'function') {
        this[click]();
      } else {
        console.error(`Função '${click}' não encontrada.`);
      }
    },
    handle_private_message() {

      if(this.newNotification) {

        this.$parent.$parent.$parent.$parent.$parent.modal_private_messages = true;
        this.$parent.$parent.$parent.$parent.$parent.private_messages_friend_id = this.data.friend_id ?? this.data.object.friend_id;
        this.$parent.$parent.$parent.$parent.$parent.refreshNotificationPrivateMessages(this.data.friend_id);

      } else if(this.$parent.$parent.$parent.refreshNotificationPrivateMessages === undefined){

        this.$parent.$parent.$parent.$parent.modal_private_messages = true;
        this.$parent.$parent.$parent.$parent.refreshNotificationPrivateMessages(this.data.friend_id);
        this.$parent.$parent.$parent.$parent.private_messages_friend_id = this.data.friend_id ?? this.data.object.friend_id;

      } else {

        this.$parent.$parent.$parent.modal_private_messages = true;
        this.$parent.$parent.$parent.private_messages_friend_id = this.data.friend_id ?? this.data.object.friend_id;
        this.$parent.$parent.$parent.refreshNotificationPrivateMessages(this.data.friend_id);     // retira a notificacao
      }

    },
  }
}

</script>

<style scoped lang="scss">

.notification-generic {
  flex-grow: 1;
  cursor: default;

  .notification-icon {
    width: 140px;
    height: 89px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #F6F6F6;


  }

  i.material-icons {
    color: #B1B1B1;
    font-family: "Material Icons";
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .notification-text {

    .title {
      color: #6954D9;
      font-family: "Hanken Grotesk", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .description {
      color: #706F6F;
      font-family: "Hanken Grotesk", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .created_at {
      color: #B1B1B1;
      font-family: "Hanken Grotesk", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }


}




</style>