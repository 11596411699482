<template>
  <div class="notification d-flex gap-3 px-1 py-2 align-items-center" :class="[flag, {'new-notification': newNotification}]">

    <component  :is="getComponentType()"
                :data="data"
                :title="title"
                :text="text"
                :flag="flag"
                :icon="icon"
                :type="type"
                :new-notification="newNotification"
                :createdAt="createdAt"
    ></component>

    <div v-if="flag !== 'reed'" class="material-symbols-outlined done"
         @click.stop="markAsReed"
         :title="$i18n.t('lms.notifications.markAsRead')">
      done
    </div>

  </div>


</template>

<script>

import GenericNotification from "@/components/lms/components/LmsNotificationsComponents/GenericNotification.vue";
import FriendRequest from "@/components/lms/components/LmsNotificationsComponents/FriendRequest.vue";

export default {
  name: "Notification",
  components: {
    GenericNotification,

  },
  props: {
    data: [],
    newNotification: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      title: "",
      text: "",
      createdAt: "",
      flag: "",
      icon: "",
      type: "",
      notificationId: 0
    }
  },
  beforeMount(){
    let root_ = this;

    root_.type = root_.data.user_notification?.type ?? root_.data.type;
    root_.title = this.data.title ?? this.data.object.title;
    root_.text = this.data.text ?? this.data.object.text;
    root_.flag = this.data.flag ?? this.data.object.flag;
    root_.createdAt = this.data.time_ago;
    root_.icon = root_.getIcon(root_.type);
    root_.notificationId = this.data.notification_id ?? this.data.object.notification_id;

    if(root_.flag === 'pendent') {
      let payload = {
        id: root_.notificationId
      }
      root_.$store.dispatch("LMS/updateNotificationAsViewed", payload)
          .then(() => {

            let payload = {
              notificationId: root_.notificationId
            }

            root_.$store.dispatch("remove_websocket_notifications_pendent_ids", payload);

          })
    }

  },
  methods: {
    getIcon(type) {

      if(type === "private_message") {
        return "mail";
      } else if(type === "cart_order" || type === "package_payment") {
        return "add_shopping_cart";
      } else if(type === "lms_finished_lesson" || type === "lms_finished_section" || type === "lms_finished_course") {
        return "local_library";
      } else if(type === "etalent_report") {
        return "summarize";
      } else {
        return "mail";
      }

    },
    getComponentType() {

      if(this.type === 'private_message'
          || this.type === 'cart_order' || this.type === "package_payment"
          || this.type === "lms_finished_lesson" || this.type === "lms_finished_section" || this.type === "lms_finished_course"
          || this.type === 'etalent_report'
          ){
        return GenericNotification;
      } else if(this.type === "friend_request") {
        return FriendRequest;
      } else {
        return GenericNotification;
      }


    },
    markAsReed() {


      let root_ = this;
      let payload = {
        id: this.data.notification_id ?? this.data.object.notification_id
      }

      this.$store.dispatch("LMS/updateNotificationAsReed", payload)
          .then(() => {
            root_.flag = 'reed';

            if(root_.data.flag === undefined) {
              root_.data.object.flag = 'reed';
            } else {
              root_.data.flag = 'reed';
            }

          });
    }

  }
}

</script>

<style scoped lang="scss">

.notification {
  border-bottom: 1px solid #E1E1E1;

  .done {
    cursor: pointer;
    color: #6954D9;
    font-size: 25px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: -10px;
      bottom: -10px;
      left: -10px;
      right: -10px;
    }

  }


  &.new-notification {
    background-color: pink !important;
  }



  &.pendent {
    background-color: #00C4FF1F;

    &:hover {
      background-color: #F9F9F9;
    }
  }

  &.viewed {
    background-color: #FFFFFF;


    &:hover {
      background-color: #F9F9F9;
    }
  }

  &:hover {
    background-color: #F9F9F9;
  }

}




</style>