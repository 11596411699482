<template>

    <div id="modal" ref="modal" class="modals bgBlack">
        <div style="width: 100vw;height: 100vh;position: fixed;top: 0;left: 0;" data-response="0" v-on:click="close"></div>
        <div ref="buy-handler-content" :class="{'modalGerais':true, 'modalQuiz':true,'quizFinished':finished}" id="modalKrpano">
            <div class="modals__content alignVertical">
                <div class="modals__content--header alignVertical">
                    <p>{{ title }}</p>
                    <a id="fs-iframe" href="#" v-on:click="openModalFS">
                        <svg style="position: absolute;width: 20px;height: 20px;right: 60px;top: 50%;transform: translateY(-50%);" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path d="M344 0H488c13.3 0 24 10.7 24 24V168c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-39-39-87 87c-9.4 9.4-24.6 9.4-33.9 0l-32-32c-9.4-9.4-9.4-24.6 0-33.9l87-87L327 41c-6.9-6.9-8.9-17.2-5.2-26.2S334.3 0 344 0zM168 512H24c-13.3 0-24-10.7-24-24V344c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2l39 39 87-87c9.4-9.4 24.6-9.4 33.9 0l32 32c9.4 9.4 9.4 24.6 0 33.9l-87 87 39 39c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8z" fill="#fff"/>
                        </svg>
                    </a>
                    <a href="#" class="close" data-response="0" v-on:click="close">
                        <img src="assets/icons/modals/x.svg" alt="">
                    </a>
                </div>
                <div class="modals__content--body alignVertical" :style="'background-image: url(\''+$url+'assets/icons/modals/conteudo.svg\');'">
                    <iframe v-on:load="sendMessage" ref="iframemodalPergunta" v-if="iframe_actual" :src="iframe_actual" frameborder="0"></iframe>

                    <!-- TELA DE FULL SCREEN -->
                    <div v-if="modalFs" class="lightbox-pdf modals bgBlack">
                        <div class="modals__content--header alignVertical">
                            <p>{{ title }} </p>
                            <a href="#" class="close" data-response="0" v-on:click="modalFs = false">
                                <svg style="position: absolute;width: 20px;height: 20px;right: 20px;top: 50%;transform: translateY(-50%);" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path d="M344 0H488c13.3 0 24 10.7 24 24V168c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-39-39-87 87c-9.4 9.4-24.6 9.4-33.9 0l-32-32c-9.4-9.4-9.4-24.6 0-33.9l87-87L327 41c-6.9-6.9-8.9-17.2-5.2-26.2S334.3 0 344 0zM168 512H24c-13.3 0-24-10.7-24-24V344c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2l39 39 87-87c9.4-9.4 24.6-9.4 33.9 0l32 32c9.4 9.4 9.4 24.6 0 33.9l-87 87 39 39c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8z" fill="#fff"/>
                                </svg>
                            </a>
                        </div>
                        <iframe v-on:load="sendMessage" ref="iframemodalPergunta" v-if="iframe_actual" :src="iframe_actual" frameborder="0"></iframe>
                    </div>
                    <!-- FIM TELA DE FULL SCREEN -->
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="box-bg" id="modal" ref="modal">
        <div class="ani box">
            <h3 class="tab">{{ $t('modaliframe.pergunta') }}<a class="btn-0" v-on:click="close">
                    <img :src="$url + 'assets/img/icon_close_box.png'" alt="Fechar" style="float: right;margin: 20px 25px 0 0" width="16">
                </a>
            </h3>
            <div class="modal-body">
               <iframe v-if="iframe_actual" :src="iframe_actual" frameborder="0"></iframe>
            </div>
        </div>
    </div> -->
</template>

<script>
    
    import { mapActions, mapGetters } from "vuex";
    
    export default {
        name: 'ModalIframe',
        props: ['modal_iframe_type'],
        computed: {
            ...mapGetters([
                'logged', 'token', 'id_form_actual', 'sessions'
            ]),
        },
        data() {
            return {
                iframe_actual:null,
                title:this.$i18n.t('modaliframe.pergunta'),
                finished: false,
                modalFs: false
            }
        },
        components: {
            
        },
        methods: {
            ...mapActions([
                'update_id_form_actual'
            ]),
            close(e) {
                this.$parent.$refs['ambient_transition'].quiz_active = false;
                this.$parent.modal_iframe = false;
                if (e)
                    e.preventDefault();
            },
            sendMessage(){
                this.$refs['iframemodalPergunta'].contentWindow.postMessage({token:this.token}, '*');
                console.log(this.$refs['iframemodalPergunta'].contentWindow.postMessage({token:this.token}, '*'));
            },
            onMessage(ev){
                if (ev && ev.data && ev.data.message){
                    console.log(ev.data.message);
                    if (ev.data.message == 'finished')
                        this.finished = true;
                }
            },
            openModalFS() {
                this.modalFs = true;
            }
        },
        created() {
            const _root = this;
            window.addEventListener('message',function (e){
                if(new URL(e.origin).host === new URL(_root.$url_api).host){
                    if (e.data.type && e.data.subtype)
                        window.callingVuejsFunction({type:"admin", subtype:"close_modal"});
                }
            });
        },
        mounted() {
            this.iframe_actual = this.$url_api + 'form/view/'+ this.id_form_actual+ '?lang='+ this.$i18n.locale;
            this.finished = false;
            window.addEventListener('message', this.onMessage);

        },
        beforeDestroy() {
            const _root = this;
            window.removeEventListener('message',function (e){
                if(new URL(e.origin).host === new URL(_root.$url_api).host){
                    if (e.data.type && e.data.subtype)
                        window.callingVuejsFunction({type:"admin", subtype:"close_modal"});
                }
            });
        },
    }
</script>

<style scoped>
    #modal
    {
        z-index: 999;
    }
    .box
    {
        width: 550px;
    }
    .tab
    {
        text-align: left;
    }

    iframe
    {
        display: block;
        height: 100%;
        min-height: 350px;
        width: 99%;
    }


    /*----- body -----*/
    .modal-body {
        box-sizing: border-box;
        display: block;
        min-height: 400px;
        padding: 25px 15px;
        text-align: center;
        width: 100%;
    }
    /*----- \body\ -----*/

    .lightbox-pdf {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 99999999999999;
    }

    @media screen and (max-width: 550px)
    {
        .box
        {
            width: calc(100% - 40px);
        }
    }
</style>