<template>
    <div class="modals bgBlack" style="z-index: 9999;" id="termos" ref="termos">
        <div class="termos">
            <div class="modals__content">
                <div class="modals__content--header alignVertical">
                    <p>{{this.$i18n.t('signup.termosECondicoes')}}</p>
                    <a class="btn-0" v-on:click="backToRegister" ref="btn-close">
                        <img src="assets/icons/modals/x.svg" alt="">
                    </a>
                </div>
                <div class="modal-body" style="text-align: justify;">
					<div class="swiper-container termos__swiper">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide">
								<div  v-html="terms"></div>
                                <!--<div v-html="regulation"></div>-->
                            </div>
                        </div>
                        <div class="swiper-scrollbar"></div>
                    </div>

				<!-- 	<div class="termos__termos alignVerticalStart">
						<input type="checkbox">
						<label> Aceito termos e condições*</label>
					</div> -->
                </div>
                <div class="modal-footer alignVertical">
                    <button v-if="$route.name !='TermosPage'" type="submit" v-on:click="$parent.modal_Termos=false"
                            class="button blue close termos__button" style="min-width:100px">Ok
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {mapGetters} from "vuex";
import axios from "axios";
import Swiper from 'swiper/bundle';

export default {
    name: 'Termos',
    props: ['txt'],
    computed: {
        ...mapGetters([
            'logged', 'token','switch'
        ]),
    },
    data() {
        return {
			termos_swiper: null,
			terms: "",
            regulation: "",
            started: false
		}
    },
    components: {},
    methods: {
        openPolitica() {
            if (this.$route.name != "TermosPage") {
                this.$parent.modal_PoliticaDePrivacidade = true;
                this.$parent.modal_Termos = false;
            } else
                this.$parent.link("PoliticaPage")
        },

        backToRegister() 
        {
            this.$router.go(-1);
        },

		getTerms(){
            axios.get(this.$url_api + 'api/getTerms', {headers: {'language': this.$i18n.locale,},
                params:{event_name: this.switch.Nome_Aba??"",regulamento:this.txt}}).then(response => this.getTermsResponse(response));
		},
        getTermsResponse(obj)
        {
            this.terms = obj.data.data.description;
            this.termos_swiper.update();
            //this.$forceUpdate();
        },

		async getRegulation(){
            this.loading = true;
            this.$parent.wait(true);
            const headers = { 'Content-Type': 'application/json;charset=UTF-8', 'Access': 'application/json', language: this.$i18n.locale, Authorization : `Bearer ${this.token}`};
            axios.get(this.$url_api + 'api/getRegulation', { headers:headers }).then(response => this.rankingRegulationLoadResponse(response)).catch(error => this.rankingRegulationLoadError(error.response));
		},
        async rankingRegulationLoadResponse(obj){
            this.$parent.wait(false);
            obj;
            this.regulation = obj.data.data;
        },
        async rankingRegulationLoadError(obj){
            obj;
            this.$parent.wait(false);
        }
    },
    created() {

    },
    mounted() {
        this.$parent.analytics('visit_terms');
		this.getTerms();
        //this.getRegulation();

		this.termos_swiper = new Swiper(".termos__swiper", {
            direction: "vertical",
			slidesPerView: "auto",
			freeMode: true,
			scrollbar: {
				el: ".swiper-scrollbar",
			},
			mousewheel: true,
        });
    },
	updated() {
        this.termos_swiper.update();
    },
    beforeDestroy() {
        this.$parent.regulamento=false;
    },
}
</script>
