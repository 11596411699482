<template>
    <div id="reset-password" ref="reset-password" class="modals bgBlack">
        <div style="width: 100vw;height: 100vh;position: fixed;top: 50;left: 0;" data-response="0"></div>
        <div ref="buy-handler-content" class="modalGerais">
            <div class="modals__content flex alignVertical">
                <div class="modals__content--header alignVertical">
                    <p>{{ $t('resetpassword.validarEmail') }}</p>
                    <a class="btn-0" v-on:click="$parent.link('Home');$parent.modal_login_ = false" ref="btn-close">
                        <img src="assets/icons/modals/x.svg" alt="">
                    </a>
                </div>
                <div class="modals__content--body alignVertical" :style="'background-image: url(\''+$url+'assets/icons/modals/conteudo.svg\');'">

                    <div class="modalnoticia__content alignVertical">
                        <form id="reset-password-form" ref="reset-password-form" v-on:submit="send">
                            <input name="token" type="hidden" :value="token_">

                            <div class="col-12 flex">
                                <input class="col-12 text" id="password" name="password" :placeholder="$t('resetpassword.senha')" ref="password" type="password">
                            </div>

                            <div class="col-12 flex">
                                <input class="col-12 text" id="password_confirmation" name="password_confirmation" :placeholder="$t('resetpassword.confirmeASenha')" ref="password_confirmation" type="password">
                            </div>

                            <div class="col-12 flex">
                                <input class="button__buttonForm button__red col-12" ref="btn-submit" type="submit" :value="$t('resetpassword.recadastrarSenha')" style="margin: 5px;">
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    </div>            

    <!-- <div class="box-bg" id="reset-password" ref="reset-password">
        <form class="ani box" id="reset-password-form" ref="reset-password-form" v-on:submit="send">
            <h3 class="tab">{{ $t('resetpassword.recadastrarSenha') }}<router-link class="btn-0" to="/login" ref="btn-close">
                    <img :src="$url + 'assets/img/icon_close_box.png'" alt="Fechar" style="float: right;margin: 20px 25px 0 0" width="16">
                </router-link>
            </h3>
            <br>
            <input name="token" type="hidden" :value="token_">
            <ul>
                <li>
                    <label for="password">{{ $t('resetpassword.senha') }}</label>
                    <input id="password" name="password" :placeholder="$t('resetpassword.senha')" ref="password" type="password">
                </li>
                <li>
                    <label for="password_confirmation">{{ $t('resetpassword.confirmeASenha') }}</label>
                    <input id="password_confirmation" name="password_confirmation" :placeholder="$t('resetpassword.confirmeASenha')" ref="password_confirmation" type="password">
                </li>
                <li>
                    <input class="btn-red" ref="btn-submit" type="submit" :value="btn_submit_value">
                    <router-link class="btn-simple" to="/">{{ $t('resetpassword.home') }}</router-link>
                </li>
            </ul>
        </form>
    </div> -->
</template>

<script>
    import axios from 'axios';
    import { mapActions } from "vuex";
    export default {
        name: 'ResetPassword',
        computed: {
           
        },
        data() {
            return {
                btn_submit      : false,
                btn_submit_value: this.$i18n.t('resetpassword.recadastrarSenha'),
                error           : null,
                message         : null,
                token_          : null
            }
        },
        components: {
            
        },
        methods: {
            ...mapActions([
                
            ]),
            block(v) {
                    this.btn_submit_value = (v ? (this.$i18n.t('resetpassword.aguarde')) : this.$i18n.t('resetpassword.entrar'));
                    this.$refs["btn-submit"].style.pointerEvents = (v ? 'none' : 'auto');
                    this.$refs["btn-submit"].style.opacity = (v ? '.4' : '1');
            },
            send(e) {
                this.error = null;
                if (this.$refs["password"].value.length < 8) {
                    this.error = this.$i18n.t('resetpassword.minimoOito');
                } else if (this.$refs["password"].value != this.$refs["password_confirmation"].value) {
                    this.error = '';
                }

                if (this.error) {
                     this.$parent.modal('error', this.$i18n.t('resetpassword.erro'), this.error, null, null, null, "OK");
                    e.preventDefault();
                } else {
                    this.sendReset(e);
                }
            },
            sendReset(e) {
                if (!this.submit) {
                    this.block(true);
                    let form_data = new FormData(this.$refs["reset-password-form"]);
                    
                    axios.post(this.$url_api + 'api/changepwd', form_data,{headers:{'language': this.$i18n.locale,}}).then(response => this.resetResponse(response)).catch(error => this.resetError(error.response));
                }
                e.preventDefault();
            },
            resetResponse(obj) {
                if (obj.data.status == 'success') {
                    this.message = obj.data.message;
                    this.$parent.modal('success', this.$i18n.t('resetpassword.sucesso'), this.message, null, null, null, "OK");
                    this.$parent.link('Login');
                }

                this.block(false);
            },
            resetError(obj) {
                this.block(false);
                let str = '';
                for (let o in obj.data.errors) {
                    str += obj.data.errors[o] + '\n';
                }
                this.error = str;
                this.$parent.modal('error', this.$i18n.t('resetpassword.erro'), this.error, null, null, null, "OK");
            }
        },
        mounted() {
            this.$refs['password'].focus();

            this.token_ = this.$route.query.token;
        },
        updated() {
            this.btn_submit_value = this.$i18n.t('resetpassword.recadastrarSenha');
        },
        destroyed() {
        }
    }
</script>

<style scoped>
    #reset-password > form > ul > li
    {
        box-sizing: border-box;
        list-style: none;
        padding: 10px;
    }
    h3
    {
        color: #b62f32;
    }
    .li-inline *
    {
        display: inline-block;
        vertical-align: middle;
    }
    .btn-blue, .btn-red
    {
        display: block;
        letter-spacing: 1px;
        padding: 15px 10px;
        width: 100%;
    }
    @media screen and (max-width: 500px)
    {
        form
        {
            width: calc(100% - 40px);
        }
    }
</style>