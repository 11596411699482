<template>
  <LmsBasicTemplate :current-page="navigationConfig.pageName" :searchField="true"
                    :searchPlaceholder="this.$i18n.t('lms.payments.searchPlaceholder')"
                    class="payments-page">
    <template v-slot:navigation-side>

      <ProfileNavigation></ProfileNavigation>

      <section class="mt-3">

        <div class="menu-buttons mt-3 d-flex flex-column gap-3">
          <div id="nav-tab" class="nav flex-column gap-3" role="tablist">
            <button v-for="(button, idxButton) in navigationConfig.navigation" class="btn nav-link py-3"
                    :class="{'active': idxButton === 1 }"
                    :id="`nav-${button.slug}-tab`" data-bs-toggle="tab" :data-bs-target="`#nav-${button.slug}`" type="button"
                    role="tab" :aria-controls="`nav-${button.slug}`" :aria-selected="idxButton === 1" :key="idxButton">

              {{ button.name }}
            </button>
          </div>

        </div>
      </section>


      <div class="card card-hint mt-5">
        <div class="card-body">
          <div class="d-flex gap-3">
            <i class="material-icons tips">
              tips_and_updates
            </i>
            <span>{{ $i18n.t('lms.payments.hint')}}</span>
          </div>
          <p class="card-text mt-3" v-text="$i18n.t('lms.payments.hint-description')"></p>

        </div>
      </div>

    </template>

    <template v-slot:content-side>

      <div id="nav-tabContent" class="tab-content ps-5" >


        <div class="tab-pane fade" id="nav-my-wallet" role="tabpanel" aria-labelledby="nav-my-wallet-tab">
          <span>Em desenvolvimento</span>
        </div>

        <div class="tab-payment-history tab-pane fade active show" id="nav-payment-history" role="tabpanel" aria-labelledby="nav-payment-history-tab">

          <div v-if="false" class="payment-graph">
            <div class="wallet">
              <div class="currencies">
                <div class="fiduciary">
                  <span>R$</span>
                  <span class="mask-money"> {{ maskMoney(145000) }}</span>
                </div>
                <div class="coins">
                  <span>${{ TotalCoins }}</span>
                  <span class="coins-name"> {{ getCoinsName }}</span>
                </div>

              </div>
              <span>Comprar moedas</span>
            </div>
            <div>
              gráfico
            </div>
          </div>

          <div class="payment-history p-5 mt-3">

            <div class="payment-history-header d-flex justify-content-between mb-3">

              <span class="">{{ $i18n.t("lms.payments.payments_history") }}</span>

              <select class="form-select col-3" id="filterDays" v-model="filterDays" @change="updateOrders">
                <option value="0" selected>{{ $i18n.t("lms.payments.all_period") }}</option>
                <option value="7" >{{ $i18n.t("lms.payments.last_7_days") }}</option>
                <option value="15" >{{ $i18n.t("lms.payments.last_15_days") }}</option>
                <option value="30" >{{ $i18n.t("lms.payments.last_30_days") }}</option>
              </select>
            </div>

            <div class="row accordion" id="accordionSections">
              <div class="accordion-item px-0" v-for="(order, idxOrder) in orders" :key="idxOrder">
                <div class="accordion-header" :id="`heading-${order.name}`" style="position:relative;">
                  <div v-if="invoices_enabled && order.invoice && order.gateway === 'pagseguro'" class="ooboy" @click="getInvoice(order.order_type, order.invoice.uuid)">
                    <span class="material-symbols-outlined">feed</span>
                    <span>NF</span>
                  </div>
                  <div class="accordion-button collapsed justify-content-between flex-wrap" type="button" data-bs-toggle="collapse"
                       :data-bs-target="`#collapse${order.name}`" aria-expanded="false"
                       :aria-controls="`collapse${order.name}`">


                    <span class="order-name col-2">{{ getOrderName(order) }}</span>

                    <div class="order-status d-flex align-items-center gap-2 col-2" :class="getOrderStatus(order).class">
                      <div class="status"></div>
                      <span>{{ getOrderStatus(order).status }}</span>
                    </div>

                    <div class="gateway d-flex align-items-center col-2">
                      <div v-if="order.gateway === 'coins'" class="d-flex align-items-center" >
                        <svg  width="28" height="28" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.6185 10.9523V13.8095C17.6185 15.0476 14.6337 16.6666 10.9518 16.6666C7.26992 16.6666 4.28516 15.0476 4.28516 13.8095V11.4285" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M4.56445 11.6752C5.38826 12.7686 7.93588 13.7943 10.9511 13.7943C14.633 13.7943 17.6178 12.2648 17.6178 10.9505C17.6178 10.2124 16.6778 9.4038 15.2025 8.83618" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M14.7611 6.19043V9.04757C14.7611 10.2857 11.7763 11.9047 8.0944 11.9047C4.4125 11.9047 1.42773 10.2857 1.42773 9.04757V6.19043" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M8.0944 9.03236C11.7763 9.03236 14.7611 7.50284 14.7611 6.18855C14.7611 4.87331 11.7763 3.33331 8.0944 3.33331C4.4125 3.33331 1.42773 4.87331 1.42773 6.18855C1.42773 7.50284 4.4125 9.03236 8.0944 9.03236Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span>{{ order.total}}</span>
                      </div>
                      <div v-else-if="order.gateway === 'pagseguro'" class="d-flex align-items-center" >
                        <span class="material-symbols-outlined">
                          attach_money
                        </span>
                        <span v-if="order.order_type === 'cart'">{{ maskMoney(order.total) }}</span>
                        <span v-else >{{ maskMoney(order.package.value) }}</span>
                      </div>
                      <div v-else class="gateway-free d-flex align-items-center">
                        <span class="material-symbols-outlined">
                          money_off
                        </span>
                        <span>{{ $i18n.t('lms.payments.gateway_free') }}</span>
                      </div>

                    </div>

                    <div class="date col-2 d-flex align-items-center" >
                      <span class="material-symbols-outlined">
                        calendar_today
                      </span>
                      <span>{{ $moment(order.created_at).format('DD/MM/YYYY') }}</span>

                    </div>

                    <div v-if="invoices_enabled" class="invoice col-1 d-flex align-items-center"></div>

                    <div class="actions-section d-flex align-items-center gap-5 col-1">

                      <i v-if="order.order_type === 'cart'" class="bi bi-chevron-down"></i>
                      <span v-else >+ {{ order.total }}</span>
                    </div>

                  </div>
                </div>
                <div :id="`collapse${order.name}`" v-if="order.items && order.items.length && order.type != 'package'"
                     class="accordion-collapse collapse" :aria-labelledby="`heading${order.name}`"
                     data-bs-parent="#accordionSections">
                  <div class="accordion-body">
                    <div>
                      <ul class="order-items px-0">
                        <li v-for="(item, idxItem) in order.items" :key="idxItem" @click="viewCourse(item)">
                          <div v-if="item.item">
                            <span >{{ item.item.title }}</span>
                          </div>

                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <pagination class="mt-4" v-if="paginationOptions.show" v-model="paginationOptions.page" @paginate="updateOrders"
                        :records="paginationOptions.total" :per-page="paginationOptions.per_page" :options="paginationOptions" />
          </div>
        </div>


      </div>


    </template>


  </LmsBasicTemplate>


</template>

<script>

import { mapGetters } from "vuex";
import LmsBasicTemplate from "@/components/lms/LmsBasicTemplate.vue";
import ProfileNavigation from "@/components/lms/components/ProfileNavigation.vue";
import CustomPagination from "@/components/lms/components/LmsHomeComponents/CustomPagination.vue";
let emitter = require('tiny-emitter/instance');
export default {
  name: "LmsPayments",
  components: {
    LmsBasicTemplate,
    ProfileNavigation
  },
  data() {
    return {
      navigationConfig: [],
      orders: [],
      invoices_enabled: false,
      filterDays: 0,
      searchText: "",
      paginationOptions: {
        paginationKey: 0,
        show: true,
        total: 10,
        page: 1,
        per_page: 6,
        template: CustomPagination
      }
    }
  },
  beforeMount() {

    let root_ = this;
    root_.navigationConfig = root_.getPageByName("LmsPayments");

    root_.updateOrders();

  },
  mounted() {

    let root_ = this;

    emitter.on('filter-courses', function(searchText){

      root_.searchText = searchText;

      if(root_.searchText.length >= 4 || root_.searchText.length === 0) {
        root_.updateOrders(1);
      }

    });

  },
  beforeDestroy() {
    emitter.off('filter-courses');
  },
  methods: {
    updateOrders(page) {


      let root_ = this;

      let payload = {
        "days": this.filterDays,
        "search": this.searchText.length >= 4 ? this.searchText : "",
        "page"        : page,
      }

      root_.$store.dispatch("LmsCart/getPaymentsHistory", payload)
          .then(data => {
            root_.orders = data.orders.data;
            root_.invoices_enabled = data.invoices_enabled;


            root_.paginationOptions.current_page  = data.orders.current_page;
            root_.paginationOptions.total         = data.orders.total;
            root_.paginationOptions.per_page      = data.orders.per_page;
            root_.paginationOptions.page          = data.orders.current_page;


          })
          .catch(error => {
            console.error("updateOrders: ", error);
          });

    },
    getOrderName(order) {
      let name = order.name;

      if(order.order_type === 'package') {
        name = order.package.name;
      }

      return name;
    },
    viewCourse(course) {
      console.log("viewCourse: ", course);

      this.$router.push({
        name: "LMSCourses",
        params: {
          lmsSlug: this.getLms.slug,
          cursoSlug: course.item.slug,
        }
      });
    },
    getOrderStatus(order) {

      let translate = {
        "status": this.$i18n.t("lms.payments.paid"),
        "class": "paid"
      }
      let status = order.gateway !== "free" ? order.status.toLowerCase() : "paid";

      //CANCELED | WAITING | PAID | IN_ANALYSIS | DECLINED | AUTHORIZED
      if(status === "canceled") {
        translate = {
          "status": this.$i18n.t("lms.payments.authorized"),
          "class": "authorized"
        }
      } else if(status === "canceled") {
        translate = {
          "status": this.$i18n.t("lms.payments.canceled"),
          "class": "canceled"
        }
      } else if(status === "declined") {
        translate = {
          "status": this.$i18n.t("lms.payments.declined"),
          "class": "declined"
        }
      } else if(status === "in_analysis") {
        translate = {
          "status": this.$i18n.t("lms.payments.in_analysis"),
          "class": "in_analysis"
        }
      } else if(status === "waiting") {
        translate = {
          "status": this.$i18n.t("lms.payments.waiting"),
          "class": "waiting"
        }
      } else if(status === "denied") {
        translate = {
          "status": this.$i18n.t("lms.payments.denied"),
          "class": "declined"
        }
      }

      return translate;

    },
    goTo(slug){
      console.log("goTo: ", slug);
      if(slug !== "") {
        this.$router.push({ name: slug})
      }
    },
    maskMoney(value) {
      let v = String(value).replace(/\D/g, '');
      v = (v / 100).toFixed(2);
      v = v.replace(".", ",");
      v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
      v = v.replace(/(\d)(\d{3}),/g, "$1.$2,");
      return v;
    },
    getInvoice(order_type, uuid) {
      let root_ = this;

      root_.$parent.wait(true);

      let payload = {
        "order_type": order_type,
        "uuid": uuid,
      }

      console.log('TESTE => ', payload);
      root_.$store.dispatch("LmsCart/downloadInvoice", payload)
        .then(data => {
          
          console.log('TESTE => ', data.invoice[0]);
          if( data.invoice ) {
            window.open(data.invoice[0], '_blank', 'download=nota.pdf');
          }

        })
        .catch(error => {
          console.error("getInvoice: ", error);
        })
        .finally( ()=>{
          root_.$parent.wait(false);
        });

    },
  },
  computed: {
    ...mapGetters("LMS", [
      "getPageByName", "getLms", "getCoinsName"
    ]),
    ...mapGetters([
      "TotalCoins"
    ])

  }
}
</script>

<style>
  body {
    overflow-y: scroll; /* Força a exibição da barra de rolagem, evita deslocamento da página quando ela aparece */
  }
</style>

<style scoped lang="scss">

  .tab-content {
    .tab-pane {
      &.active {
        color: #6954D9 !important;
      }
    }
  }


  .tab-payment-history {
    background-color: transparent;
  }

  .payment-graph {
    .wallet {
      .currencies {
        .fiduciary {
          span {
            color: #706F6F;
            font-family: "Space Grotesk", sans-serif;
            font-size: 28px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          .mask-money {
            color: #6954D9;
            font-family: Space Grotesk;
            font-size: 48px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }

        }

        .coins {

        }

      }
    }
  }

  .payment-graph {
    padding: 50px;
    border-radius: 50px;
    background: #FFF;
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.10);
  }

  .payment-history {
    min-height: 680px;
    border-radius: 50px;
    background: #FFF;
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.10);

    .payment-history-header {

      span {
        color: #6954D9;
        font-family: "Hanken Grotesk", sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      select#filterDays {
        width: 35%;

        color: #706F6F;
        font-family: "Hanken Grotesk", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding: 15px;
      }

    }



    .accordion-item {

      border: none;

      &:not(:last-child) {
        border-bottom: 1px solid #E1E1E1;
      }

      .gateway {

        color: #B1B1B1;
        font-family: Material Icons;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        svg {
          fill: #B1B1B1;
        }

      }

      .actions-section {
        span {
          color: var(--buttons_color);
          font-family: "Hanken Grotesk", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 40px;
        }
      }


    }

    .accordion-button {

      .date {
        color: #B1B1B1;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .order-name {
        color: #706F6F;
        font-family: "Hanken Grotesk", sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }


      .order-status {

        .status {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: #10CD79;
        }

        span {
          font-family: "Hanken Grotesk", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 40px;
          color: #10CD79;
        }

        &.authorized {
          .status {
            background-color: #10CD79;
          }

          span {
            color: #10CD79;
          }
        }

        &.canceled, &.declined {

          .status {
            background-color: #E82E2E;
          }
          span {
            color: #E82E2E;
          }

        }

        &.waiting, &.in_analysis {
          .status {
            background-color: #F5BF04;
          }
          span {
            color: #F5BF04;
          }
        }
      }

      &:not(.collapsed) {
        border-radius: 10px 10px 0 0;
        background: #F6F6F6;
        box-shadow: none;

        .bi-chevron-down {
          transform: rotate(180deg);
        }
      }

      &:after {
        content: none; /* Remove a seta padrão */
      }

      .bi-chevron-down {
        color: var(--buttons_color);
        font-size: 25px;
        font-style: normal;
        line-height: normal;
        transition: all 0.5s ease;
      }
    }

    &.active {
      background-color: transparent;
    }




    .order-items {
      list-style: none;
      color: #706F6F;
      font-family: "Hanken Grotesk", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      li {
        cursor: pointer;
      }

    }



  }


  .card-hint {

    border-radius: 10px;
    border: 1px solid #E1E1E1;

    .tips {
      color: var(--buttons_color);
    }

    .card-body {
      span {
        color: #706F6F;
        font-family: "Hanken Grotesk", sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .card-text {
        color: #706F6F;
        font-family: "Hanken Grotesk", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .menu-buttons {
    .nav {
      button.btn {
        color: #706F6F;
        background-color: #F6F6F6;
        font-size: 18px;
        font-family: "Hanken Grotesk", sans-serif;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: left;

        .bi {
          color: var(--buttons_color);
        }

        &.active {
          color: #706F6F !important;
          border: 1px solid #5D5C5C;
        }

        &:active {
          border: 1px solid #5D5C5C;
        }
      }
    }


  }

  .VuePagination {
    display: flex;
    justify-content: center;
  }

  .ooboy{
    position: absolute;
    right: 14.5%;
    top: 50%;
    z-index: 4;
    transform: translate(0, -50%);
    color: #B1B1B1;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    align-items: center !important;
    display: flex !important;
    cursor: pointer;
  }
</style>