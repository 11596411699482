<template>

    <div class="modals bgAzul" id="package-buy" ref="package-buy">
        <div v-on:click="$parent.modal_package_buy = false;"></div>
        <div class="package-buy">
            <div class="modals__content">
                <div class="modals__content--header alignVertical">
                    <p>{{ $t('package_buy.title') }}</p>
                    <a v-on:click="$parent.modal_package_buy = false" ref="btn-close">
                        <img src="assets/icons/modals/x-black.svg" alt="">
                    </a>
                </div>
                <div class="modals__content--body flex" v-if="payment_status == 'success'">
                    <div class="col-sm-12 col-12">
                        <div class="payment-status">
                            <img src="assets/icons/modals/check-payment.svg" alt="" />
                            <h4>Pedido Gerado!</h4>
                            <p>Número do pedido: {{ this.request_number }}</p>
                            <p>Acompanhe o status do pedido no <span class="click-profile" v-on:click="goProfile()">perfil</span></p>
                        </div>
                    </div>
                </div>
                <form v-else class="modals__content--body flex flex-wrap" id="package-buy-form">
                    <div class="col-sm-12 col-12">
                        <div class="credit-card-icon">
                            <svg width="60" height="50" viewBox="0 0 60 50" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M54.0469 13.0469V7H5.95312V13.0469H54.0469ZM54.0469 43V25H5.95312V43H54.0469ZM54.0469 0.953125C55.7344 0.953125 57.1406 1.5625 58.2656 2.78125C59.3906 3.90625 59.9531 5.3125 59.9531 7V43C59.9531 44.6875 59.3906 46.1406 58.2656 47.3594C57.1406 48.4844 55.7344 49.0469 54.0469 49.0469H5.95312C4.26562 49.0469 2.85938 48.4844 1.73438 47.3594C0.609375 46.1406 0.046875 44.6875 0.046875 43V7C0.046875 5.3125 0.609375 3.90625 1.73438 2.78125C2.85938 1.5625 4.26562 0.953125 5.95312 0.953125H54.0469Z"
                                    fill="#33BCEE" />
                            </svg>
                            <span>Cartão de Crédito</span>
                        </div>
                    </div>
                    <input name="cc_hash" ref="cc_hash" type="hidden">
                    <div class="col-sm-12 col-9">
                        <label for="name">{{ $t('package_buy.name') }}</label>
                        <input class="text" maxlength="60" name="name" :placeholder="$t('package_buy.name')" ref="name"
                            type="text">

                    </div>
                    <div class="col-sm-12 col-3">
                        <label for="exp">{{ $t('package_buy.exp') }}</label>
                        <input class="text" maxlength="7" name="exp" :placeholder="$t('package_buy.exp')" ref="exp"
                            type="text" v-on:keyup="maskDate">
                    </div>

                    <div class="col-sm-12 col-9">
                        <label for="cc_number">{{ $t('package_buy.cc_number') }}</label>
                        <input class="text" maxlength="19" name="cc_number" :placeholder="$t('package_buy.cc_number')"
                            ref="cc_number" type="text" v-on:keyup="maskCC">
                    </div>

                    <div class="col-sm-12 col-3">
                        <label for="security_code">{{ $t('package_buy.security_code') }}</label>
                        <input class="text" maxlength="3" name="security_code"
                            :placeholder="$t('package_buy.security_code')" ref="security_code" type="text"
                            v-on:keyup="maskNumber">
                    </div>

                    <div style="margin-left:auto;margin-right:auto;margin-top:20px;">
                        <button class="button__secondary col-12" type="button" v-on:click="pay">{{
                            $t('package_buy.pay')
                        }}</button>
                    </div>
                </form>
                <div class="modals__content--footer flex">
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import axios from 'axios';
import { mapActions, mapGetters } from "vuex";
export default
    {
        name: 'PackageBuy',
        data() {
            return {
                cc:
                {
                    /*
                    Nas vendas pela internet, o PagSeguro aceita as principais bandeiras: 
                    American Express, 
                    Aura, 
                    Banese Card, 
                    Cabal, 
                    Diners, 
                    Elo, 
                    FortBrasil, 
                    Grand Card, 
                    Hiper, 
                    Hipercard, 
                    Mais, 
                    Mastercard,
                    Personal Cardo, 
                    Soro Cred, 
                    Vale Card, 
                    Up Brasil, 
                    Visa
                    Vólus.
                    */

                    Amex: /^3[47][0-9]{13}$/,//ok
                    Aura: /^((?!504175))^((?!5067))(^50[0-9])/,//ok
                    BaneseCard: '^636117',
                    Cabal: '(60420[1-9]|6042[1-9][0-9]|6043[0-9]{2}|604400)',//ok
                    DinersClub: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,//ok
                    Discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,//ok
                    Elo: /^4011(78|79)|^43(1274|8935)|^45(1416|7393|763(1|2))|^50(4175|6699|67[0-6][0-9]|677[0-8]|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9])|^627780|^63(6297|6368|6369)|^65(0(0(3([1-3]|[5-9])|4([0-9])|5[0-1])|4(0[5-9]|[1-3][0-9]|8[5-9]|9[0-9])|5([0-2][0-9]|3[0-8]|4[1-9]|[5-8][0-9]|9[0-8])|7(0[0-9]|1[0-8]|2[0-7])|9(0[1-9]|[1-6][0-9]|7[0-8]))|16(5[2-9]|[6-7][0-9])|50(0[0-9]|1[0-9]|2[1-9]|[3-4][0-9]|5[0-8]))/,//ok
                    FortBrasil: '^628167',
                    GrandCard: '^605032',
                    Hipercard: /^606282|^3841(?:[0|4|6]{1})0/,//ok
                    JCB: /^(?:2131|1800|35\d{3})\d{11}/,//ok
                    Mastercard: /^((5(([1-2]|[4-5])[0-9]{8}|0((1|6)([0-9]{7}))|3(0(4((0|[2-9])[0-9]{5})|([0-3]|[5-9])[0-9]{6})|[1-9][0-9]{7})))|((508116)\\d{4,10})|((502121)\\d{4,10})|((589916)\\d{4,10})|(2[0-9]{15})|(67[0-9]{14})|(506387)\\d{4,10})/,//ok
                    // Mastercard: /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/,
                    PersonalCard: '^636085',
                    Sorocred: '^627892|^636414',//ok
                    Valecard: '^606444|^606458|^606482',
                    Visa: /^4[0-9]{15}$/,//ok
                    
                    /*
                    Amex: ^3[47][0-9]{13}$
                    Aura: /^((?!504175))^((?!5067))(^50[0-9])/
                    Banese Card: '^636117'
                    Cabal: '(60420[1-9]|6042[1-9][0-9]|6043[0-9]{2}|604400)'
                    Diners: '(36[0-8][0-9]{3}|369[0-8][0-9]{2}|3699[0-8][0-9]|36999[0-9])
                    Discover: /^6(?:011|5[0-9]{2})[0-9]{12}/
                    Elo: /^4011(78|79)|^43(1274|8935)|^45(1416|7393|763(1|2))|^50(4175|6699|67[0-6][0-9]|677[0-8]|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9])|^627780|^63(6297|6368|6369)|^65(0(0(3([1-3]|[5-9])|4([0-9])|5[0-1])|4(0[5-9]|[1-3][0-9]|8[5-9]|9[0-9])|5([0-2][0-9]|3[0-8]|4[1-9]|[5-8][0-9]|9[0-8])|7(0[0-9]|1[0-8]|2[0-7])|9(0[1-9]|[1-6][0-9]|7[0-8]))|16(5[2-9]|[6-7][0-9])|50(0[0-9]|1[0-9]|2[1-9]|[3-4][0-9]|5[0-8]))/
                    Fort Brasil: '^628167'
                    GrandCard: '^605032'
                    Hipercard: ^606282|^3841(?:[0|4|6]{1})0
                    JCB: /^(?:2131|1800|35\d{3})\d{11}/
                    Mastercard: ^((5(([1-2]|[4-5])[0-9]{8}|0((1|6)([0-9]{7}))|3(0(4((0|[2-9])[0-9]{5})|([0-3]|[5-9])[0-9]{6})|[1-9][0-9]{7})))|((508116)\\d{4,10})|((502121)\\d{4,10})|((589916)\\d{4,10})|(2[0-9]{15})|(67[0-9]{14})|(506387)\\d{4,10})
                    Personal Card: '^636085'
                    Sorocred: '^627892|^636414'
                    Valecard: '^606444|^606458|^606482'
                    Visa: ^4[0-9]{15}$

                    Segundo o gist do @erikhenrique está faltando no Elo o 506699 e 636369
                    https://gist.github.com/erikhenrique/5931368
                    */

                    /*
                    Visa: /^4[0-9]{12}(?:[0-9]{3})/,
                    Mastercard: /^5[1-5][0-9]{14}/,
                    Amex: /^3[47][0-9]{13}/,
                    DinersClub: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
                    Discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
                    JCB: /^(?:2131|1800|35\d{3})\d{11}/
                    */
                },
                packages: null,
                payment_status: null,
                request_number: null
            }
        },
        computed: {
            ...mapGetters(['token', 'package']),
        },
        components: {},
        methods:
        {
            ...mapActions([]),
            check_cc(cc_number)
            {
                // for (let cartao in cartoes) if (cc_number.match(this.cc[cartao])) return cartao;
                for (let c in this.cc) if (cc_number.match(this.cc[c])) return c;
                return false;
            },
            check(e) {
                if ((e.key !== "Backspace") || (e.key !== "ArrowLeft") || (e.key !== "ArrowRight") || (e.key !== "Tab") || (e.key !== "Delete")) {
                    if (!/^[0-9]*$/.test(e.key)) {
                        e.preventDefault();
                        return false;
                    }
                }
            },
            justNumber(v) {
                return String(v).replace(/\D/g, '');
            },
            maskNumber(e) {
                if (e) {
                    this.check(e);
                    e.currentTarget.value = this.justNumber(e.currentTarget.value);
                }
            },
            maskDate(e) {
                if (e) {
                    this.check(e);
                    let x = this.justNumber(e.currentTarget.value).match(/(\d{0,2})(\d{0,4})/);
                    e.currentTarget.value = !x[2] ? x[1] : x[1] + '/' + x[2];
                }
            },
            maskMoney(e) {
                let value = e.currentTarget.value;
                let v = this.justNumber(value);
                v = (v / 100).toFixed(2);
                v = v.replace(".", ",");
                v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
                v = v.replace(/(\d)(\d{3}),/g, "$1.$2,");
                e.currentTarget.value = v;
            },
            maskCC(e) {
                if (e) {
                    this.check(e);
                    let v = this.justNumber(e.currentTarget.value).match(/(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})/);
                    e.currentTarget.value = !v[2] ? v[1] : v[1] + ' ' + v[2] + (v[3] ? ' ' + v[3] : '') + (v[4] ? ' ' + v[4] : '');
                }
            },
            getPublicKey() {
                let headers = { 'Content-Type': 'application/json;charset=UTF-8', 'Access': 'application/json', language: this.$i18n.locale, Authorization: `Bearer ${this.token}` };
                let form_data = new FormData();
                axios.post(this.$url_api + "api/package/publickey", form_data, { headers: headers }).then((response) => this.getPublicKeyResponse(response)).catch((error) => this.getPublicKeyError(error.response));
            },
            getPublicKeyResponse(obj) {
                if (obj.data.success == false) {
                    alert(obj.data.errors.join('\n'));
                    this.$parent.modal_package_buy = false;
                }
                else {
                    if (obj.data == '') {
                        alert('Não é possível fazer pagamentos nesse momento. Tente novamente em alguns minutos.');
                        this.$parent.modal_package_buy = false;
                    }
                    else {
                        this.$refs['cc_hash'].value = obj.data.public_key;
                    }

                }
                obj;
                this.$parent.wait(false);
            },
            getPublicKeyError(obj) {
                obj;
                this.$parent.wait(false);
            },
            pay(e) {
                e.preventDefault();
                if (this.check_cc(this.justNumber(this.$refs['cc_number'].value)) === false)
                {
                    alert('Número do cartão inválido.');
                }
                else
                {
                    let exp_ = this.$refs['exp'].value.split("/");
                    let root_ = this;

                    let card = window.PagSeguro.encryptCard({
                        publicKey: root_.$refs['cc_hash'].value,
                        holder: root_.$refs['name'].value,
                        number: root_.justNumber(root_.$refs['cc_number'].value),
                        expMonth: exp_[0],
                        expYear: exp_[1],
                        securityCode: root_.$refs['security_code'].value
                    });

                    let encrypted = card.encryptedCard;

                    if (encrypted) {
                        this.$parent.wait(true);

                        let headers = { 'Content-Type': 'application/json;charset=UTF-8', 'Access': 'application/json', language: this.$i18n.locale, Authorization: `Bearer ${this.token}` };
                        let form_data = new FormData();
                        form_data.append('package_id', this.package.id);
                        form_data.append('type', 1);//1 = cartão de crédito, 2 = pix
                        form_data.append('payment_type', this.package.type);//0 = normal, 1 = recurrent
                        form_data.append('hash_cc', encrypted);
                        axios.post(this.$url_api + "api/package/pay", form_data, { headers: headers }).then((response) => this.payResponse(response)).catch((error) => this.payError(error.response));
                    }
                    else {
                        alert('Dados do cartão inválido, verifique!');
                    }
                }
            },
            payResponse(obj) {
                if ((obj.data.success == true) && (obj.data.p.id)) {
                    //this.$parent.modal_package_buy = false;
                    //alert('Compra realizada com sucesso.');
                    this.payment_status = 'success';
                    this.request_number = obj.data.package_payment;
                }
                else if (obj.data.success == false) {
                    alert(obj.data.errors.join('\n'));
                }
                this.$parent.wait(false);
            },
            payError(obj) {
                if (obj.status == 500)
                {
                    /*
                    "Client error: `POST https://sandbox.api.pagseguro.com/charges` resulted in a `400 Bad Request` response:
                    {"error_messages":[{"code":"40002","message":"BRAND_NOT_FOUND","description":"invalid_parameter","parameter_name":"payme (truncated...)
                    "
                    */
                   alert('Não foi possível efetuar o pagamento pois alguns dados estão incorretos. Por favor, revise os dados e tente novamente.');
                }
                this.$parent.wait(false);
            },
            goProfile() {
                this.$parent.modal_package_buy = false;
                this.$parent.link('Profile');
            }
        },
        mounted() {
            this.$parent.wait(true);

            let root_ = this;
            let pagseguro_js = document.createElement("script");
            pagseguro_js.onload = function () {
                root_.getPublicKey();
            };
            pagseguro_js.setAttribute("id", "pagseguro_security");
            pagseguro_js.setAttribute("src", "https://assets.pagseguro.com.br/checkout-sdk-js/rc/dist/browser/pagseguro.min.js");
            if (document.getElementById('pagseguro_security')) {
                document.getElementById('pagseguro_security').remove();
            }
            document.head.appendChild(pagseguro_js);
        }
    }
</script>

<style scoped>

#package-buy-form ul,
#package-buy-form li {
    margin: 0;
    padding: 0;
    list-style: none;
}

#package-buy-form>ul>li {
    box-sizing: border-box;
    display: block;
    padding: 10px;
    width: 100%;
}

#package-buy-form>ul>li>label {
    display: block;
}

#package-buy-form>ul>li>label {}

#package-buy-form>ul>li>ul>li {
    box-sizing: border-box;
    display: block;
    padding: 5px 0;
}

#package-buy-form>ul>li>ul>li>img {
    max-width: 150px;
}
</style>
